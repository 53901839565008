import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import InstagramPostVM from "../types/instagramPost";

interface SocialState {
  instagramPosts: InstagramPostVM[];
  status: "idle" | "loading" | "failed";
}

const initialState: SocialState = {
  instagramPosts: [],
  status: "idle",
};

export const getInstagramPosts = createAsyncThunk<InstagramPostVM[]>("social/get", async () => {
  const response = await http.get<InstagramPostVM[]>("/social/instagram");
  return response.data;
});

const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInstagramPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInstagramPosts.fulfilled, (state, action) => {
        state.status = "idle";
        state.instagramPosts = action.payload;
      })
      .addCase(getInstagramPosts.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default socialSlice.reducer;
