import { createSlice } from "@reduxjs/toolkit";

interface AdminState {
  menuOpen: boolean;
}

const initialState: AdminState = {
  menuOpen: true,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    handleMenuState: (state, action) => {
      state.menuOpen = action.payload;
    },
  },
  extraReducers: () => {},
});

export default adminSlice.reducer;
export const { handleMenuState } = adminSlice.actions;
