import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "../../../store";
import HistoryAnswerView from "./HistoryAnswerView";
import HistoryPetsView from "./HistoryPetsView";

interface Props {}

const PetInfoView: React.FC<Props> = (props) => {
  const { adminApplication } = useSelector((store) => store.applications);

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"baseline"} mb={3}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              Current Dogs: {adminApplication?.numberOfDogs}
            </Typography>
          </Grid>
          <HistoryPetsView adminApplication={adminApplication!} dog />
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              Current Cats: {adminApplication?.numberOfCats}
            </Typography>
          </Grid>
          <HistoryPetsView adminApplication={adminApplication!} cat />
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              Current Other Pets: {adminApplication?.numberOfOtherPets}
            </Typography>
          </Grid>
          <HistoryPetsView adminApplication={adminApplication!} other />
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              Number of Pets In the Past 15 Years: {adminApplication?.numberOfPastPets}
            </Typography>
          </Grid>
          <HistoryPetsView adminApplication={adminApplication!} prev />
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.GivenUpPet}
              answerString={adminApplication?.givenUpPet ? "Yes" : "No"}
              questionText="Have You Ever Given Up a Pet?"
            />
          </Grid>
          {adminApplication?.givenUpPet === true && (
            <Grid item md={6} xs={12}>
              <HistoryAnswerView
                historyArray={adminApplication?.history?.ReasonForGivingUp}
                answerString={adminApplication.reasonForGivingUp}
                questionText="Reason for Giving Up"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PetInfoView;
