import React from "react";
import { Box } from "@mui/material";
import EventsView from "../views/EventsView";

interface Props {}

const EventsMarketingPage: React.FC<Props> = (props) => {
  return (
    <Box>
      <EventsView />
    </Box>
  );
};

export default EventsMarketingPage;
