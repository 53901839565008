import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import getSearchQueryString from "../helpers/getSearchQueryString";
import SearchParams from "../types/searchParams";
import SearchResults from "../types/searchResults";
import Adoption from "../types/adoption";

interface AdoptionsState {
  status: "idle" | "loading" | "failed";
  adoption: Adoption;
  adoptionSearchResults?: SearchResults;
}

const initialState: AdoptionsState = {
  status: "idle",
  adoption: {
    id: 0,
    dog: undefined,
    adopter: undefined,
    adoptionDate: new Date(),
    applicationId: undefined,
    feeReceived: false,
    contractSigned: false,
    paymentDetails: {
      id: 0,
      amount: undefined,
      donation: 0,
      paymentMethod: undefined,
      checkNumber: undefined,
    },
    application: undefined,
  },
};

export const saveAdoption = createAsyncThunk<Adoption, Adoption>("/adoptions/post", async (adoption: Adoption) => {
  const response = await http.post<Adoption>(`/adoptions`, adoption);
  return response.data;
});

export const getAdoption = createAsyncThunk<Adoption, number>("/adoptions/get/id", async (id: number) => {
  const response = await http.get<Adoption>(`/adoptions/${id}`);
  return response.data;
});

export const editAdoption = createAsyncThunk<Adoption, Adoption>("adoptions/edit", async (adoption: Adoption) => {
  const response = await http.put<Adoption>(`/adoptions/${adoption.id}`, adoption);
  return response.data;
});

export const deleteAdoption = createAsyncThunk<Adoption, number>("adoptions/delete", async (id: number) => {
  const response = await http.delete<Adoption>(`/adoptions/${id}`);
  return response.data;
});

export const getAdoptionsSearch = createAsyncThunk<
  SearchResults,
  {
    search: SearchParams;
    startDate?: Date;
    endDate?: Date;
  }
>("adoptions/search", async ({ search, startDate, endDate }) => {
  let qs = getSearchQueryString(search);
  let date = "";
  function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  date += !!startDate && isValidDate(startDate) ? `&startDate=${startDate.toUTCString()}` : "";
  date += !!endDate && isValidDate(endDate) ? `&endDate=${endDate.toUTCString()}` : "";
  const response = await http.get<SearchResults>(`/adoptions/search?${qs}${date}`);
  return response.data;
});

const adoptionsSlice = createSlice({
  name: "adoptions",
  initialState,
  reducers: {
    clearAdoption(state) {
      state.adoption = { ...initialState.adoption };
    },
    clearAdoptionSearch(state) {
      state.adoptionSearchResults = initialState.adoptionSearchResults;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAdoption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveAdoption.fulfilled, (state, action) => {
        state.status = "idle";
        state.adoption = action.payload;
      })
      .addCase(saveAdoption.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getAdoption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdoption.fulfilled, (state, action) => {
        state.status = "idle";
        state.adoption = action.payload;
      })
      .addCase(getAdoption.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(editAdoption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editAdoption.fulfilled, (state, action) => {
        state.status = "idle";
        state.adoption = action.payload;
      })
      .addCase(editAdoption.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(deleteAdoption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAdoption.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(deleteAdoption.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getAdoptionsSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdoptionsSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.adoptionSearchResults = action.payload;
      })
      .addCase(getAdoptionsSearch.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default adoptionsSlice.reducer;
export const { clearAdoption, clearAdoptionSearch } = adoptionsSlice.actions;
