import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { ReactElement } from "react";
import { useSelector } from "../../store";
import theme from "../../theme";
import { TabProps } from "./Tab";

interface Props {
  children: Array<ReactElement<TabProps>>;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  dirty?: boolean;
  setWarningModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setNextTab?: React.Dispatch<React.SetStateAction<number>>;
}

const Tabs: React.FC<Props> = (props) => {
  const { children, activeTab, setActiveTab, dirty, setWarningModal, setNextTab } = props;
  const { menuOpen } = useSelector((store) => store.admin);

  const handleTabChange = (tabNumber: number) => {
    if (!!dirty) {
      if (setWarningModal) {
        setWarningModal(true);
      }
      if (setNextTab) {
        setNextTab(tabNumber);
      }
    } else {
      setActiveTab(tabNumber);
    }
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            marginX: { xl: 10, md: menuOpen ? 0 : 10, xs: 0 },
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          {children.map((step, index) => {
            return (
              !step.props.hideTab && (
                <Box
                  key={index}
                  sx={{
                    flex: 1,
                    background: {
                      md:
                        index + 1 === children.length
                          ? "#ffffff00"
                          : activeTab === index + 2
                          ? theme.palette.primary.main
                          : theme.palette.success.main,
                      xs: "#ffffff00",
                    },
                    zIndex: index + 1 === activeTab ? 300 : Math.round(200 / (index + 1)),
                  }}
                >
                  <Box
                    key={step.props.label}
                    className={index + 1 === activeTab ? "active" : ""}
                    onClick={() => {
                      handleTabChange(index + 1);
                    }}
                    sx={{
                      background: index + 1 === activeTab ? theme.palette.primary.main : theme.palette.success.main,
                      textAlign: "center",
                      padding: "15px",
                      boxShadow: {
                        md:
                          activeTab === index + 2
                            ? `-3px 2px 5px ${theme.palette.info.main}`
                            : `10px 2px 5px ${theme.palette.info.main}`,
                        xs: `10px 5px 5px ${theme.palette.info.main}`,
                      },
                      borderTopRightRadius: "25px",
                      borderTopLeftRadius: { md: "0px", xs: "25px" },
                      color: index + 1 === activeTab ? theme.palette.primary.contrastText : theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                      {step.props.label}
                    </Typography>
                  </Box>
                </Box>
              )
            );
          })}
        </Box>
        <Box
          sx={{
            borderBottom: `2px solid ${theme.palette.success.main}`,
          }}
        />
      </Container>
      <Box
        sx={{
          marginY: "40px",
          paddingX: "30px",
          paddingY: "10px",
        }}
      >
        {children[activeTab - 1]?.props?.children ? children[activeTab - 1].props.children : null}
      </Box>
    </>
  );
};

export default Tabs;
