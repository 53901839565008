import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { editEvent, getEvent, postEvent } from "../../../slices/events";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import EventVM from "../../../types/event";
import { Box, Container, Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import ActionButton from "../../base/ActionButton";
import { Inputs } from "../../forms";
import { getSourceTypes, getStatesSearch } from "../../../slices/options";
import { createVet, editVet, getVet } from "../../../slices/vet";
import Vet from "../../../types/vet";
import getEmailRegex from "../../../helpers/emailRegex";
import { createSource, editSource, getSource } from "../../../slices/sources";
import DogSource from "../../../types/dogSource";
import SourceDetailView from "./SourceDetailView";
import KeyValue from "../../../types/keyValue";
import Address from "../../../types/address";

interface Props {}

const EditCreateSource: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { source, status } = useSelector((store) => store.sources);
  const [loading, setLoading] = useState(true);
  const editMode = window.location.href.includes("?edit=true");
  const { id } = useParams();

  useEffect(() => {
    if (source?.id !== +id!) {
      dispatch(getSource(+id!));
    }
  }, [dispatch, id, source?.id]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required("Required"),
    license: Yup.string().nullable(),
    originType: Yup.object().nullable(),
    address: Yup.object().shape({
      line1: Yup.string().nullable(),
      line2: Yup.string().nullable(),
      city: Yup.string().when("line1", {
        is: (val: string) => !!val,
        then: (schema) => schema.required("Required"),
      }),
      state: Yup.object().when("line1", {
        is: (val: string) => !!val,
        then: (schema) => schema.required("Required"),
      }),
      postalCode: Yup.string().when("line1", {
        is: (val: string) => !!val,
        then: (schema) => schema.required("Required"),
      }),
    }),
    website: Yup.string().nullable(),
    primaryContactName: Yup.string().nullable(),
    primaryContactTitle: Yup.string().nullable(),
    primaryContactPhone: Yup.string().min(14, "Must be a valid phone number").nullable().nullable(),
    primaryContactEmail: Yup.string()
      .email("Must be a valid email")
      .matches(getEmailRegex(), "Must be a valid email")
      .nullable(),
    secondaryContactName: Yup.string().nullable(),
    secondaryContactTitle: Yup.string().nullable(),
    secondaryContactPhone: Yup.string().min(14, "Must be a valid phone number").nullable().nullable(),
    secondaryContactEmail: Yup.string()
      .email("Must be a valid email")
      .matches(getEmailRegex(), "Must be a valid email")
      .nullable(),
  });

  const handleSubmit = async (values: DogSource) => {
    if (source?.id === 0) {
      await dispatch(
        createSource({
          ...values,
          address:
            values.address &&
            values.address.line1 &&
            values.address.city &&
            values.address.state &&
            values.address.postalCode
              ? {
                  line1: values.address.line1,
                  line2: values.address.line2 ? values.address.line2 : "",
                  city: values.address.city,
                  state: values.address.state,
                  postalCode: values.address.postalCode,
                }
              : undefined,
        })
      ).then((result: any) => {
        if (result && result.payload) {
          dispatch(getSource(result.payload.id));
          navigate(`/admin/sources`);
        }
      });
    } else if (source?.id! > 0) {
      await dispatch(editSource(values));
      setLoading(true);
      navigate(`/admin/sources`);
      setLoading(false);
    }
  };

  const handleCancelBack = async () => {
    if (source?.id! > 0) {
      navigate(`/admin/sources/${source.id}`);
    } else {
      navigate("/admin/sources");
    }
  };

  return (
    <>
      <Container>
        {!!editMode ? (
          <Formik
            enableReinitialize={loading}
            initialValues={source!}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form noValidate>
                {editMode ? (
                  <>
                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems={"center"}>
                      <Grid item xs={12} sx={{ margin: "15px" }}>
                        <Typography>Source Information</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="name" label="Name" required component={Inputs.Text} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="license" label="Kennel License" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field
                          component={Inputs.OptionDropdown}
                          name="originType"
                          label="Source Type"
                          searchFunction={getSourceTypes}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="website" label="Website" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <Field name="address.line1" label="Address Line 1" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field name="address.line2" label="Address Line 2" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field name="address.city" label="City" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field
                          name="address.state"
                          label="State"
                          component={Inputs.OptionDropdown}
                          searchFunction={getStatesSearch}
                          filterForm={{ pageSize: 50 }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Field name="address.postalCode" label="Zip Code" component={Inputs.Text} />
                      </Grid>
                      <Grid item xs={12} sx={{ margin: "15px" }}>
                        <Typography>Primary Contact</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="primaryContactName" label="Name" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="primaryContactTitle" label="Title" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="primaryContactPhoneNumber" label="Phone" component={Inputs.Phone} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="primaryContactEmail" label="Email" component={Inputs.Text} />
                      </Grid>
                      <Grid item xs={12} sx={{ margin: "15px" }}>
                        <Typography>Secondary Contact</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="secondaryContactName" label="Name" component={Inputs.Text} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="secondaryContactTitle" label="Title" component={Inputs.Text} />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <Field name="secondaryContactPhoneNumber" label="Phone" component={Inputs.Phone} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field name="secondaryContactEmail" label="Email" component={Inputs.Text} />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 2,
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: theme.palette.primary.contrastText,
                    paddingY: 2,
                    zIndex: 1000,
                  }}
                >
                  <ActionButton
                    text={editMode ? "Cancel" : "Back"}
                    onClick={handleCancelBack}
                    color="secondary"
                    type="button"
                  />
                  {editMode ? (
                    <Inputs.Submit isSubmitting={status === "loading"} text="Save" color="primary" />
                  ) : (
                    <ActionButton
                      text="Edit"
                      onClick={() => {
                        navigate(`/admin/sources/${source?.id}?edit=true`);
                      }}
                      color="primary"
                      type="button"
                    />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <SourceDetailView id={+id!} />
        )}
      </Container>
    </>
  );
};

export default EditCreateSource;
