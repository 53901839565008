import React from "react";
import AboutUsView from "../views/AboutUsView";
import { Box } from "@mui/material";

interface Props {}

const AboutUsPage: React.FC<Props> = (props) => {
  return (
    <Box sx={{ marginTop: "24px" }}>
      <AboutUsView />
    </Box>
  );
};

export default AboutUsPage;
