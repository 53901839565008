import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import FileVM from "../types/fileVM";
import SearchParams from "../types/searchParams";
import SearchResults from "../types/searchResults";
import getSearchQueryString from "../helpers/getSearchQueryString";
import { AxiosProgressEvent } from "axios";
import MergedDocument from "../types/mergedDocument";
import { Email } from "@mui/icons-material";

interface FileState {
  status: "idle" | "loading" | "failed";
  downloadStatus: "idle" | "loading" | "failed";
  newFile: FileVM;
  fileSearch: FileVM[];
  fileSearchResults?: SearchResults;
  uploadComplete?: number;
  videoPlaying?: { id: number; playing: boolean };
  homeVideo?: FileVM;
  documentFilter: SearchParams;
  failedMergedDocuments?: string[];
}

const initialState: FileState = {
  status: "idle",
  downloadStatus: "idle",
  newFile: {
    id: 0,
    fileType: undefined,
    url: "",
    entityId: 0,
  },
  fileSearch: [],
  documentFilter: {
    query: "",
    filters: ["type:4"],
    pageNumber: 1,
    pageSize: 10,
    orderBy: "name",
    orderDirection: "Ascending",
  },
};

const buildFormData = (formData: any, data: any, parentKey?: any) => {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null || data === undefined ? null : data;

    if (!!data) {
      formData.append(parentKey, value);
    }
  }

  return formData;
};

const jsonToFormData = (data: any) => {
  const formData = new FormData();
  const newData = buildFormData(formData, data);
  return newData;
};

export const postFile = createAsyncThunk<number, FileVM>("file/post", async (file: FileVM, { dispatch }) => {
  var form = jsonToFormData(file);

  if (file.upload) {
    if (file.upload.type.split("/")[0] === "video") {
      form.append("upload", file.upload.text());
    } else {
      form.append("upload", file.upload);
    }
  }

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
      dispatch(setUploadPercent(percentCompleted));
    },
  };

  const response = await http.post(`/files`, form, config);
  return response.data;
});

export const putFile = createAsyncThunk<FileVM, FileVM>("/files/put", async (file: FileVM) => {
  const response = await http.put<FileVM>(`/files/${file.id}`, file);
  return response.data;
});

export const deleteFile = createAsyncThunk<FileVM, number>("files/delete", async (id: number) => {
  const response = await http.delete<FileVM>(`/files/${id}`);
  return response.data;
});

export const orderFiles = createAsyncThunk<null, FileVM[]>("/files/order", async (files: FileVM[]) => {
  const response = await http.put(`/files/ordering`, files);
  return response.data;
});

export const getFilesById = createAsyncThunk<FileVM[], { entityId: number; typeId: number }>(
  "files/get-all",
  async ({ entityId, typeId }) => {
    const response = await http.get<FileVM[]>(`files?entityId=${entityId}&typeId=${typeId}`);
    return response.data;
  }
);

export const getOneFileById = createAsyncThunk<FileVM, number>("files/get/id", async (id: number) => {
  const response = await http.get<FileVM>(`/files/${id}`);
  return response.data;
});

export const searchFiles = createAsyncThunk<SearchResults, SearchParams>("files/search", async (search) => {
  let qs = getSearchQueryString(search);
  const response = await http.get<SearchResults>(`/files/search?${qs}`);
  return response.data;
});

export const emailFile = createAsyncThunk<null, { fileId: number; email?: string }>(
  "files/email",
  async ({ fileId, email }) => {
    return await http.patch(`/files/email?fileId=${fileId}`, email);
  }
);

export const emailMergedFile = createAsyncThunk<null, { fileIds: string[]; email?: string }>(
  "files/email-merge",
  async ({ fileIds, email }) => {
    var fileVm = {
      Email: email,
      FileIds: fileIds,
    };
    return await http.post(`/files/email-merge`, fileVm);
  }
);

export const combineDocuments = createAsyncThunk<MergedDocument, SearchParams>(
  "files/combine",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);

    let date = "";

    function isValidDate(d: any) {
      return d instanceof Date && !isNaN(d.getTime());
    }

    date +=
      !!search.intakeStart && isValidDate(search.intakeStart)
        ? `&intakeStartDate=${search.intakeStart.toUTCString()}`
        : "";
    date +=
      !!search.intakeEnd && isValidDate(search.intakeEnd) ? `&intakeEndDate=${search.intakeEnd.toUTCString()}` : "";
    date +=
      !!search.statusStart && isValidDate(search.statusStart)
        ? `&statusStartDate=${search.statusStart.toUTCString()}`
        : "";
    date +=
      !!search.statusEnd && isValidDate(search.statusEnd) ? `&statusEndDate=${search.statusEnd.toUTCString()}` : "";

    const response = await http.get<MergedDocument>(`/dog-documents?${qs}${date}`);

    const link = document.createElement("a");
    link.href = response.data.url;
    link.download = "document-merge.pdf";
    link.click();
    link.remove();

    return response.data;
  }
);

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setUploadPercent: (state, action) => {
      state.uploadComplete = action.payload;
    },
    startVideo: (state, action) => {
      state.videoPlaying = {
        id: action.payload,
        playing: true,
      };
    },
    stopVideo: (state, action) => {
      state.videoPlaying = {
        id: action.payload,
        playing: false,
      };
    },
    resetFile: (state) => {
      state.newFile = { ...initialState.newFile };
    },
    setDocumentFilter(state, action) {
      state.documentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postFile.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(postFile.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(putFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putFile.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(putFile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFile.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(deleteFile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getOneFileById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOneFileById.fulfilled, (state, action) => {
        state.status = "idle";
        state.newFile = action.payload;
      })
      .addCase(getOneFileById.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getFilesById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilesById.fulfilled, (state, action) => {
        state.status = "idle";
        state.fileSearch = action.payload;
      })
      .addCase(getFilesById.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(searchFiles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchFiles.fulfilled, (state, action) => {
        state.status = "idle";
        state.fileSearchResults = action.payload;
      })
      .addCase(searchFiles.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(emailFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(emailFile.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(emailFile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(emailMergedFile.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(emailMergedFile.fulfilled, (state, action) => {
        state.downloadStatus = "idle";
      })
      .addCase(emailMergedFile.rejected, (state) => {
        state.downloadStatus = "failed";
      })
      .addCase(combineDocuments.pending, (state) => {
        state.downloadStatus = "loading";
      })
      .addCase(combineDocuments.fulfilled, (state, action) => {
        state.downloadStatus = "idle";
        state.failedMergedDocuments = action.payload.failedFiles;
      })
      .addCase(combineDocuments.rejected, (state) => {
        state.downloadStatus = "failed";
      });
  },
});

export default fileSlice.reducer;
export const { setUploadPercent, startVideo, stopVideo, resetFile, setDocumentFilter } = fileSlice.actions;
