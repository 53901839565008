import React from "react";
import Application from "../../../../types/application";
import ReactPDF, { Text, View } from "@react-pdf/renderer";

interface Props {
  application?: Application;
  styles: ReactPDF.Styles;
}

const PreferencesPage: React.FC<Props> = (props) => {
  const { application, styles } = props;
  return (
    <View style={styles.page}>
      <View>
        <Text style={styles.header}>Preferences</Text>
      </View>
      <View>
        <Text style={styles.mapQuestionText}>Which Of These Behaviors Are You Willing To Tolerate?</Text>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Barking (Excessive):</Text>
          <Text style={styles.answerText}>{application?.acceptBarking ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Chewing (Inappropriate):</Text>
          <Text style={styles.answerText}>{application?.acceptChewing ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Digging:</Text>
          <Text style={styles.answerText}>{application?.acceptDigging ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Needs House Training:</Text>
          <Text style={styles.answerText}>{application?.acceptHouseTraining ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Lack of Social Skills:</Text>
          <Text style={styles.answerText}>{application?.acceptSocial ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Separation Anxiety:</Text>
          <Text style={styles.answerText}>{application?.acceptSeparation ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Other:</Text>
          <Text style={styles.answerText}>{application?.acceptOther ? "Yes" : "No"}</Text>
        </View>
        {application?.acceptOther && (
          <View style={styles.separator}>
            <Text style={styles.smallerQuestionText}>Enter Other:</Text>
            <Text style={styles.answerText}>{application?.otherText}</Text>
          </View>
        )}
      </View>
      <View style={{ display: "flex", flexDirection: "column", marginBottom: "25px" }}>
        <View>
          <Text style={styles.mapQuestionText}>How Will You Address These Behaviors?</Text>
        </View>
        <View style={{ display: "flex", flexDirection: "column" }}>
          {application?.addressBehaviors?.map((address) => {
            return (
              <View key={address.id}>
                <Text style={styles.basicMapText}>{address.value}</Text>
              </View>
            );
          })}
        </View>
      </View>
      <View>
        <Text style={styles.mapQuestionText}>Would You Consider Adopting a Dog That:</Text>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Is Mixed Breed:</Text>
          <Text style={styles.answerText}>{application?.considerMixedBreed ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Needs to Be the Only Pet:</Text>
          <Text style={styles.answerText}>{application?.considerOnlyPet ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Is Afraid of People:</Text>
          <Text style={styles.answerText}>{application?.considerAfraid ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Is On Meds:</Text>
          <Text style={styles.answerText}>{application?.considerOnMeds ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Has a Health Condition:</Text>
          <Text style={styles.answerText}>{application?.considerHealthCondition ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Needs Training:</Text>
          <Text style={styles.answerText}>{application?.considerUntrained ? "Yes" : "No"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Is Special Needs :</Text>
          <Text style={styles.answerText}>{application?.considerSpecialNeeds ? "Yes" : "No"}</Text>
        </View>
      </View>

      <View>
        <Text style={styles.mapQuestionText}>New Dog Preferences</Text>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Gender:</Text>
          <Text style={styles.answerText}>
            {application?.genderPreference ? application?.genderPreference.value : "No Preference"}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Age:</Text>
          <Text style={styles.answerText}>
            {application?.agePreference ? application?.agePreference.value : "No Preference"}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.smallerQuestionText}>Size:</Text>
          <Text style={styles.answerText}>
            {application?.sizePreference ? application?.sizePreference.value : "No Preference"}
          </Text>
        </View>
      </View>

      <View>
        <Text style={styles.header}>Other</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          {application?.halReferral && (
            <View style={styles.botMargin}>
              <Text style={styles.questionText}>How Did You Hear About HAL?</Text>
              <Text style={styles.answerText}>{application?.halReferral.value}</Text>
            </View>
          )}
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          {application?.otherInfo && (
            <View style={styles.botMargin}>
              <Text style={styles.questionText}>Is There Any Other Info You'd Like to Share With Us?:</Text>
              <Text style={styles.answerText}>{application?.otherInfo}</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default PreferencesPage;
