import { Navigate, RouteObject } from "react-router-dom";
import AdminLayout from "./components/layouts/AdminLayout";
import AuthLayout from "./components/layouts/AuthLayout";
import MainLayout from "./components/layouts/MainLayout";
import AboutUsPage from "./components/pages/AboutUsPage";
import AdminDogsPage from "./components/pages/AdminDogsPage";
import AdoptableDogsPage from "./components/pages/AdoptableDogsPage";
import ApplicationsPage from "./components/pages/ApplicationsPage";
// import DashboardPage from "./components/pages/DashboardPage";
import DocumentsPage from "./components/pages/DocumentsPage";
import DogApplicationPage from "./components/pages/DogApplicationPage";
import DogPage from "./components/pages/DogPage";
import DonatePage from "./components/pages/DonatePage";
import EditCreateDogPage from "./components/pages/EditCreateDogPage";
import EventsPage from "./components/pages/EventsPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import RegisterPage from "./components/pages/RegisterPage";
import ReportsPage from "./components/pages/ReportsPage";
import ResetPasswordPage from "./components/pages/ResetPasswordPage";
import UserDetailPage from "./components/pages/UserDetailPage";
import UserProfilePage from "./components/pages/UserProfilePage";
import UsersPage from "./components/pages/UsersPage";
import VetsPage from "./components/pages/VetsPage";
import VolunteerApplicationsPage from "./components/pages/VolunteerApplicationsPage";
import VolunteerPage from "./components/pages/VolunteerPage";
import EditCreateEventPage from "./components/pages/EditCreateEventPage";
import EditCreateVetPage from "./components/pages/EditCreateVetPage";
import EventPage from "./components/pages/EventPage";
import User from "./types/user";
import ChangePassword from "./components/pages/ChangePasswordPage";
import AdoptionProcessPage from "./components/pages/AdoptionProcessPage";
import ApplicationViewPage from "./components/pages/ApplicationViewPage";
import SiteSearchPage from "./components/pages/SiteSearchPage";
import checkUserRole, { UserRoles } from "./helpers/checkUserRole";
import adminReroute from "./helpers/adminReroute";
import HelpUsPage from "./components/pages/HelpUsPage";
import FosterPage from "./components/pages/FosterPage";
import EventsMarketingPage from "./components/pages/EventsMarketingPage";
import SourcesPage from "./components/pages/SourcesPage";
import EditCreateSourcePage from "./components/pages/EditCreateSourcePage";

const routes = (
  isLoggedIn?: boolean,
  hasAdminAccess?: boolean,
  loggedInUser?: User,
  tempPassword?: boolean
): RouteObject[] => [
  {
    path: "/admin",
    element:
      isLoggedIn && hasAdminAccess && loggedInUser ? (
        <AdminLayout />
      ) : (
        <Navigate
          to="/"
          state={!isLoggedIn || !loggedInUser ? { prevPath: window.location.pathname, loginForRedirect: true } : {}}
        />
      ),
    children: [
      // { path: "dashboard", element: <DashboardPage /> },
      {
        path: "dogs",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) ||
          checkUserRole(loggedInUser!, UserRoles.Document) ||
          checkUserRole(loggedInUser!, UserRoles.Foster) ||
          checkUserRole(loggedInUser!, UserRoles.Inventory) ||
          checkUserRole(loggedInUser!, UserRoles.Volunteer) ? (
            <AdminDogsPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
        index: true,
      },
      { path: "dog/:id", element: <EditCreateDogPage />, index: true },
      {
        path: "applications",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) ||
          checkUserRole(loggedInUser!, UserRoles.Foster) ||
          checkUserRole(loggedInUser!, UserRoles.Volunteer) ? (
            <ApplicationsPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
        index: true,
      },
      {
        path: "applications/:id",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) ||
          checkUserRole(loggedInUser!, UserRoles.Foster) ||
          checkUserRole(loggedInUser!, UserRoles.Volunteer) ? (
            <ApplicationViewPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
        index: true,
      },
      { path: "volunteer-applications", element: <VolunteerApplicationsPage />, index: true },
      {
        path: "events",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Marketing) ? (
            <EventsPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
        index: true,
      },
      {
        path: "events/:id",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Marketing) ? (
            <EditCreateEventPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
        index: true,
      },
      {
        path: "reports",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Report) ? (
            <ReportsPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
        index: true,
      },
      { path: "documents", element: <DocumentsPage />, index: true },
      {
        path: "vets",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) ||
          checkUserRole(loggedInUser!, UserRoles.Volunteer) ||
          checkUserRole(loggedInUser!, UserRoles.Foster) ? (
            <VetsPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
      },
      {
        path: "vets/:id",
        element:
          checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Volunteer) ? (
            <EditCreateVetPage />
          ) : (
            <Navigate to={adminReroute(loggedInUser!)} />
          ),
        index: true,
      },
      { path: "sources", element: <SourcesPage /> },
      { path: "sources/:id", element: <EditCreateSourcePage /> },
      { path: "users", element: <UsersPage /> },
      { path: "user/:id", element: <UserDetailPage /> },
      { path: "", element: <Navigate to={adminReroute(loggedInUser!)} /> },
    ],
  },
  {
    path: "/auth",
    element:
      !isLoggedIn || !loggedInUser ? (
        tempPassword ? (
          <Navigate to="/auth/password/change" />
        ) : (
          <AuthLayout />
        )
      ) : (
        <Navigate to="/home" />
      ),
    children: [
      { path: "login", element: <LoginPage /> },
      { path: "forgot-password", element: <ForgotPasswordPage /> },
      { path: "reset-password", element: <ResetPasswordPage /> },
      { path: "register", element: <RegisterPage /> },
      { path: "change-password", element: <ChangePassword /> },
      { path: "", element: <Navigate to="/auth/login" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "home", element: <HomePage />, index: true },
      { path: "dogs", element: <AdoptableDogsPage />, index: true },
      { path: "dog/:id", element: <DogPage />, index: true },
      { path: "adopt/:id", element: <DogApplicationPage />, index: true },
      { path: "volunteer", element: <VolunteerPage />, index: true },
      { path: "help-us", element: <HelpUsPage />, index: true },
      { path: "foster", element: <FosterPage />, index: true },
      { path: "donate", element: <DonatePage />, index: true },
      { path: "about-us", element: <AboutUsPage />, index: true },
      {
        path: "user-profile",
        element: isLoggedIn ? (
          <UserProfilePage />
        ) : (
          <Navigate to="/" state={{ prevPath: window.location.pathname, loginForRedirect: true }} />
        ),
      },
      { path: "adoption-process", element: <AdoptionProcessPage />, index: true },
      { path: "event/:id", element: <EventPage />, index: true },
      { path: "events", element: <EventsMarketingPage />, index: true },
      { path: "search", element: <SiteSearchPage />, index: true },
      { path: "", element: <Navigate to="/home" /> },
      { path: "*", element: <Navigate to="/home" /> },
    ],
  },
];

export default routes;
