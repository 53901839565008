import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import { PDFViewer } from "@react-pdf/renderer";
import PdfAppView from "./PdfAppView";
import { useSelector } from "../../../store";
import ActionButton from "../../base/ActionButton";

interface Props {}

const PdfAppPreview: React.FC<Props> = (props) => {
  const { adminApplication, status } = useSelector((store) => store.applications);

  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: { md: 600, sm: 500, xs: 500 },
        width: { md: 800, sm: 600, xs: 300 },
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {status === "idle" && (
        <PDFViewer height={sm ? 500 : xs ? 500 : 600} width={xs ? 300 : sm ? 600 : 800}>
          <PdfAppView application={adminApplication} />
        </PDFViewer>
      )}
    </Box>
  );
};

export default PdfAppPreview;
