import { Box, Typography, Slider, Grid } from "@mui/material";
import React from "react";
import Cropper from "react-easy-crop";
import { useSelector } from "../../store";
import theme from "../../theme";
import ActionButton from "../base/ActionButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DogCardExample from "../base/DogCardExample";
import EventExample from "../base/EventExample";

interface Props {
  cropImage: string;
  crop: any;
  zoom: any;
  setCrop: any;
  setZoom: any;
  onCropComplete: any;
  closeCropModal: any;
  uploadFiles: () => void;
  cropImagePreview: string;
  eventPhoto?: boolean;
}

const ImageCropView: React.FC<Props> = (props) => {
  const { cropImage, crop, zoom, setCrop, setZoom, onCropComplete, closeCropModal, uploadFiles, cropImagePreview } =
    props;
  const { status: fileStatus } = useSelector((store) => store.file);

  return (
    <Box
      sx={{
        width: { lg: 1024, md: 900, xs: window.innerWidth },
        height: { md: "auto", xs: window.innerHeight },
        overflow: "auto",
        backgroundColor: theme.palette.background.paper,
        borderRadius: { md: 20, xs: 0 },
        paddingX: { lg: 10, md: 5, xs: 0 },
        paddingY: 5,
      }}
    >
      {/* Everything */}
      <Grid
        container
        sx={{
          flexDirection: { md: "row", xs: "column" },
          justifyContent: { md: "space-between", xs: "center" },
        }}
        alignItems="center"
      >
        {/* Cropper and Buttons */}
        <Grid item sx={{ padding: 2 }}>
          <Grid container direction={"column"}>
            <Grid
              item
              position={"relative"}
              sx={props.eventPhoto ? { height: 270, width: 350 } : { height: 350, width: 350 }}
            >
              <Cropper
                image={cropImage}
                crop={crop}
                zoom={zoom}
                aspect={props.eventPhoto ? 16 / 12 : 1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </Grid>
            <Grid item>
              <Grid container direction={"column"}>
                <Grid item sx={{ textAlign: "center" }} pt={2}>
                  <Typography variant="h2">Zoom</Typography>
                  <Slider
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e, zoom) => {
                      setZoom(Number(zoom));
                    }}
                  />
                </Grid>
                <Grid item pb={2}>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <RemoveIcon />
                    </Grid>
                    <Grid item>
                      <AddIcon />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item pb={2}>
                  <Grid container justifyContent={"space-around"}>
                    <Grid item>
                      <ActionButton type="button" text="Cancel" color="secondary" onClick={closeCropModal} />
                    </Grid>
                    <Grid item>
                      <ActionButton
                        type="button"
                        text="Save"
                        onClick={uploadFiles}
                        disabled={fileStatus === "loading"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Examples */}
        <Grid item>
          {props.eventPhoto ? (
            <Grid container direction={"column"} spacing={5} alignItems="center" justifyContent={"center"}>
              <Grid item sx={{ textAlign: "center" }}>
                <Typography variant="h3" color="primary" pb="8px">
                  Crop Photo
                </Typography>
                <EventExample image={cropImagePreview} />
              </Grid>
            </Grid>
          ) : (
            <Grid container direction={"column"} gap={5} alignItems="center" justifyContent={"center"}>
              {/* Dog Card Example */}
              <Grid item>
                <Grid container direction={"column"} alignItems={"center"}>
                  <Typography variant="h3" color="primary" textAlign={"center"}>
                    Adoptable Dogs Page Dog Card
                  </Typography>
                  <Typography variant="caption" color="primary" textAlign={"center"}>
                    The Adopt and favorite buttons will not appear on phone screens
                  </Typography>
                  <DogCardExample image={cropImagePreview} />
                </Grid>
              </Grid>

              {/* DDP Example */}
              <Grid item sx={{ textAlign: "center" }}>
                <Typography variant="h3" color="primary" pb="8px">
                  Dog Detail Page
                </Typography>
                <Box
                  component="img"
                  alt={`example picture`}
                  src={cropImagePreview}
                  sx={{
                    objectFit: "cover",
                    height: "275px",
                    width: "275px",
                    borderRadius: "25px",
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageCropView;
