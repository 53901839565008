import React from "react";
import TitleBar from "../base/TitleBar";
import VolunteerApplicationsView from "../views/VolunteerApplicationsView";

interface Props {}

const VolunteerApplicationsPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Volunteer Applications" />
      <VolunteerApplicationsView />
    </>
  );
};

export default VolunteerApplicationsPage;
