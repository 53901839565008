import React from "react";
import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import theme from "../../../theme";
import DogDetail from "../../../types/dogDetail";
import DogPdfView from "./DogPdfView";
import User from "../../../types/user";
import DogSource from "../../../types/dogSource";
import UserSimple from "../../../types/userSimple";

interface Props {
  dog?: DogDetail;
  foster?: User;
  source?: DogSource;
  adopter?: UserSimple;
}

const PdfDogView: React.FC<Props> = (props) => {
  const { dog, foster, source, adopter } = props;

  return (
    <Document>
      <Page size="A4" style={styles.container}>
        <DogPdfView dog={dog} foster={foster} source={source} adopter={adopter} styles={styles} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: "40px",
  },
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  addressText: {
    fontSize: "10px",
  },
  header: {
    fontSize: "15px",
    textAlign: "center",
    marginBottom: "10px",
  },
  questionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "calc(100% - 80px)",

    borderTop: "1px solid black",
    paddingTop: "10px",
  },
  separator: {
    width: "30%",
    marginBottom: "15px",
  },
  addressSeparator: {
    width: "49%",
    marginBottom: "15px",
  },
  answerText: {
    fontSize: "13px",
  },
  questionText: {
    fontSize: "10px",
    marginBottom: "5px",
  },
});

export default PdfDogView;
