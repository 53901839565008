import React, { useRef } from "react";
import { Badge, Box, IconButton as MuiButton, Tooltip, Typography } from "@mui/material";
import theme from "../../theme";

interface Props {
  onClick: (e?: any) => void;
  icon: React.ReactElement;
  label: string;
  badgeNumber?: number;
  circle?: boolean;
  size?: string;
  disabled?: boolean;
  // success?: boolean;
  // lowOpacity?: boolean;
  hideTooltip?: boolean;
  type?: "submit" | "button";
  tabIndex?: number;
}

const IconButton: React.FC<Props> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  return (
    <Box
      sx={{
        padding: "0px",
        width: "fit-content",
      }}
    >
      {props.hideTooltip ? (
        <MuiButton
          color="inherit"
          ref={anchorRef}
          onClick={props.onClick}
          disabled={props.disabled}
          type={props.type || "button"}
          tabIndex={props.tabIndex}
        >
          <Badge color="warning" badgeContent={props.badgeNumber}>
            {props.icon}
          </Badge>
        </MuiButton>
      ) : (
        <Tooltip title={<Typography>{props.label}</Typography>}>
          <MuiButton color="inherit" ref={anchorRef} onClick={props.onClick} type={props.type || "button"}>
            <Badge color="warning" badgeContent={props.badgeNumber}>
              {props.icon}
            </Badge>
          </MuiButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default IconButton;
