import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Sample from "../types/sample";
import http from "../api/http";

interface SampleState {
  sample?: Sample;
  status: "idle" | "loading" | "failed";
}

const initialState: SampleState = {
  sample: undefined,
  status: "idle",
};

export const fetchSample = createAsyncThunk<Sample>("sample/fetch", async () => {
  const response = await http.get<Sample>("/sample");
  return response.data;
});

const sampleSlice = createSlice({
  name: "sample",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSample.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSample.fulfilled, (state, action) => {
        state.status = "idle";
        state.sample = action.payload;
      })
      .addCase(fetchSample.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default sampleSlice.reducer;
