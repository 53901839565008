import React, { useRef, useState } from "react";
import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import theme from "../../../theme";
import { AccessTime } from "@mui/icons-material";
import HistoryVM from "../../../types/historyVM";
import Moment from "react-moment";
import Modal from "../../base/Modal";
import { useDispatch } from "../../../store";
import { getApplication, setActiveApp } from "../../../slices/applications";

interface Props {
  historyArray?: HistoryVM[];
  answerString?: string;
  tel?: boolean;
  questionText: string;
  otherDog?: boolean;
  dogId?: number;
  modalQuestionText?: string;
}

const HistoryAnswerView: React.FC<Props> = (props) => {
  const { historyArray, answerString, tel, questionText, otherDog, dogId, modalQuestionText } = props;
  const dispatch = useDispatch();
  const [hover, setHover] = useState({ visible: false, id: 0 });
  const [menu, setMenu] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Typography variant="body2" fontStyle="italic">
          {questionText}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction={"row"}>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                backgroundColor:
                  historyArray && historyArray.length && historyArray?.length > 0
                    ? theme.palette.secondary.main
                    : "initial",
                width: "fit-content",
                borderRadius: "5px",
                marginRight: "5px",
              }}
            >
              {tel ? (
                <a href={`tel:${answerString}`} style={{ color: theme.palette.primary.main }}>
                  {answerString}
                </a>
              ) : otherDog ? (
                <Box
                  onClick={() => window.open(`/admin/dog/${dogId}`, "_blank")}
                  sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                >
                  {answerString}
                </Box>
              ) : (
                answerString
              )}
            </Typography>
          </Grid>
          <Grid item sx={{ paddingTop: "3px" }}>
            {historyArray && historyArray.length && historyArray?.length > 0 ? (
              <Box
                ref={anchorRef}
                onClick={
                  sm
                    ? () => {
                        setMenu(true);
                      }
                    : undefined
                }
              >
                <Tooltip
                  title={
                    <Box sx={{ width: "fit-content" }}>
                      {historyArray?.map((item) => {
                        return (
                          <Box
                            onMouseEnter={() => setHover({ visible: true, id: item.id })}
                            onMouseLeave={() => setHover({ visible: false, id: 0 })}
                            color={
                              hover && item.id === hover.id
                                ? theme.palette.secondary.main
                                : theme.palette.primary.contrastText
                            }
                          >
                            <Typography
                              sx={{ cursor: "pointer" }}
                              onClick={async () => {
                                dispatch(setActiveApp(item.id));
                              }}
                            >
                              <Moment format="MM/DD/YY">{item.date}</Moment>-{" "}
                              {item.value === "True" ? "Yes" : item.value === "False" ? "No" : item.value}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  }
                >
                  <AccessTime fontSize="small" />
                </Tooltip>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Modal open={menu} onClose={() => setMenu(false)}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: "10px",
            padding: "5px",
          }}
        >
          <Typography textAlign={"center"}>{modalQuestionText ? modalQuestionText : questionText}</Typography>
          <Box
            sx={{
              maxHeight: "250px",
              overflow: "auto",
              padding: "5px",
            }}
          >
            {historyArray?.map((item) => {
              return (
                <Box color={theme.palette.primary.main} sx={{ textDecoration: "underline", padding: "5px" }}>
                  <Typography
                    onClick={async () => {
                      dispatch(setActiveApp(item.id));
                      setMenu(false);
                    }}
                  >
                    <Moment format="MM/DD/YY">{item.date}</Moment>-{" "}
                    {item.value === "True" ? "Yes" : item.value === "False" ? "No" : item.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default HistoryAnswerView;
