import React, { useState, useEffect } from "react";
import { FieldProps, useFormikContext } from "formik";
import { Autocomplete, AutocompleteChangeReason, Popper, TextFieldProps, Typography, debounce } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FormikTextField, { CustomInputProps } from "./FormikTextField";
import { AsyncThunk } from "@reduxjs/toolkit";
import SearchParams from "../../types/searchParams";
import SearchResults from "../../types/searchResults";
import { useDispatch, useSelector } from "../../store";
import KeyValue from "../../types/keyValue";

interface Props {
  searchFunction: AsyncThunk<SearchResults, SearchParams, any>;
  onSelected?: (value: KeyValue) => void;
  multiple?: boolean;
  filterForm?: SearchParams;
  hideLargeResults?: boolean;
  freeSolo?: boolean;
  showId?: boolean;
}

const FormikAutocompleteOption: React.FC<FieldProps & TextFieldProps & CustomInputProps & Props> = (props) => {
  const { searchFunction, onSelected, multiple, filterForm, hideLargeResults, freeSolo, disabled, ...rest } = props;

  const { setFieldValue } = useFormikContext();

  const [options, setOptions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [hide, setHide] = useState<boolean>(false);
  const { status: optionStatus } = useSelector((store) => store.options);
  const { status: userStatus } = useSelector((store) => store.users);

  const dispatch = useDispatch();

  useEffect(() => {
    if (hideLargeResults) {
      setHide(true);
    }
    fetchInitialOptions();
  }, [dispatch, hide]);

  const fetchInitialOptions = async () => {
    if (!hide) {
      const newOptions = (await dispatch(searchFunction(filterForm ? { ...filterForm, query: "" } : { query: "" })))
        .payload as any;
      if (newOptions && newOptions.results) {
        setOptions(newOptions.results);
      }
    }
  };

  const updateSearch = debounce((query: string) => {
    setSearchQuery(query);
    const fetchOptions = async () => {
      const newOptions = (
        await dispatch(searchFunction(filterForm ? { ...filterForm, query: query } : { query: query }))
      ).payload as any;
      if (newOptions && newOptions.results) {
        setOptions(newOptions.results);
      }
    };

    fetchOptions();
  }, 250);

  const onChange = (event: React.ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason) => {
    setFieldValue(props.field.name, value || null);
    onSelected?.(value);
    if ((multiple && searchQuery) || reason === "clear") {
      fetchInitialOptions();
      setSearchQuery("");
    }
  };

  const customPopper = function (props: any) {
    return <Popper {...props} placement="bottom" />;
  };

  return (
    <Autocomplete
      readOnly={disabled}
      PopperComponent={customPopper}
      multiple={!!multiple}
      disablePortal
      freeSolo={freeSolo === true}
      value={props.field.value || null}
      filterOptions={(x) => x}
      options={hide && !searchQuery ? [] : options}
      getOptionLabel={(option) => {
        let label = option?.name || option?.value || `${option?.firstName} ${option?.lastName}` || "";
        return !!props.showId ? `${option.id} - ${label}` : label;
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      popupIcon={<ExpandMore fontSize="medium" color="primary" />}
      onFocus={() => {
        if (!options.length) {
          fetchInitialOptions();
        }
      }}
      noOptionsText={
        <Typography color="primary">
          {optionStatus === "loading" || userStatus === "loading"
            ? "Loading..."
            : searchQuery === "" && hideLargeResults
            ? "Begin typing to search"
            : "No results for provided search term. Backspace and try searching something else."}
        </Typography>
      }
      onChange={onChange}
      // autoSelect
      renderInput={(params) => (
        <FormikTextField
          {...params}
          size={!!props.secondary ? "medium" : "small"}
          {...rest}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSearch(e.currentTarget.value)}
          isAutoComplete
        />
      )}
    />
  );
};

export default FormikAutocompleteOption;
