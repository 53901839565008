import React, { useEffect, useRef, useState } from "react";
import ApplicationHistory from "../../../types/applicationHistory";
import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { AccessTime, AccountCircle, Medication } from "@mui/icons-material";
import theme from "../../../theme";
import Moment from "react-moment";
import { useDispatch } from "../../../store";
import HistoryVM from "../../../types/historyVM";
import { getApplication, setActiveApp } from "../../../slices/applications";
import Modal from "../../base/Modal";

interface Props {
  adminApplication: ApplicationHistory;
  personal?: boolean;
  vet?: boolean;
}

const HistoryReferenceView: React.FC<Props> = (props) => {
  const { adminApplication, personal, vet } = props;
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const [hover, setHover] = useState({ visible: false, id: 0 });
  const [menu, setMenu] = useState(false);
  const [refArray, setRefArray] = useState<HistoryVM[]>([]);

  useEffect(() => {
    if (
      !!personal &&
      adminApplication?.history?.PersonalReferences &&
      adminApplication?.history?.PersonalReferences.length &&
      adminApplication?.history?.PersonalReferences?.length > 1
    ) {
      setRefArray(adminApplication.history.PersonalReferences);
    } else if (
      !!vet &&
      adminApplication?.history?.VetReferences &&
      adminApplication?.history?.VetReferences.length &&
      adminApplication?.history?.VetReferences?.length > 1
    ) {
      setRefArray(adminApplication.history.VetReferences);
    }
  }, [personal, vet]);

  return (
    <>
      {!!personal ? (
        <Grid
          item
          xs={12}
          sx={{
            marginTop: { md: 1, xs: 0 },
            backgroundColor:
              adminApplication?.history?.PersonalReferences &&
              adminApplication?.history?.PersonalReferences.length &&
              adminApplication?.history?.PersonalReferences?.length > 1
                ? theme.palette.secondary.main
                : "inherit",
          }}
        >
          {adminApplication?.personalReferences &&
            adminApplication.personalReferences.length > 0 &&
            adminApplication.personalReferences.map((reference, index) => {
              return (
                <Grid container spacing={2} mb={3} key={index}>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: "25px",
                      flexDirection: "column",
                    }}
                  >
                    <AccountCircle />
                    {adminApplication?.history?.PersonalReferences &&
                    adminApplication?.history?.PersonalReferences.length &&
                    adminApplication?.history?.PersonalReferences?.length > 1 ? (
                      <Box
                        ref={anchorRef}
                        onClick={
                          sm
                            ? () => {
                                setMenu(true);
                              }
                            : undefined
                        }
                      >
                        <Tooltip
                          title={
                            <Box sx={{ width: "fit-content" }}>
                              {adminApplication?.history?.PersonalReferences?.map((item) => {
                                const ref = JSON.parse(item.value);
                                return (
                                  <Box
                                    onMouseEnter={() => setHover({ visible: true, id: item.id })}
                                    onMouseLeave={() => setHover({ visible: false, id: 0 })}
                                    color={
                                      hover && item.id === hover.id
                                        ? theme.palette.secondary.main
                                        : theme.palette.primary.contrastText
                                    }
                                    sx={{ cursor: "pointer" }}
                                    onClick={async () => {
                                      dispatch(setActiveApp(item.id));
                                    }}
                                  >
                                    <Typography>
                                      <Moment format="MM/DD/YY">{item.date}</Moment>- {`${ref.Name}`}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          }
                        >
                          <AccessTime fontSize="small" />
                        </Tooltip>
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item md={3} xs={11}>
                    <Typography variant="body2" fontStyle="italic">
                      Personal Reference Number {index + 1}
                    </Typography>
                    <Typography variant="body1">{reference.name}</Typography>
                  </Grid>
                  <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                  <Grid item md={4} xs={5}>
                    <Typography variant="body2" fontStyle="italic">
                      Relationship
                    </Typography>
                    <Typography variant="body1">{reference.relationship}</Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="body2" fontStyle="italic">
                      Phone
                    </Typography>
                    <Typography variant="body1">
                      <a href={`tel:${reference?.phone}`} style={{ color: theme.palette.primary.main }}>
                        {reference?.phone}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      ) : !!vet ? (
        <Grid
          item
          xs={12}
          marginTop={1}
          sx={{
            backgroundColor:
              adminApplication?.history?.VetReferences &&
              adminApplication?.history?.VetReferences.length &&
              adminApplication?.history?.VetReferences?.length > 1
                ? theme.palette.secondary.main
                : "inherit",
          }}
        >
          {adminApplication?.vetReferences?.map((reference, index) => {
            return (
              <Grid container spacing={2} mb={3} key={index}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "25px",
                    flexDirection: "column",
                  }}
                >
                  <Medication />
                  {adminApplication?.history?.VetReferences &&
                  adminApplication?.history?.VetReferences.length &&
                  adminApplication?.history?.VetReferences?.length > 1 ? (
                    <Box
                      ref={anchorRef}
                      onClick={
                        sm
                          ? () => {
                              setMenu(true);
                            }
                          : undefined
                      }
                    >
                      <Tooltip
                        title={
                          <Box sx={{ width: "fit-content" }}>
                            {adminApplication?.history?.VetReferences?.map((item) => {
                              const ref = JSON.parse(item.value);
                              return (
                                <Box
                                  onMouseEnter={() => setHover({ visible: true, id: item.id })}
                                  onMouseLeave={() => setHover({ visible: false, id: 0 })}
                                  color={
                                    hover && item.id === hover.id
                                      ? theme.palette.secondary.main
                                      : theme.palette.primary.contrastText
                                  }
                                  sx={{ cursor: "pointer" }}
                                  onClick={async () => {
                                    dispatch(getApplication({ id: item.id }));
                                  }}
                                >
                                  <Typography>
                                    <Moment format="MM/DD/YY">{item.date}</Moment>- {`${ref.Name}`}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        }
                      >
                        <AccessTime fontSize="small" />
                      </Tooltip>
                    </Box>
                  ) : null}
                </Grid>
                <Grid item md={3} xs={11}>
                  <Typography variant="body2" fontStyle="italic">
                    Vet Reference Number {index + 1}
                  </Typography>
                  <Typography variant="body1">{reference.name}</Typography>
                </Grid>
                <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                <Grid item md={4} xs={5}>
                  <Typography variant="body2" fontStyle="italic">
                    Location
                  </Typography>
                  <Typography variant="body1">
                    {reference.city}, {reference.state?.value}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <Typography variant="body2" fontStyle="italic">
                    Phone
                  </Typography>
                  <Typography variant="body1">
                    <a href={`tel:${reference?.phone}`} style={{ color: theme.palette.primary.main }}>
                      {reference?.phone}
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} />
                <Grid item md={3} xs={11}>
                  <Typography variant="body2" fontStyle="italic">
                    Pets on File
                  </Typography>
                  <Typography variant="body1">{reference.petsOnFile}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
      <Modal open={menu} onClose={() => setMenu(false)}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: "10px",
          }}
        >
          <Typography textAlign={"center"}>
            {!!personal ? "Personal References" : !!vet ? "Vet References" : ""}
          </Typography>
          <Box
            sx={{
              maxHeight: "250px",
              overflow: "auto",
              padding: "5px",
            }}
          >
            {refArray.map((item) => {
              const ref = JSON.parse(item.value);

              return (
                <Box color={theme.palette.primary.main} sx={{ textDecoration: "underline", padding: "5px" }}>
                  <Typography
                    onClick={async () => {
                      dispatch(setActiveApp(item.id));
                      setMenu(false);
                    }}
                  >
                    <Moment format="MM/DD/YY">{item.date}</Moment>- {`${ref.Name}`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default HistoryReferenceView;
