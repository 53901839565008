import SearchParams from "../types/searchParams";

const getSearchQueryString = (model: SearchParams): URLSearchParams => {
  let qs = new URLSearchParams();
  model.id && qs.append("id", model.id?.toString());
  model.query && qs.append("query", model.query);
  model.pageNumber && qs.append("pageNumber", model.pageNumber?.toString());
  model.pageSize && qs.append("pageSize", model.pageSize?.toString());
  model.filters?.forEach((filter) => qs.append("filters", filter));
  model.orderBy && qs.append("orderBy", model.orderBy);
  model.orderDirection && qs.append("orderDirection", model.orderDirection);

  return qs;
};

export default getSearchQueryString;
