import { Box, Container } from "@mui/material";
import React from "react";
import { useSelector } from "../../../store";
import DogAdoptionRecord from "../DogAdoptionRecord";
import DogApplicationTable from "../DogApplicationTable";

interface Props {
  id: number;
}

const DogAdoptionApplicationTab: React.FC<Props> = (props) => {
  const { id } = props;
  const { dog } = useSelector((store) => store.dogs);

  return (
    <Container>
      <Box sx={{ marginY: 3 }}>
        <DogApplicationTable dogId={dog?.id || +id!} />
      </Box>
      <Box sx={{ marginY: 3 }}>
        <DogAdoptionRecord id={dog?.id || +id!} type="dog" />
      </Box>
    </Container>
  );
};

export default DogAdoptionApplicationTab;
