import React from "react";
import ForgotPasswordView from "../views/Auth/ForgotPasswordView";

interface Props {}

const ForgotPasswordPage: React.FC<Props> = (props) => {
  return (
    <>
      <ForgotPasswordView />
    </>
  );
};

export default ForgotPasswordPage;
