import React from "react";
import TitleBar from "../base/TitleBar";
import AdminEventsTable from "../views/Events/AdminEventsTable";

interface Props {}

const EventsPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Events" />
      <AdminEventsTable />
    </>
  );
};

export default EventsPage;
