import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Icon, { IconType } from "./Icon";
import theme from "../../theme";

interface Props {
  text: string;
  link?: string;
}

const TextHeader: React.FC<Props> = (props) => {
  const { text, link } = props;
  const navigate = useNavigate();

  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const footprints = [1, 2, ...(sm ? [3] : []), ...(md ? [4] : [])];

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        width: "100%",
        py: "15px",
        cursor: link ? "pointer" : "initial",
        backgroundColor: link ? theme.palette.primary.main : theme.palette.secondary.main,
      }}
      onClick={() => {
        if (link) {
          navigate(link);
        }
      }}
    >
      {footprints.map((print, index) => (
        <Grid item key={print}>
          <Icon
            type={IconType.DogsFilled}
            size={sm ? "45px" : "30px"}
            transform={`rotate(90deg) translate(${index % 2 ? 15 : -15}px, 0px)`}
            color={link ? theme.palette.primary.contrastText : theme.palette.primary.main}
          />
        </Grid>
      ))}
      <Grid item>
        <Typography
          textAlign={"center"}
          fontSize={{ md: "30px", xs: "17px" }}
          color={link ? theme.palette.primary.contrastText : theme.palette.primary.main}
        >
          {text}
        </Typography>
      </Grid>
      {footprints.map((print, index) => (
        <Grid item key={print}>
          <Icon
            type={IconType.DogsFilled}
            size={sm ? "45px" : "30px"}
            transform={`rotate(-90deg) translate(${index % 2 ? -15 : 15}px, 0px)`}
            color={link ? theme.palette.primary.contrastText : theme.palette.primary.main}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TextHeader;
