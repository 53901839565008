import React from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import { Box, Checkbox, CheckboxProps, Typography } from "@mui/material";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";

interface Props {
  colorVariant: "white" | "blue";
  fontVariant: "caption" | "body2" | "h5";
  label: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  tabIndex?: number;
}

const FormikCheckbox: React.FC<FieldProps & CheckboxProps & Props> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const submitCount = getIn(props.form.submitCount, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { colorVariant, fontVariant, label, checked, field, onChange, ...rest } = props;

  const { setFieldValue } = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) {
      onChange(checked);
    } else {
      setFieldValue(field.name, checked);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          onChange={handleChange}
          checked={checked}
          sx={{
            color: colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main,
            "&.Mui-checked": {
              outline: colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main,
            },
            paddingLeft: 0,
          }}
          icon={
            <Icon
              type={IconType.Dogs}
              color={colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main}
              size="35px"
            />
          }
          checkedIcon={
            <Icon
              type={IconType.DogsFilled}
              color={colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main}
              size="35px"
            />
          }
          // {...field}
          // {...rest}
          disableFocusRipple
          disableRipple
          tabIndex={props.tabIndex}
        />
        <Typography variant={fontVariant ? fontVariant : "caption"}>{label}</Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: ".75rem",
            letterSpacing: "0em",
            fontWeight: 400,
            textAlign: "left",
            ml: "14px",
            mt: "5px",
          }}
          color={theme.palette.error.main}
        >
          {(!!isTouched || submitCount !== 0) && errorMessage}
        </Typography>{" "}
      </Box>
    </>
  );
};

export default FormikCheckbox;
