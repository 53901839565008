import React, { useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Grid, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../forms";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser, resetUser, setAuthModal } from "../../slices/auth";
import { handleErrorToastState, handleSuccessToastState, setErrorMessage, setSuccessMessage } from "../../slices/toast";
import theme from "../../theme";

interface Props {}

const ChangePassword: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { awsUser } = useSelector((store) => store.auth);
  const initialValues = { password: "", confirmPassword: "" };
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape(
    {
      password: Yup.string()
        .required("You must enter a valid password")
        .min(8, "Password must have at least 8 characters")
        .matches(/[0-9]/, "Your password must have at least 1 numerical character")
        .matches(/[a-z]/, "Your password must have at least 1 lowercase character")
        .matches(/[A-Z]/, "Your password must have at least 1 uppercase character"),
      confirmPassword: Yup.string()
        .nullable()
        .when("password", {
          is: (val: string) => val && val.length > 0,
          then: (schema) =>
            schema.required("Please confirm new password").oneOf([Yup.ref("password")], "Passwords must match"),
        }),
    },
    [["password", "confirmPassword"]]
  );

  const handleSubmit = (password: string) => {
    if (password && awsUser) {
      awsUser.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: async () => {
            dispatch(handleSuccessToastState(true));
            dispatch(setSuccessMessage("Password Changed Successfully"));
            await dispatch(getLoggedInUser());
            dispatch(resetUser());
            dispatch(setAuthModal(false));
            navigate("/user-profile");
          },
          onFailure: (err) => {
            dispatch(setErrorMessage(err.message));
            dispatch(handleErrorToastState(true));
          },
        }
      );
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: { xs: 25, sm: 0 },
      }}
    >
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingY: "50px",
            paddingX: "50px",
          }}
        >
          <Box>
            <img height={200} src="/static/images/hallogo.png" alt="logo" />
          </Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values.password);
            }}
          >
            <Form noValidate>
              <Grid container justifyContent="center">
                <Grid item sm={8} xs={12}>
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", margin: 1 }}>
                    <Typography variant="h6" align="center" color="primary">
                      Please change your password before continuing.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Field
                    name="password"
                    label="New Password"
                    required
                    component={Inputs.Password}
                    secondary
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    required
                    component={Inputs.Password}
                    secondary
                    showPassword={showPassword}
                  />
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Grid item>
                      <Inputs.Submit text="Save Password" isSubmitting={false} color="primary" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
