import * as React from "react";
import { TextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FieldProps, useFormikContext } from "formik";
import FormikTextField, { CustomInputProps } from "./FormikTextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface Props {
  handleChange?: (value: string) => void;
}

const FormikDatepicker: React.FC<FieldProps & TextFieldProps & CustomInputProps & Props> = (props) => {
  const formikProps = useFormikContext();

  const { handleChange: onDateSelected, ...rest } = props;

  function handleChange(value: any, keyboardInputValue?: string | undefined): void {
    formikProps.setFieldValue(props.field.name, value);
    onDateSelected?.(value);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Basic example"
        value={props.field.value || null}
        onChange={handleChange}
        renderInput={(params) => (
          <FormikTextField
            {...params}
            size={!!props.secondary ? "medium" : "small"}
            onChange={handleChange}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FormikDatepicker;
