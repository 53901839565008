import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "../base/Search";
import { useDispatch, useSelector } from "../../store";
import { debounce } from "lodash";
import SearchParams from "../../types/searchParams";
import { getDogsSearch } from "../../slices/dogs";
import { searchEvents } from "../../slices/events";
import EventVM from "../../types/event";
import { setSearchString } from "../../slices/search";
import DogSimple from "../../types/dogSimple";

interface Props {}

const SiteSearchView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eventFilterForm, setEventFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
    filters: ["status:2", "status:3"],
    orderBy: "start",
    orderDirection: "Ascending",
  });
  const [dogFilterForm, setDogFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
    filters: ["status:2", "status:4", "status:10"],
    orderBy: "name",
    orderDirection: "Ascending",
  });
  const { searchResults: eventSearchResults, status: eventStatus } = useSelector((store) => store.events);
  const { dogSearch, searchStatus } = useSelector((store) => store.dogs);
  const { searchString } = useSelector((store) => store.search);

  useEffect(() => {
    if (searchString) {
      dispatch(getDogsSearch({ search: dogFilterForm }));
      dispatch(searchEvents({ search: eventFilterForm }));
    }
  }, [dogFilterForm, eventFilterForm, dispatch]);

  const updateSearches = debounce((query: string) => {
    dispatch(setSearchString(query));
    setEventFilterForm({ ...eventFilterForm, pageNumber: 1, pageSize: 10, query: query });
    setDogFilterForm({ ...dogFilterForm, pageNumber: 1, pageSize: 10, query: query });
  }, 250);

  const getHighlightedText = (text: string, highlight: any) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <Box component={"span"} style={{ backgroundColor: "yellow" }} key={index}>
              {part}
            </Box>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <Paper
      sx={{
        borderRadius: 10,
        marginX: { xl: 20, lg: 15, md: 10, xs: 2 },
        marginTop: 5,
        paddingY: 5,
        paddingX: { xl: 15, lg: 10, md: 5, xs: 2 },
        height: "100%",
        overflow: "auto",
      }}
    >
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <Grid container direction={"row"}>
            <Grid sx={{ pb: 2 }} item xs={12}>
              <Typography variant="h1" px={"10px"}>
                Search Results for:
              </Typography>
              <Typography variant="h5" textAlign={"center"} sx={{ display: { xs: "flex", md: "none" } }}>
                Search for dogs by their names and headline descriptions, and events by their names and descriptions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Search handleChange={updateSearches} label="Search" value={searchString} />
            </Grid>
          </Grid>
        </Grid>
        {eventStatus === "loading" && searchStatus === "loading" && searchString ? (
          <Grid item>
            <Typography variant="h1" textAlign={"center"}>
              Loading...
            </Typography>
          </Grid>
        ) : null}
        {eventSearchResults?.results.length && searchString.length && eventStatus === "idle" && searchStatus === "idle"
          ? eventSearchResults?.results.map((event: EventVM) => {
              return (
                <Grid item key={`${event.id}-${event.name}`} ml={1}>
                  <Typography
                    variant="h1"
                    onClick={() => {
                      navigate(`/event/${event.id}`);
                    }}
                    sx={{ cursor: "pointer", textDecoration: "underline", pb: 1 }}
                  >
                    {event.status.id === 2 ? "Ongoing Event-  " : "Upcoming Event- "}{" "}
                    {getHighlightedText(event.name, searchString)}
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: "100px",
                      height: "fit-content",
                      overflow: "auto",
                      display: { md: "flex", xs: "none" },
                    }}
                  >
                    {getHighlightedText(event.details, searchString)}
                  </Box>
                </Grid>
              );
            })
          : null}
        {dogSearch?.results.length && searchString.length && searchStatus === "idle" && eventStatus === "idle"
          ? dogSearch?.results.map((dog: DogSimple) => {
              return (
                <Grid item key={`${dog.id}-${dog.name}`} ml={1}>
                  <Typography
                    variant="h1"
                    onClick={() => {
                      navigate(`/dog/${dog.id}`);
                    }}
                    sx={{ cursor: "pointer", textDecoration: "underline", pb: 1 }}
                  >
                    Adoptable Dog- {getHighlightedText(dog.name, searchString)}
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: "75px",
                      height: "fit-content",
                      overflow: "auto",
                      display: { md: "flex", xs: "none" },
                    }}
                  >
                    <Grid container direction={"column"}>
                      <Grid item>{getHighlightedText(dog.headline, searchString)}</Grid>
                      <Grid item>Breed: {getHighlightedText(dog?.primaryBreed?.value!, searchString)}</Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })
          : null}
        {!dogSearch?.results.length &&
        !eventSearchResults?.results.length &&
        searchString &&
        eventStatus === "idle" &&
        searchStatus === "idle" ? (
          <Grid item>
            <Typography variant="h1" textAlign={"center"}>
              No results found
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default SiteSearchView;
