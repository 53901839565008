import React from "react";
import { FieldProps } from "formik";
import { TextFieldProps } from "@mui/material/TextField";
import FormikTextField, { CustomInputProps } from "./FormikTextField";

interface Props {}

const FormikNumberField: React.FC<FieldProps & TextFieldProps & CustomInputProps & Props> = (props) => {
  return <FormikTextField {...props} type="number" />;
};

export default FormikNumberField;
