import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { fetchSample } from "../../slices/sample";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchParams from "../../types/searchParams";
import DogSimple from "../../types/dogSimple";
import theme from "../../theme";
import TableActions from "../base/TableActions";
import ActionButton from "../base/ActionButton";
import StyledTable from "../base/StyledTable";
import { getApplication, getApplicationsSearch } from "../../slices/applications";
import { getDogSimpleById, resetSimpleDog } from "../../slices/dogs";
import Moment from "react-moment";

interface Props {
  userId: number;
}

const UserApplicationTable: React.FC<Props> = (props) => {
  const { applicationsSearchResults } = useSelector((store) => store.applications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getApplicationsSearch({
        search: { query: "", filters: [`applicant:${props.userId}`], pageNumber: 1, pageSize: 5 },
      })
    );
  }, [dispatch, props.userId]);

  const appliedDogHeaders = ["Dog Name", "Interest", "Status", "Actions"];
  const myApplication = applicationsSearchResults?.results[0];

  let appliedDogs: DogSimple[] = [myApplication?.selectedDog!];
  appliedDogs = appliedDogs.concat(myApplication?.otherDogs!);

  const appliedDogsExist = myApplication && appliedDogs && appliedDogs.length > 0;

  const applicationRows = appliedDogsExist
    ? appliedDogs?.map((dog: DogSimple) => {
        return {
          dogName: (
            <Box>
              <Typography variant="caption" color="primary">
                {dog?.name}
              </Typography>
            </Box>
          ),
          interest: myApplication?.selectedDog?.id === dog?.id ? "Primary" : "Secondary",
          status: dog?.status?.value,
          actions: <TableActions id={dog?.id} handleView={() => window.open(`/admin/dog/${dog?.id}`, "_blank")} />,
        };
      })
    : [];

  const appliedTitle = (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1} justifyContent="space-between" padding={0}>
      <Typography variant="body2">
        {myApplication?.status?.id === 1 ? "Applications in Progress:" : "Dogs Applied For"}
      </Typography>
      {myApplication?.status?.id !== 1 && (
        <ActionButton
          text={"View Application"}
          color="warning"
          onClick={() => {
            if (myApplication.status?.id !== 9 && myApplication.status?.id !== 10 && myApplication.selectedDog) {
              dispatch(getDogSimpleById(myApplication.selectedDog.id));
            } else {
              dispatch(resetSimpleDog());
            }
            window.open(`/admin/applications/${myApplication.id}`, "_blank");
          }}
          small
          type="button"
        />
      )}
    </Box>
  );

  return (
    <Grid container spacing={1} justifyContent="center">
      {appliedDogsExist && (myApplication.selectedDog || myApplication?.otherDogs?.length) ? (
        <Grid item xs={12}>
          <StyledTable
            headers={appliedDogHeaders}
            rows={applicationRows}
            defaultPaging
            paging={true}
            sizeVariant="small"
            title={appliedTitle}
            totalRows={appliedDogs.length}
          />
        </Grid>
      ) : myApplication?.status?.id === 9 ? (
        <Grid item xs={12}>
          <Typography>
            Application Status: Submitted (No Dog) - This applicant previously applied for a dog that is no longer
            available. They have been notified that they need to update their application with new dogs of interest.
          </Typography>
        </Grid>
      ) : myApplication?.status?.id === 1 ? (
        <Grid item xs={12}>
          <Typography>
            Application Status: Incomplete{" "}
            {!!myApplication?.submittedDate ? <Moment format="MM/DD/YYYY">{myApplication?.submittedDate!}</Moment> : ""}{" "}
            - This applicant has either withdrawn their application or has started an application but has not finished
            it yet. They have been notified that they need to update their application with new dogs of interest if they
            wish to be considered for adoption.
          </Typography>
        </Grid>
      ) : myApplication?.status?.id === 7 ? (
        <Grid item xs={12}>
          <Typography>
            Application Status: Adopted - This applicant has adopted a dog from HAL! Their application has been reset so
            they can apply for more dogs in the future.
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography>No current applications in progress.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default UserApplicationTable;
