import React from "react";
import TitleBar from "../base/TitleBar";
import SourcesView from "../views/Sources/SourcesView";

interface Props {}

const SourcesPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Sources" />
      <SourcesView />
    </>
  );
};

export default SourcesPage;
