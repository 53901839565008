import React from "react";
import AdoptableDogsView from "../views/AdoptableDogsView";
import { Box } from "@mui/material";

interface Props {}

const AdoptableDogsPage: React.FC<Props> = (props) => {
  return (
    <Box sx={{}}>
      <AdoptableDogsView />
    </Box>
  );
};

export default AdoptableDogsPage;
