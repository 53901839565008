import React from "react";
import TitleBar from "../base/TitleBar";
import AdminDogTable from "../views/AdminDogTable";

interface Props {}

const AdminDogsPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Dogs" />
      <AdminDogTable />
    </>
  );
};

export default AdminDogsPage;
