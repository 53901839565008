import { Box, CircularProgress, Container, DialogActions, Grid, LinearProgress, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  deleteFile,
  getFilesById,
  getOneFileById,
  postFile,
  putFile,
  resetFile,
  startVideo,
  stopVideo,
} from "../../../slices/file";
import { useDispatch, useSelector } from "../../../store";
import FileVM from "../../../types/fileVM";
import getCroppedImg from "../../../utils/cropImage";
import ActionButton from "../../base/ActionButton";
import Carousel from "../../base/Carousel";
import Dialog from "../../base/Dialog";
import Modal from "../../base/Modal";
import { Inputs } from "../../forms";
import ImageCropView from "../ImageCropView";
import theme from "../../../theme";
import ReactPlayer from "react-player";
import { editEvent, getEvent } from "../../../slices/events";

interface Props {
  viewImage: string;
  setViewImage: React.Dispatch<React.SetStateAction<string>>;
  getMainPhoto: (photos: FileVM[]) => void;
}

const EventPhotoTab: React.FC<Props> = (props) => {
  const { viewImage, setViewImage, getMainPhoto } = props;
  const dispatch = useDispatch();
  const { status: fileStatus, uploadComplete, videoPlaying, fileSearch, newFile } = useSelector((store) => store.file);
  const { event, status, photo } = useSelector((store) => store.events);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(0);
  const [deleteImageUrl, setDeleteImageUrl] = useState("");
  const [cropModal, setCropModal] = useState(false);
  const [mainImageId, setMainImageId] = useState(event.mainPhotoId ? event.mainPhotoId : -1);
  const [cropImage, setCropImage] = useState("");
  const [croppedArea, setCroppedArea] = useState();
  const [cropImagePreview, setCropImagePreview] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [video, setVideo] = useState<FileVM>();
  const [deleteVideo, setDeleteVideo] = useState<FileVM>();

  useEffect(() => {
    getMainPhoto(fileSearch);
  }, [fileSearch]);

  useEffect(() => {
    dispatch(getFilesById({ entityId: event.id!, typeId: 2 }));
  }, [dispatch, event]);

  const post = async (file: any) => {
    await dispatch(postFile({ ...photo, upload: file })).then((result: any) => {
      let photoId = result.payload as number;
      if ((mainImageId === 0 || event.mainPhotoId === null) && file.type.split("/")[0] !== "video") {
        setMainImageId(photoId);
        dispatch(editEvent({ ...event, mainPhotoId: photoId })).then(() => {
          dispatch(getEvent(event.id!));
        });
      }
      let newFile: FileVM = { ...photo, id: photoId, entityId: event.id };
      dispatch(putFile(newFile)).then(() => {
        dispatch(getFilesById({ entityId: event.id!, typeId: 2 })).then((result) => {
          let photos = result.payload as FileVM[];
          let selectedPhoto = photos.find((p: FileVM) => p.id === photoId);
          setViewImage(selectedPhoto!.url);

          if (selectedPhoto?.fileType?.id === 12) {
            setVideo(selectedPhoto);
            dispatch(startVideo(selectedPhoto?.id));
          }
        });
        dispatch(getEvent(event.id!));
      });
    });
  };

  const uploadFiles = async () => {
    setCropModal(false);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    const { file, url } = await getCroppedImg(cropImage, croppedArea);
    await post(file);
  };

  const handleDeleteImage = async () => {
    await dispatch(deleteFile(deleteImageId)).then(() => {
      dispatch(getFilesById({ entityId: event.id!, typeId: 2 })).then((result: any) => {
        if (result.payload.length) {
          const mainPhoto = result.payload.find((photo: FileVM) => photo.id === mainImageId);
          if (mainPhoto) {
            if (deleteImageId === video?.id) {
              setVideo(undefined);
              setViewImage(mainPhoto.url);
            }
            return;
          } else {
            for (let i = 0; i < result.payload.length; i++) {
              if (result.payload[i].fileType.id === 1) {
                setMainImageId(result.payload[i].id!);
                dispatch(editEvent({ ...event, mainPhotoId: result.payload[i].id })).then(() => {
                  dispatch(getEvent(event.id!));
                  setViewImage(result.payload[i].url);
                });
                break;
              } else {
                dispatch(editEvent({ ...event, mainPhotoId: undefined })).then(() => {
                  dispatch(getEvent(event.id!));
                });
                setMainImageId(0);
                setViewImage("");
              }
            }
          }
        } else {
          dispatch(editEvent({ ...event, mainPhotoId: undefined })).then(() => {
            dispatch(getEvent(event.id!));
          });
          setMainImageId(0);
          setViewImage("");
          setVideo(undefined);
        }
      });
    });
    setDeleteModal(false);
    setDeleteImageId(0);
    dispatch(resetFile());
    setDeleteVideo(undefined);
    dispatch(getEvent(event.id!));
  };

  const onSelectFile = (files: File[]) => {
    let avatar = URL.createObjectURL(files[0]);
    setCropImage(avatar);
  };

  const onCropComplete = async (croppedAreaPercentage: any, croppedAreaPixels: any) => {
    await setCroppedArea(croppedAreaPixels);
    const { file, url } = await getCroppedImg(cropImage, croppedAreaPixels);
    await setCropImagePreview(url);
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const closeCropModal = () => {
    setCropModal(false);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  };

  const handleMainImageChange = (id: number, url: string) => {
    dispatch(editEvent({ ...event, mainPhotoId: id })).then(() => {
      dispatch(getEvent(event.id!));
    });
    setMainImageId(id);
    setViewImage(url);
  };

  const handleVideoClick = (video: FileVM) => {
    setVideo(video);
    setViewImage(video.url);
    dispatch(startVideo(video.id));
  };

  const handleImageClick = (id: number, url: string) => {
    setVideo(undefined);
    setViewImage(url);
    dispatch(stopVideo(0));
  };

  return (
    <Box>
      <Container>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            {fileStatus === "loading" && !!uploadComplete && (
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                <Box sx={{ width: "100%", marginRight: 2 }}>
                  <LinearProgress
                    value={uploadComplete || 0}
                    color="primary"
                    variant="determinate"
                    style={{
                      height: 25,
                      backgroundColor: theme.palette.info.main,
                      borderRadius: 8,
                    }}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.primary">{`${uploadComplete || 0}%`}</Typography>
                </Box>
              </Box>
            )}
            <Field
              component={Inputs.Dropzone}
              label="Pictures"
              name="upload"
              onDrop={(files: File[]) => {
                if (files[0].type.split("/")[0] === "video") {
                  post(files[0]);
                } else {
                  setCropModal(true);
                  onSelectFile(files);
                }
              }}
            />
            <Grid container direction={"column"}>
              <Grid item xs={12}>
                {fileStatus === "loading" && !uploadComplete ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress color="primary" size="60px" />
                  </Box>
                ) : fileSearch.length > 0 ? (
                  <Carousel
                    files={event.id! > 0 && fileSearch.length ? fileSearch : []}
                    filesPerView={4}
                    onVideoClick={handleVideoClick}
                    onPictureClick={handleImageClick}
                    onDeleteClick={(id: number, url: string) => {
                      dispatch(getOneFileById(id)).then((response: any) => {
                        if (response.payload.fileType.id === 5) {
                          setDeleteModal(true);
                          setDeleteImageId(response.payload.id);
                          setDeleteVideo(response.payload);
                          dispatch(startVideo(response.payload.id));
                        } else {
                          setDeleteModal(true);
                          setDeleteImageId(id);
                          setDeleteImageUrl(url);
                        }
                      });
                    }}
                    onHeartClick={(id: number, url: string) => {
                      setMainImageId(id);
                      setViewImage(url);
                      handleMainImageChange(id, url);
                    }}
                    mainPhotoId={mainImageId > 0 ? mainImageId : event.mainPhotoId}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item alignSelf={"center"}>
                {video && videoPlaying?.id === video.id ? (
                  <Box
                    sx={{
                      objectFit: "cover",
                      width: { lg: 700, md: 525, xs: "100%" },
                    }}
                  >
                    <ReactPlayer
                      onEnded={() => dispatch(stopVideo(video.id))}
                      height="100%"
                      width="100%"
                      playing
                      controls={true}
                      url={video.url}
                    />
                  </Box>
                ) : viewImage ? (
                  <Box
                    component="img"
                    alt={`${event.name} picture`}
                    src={event.photos ? viewImage : ""}
                    sx={{
                      objectFit: "cover",
                      width: { lg: 700, md: 525, xs: "100%" },
                      borderRadius: 10,
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Container>
      <Modal open={cropModal} onClose={closeCropModal}>
        <ImageCropView
          eventPhoto
          cropImage={cropImage}
          crop={crop}
          zoom={zoom}
          setCrop={setCrop}
          setZoom={setZoom}
          onCropComplete={onCropComplete}
          closeCropModal={closeCropModal}
          uploadFiles={uploadFiles}
          cropImagePreview={cropImagePreview}
        />
      </Modal>
      <Dialog
        open={deleteModal}
        title={`Are you sure you would like to delete this ${
          newFile?.fileType?.id === 5 ? "video" : "image"
        }? This cannot be undone.`}
        warning
      >
        <Grid container direction={"column"}>
          <Grid item alignSelf={"center"}>
            {deleteVideo && videoPlaying?.id === deleteVideo.id ? (
              <ReactPlayer
                width={"100%"}
                controls={true}
                url={deleteVideo.url}
                onEnded={() => dispatch(stopVideo(deleteVideo.id))}
              />
            ) : (
              <img
                alt="upload"
                width={"150px"}
                height={"150px"}
                src={deleteImageUrl}
                style={{
                  borderRadius: "25px",
                  objectFit: "cover",
                  alignSelf: "center",
                }}
              />
            )}
          </Grid>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={handleDeleteImage}
            />
          </DialogActions>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default EventPhotoTab;
