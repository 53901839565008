import React from "react";
import TitleBar from "../base/TitleBar";
import { useSelector } from "../../store";
import EditCreateEventView from "../views/Events/EditCreateEventView";

interface Props {}

const EditCreateEventPage: React.FC<Props> = (props) => {
  const { event } = useSelector((store) => store.events);

  return (
    <>
      <TitleBar title={!event || event?.id === 0 ? "Create Event" : `${event?.name}`} />
      <EditCreateEventView />
    </>
  );
};

export default EditCreateEventPage;
