import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import User from "../types/user";

interface ReportState {
  status: "idle" | "loading" | "failed";
}

const initialState: ReportState = {
  status: "idle",
};

export const getReports = createAsyncThunk<
  boolean,
  {
    reportUrl: string;
    startDate?: Date;
    endDate?: Date;
    microchipCompanyId?: number;
    foster?: User;
    intakeStart?: Date;
    intakeEnd?: Date;
    adoptionStart?: Date;
    adoptionEnd?: Date;
    role?: number;
    active?: number;
    dogStatus?: number[];
    subStatus?: number[];
    auditStatus?: number[];
    source?: number[];
    sourceType?: number[];
    missingSource?: boolean;
    rabiesExpired?: boolean;
    missingChip?: boolean;
    missingColors?: boolean;
    missingMarkings?: boolean;
    unaltered?: boolean;
    reportName?: string;
    rabiesExpiring?: boolean;
    alterationExpiring?: boolean;
    dateFilterId?: number;
  }
>(
  `report/get`,
  async ({
    reportUrl,
    startDate,
    endDate,
    microchipCompanyId,
    foster,
    intakeStart,
    intakeEnd,
    adoptionStart,
    adoptionEnd,
    role,
    active,
    dogStatus,
    subStatus,
    auditStatus,
    source,
    sourceType,
    missingSource,
    rabiesExpired,
    missingChip,
    missingColors,
    missingMarkings,
    unaltered,
    reportName,
    rabiesExpiring,
    alterationExpiring,
    dateFilterId,
  }) => {
    let date = "";
    let companyId = "";
    let intakeDate = "";
    let adoptionDate = "";
    let fosterId = "";
    let roleId = "";
    let activeValue = "";
    let statusList = "";
    let subStatusList = "";
    let auditStatusList = "";
    let sourceList = "";
    let sourceTypeList = "";
    let sourceBool = "";
    let rabiesBool = "";
    let chipBool = "";
    let colorBool = "";
    let markingBool = "";
    let unalteredBool = "";
    let rabiesExpiration = "";
    let alterExpiration = "";
    let dateFilter = "";

    function isValidDate(d: any) {
      return d instanceof Date && !isNaN(d.getTime());
    }

    date += !!startDate && isValidDate(startDate) ? `?startDate=${startDate.toUTCString()}` : "";
    date +=
      !!endDate && isValidDate(endDate)
        ? startDate
          ? `&endDate=${endDate.toUTCString()}`
          : `?endDate=${endDate.toUTCString()}`
        : "";
    companyId += microchipCompanyId ? `&companyId=${microchipCompanyId}` : "";
    intakeDate += intakeStart && isValidDate(intakeStart) ? `?intakeStart=${intakeStart.toUTCString()}` : "";
    intakeDate +=
      intakeEnd && isValidDate(intakeEnd)
        ? intakeStart
          ? `&intakeEnd=${intakeEnd.toUTCString()}`
          : `?intakeEnd=${intakeEnd.toUTCString()}`
        : "";
    adoptionDate +=
      adoptionStart && isValidDate(adoptionStart)
        ? intakeDate
          ? `&adoptionStart=${adoptionStart.toUTCString()}`
          : `?adoptionStart=${adoptionStart.toUTCString()}`
        : "";
    adoptionDate +=
      adoptionEnd && isValidDate(adoptionEnd)
        ? adoptionStart
          ? `&adoptionEnd=${adoptionEnd.toUTCString()}`
          : `?adoptionEnd=${adoptionEnd.toUTCString()}`
        : "";
    fosterId += foster ? (intakeDate || adoptionDate ? `&fosterId=${foster.id}` : `?fosterId=${foster.id}`) : "";
    roleId += role ? (startDate || endDate ? `&roleId=${role}` : `?roleId=${role}`) : "";
    activeValue += active
      ? startDate || endDate || role
        ? `&active=${active === 1 ? true : false}`
        : `?active=${active === 1 ? true : false}`
      : "";

    statusList += dogStatus?.length ? `&${dogStatus.map((n) => `statusIds=${n}`).join("&")}` : "";
    subStatusList += subStatus?.length ? `&${subStatus.map((n) => `subStatusIds=${n}`).join("&")}` : "";

    auditStatusList += auditStatus?.length
      ? intakeStart || intakeEnd
        ? `&${auditStatus.map((n) => `statusIds=${n}`).join("&")}`
        : `?${auditStatus.map((n) => `statusIds=${n}`).join("&")}`
      : "";
    sourceList += source?.length
      ? auditStatus?.length
        ? `&${source.map((n) => `sourceIds=${n}`).join("&")}`
        : `?${source.map((n) => `sourceIds=${n}`).join("&")}`
      : "";
    sourceTypeList += sourceType?.length
      ? auditStatus?.length || source?.length
        ? `&${sourceType.map((n) => `sourceTypeIds=${n}`).join("&")}`
        : `?${sourceType.map((n) => `sourceTypeIds=${n}`).join("&")}`
      : "";
    sourceBool += missingSource
      ? auditStatus?.length || source?.length || sourceType?.length
        ? `&missingSource=${missingSource}`
        : `?missingSource=${missingSource}`
      : "";
    rabiesBool += rabiesExpired
      ? auditStatus?.length || source?.length || sourceType?.length || missingSource
        ? `&rabiesExpired=${rabiesExpired}`
        : `?rabiesExpired=${rabiesExpired}`
      : "";
    chipBool += missingChip
      ? auditStatus?.length || source?.length || sourceType?.length || missingSource || rabiesExpired
        ? `&missingChip=${missingChip}`
        : `?missingChip=${missingChip}`
      : "";
    colorBool += missingColors
      ? auditStatus?.length || source?.length || sourceType?.length || missingSource || rabiesExpired || missingChip
        ? `&missingColors=${missingColors}`
        : `?missingColors=${missingColors}`
      : "";
    markingBool += missingMarkings
      ? auditStatus?.length ||
        source?.length ||
        sourceType?.length ||
        missingSource ||
        rabiesExpired ||
        missingChip ||
        missingColors
        ? `&missingMarkings=${missingMarkings}`
        : `?missingMarkings=${missingMarkings}`
      : "";
    unalteredBool += unaltered
      ? auditStatus?.length ||
        source?.length ||
        sourceType?.length ||
        missingSource ||
        rabiesExpired ||
        missingChip ||
        missingColors ||
        missingMarkings
        ? `&unaltered=${unaltered}`
        : `?unaltered=${unaltered}`
      : "";
    rabiesExpiration += rabiesExpiring ? `&rabies=${rabiesExpiring}` : "";
    alterExpiration += alterationExpiring ? `&spayNeuter=${alterationExpiring}` : "";
    dateFilter += dateFilterId ? `?dateFilter=${dateFilterId}` : "";

    const response = await http.get<Blob>(
      `/reports/${reportUrl}${dateFilter}${date}${companyId}${statusList}${subStatusList}${intakeDate}${adoptionDate}${fosterId}${roleId}${activeValue}${auditStatusList}${sourceList}${sourceTypeList}${sourceBool}${rabiesBool}${chipBool}${colorBool}${markingBool}${unalteredBool}${rabiesExpiration}${alterExpiration}`,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${reportName ?? reportUrl}.xlsx`);
    document.body.appendChild(link);
    link.click();
    return true;
  }
);

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReports.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(getReports.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default reportSlice.reducer;
