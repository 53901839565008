import React, { useRef } from "react";
import ReactPlayer from "react-player";
import FileVM from "../../types/fileVM";
import { Box } from "@mui/material";

interface Props {
  video: FileVM;
  onVideoClick?: (video: FileVM) => void;
}

const VideoPlayer: React.FC<Props> = (props) => {
  const videoPlayerRef: React.LegacyRef<any> | undefined = useRef(null);

  const handleClickPreview = () => {
    videoPlayerRef.current.showPreview();
    props.onVideoClick?.(props.video);
  };

  return (
    <Box
      sx={{
        height: "100%",
        "& .react-player__preview": {
          borderRadius: "15px",
        },
      }}
    >
      <ReactPlayer
        ref={videoPlayerRef}
        height="100%"
        width="100%"
        light={true}
        muted
        onClickPreview={handleClickPreview}
        url={props.video.url}
        controls={false}
      />
    </Box>
  );
};

export default VideoPlayer;
