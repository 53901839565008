import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import { getAgeOptions, getBehaviorSolutions, getDogSizes, getGenderOptions } from "../../../slices/options";
import Preferences from "../../../types/application/preferences";
import { useDispatch, useSelector } from "../../../store";
import { replaceOtherDogs, replaceSelectedDog, savePreferencesStep } from "../../../slices/applications";
import { clearDogSearch, getDogsSearch } from "../../../slices/dogs";
import SearchParams from "../../../types/searchParams";
import DogSimple from "../../../types/dogSimple";

interface Props {
  formikRef?: React.RefObject<FormikProps<any>>;
}

const PreferencesStep: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { dogSearch } = useSelector((store) => store.dogs);
  const [filterForm, setFilterForm] = useState<SearchParams>({
    query: "",
    pageNumber: 1,
    pageSize: 10,
    filters: ["status:2", "status:4", "status:10"],
  });
  const { myApplication } = useSelector((store) => store.applications);

  useEffect(() => {
    dispatch(clearDogSearch());
  }, [dispatch]);

  const initialState: Preferences = useSelector((store) => ({
    acceptBarking: store.applications.myApplication?.acceptBarking || false,
    acceptChewing: store.applications.myApplication?.acceptChewing || false,
    acceptDigging: store.applications.myApplication?.acceptDigging || false,
    acceptHouseTraining: store.applications.myApplication?.acceptHouseTraining || false,
    acceptSocial: store.applications.myApplication?.acceptSocial || false,
    acceptSeparation: store.applications.myApplication?.acceptSeparation || false,
    acceptOther: store.applications.myApplication?.acceptOther || false,
    considerMixedBreed: store.applications.myApplication?.considerMixedBreed || false,
    considerOnlyPet: store.applications.myApplication?.considerOnlyPet || false,
    considerAfraid: store.applications.myApplication?.considerAfraid || false,
    considerOnMeds: store.applications.myApplication?.considerOnMeds || false,
    considerHealthCondition: store.applications.myApplication?.considerHealthCondition || false,
    considerUntrained: store.applications.myApplication?.considerUntrained || false,
    considerSpecialNeeds: store.applications.myApplication?.considerSpecialNeeds || false,
    genderPreference: store.applications.myApplication?.genderPreference,
    agePreference: store.applications.myApplication?.agePreference,
    sizePreference: store.applications.myApplication?.sizePreference,
    addressBehaviors: store.applications.myApplication?.addressBehaviors || [],
    whyNoBehaviors: store.applications.myApplication?.whyNoBehaviors || undefined,
    otherText: store.applications.myApplication?.otherText || undefined,
    otherDogs:
      [
        store.applications.myApplication?.selectedDog,
        ...(store.applications.myApplication?.otherDogs?.length ? store.applications.myApplication?.otherDogs! : []),
      ] || [],
  }));

  const handleSubmit = (values: any) => {
    dispatch(savePreferencesStep(values));
    const originalSelectedDog = values.otherDogs.some((dog: DogSimple) => dog.id === myApplication?.selectedDog?.id);

    if (originalSelectedDog) {
      const filteredList = values.otherDogs.filter((dog: DogSimple) => dog.id !== myApplication?.selectedDog?.id);
      dispatch(replaceOtherDogs(filteredList));
    } else {
      const newSelected = values.otherDogs[0];
      dispatch(replaceSelectedDog(newSelected));
      const filteredList = values.otherDogs.filter((dog: DogSimple) => dog.id !== newSelected.id);
      dispatch(replaceOtherDogs(filteredList));
    }
  };

  const validationSchema = Yup.object().shape({
    acceptBarking: Yup.boolean().nullable(),
    acceptChewing: Yup.boolean().nullable(),
    acceptDigging: Yup.boolean().nullable(),
    acceptHouseTraining: Yup.boolean().nullable(),
    acceptSocial: Yup.boolean().nullable(),
    acceptSeparation: Yup.boolean().nullable(),
    acceptOther: Yup.boolean().nullable(),
    considerMixedBreed: Yup.boolean().nullable(),
    considerOnlyPet: Yup.boolean().nullable(),
    considerAfraid: Yup.boolean().nullable(),
    considerOnMeds: Yup.boolean().nullable(),
    considerHealthCondition: Yup.boolean().nullable(),
    considerUntrained: Yup.boolean().nullable(),
    considerSpecialNeeds: Yup.boolean().nullable(),
    genderPreference: Yup.object().nullable().typeError("Delete typed input and select an option"),
    agePreference: Yup.object().nullable().typeError("Delete typed input and select an option"),
    sizePreference: Yup.object().nullable().typeError("Delete typed input and select an option"),
    addressBehaviors: Yup.array().when(
      [
        "acceptBarking",
        "acceptChewing",
        "acceptDigging",
        "acceptHouseTraining",
        "acceptSocial",
        "acceptSeparation",
        "acceptOther",
      ],
      {
        is: (
          barking: boolean,
          chewing: boolean,
          acceptDigging: boolean,
          acceptHouseTraining: boolean,
          acceptSocial: boolean,
          acceptSeparation: boolean,
          acceptOther: boolean
        ) =>
          !!barking ||
          !!chewing ||
          !!acceptDigging ||
          !!acceptHouseTraining ||
          !!acceptSocial ||
          !!acceptSeparation ||
          !!acceptOther,
        then: (schema) =>
          schema
            .of(Yup.object().typeError("Delete typed input and select an option").required("Required"))
            .min(1, "Selection Required"),
      }
    ),
    otherText: Yup.string().when("acceptOther", {
      is: (value: boolean) => !!value,
      then: (schema) => schema.required("Required"),
    }),
    whyNoBehaviors: Yup.string().when(
      [
        "acceptBarking",
        "acceptChewing",
        "acceptDigging",
        "acceptHouseTraining",
        "acceptSocial",
        "acceptSeparation",
        "acceptOther",
      ],
      {
        is: (
          barking: boolean,
          chewing: boolean,
          acceptDigging: boolean,
          acceptHouseTraining: boolean,
          acceptSocial: boolean,
          acceptSeparation: boolean,
          acceptOther: boolean
        ) =>
          !barking &&
          !chewing &&
          !acceptDigging &&
          !acceptHouseTraining &&
          !acceptSocial &&
          !acceptSeparation &&
          !acceptOther,
        then: (schema) => schema.required("Required"),
      }
    ),
    otherDogs: Yup.array()
      .of(Yup.object().typeError("Delete typed input and select an option").required("Required"))
      .min(1, "You've removed all dogs from this application. You must include one to continue.")
      .max(4, "Maximum four (4) dogs allowed per application. Please remove dog(s) to continue.")
      .required("Required"),
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      innerRef={props.formikRef}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ values }) => (
        <Form noValidate>
          <Grid container sx={{ paddingTop: "5px", marginBottom: 15 }}>
            <Grid item xs={12}>
              <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}>
                Which of these behaviors are you willing to tolerate?
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="acceptBarking" label={`Barking (Excessive)`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="acceptChewing" label={`Chewing (Inappropriate)`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="acceptDigging" label={`Digging`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="acceptHouseTraining" label={`House Training`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="acceptSocial" label={`Lack of Social Skills`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="acceptSeparation" label={`Separation Anxiety`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="acceptOther" label={`Other`} />
            </Grid>
            <Grid item xs={12} md={8}>
              {values.acceptOther && <Field component={Inputs.Text} name="otherText" label={`Enter Other`} required />}
            </Grid>
            <Grid item xs={12}>
              {(!!values.acceptBarking ||
                !!values.acceptChewing ||
                !!values.acceptDigging ||
                !!values.acceptHouseTraining ||
                !!values.acceptSocial ||
                !!values.acceptSeparation ||
                !!values.acceptOther) && (
                <Field
                  component={Inputs.OptionDropdown}
                  name="addressBehaviors"
                  label={"How will you address these behaviors? (Select all that apply)"}
                  searchFunction={getBehaviorSolutions}
                  multiple
                  required
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {!values.acceptBarking &&
                !values.acceptChewing &&
                !values.acceptDigging &&
                !values.acceptHouseTraining &&
                !values.acceptSocial &&
                !values.acceptSeparation &&
                !values.acceptOther && (
                  <Field component={Inputs.Text} name="whyNoBehaviors" label={"If none, why not?"} required />
                )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}>
                Would you consider adopting a dog that:
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="considerMixedBreed" label={`Is Mixed Breed`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="considerOnlyPet" label={`Needs to Be the Only Pet`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="considerAfraid" label={`Is Afraid of People`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="considerOnMeds" label={`Is On Meds`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="considerHealthCondition" label={`Has a Health Condition`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="considerUntrained" label={`Needs Training`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Switch} name="considerSpecialNeeds" label={`Is Special Needs`} />
            </Grid>
            <Grid item xs={12} md={8} />

            <Grid item xs={12}>
              <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}>
                New Dog Preferences
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={Inputs.OptionDropdown}
                searchFunction={getGenderOptions}
                name="genderPreference"
                label={`Gender`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={Inputs.OptionDropdown}
                searchFunction={getAgeOptions}
                name="agePreference"
                label={`Age`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={Inputs.OptionDropdown}
                searchFunction={getDogSizes}
                name="sizePreference"
                label={`Adult Size`}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography textAlign={"center"} mb={2} sx={{ typography: { sm: "body1", xs: "h5" } }}>
                Know any other dogs you'd like to apply for? Search for them here and add them to this application!
              </Typography>
              <Typography textAlign={"center"} mb={2} sx={{ typography: { sm: "body1", xs: "h5" } }}>
                Change your mind about any of the ones you've already applied for? Click the x next to their names below
                to remove them from your application.
              </Typography>
              <Field
                name="otherDogs"
                label={`Start typing to Search (Max: 4)`}
                multiple
                options={dogSearch?.results && filterForm.query ? dogSearch.results : []}
                component={Inputs.Dropdown}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterForm({ ...filterForm, query: e.currentTarget.value });
                  dispatch(getDogsSearch({ search: { ...filterForm, query: e.currentTarget.value } }));
                }}
                onSelected={() => setFilterForm({ ...filterForm, query: "" })}
                noOptionsText={filterForm.query === "" ? "Start typing to search" : undefined}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PreferencesStep;
