import React from "react";
import FosterView from "../views/FosterView";
import { Box } from "@mui/material";

interface Props {}

const FosterPage: React.FC<Props> = (props) => {
  return (
    <Box sx={{ marginTop: "24px" }}>
      <FosterView />
    </Box>
  );
};

export default FosterPage;
