import React from "react";
import TitleBar from "../base/TitleBar";
import { useSelector } from "../../store";
import EditCreateSource from "../views/Sources/EditCreateSource";

interface Props {}

const EditCreateSourcePage: React.FC<Props> = (props) => {
  const { source } = useSelector((store) => store.sources);

  return (
    <>
      <TitleBar title={!source || source?.id === 0 ? "Create Dog Source" : `${source?.name}`} />
      <EditCreateSource />
    </>
  );
};

export default EditCreateSourcePage;
