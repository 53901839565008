import React from "react";
import Application from "../../../../types/application";
import ReactPDF, { Text, View } from "@react-pdf/renderer";

interface Props {
  application?: Application;
  styles: ReactPDF.Styles;
}

const CarePage: React.FC<Props> = (props) => {
  const { application, styles } = props;
  return (
    <View style={styles.page}>
      <View>
        <Text style={styles.header}>Care</Text>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Do All Family Members Want a Dog?</Text>
          <Text style={styles.answerText}>{application?.familyWantsDog ? "Yes" : "No"}</Text>
        </View>
        {!application?.familyWantsDog && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Explain Who Does Not Want a Dog and Why:</Text>
            <Text style={styles.answerText}>{application?.oppositionExplanation}</Text>
          </View>
        )}
        <View style={styles.separator}>
          <Text style={styles.questionText}>Where Will the Dog Be Kept During the Day?</Text>
          <Text style={styles.answerText}>{application?.dogLocationDay}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Where Will the Dog Be Kept At Night?</Text>
          <Text style={styles.answerText}>{application?.dogLocationNight}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Max Hours Alone in 24 Hrs</Text>
          <Text style={styles.answerText}>{application?.maxHoursAlone}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Who Will Care for the Dog While on Vacation?</Text>
          <Text style={styles.answerText}>{application?.vacationHelp}</Text>
        </View>
        <View style={{ width: "100%", marginBottom: "35px" }}>
          <Text style={styles.questionText}>
            What Provisions Have You Made If You Are No Longer Able to Care For Dog?
          </Text>
          <Text style={styles.answerText}>{application?.dogCarePlan}</Text>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <View>
          <Text style={styles.mapQuestionText}>What Would Make You Give Up a Dog?</Text>
        </View>
        <View style={{ display: "flex", flexDirection: "column" }}>
          {application?.giveUpDogReasons?.map((reason) => {
            return (
              <View key={reason.id}>
                <Text style={styles.basicMapText}>{reason.value}</Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default CarePage;
