import { Typography, Box, DialogActions, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { deleteFile, getFilesById, postFile, startVideo, stopVideo } from "../../../slices/file";
import Dropzone from "../../base/Dropzone";
import Carousel from "../../base/Carousel";
import Dialog from "../../base/Dialog";
import ActionButton from "../../base/ActionButton";
import FileVM from "../../../types/fileVM";
import ReactPlayer from "react-player";
import theme from "../../../theme";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";

interface Props {
  applicantId: number;
}

const MediaView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { status: fileStatus, fileSearch, uploadComplete, videoPlaying } = useSelector((store) => store.file);
  const { loggedInUser } = useSelector((store) => store.auth);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(0);
  const [deleteImageUrl, setDeleteImageUrl] = useState("");
  const [viewImage, setViewImage] = useState("");
  const [video, setVideo] = useState<FileVM>();

  useEffect(() => {
    dispatch(getFilesById({ entityId: props.applicantId, typeId: 10 })).then((result: any) => {
      if (result.payload.length > 0) {
        setViewImage(result.payload[0].url);
      }
    });
  }, [dispatch]);

  const handleImageClick = (id: number, url: string) => {
    setVideo(undefined);
    setViewImage(url);
    dispatch(stopVideo(0));
  };

  const handleSelectFile = (file: File[]) => {
    const newFile = {
      id: 0,
      fileType: { id: file[0].type.split("/")[0] === "video" ? 11 : 10, value: "" },
      entityId: props.applicantId,
      url: "",
      upload: file[0],
    };
    dispatch(postFile(newFile)).then((result) => {
      dispatch(getFilesById({ entityId: props.applicantId, typeId: 10 })).then((result: any) => {
        if (viewImage === "") {
          setViewImage(result.payload[0].url);
        }
      });
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const handleDeleteImage = async () => {
    await dispatch(deleteFile(deleteImageId));
    await dispatch(getFilesById({ entityId: props.applicantId, typeId: 10 })).then((result: any) => {
      if (result.payload.length) {
        setViewImage(result.payload[0].url);
      } else {
        setViewImage("");
      }
    });
    setDeleteModal(false);
  };

  const handleVideoClick = (video: FileVM) => {
    setVideo(video);
    setViewImage(video.url);
    dispatch(startVideo(video.id));
  };

  return (
    <Box>
      {checkUserRole(loggedInUser!, UserRoles.Admin) && (
        <Box>
          {fileStatus === "loading" && !!uploadComplete && (
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
              <Box sx={{ width: "100%", marginRight: 2 }}>
                <LinearProgress
                  value={uploadComplete || 0}
                  color="primary"
                  variant="determinate"
                  style={{
                    height: 25,
                    backgroundColor: theme.palette.info.main,
                    borderRadius: 8,
                  }}
                />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.primary">{`${uploadComplete || 0}%`}</Typography>
              </Box>
            </Box>
          )}
          <Typography textAlign={"center"}>Upload Application Photos/Videos</Typography>
          <Dropzone
            onDrop={(file: File[]) => {
              handleSelectFile(file);
            }}
          />
        </Box>
      )}
      {fileSearch.length > 0 ? (
        <Carousel
          files={props.applicantId > 0 && fileSearch.length ? fileSearch : []}
          filesPerView={4}
          onPictureClick={handleImageClick}
          onVideoClick={handleVideoClick}
          onDeleteClick={
            checkUserRole(loggedInUser!, UserRoles.Admin)
              ? (id: number, url: string) => {
                  setDeleteModal(true);
                  setDeleteImageId(id);
                  setDeleteImageUrl(url);
                }
              : undefined
          }
        />
      ) : (
        <></>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        {video && videoPlaying?.id === video.id ? (
          <ReactPlayer controls={true} url={video.url} onEnded={() => dispatch(stopVideo(video.id))} />
        ) : viewImage && fileSearch.length > 0 ? (
          <Box
            component="img"
            alt={`application picture`}
            src={viewImage}
            sx={{
              objectFit: "cover",
              height: { lg: "400px", md: "350px", xs: "258px" },
              width: { lg: "400px", md: "350px", xs: "258px" },
              borderRadius: "25px",
            }}
          />
        ) : null}
      </Box>
      <Dialog open={deleteModal} title={`Are you sure you would like to delete this? This cannot be undone.`} warning>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <img
            alt="upload"
            width={"150px"}
            height={"150px"}
            src={deleteImageUrl}
            style={{
              borderRadius: "25px",
              objectFit: "cover",
              alignSelf: "center",
            }}
          />
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={fileStatus === "loading" ? true : false}
              color="error"
              onClick={handleDeleteImage}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MediaView;
