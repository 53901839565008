import React from "react";
import { Grid } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import { getGiveUpReasons } from "../../../slices/options";
import { saveCareStep } from "../../../slices/applications";
import { useDispatch, useSelector } from "../../../store";
import Care from "../../../types/application/care";
import KeyValue from "../../../types/keyValue";

interface Props {
  formikRef?: React.RefObject<FormikProps<any>>;
}

const CareStep: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const initialState: Care = useSelector((store) => ({
    familyWantsDog: store.applications.myApplication?.familyWantsDog,
    oppositionExplanation: store.applications.myApplication?.oppositionExplanation || "",
    dogLocationDay: store.applications.myApplication?.dogLocationDay,
    dogLocationNight: store.applications.myApplication?.dogLocationNight,
    maxHoursAlone:
      store.applications.myApplication?.maxHoursAlone == null
        ? undefined
        : store.applications.myApplication?.maxHoursAlone,
    vacationHelp: store.applications.myApplication?.vacationHelp,
    dogCarePlan: store.applications.myApplication?.dogCarePlan,
    giveUpDogReasons: store.applications.myApplication?.giveUpDogReasons || [],
    giveUpOther: store.applications.myApplication?.giveUpOther || "",
  }));

  const handleSubmit = (values: any) => {
    dispatch(saveCareStep(values));
  };

  const validationSchema = Yup.object().shape({
    familyWantsDog: Yup.boolean().nullable(),
    oppositionExplanation: Yup.string().when("familyWantsDog", {
      is: (value: boolean) => !value,
      then: (schema) => schema.required("Required"),
    }),
    dogLocationDay: Yup.string().required("Required"),
    dogLocationNight: Yup.string().required("Required"),
    maxHoursAlone: Yup.number()
      .required("Required")
      .test("noDecimal", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
    vacationHelp: Yup.string().required("Required"),
    dogCarePlan: Yup.string().required("Required"),
    giveUpDogReasons: Yup.array()
      .of(Yup.object().typeError("Delete typed input and select an option").required("Required"))
      .min(1, "Selection Required")
      .required("Required"),
    giveUpOther: Yup.string().when("giveUpDogReasons", {
      is: (val: KeyValue[]) => !!val && !!val.find((v) => v.id === 17),
      then: (schema) => schema.required("Required"),
    }),
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      innerRef={props.formikRef}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ values }) => (
        <Form noValidate>
          <Grid container sx={{ paddingTop: "5px" }}>
            <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <Field
                component={Inputs.Switch}
                name="familyWantsDog"
                label="Do all family members want a dog? (Click for yes)"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {!values.familyWantsDog && (
                <Field
                  component={Inputs.Text}
                  name="oppositionExplanation"
                  label="If no, who doesn't want a dog and why?"
                  required
                />
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                component={Inputs.Text}
                name="dogLocationDay"
                label="Where will the dog be kept during the day?"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                component={Inputs.Text}
                name="dogLocationNight"
                label="Where will the dog be kept at night?"
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Number} name="maxHoursAlone" label="Max Hours alone in 24hrs" required />
            </Grid>
            <Grid item xs={12} md={8}>
              <Field
                component={Inputs.Text}
                name="vacationHelp"
                label="Who will care for the dog while on vacation?"
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                component={Inputs.Text}
                name="dogCarePlan"
                label="What provisions have you made if you are no longer able to care for dog?"
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                component={Inputs.OptionDropdown}
                searchFunction={getGiveUpReasons}
                name="giveUpDogReasons"
                label="What would make you give up a dog? (Select all that apply)"
                required
                multiple
              />
            </Grid>
            {!!values.giveUpDogReasons && !!values.giveUpDogReasons.find((v: KeyValue) => v.id === 17) && (
              <Grid item xs={12} md={12}>
                <Field component={Inputs.TextArea} name="giveUpOther" label="Enter Other" required />
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CareStep;
