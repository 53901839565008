import React from "react";
import ResetPasswordView from "../views/Auth/ResetPasswordView";

interface Props {}

const ResetPasswordPage: React.FC<Props> = (props) => {
  return (
    <>
      <ResetPasswordView />
    </>
  );
};

export default ResetPasswordPage;
