import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../../store";
import Moment from "react-moment";
import Carousel from "../../base/Carousel";
import FileVM from "../../../types/fileVM";
import { MedicalServices } from "@mui/icons-material";
import { startVideo, stopVideo } from "../../../slices/file";
import ReactPlayer from "react-player";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import { getAdoptionsSearch } from "../../../slices/adoptions";
import Adoption from "../../../types/adoption";
import Modal from "../../base/Modal";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDogView from "./PdfDogView";
import PdfDogPreview from "./PdfDogPreview";
import theme from "../../../theme";
import ActionButton from "../../base/ActionButton";
import { getUserId } from "../../../slices/users";
import { getSource } from "../../../slices/sources";

interface Props {
  viewImage: string;
  getMainPhoto: (photos: FileVM[]) => void;
  setViewPhoto: (id: number) => void;
  handleCancelBack: () => void;
}

const ReadOnlyDog: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { dog } = useSelector((store) => store.dogs);
  const { adoptionSearchResults, status } = useSelector((store) => store.adoptions);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { user } = useSelector((store) => store.users);
  const { source } = useSelector((store) => store.sources);
  const { menuOpen } = useSelector((store) => store.admin);
  const { videoPlaying } = useSelector((store) => store.file);
  const { viewImage, getMainPhoto, setViewPhoto } = props;

  const [video, setVideo] = useState<FileVM>();
  const [adoption, setAdoption] = useState<Adoption | undefined>();
  const [printModalOpen, setPrintModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getAdoptionsSearch({
        search: {
          query: "",
          filters: [`dog:${dog.id}`],
          pageNumber: 1,
          pageSize: 5,
          orderBy: "id",
          orderDirection: "Descending",
        },
      })
    );
  }, [dispatch, dog.id]);

  useEffect(() => {
    getMainPhoto(dog.photos);
  }, [dog, dispatch]);

  useEffect(() => {
    if (adoptionSearchResults?.results.length) {
      let currentAdoption = adoptionSearchResults?.results.find((f: Adoption) => f.status?.id !== 4);
      if (!!currentAdoption) {
        setAdoption(currentAdoption);
      }
    } else {
      setAdoption(undefined);
    }
  }, [adoptionSearchResults]);

  const handleVideoClick = (video: FileVM) => {
    setVideo(video);
    setViewPhoto(video.id!);
    dispatch(startVideo(video.id));
  };

  const handleImageClick = (id: number) => {
    setVideo(undefined);
    setViewPhoto(id);
    dispatch(stopVideo(0));
  };

  const openPdf = async () => {
    await dispatch(getUserId(dog.foster?.id!));
    await dispatch(getSource(dog.dogOrigin?.id!));

    setPrintModalOpen(!printModalOpen);
  };

  const cancelPdf = () => {
    setPrintModalOpen(false);
  };

  return (
    <>
      <Grid container columnSpacing={5} px={{ xs: 2, md: 0 }}>
        <Grid item xl={4} lg={menuOpen ? 4 : 6} md={menuOpen ? 12 : 6} xs={12}>
          <Grid container direction={"column"} px="5px">
            <Grid item alignSelf={"center"}>
              {video && videoPlaying?.id === video.id ? (
                <Box
                  sx={{
                    padding: 1,
                    objectFit: "cover",
                    height: { md: "350px", xs: "258px" },
                    width: { md: "350px", xs: "258px" },
                  }}
                >
                  <ReactPlayer
                    onEnded={() => dispatch(stopVideo(video.id))}
                    height="100%"
                    width="100%"
                    playing
                    controls={true}
                    url={video.url}
                  />
                </Box>
              ) : viewImage ? (
                <Box
                  component="img"
                  alt={`${dog.name} picture`}
                  src={dog.photos ? viewImage : ""}
                  sx={{
                    objectFit: "cover",
                    height: { md: "350px", xs: "258px" },
                    width: { md: "350px", xs: "258px" },
                    borderRadius: "25px",
                  }}
                />
              ) : (
                // Replace with "Image coming soon stock picture"
                <Box>
                  <Typography>No Selected Picture</Typography>
                </Box>
              )}
            </Grid>
            <Grid item>
              <Carousel
                files={dog.photos}
                filesPerView={4}
                onPictureClick={handleImageClick}
                onVideoClick={handleVideoClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={0.5} md={2} sx={{ display: { xl: "none", lg: menuOpen ? "flex" : "none", xs: "none" } }} />
        <Grid item xl={8} lg={menuOpen ? 7.5 : 6} md={menuOpen ? 12 : 6} xs={12}>
          <Grid
            container
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            mb={3}
          >
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                ID
              </Typography>
              <Typography variant="body1">{dog.id}</Typography>
            </Grid>
            {/* Medical */}
            <Grid item md={3} xs={12}>
              {dog.medicalDog ? (
                <Grid container direction="row" justifyContent={"flex-start"} mb={1}>
                  <Grid item sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                    <MedicalServices color="primary" />
                    <Typography variant="body2">Medical Dog</Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item md={3} />
            <Grid item md={3}>
              <ActionButton text="Print" type="button" onClick={openPdf} />
            </Grid>
          </Grid>
          {/* Names, Breeds */}
          <Grid container spacing={1} justifyContent="center" alignItems={"baseline"} mb={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Dog Name
              </Typography>
              <Typography variant="body1">{dog.name}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Primary Breed
              </Typography>
              <Typography variant="body1">{dog.primaryBreed?.value}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Secondary Breed
              </Typography>
              <Typography variant="body1">{dog.secondaryBreed?.value ? dog.secondaryBreed.value : "None"}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Also Known As
              </Typography>
              <Typography variant="body1" sx={{ marginY: dog.alsoKnownAs ? 0 : "28px" }}>
                {dog.alsoKnownAs}
              </Typography>
            </Grid>
          </Grid>
          {/* Gender, fee, dates */}
          <Grid container spacing={1} justifyContent="center" alignItems={"baseline"} mb={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Gender
              </Typography>
              <Typography variant="body1">{dog.gender?.value}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Adoption Fee
              </Typography>
              <Typography variant="body1">{`$${dog.adoptionFee}.00`}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Intake Date
              </Typography>
              <Moment format="MM/DD/YYYY">{dog.intakeDate}</Moment>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Approximate Birth Date
              </Typography>
              <Moment format="MM/DD/YYYY">{dog.approximateBirthdate}</Moment>
            </Grid>
          </Grid>
          {/* Foster, Shelter, Microchips */}
          <Grid container spacing={1} justifyContent="center" alignItems={"baseline"} mb={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Foster
              </Typography>
              {dog.foster && dog.foster.value ? (
                <Box
                  onClick={() => window.open(`/admin/user/${dog.foster?.id}`, "_blank")}
                  sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                >
                  <Typography variant="body1">{dog.foster.value}</Typography>
                </Box>
              ) : (
                <Typography variant="body1">None</Typography>
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Source
              </Typography>
              {checkUserRole(loggedInUser!, UserRoles.Admin) && dog.dogOrigin ? (
                <Box
                  onClick={() => window.open(`/admin/sources/${dog.dogOrigin?.id}`, "_blank")}
                  sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                >
                  <Typography variant="body1">{dog.dogOrigin?.value}</Typography>
                </Box>
              ) : (
                <Typography variant="body1">{dog.dogOrigin ? dog.dogOrigin.value : "None"}</Typography>
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Microchip Company
              </Typography>
              <Typography variant="body1">{dog.microchipCompany ? dog.microchipCompany.value : "None"}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Microchip Number
              </Typography>
              <Typography variant="body1">{dog.microchipNumber ? dog.microchipNumber : "None"}</Typography>
            </Grid>
          </Grid>
          {/* License, rabies, and alter */}
          <Grid container spacing={1} justifyContent="flex-start" alignItems={"baseline"} mb={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Location
              </Typography>
              {dog.location && dog.location.value ? (
                <Box
                  onClick={() => window.open(`/admin/user/${dog.location?.id}`, "_blank")}
                  sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                >
                  <Typography variant="body1">{dog.location.value}</Typography>
                </Box>
              ) : (
                <Typography variant="body1">None</Typography>
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Rabies Due
              </Typography>
              {dog.rabiesDue ? <Moment format="MM/DD/YYYY">{dog.rabiesDue}</Moment> : "N/A"}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Alter Status (spay/neuter)
              </Typography>
              <Typography variant="body1">{dog.alterStatus?.value ?? "None"}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Alter Due Date
              </Typography>
              {dog.alterDue ? <Moment format="MM/DD/YYYY">{dog.alterDue}</Moment> : "N/A"}
            </Grid>
          </Grid>
          {/* Weight, markings, and coat */}
          <Grid container spacing={1} justifyContent="flex-start" alignItems={"baseline"} mb={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Weight
              </Typography>
              <Typography variant="body1">{dog.weight ? dog.weight.value : "Not selected"}</Typography>
            </Grid>

            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Markings
              </Typography>
              <Typography variant="body1">{dog.markings?.length ? dog.markings : "None"}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Coat Colors
              </Typography>
              {dog.coatColors?.length ? (
                <Typography variant="body1">
                  {dog.coatColors.map(
                    (color, index) => `${color.value}` + `${index + 1 !== dog.coatColors?.length ? ", " : ""}`
                  )}
                </Typography>
              ) : (
                <Typography variant="body1">No colors chosen</Typography>
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Mother
              </Typography>
              {dog.dogMother ? (
                <Box
                  onClick={() => window.open(`/admin/dog/${dog.dogMother?.id}`, "_blank")}
                  sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                >
                  <Typography variant="body1">{dog.dogMother.name}</Typography>
                </Box>
              ) : (
                <Typography variant="body1">None</Typography>
              )}
            </Grid>
          </Grid>
          {/* Status and disposition dates */}
          <Grid container justifyContent="flex-start" spacing={1} alignItems={"baseline"} mb={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Status
              </Typography>
              <Typography variant="body1">{`${dog.status?.value} ${
                dog.subStatus?.value ? `- ${dog.subStatus.value}` : ""
              }`}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Status Change Date:
              </Typography>
              {dog.statusUpdated ? <Moment format="MM/DD/YYYY">{dog.statusUpdated}</Moment> : "N/A"}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Disposition Date
              </Typography>
              {dog.dispositionDate ? (
                <Moment format="MM/DD/YYYY">{dog.dispositionDate}</Moment>
              ) : adoption && adoption.adoptionDate ? (
                <Moment format="MM/DD/YYYY">{adoption.adoptionDate}</Moment>
              ) : (
                "N/A"
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Adopter
              </Typography>
              {checkUserRole(loggedInUser!, UserRoles.Admin) && !!adoption && status !== "loading" ? (
                <Box
                  onClick={() => window.open(`/admin/user/${adoption.adopter?.id}`, "_blank")}
                  sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                >
                  <Typography variant="body1">
                    {adoption.adopter?.firstName} {adoption.adopter?.lastName}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body1">
                  {!!adoption ? `${adoption.adopter?.firstName} ${adoption.adopter?.lastName}` : "N/A"}
                </Typography>
              )}
            </Grid>
          </Grid>
          {/* Headline */}
          <Grid container spacing={1} justifyContent="flex-start" mb={3}>
            <Grid item xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Headline
              </Typography>
              <Typography variant="body1">{dog.headline}</Typography>
            </Grid>
          </Grid>
          {/* Description */}
          <Grid container spacing={1} justifyContent="flex-start" mb={5}>
            <Grid item md={12} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                Description
              </Typography>
              <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                {dog.description}
              </Typography>
              <Typography variant="body1" sx={{ lineHeight: "18px" }} textAlign="center" justifySelf="center">
                <br />
                PA kennel license #5310 • 100 W Main St Lansdale, PA 19446
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={printModalOpen} onClose={cancelPdf}>
        <Box
          sx={{
            borderRadius: 5,
            backgroundColor: theme.palette.background.default,
            padding: 2,
          }}
        >
          <PdfDogPreview adopter={adoption?.adopter!} />
          <Grid container direction={"row"} justifyContent={"space-between"} pt={2}>
            <Grid item sx={{ p: 1 }}>
              <ActionButton text="Cancel" type="button" onClick={cancelPdf} />
            </Grid>
            <Grid item sx={{ p: 1 }}>
              <PDFDownloadLink
                document={<PdfDogView dog={dog} adopter={adoption?.adopter} source={source} foster={user} />}
                fileName={`${dog.id}-${dog.name}-Details`}
              >
                <ActionButton text="Download" type="button" onClick={() => {}} />
              </PDFDownloadLink>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default ReadOnlyDog;
