import User from "../types/user";

export enum UserRoles {
  Admin = 1,
  Document,
  Foster,
  Inventory,
  Marketing,
  Personnel,
  Report,
  Volunteer,
}

const checkRole = (user: User, role: UserRoles): boolean => {
  if (!user || user.roles === undefined || user.roles.length < 1) return false;
  return !!user?.roles?.find((r) => r?.id === role);
};

export default checkRole;
