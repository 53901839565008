import React from "react";
import TitleBar from "../base/TitleBar";
import { useSelector } from "../../store";
import EditCreateVet from "../views/Vets/EditCreateVet";

interface Props {}

const EditCreateVetPage: React.FC<Props> = (props) => {
  const { vet } = useSelector((store) => store.vet);

  return (
    <>
      <TitleBar title={!vet || vet?.id === 0 ? "Create Vet" : `${vet?.clinicName}`} />
      <EditCreateVet />
    </>
  );
};

export default EditCreateVetPage;
