import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../../helpers/getAddressLink";
import HistoryAnswerView from "./HistoryAnswerView";

interface Props {}

const ApplicantView: React.FC<Props> = (props) => {
  const { adminApplication } = useSelector((store) => store.applications);

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"baseline"} mb={3}>
          <Grid item md={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Name
            </Typography>
            <Grid container direction={"row"}>
              <Grid
                item
                sx={{
                  mr:
                    adminApplication?.history &&
                    adminApplication?.history?.["Applicant.FirstName"] &&
                    adminApplication?.history?.["Applicant.FirstName"].length > 1
                      ? 1
                      : 0,
                }}
              >
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.FirstName"]}
                  answerString={
                    adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.firstName
                      ? adminApplication?.historicalApplicant?.firstName
                      : adminApplication?.applicant?.firstName
                  }
                  questionText=""
                  modalQuestionText="First Name"
                />
              </Grid>
              <Grid item>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.LastName"]}
                  answerString={
                    adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.lastName
                      ? adminApplication?.historicalApplicant?.lastName
                      : adminApplication?.applicant?.lastName
                  }
                  questionText=""
                  modalQuestionText="Last Name"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["Applicant.Age"]}
              answerString={adminApplication?.historicalApplicant?.age?.toString()}
              questionText="Age"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Email
            </Typography>
            <Typography variant="body1">
              {adminApplication?.status?.id === 10 && adminApplication.historicalApplicant?.email
                ? `${adminApplication?.historicalApplicant?.email}`
                : `${adminApplication?.applicant?.email}`}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="body1">
              {adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.phone ? (
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.Phone"]}
                  answerString={adminApplication?.historicalApplicant?.phone}
                  questionText="Phone Number(s)"
                  tel
                  modalQuestionText="Primary Phone"
                />
              ) : (
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.Phone"]}
                  answerString={adminApplication?.applicant?.phone}
                  questionText="Phone Number(s)"
                  tel
                  modalQuestionText="Primary Phone"
                />
              )}
            </Typography>
            <Typography variant="body1">
              {adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.secondaryPhone ? (
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.SecondaryPhone"]}
                  answerString={adminApplication?.historicalApplicant?.secondaryPhone}
                  questionText=""
                  modalQuestionText="Secondary Phone"
                  tel
                />
              ) : (
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.SecondaryPhone"]}
                  answerString={adminApplication?.applicant?.secondaryPhone}
                  questionText=""
                  modalQuestionText="Secondary Phone"
                  tel
                />
              )}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Address
            </Typography>
            <Grid container direction={"row"}>
              <Grid item mr={"5px"}>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faLocationDot}
                  size="sm"
                  onClick={() =>
                    window.open(
                      getAddressLink(
                        adminApplication?.status?.id === 10 && adminApplication.historicalApplicant?.address
                          ? adminApplication?.historicalApplicant?.address!
                          : adminApplication?.applicant?.address!
                      ),
                      "_blank"
                    )
                  }
                />
              </Grid>
              <Grid item>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.Address.Line1"]}
                  answerString={
                    adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.address?.line1
                      ? adminApplication?.historicalApplicant?.address?.line1
                      : adminApplication?.applicant?.address?.line1
                  }
                  questionText=""
                  modalQuestionText="Address Line 1"
                />
              </Grid>
            </Grid>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["Applicant.Address.Line2"]}
              answerString={
                adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.address?.line2
                  ? adminApplication?.historicalApplicant?.address?.line2
                  : adminApplication?.applicant?.address?.line2
              }
              questionText=""
              modalQuestionText="Address Line 2"
            />
            <Grid container direction={"row"}>
              <Grid
                item
                sx={{
                  mr:
                    adminApplication?.history &&
                    adminApplication.history["Applicant.Address.City"] &&
                    adminApplication.history["Applicant.Address.City"].length > 1
                      ? 1
                      : 0,
                }}
              >
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.Address.City"]}
                  answerString={
                    adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.address
                      ? adminApplication?.historicalApplicant?.address?.city + ","
                      : adminApplication?.applicant?.address?.city + ","
                  }
                  questionText=""
                  modalQuestionText="Address City"
                />
              </Grid>
              <Grid
                item
                sx={{
                  mr:
                    adminApplication?.history &&
                    adminApplication.history["Applicant.Address.State.Name"] &&
                    adminApplication.history["Applicant.Address.State.Name"].length > 1
                      ? 1
                      : 0,
                }}
              >
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.Address.State.Name"]}
                  answerString={
                    adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.address
                      ? adminApplication?.historicalApplicant?.address?.state?.value
                      : adminApplication?.applicant?.address?.state?.value
                  }
                  questionText=""
                  modalQuestionText="Address State"
                />
              </Grid>
              <Grid item>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["Applicant.Address.PostalCode"]}
                  answerString={
                    adminApplication?.status?.id === 10 || adminApplication?.historicalApplicant?.address
                      ? adminApplication?.historicalApplicant?.address?.postalCode
                      : adminApplication?.applicant?.address?.postalCode
                  }
                  questionText=""
                  modalQuestionText="Address Zip Code"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.Occupation}
              answerString={adminApplication?.occupation}
              questionText="Occupation"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["WorkSchedule.Value"]}
              answerString={adminApplication?.workSchedule?.value}
              questionText="Work Schedule"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AdoptionReason}
              answerString={adminApplication?.adoptionReason}
              questionText="Why Do You Want a Dog?"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["AdoptionTimeline.Value"]}
              answerString={adminApplication?.adoptionTimeline?.value}
              questionText="When Do You Want To Adopt?"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApplicantView;
