import React, { useRef, useState } from "react";
import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "../../../store";
import { AccessTime, AddHome } from "@mui/icons-material";
import theme from "../../../theme";
import HistoryAnswerView from "./HistoryAnswerView";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import Modal from "../../base/Modal";
import { setActiveApp } from "../../../slices/applications";

interface Props {}

const HomeView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { adminApplication } = useSelector((store) => store.applications);

  const [hover, setHover] = useState({ visible: false, id: 0 });
  const [menu, setMenu] = useState(false);

  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const renderTooltip = (itemList: any[]) => {
    return (
      <Box
        ref={anchorRef}
        onClick={
          sm
            ? () => {
                setMenu(true);
              }
            : undefined
        }
      >
        <Tooltip
          title={
            <Box sx={{ width: "fit-content" }}>
              {adminApplication?.history?.HouseholdResidents?.map((item) => {
                const res = JSON.parse(item.value);
                return (
                  <Box
                    onMouseEnter={() => setHover({ visible: true, id: item.id })}
                    onMouseLeave={() => setHover({ visible: false, id: 0 })}
                    color={
                      hover && item.id === hover.id ? theme.palette.secondary.main : theme.palette.primary.contrastText
                    }
                    sx={{ cursor: "pointer" }}
                    onClick={() => dispatch(setActiveApp(item.id))}
                  >
                    <Typography>
                      {`${res.FirstName} ${res.LastName}- `}
                      <Moment format="MM/DD/YYYY">{item.date}</Moment>
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          }
        >
          <AccessTime fontSize="small" />
        </Tooltip>
      </Box>
    );
  };

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"baseline"} mb={3}>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["ResidenceType.Value"]}
              answerString={adminApplication?.residenceType?.value}
              questionText="Type of Residence"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Residents in Home
            </Typography>
            <Typography variant="body1">{adminApplication?.residentsInHome! + 1}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: { md: 5, xs: 2 } }}>
            <Grid container spacing={2} mb={3}>
              <Grid
                item
                xs={1}
                md={1}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
              >
                <AddHome />
              </Grid>
              <Grid item md={5} xs={11}>
                <Typography variant="body2" fontStyle="italic">
                  Resident Number 1 (Applicant)
                </Typography>
                <Typography variant="body1">
                  {adminApplication?.applicant?.firstName} {adminApplication?.applicant?.lastName}
                </Typography>
              </Grid>
              <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
              <Grid item md={3} xs={11}>
                <Typography variant="body2" fontStyle="italic">
                  Age
                </Typography>
                <Typography variant="body1">{adminApplication?.applicant?.age}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                backgroundColor:
                  adminApplication?.history?.HouseholdResidents &&
                  adminApplication?.history?.HouseholdResidents.length &&
                  adminApplication?.history?.HouseholdResidents?.length > 0
                    ? theme.palette.secondary.main
                    : "inherit",
                // pl: 1,
              }}
            >
              {adminApplication?.householdResidents && adminApplication.householdResidents.length > 0
                ? adminApplication?.householdResidents.map((resident, index) => {
                    return (
                      <Grid container spacing={2} mb={3} key={resident.id}>
                        <Grid
                          item
                          xs={1}
                          md={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "25px",
                            flexDirection: "column",
                          }}
                        >
                          <AddHome />
                          {adminApplication?.history?.HouseholdResidents &&
                          adminApplication?.history?.HouseholdResidents.length &&
                          adminApplication?.history?.HouseholdResidents?.length > 0
                            ? renderTooltip(adminApplication?.history?.HouseholdResidents!)
                            : null}
                        </Grid>
                        <Grid item md={5} xs={11}>
                          <Typography variant="body2" fontStyle="italic">
                            Resident Number {index + 2}
                          </Typography>
                          <Typography variant="body1">
                            {resident.firstName} {resident.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                        <Grid item md={3} xs={6}>
                          <Typography variant="body2" fontStyle="italic">
                            Age
                          </Typography>
                          <Typography variant="body1">{resident.age}</Typography>
                        </Grid>
                        <Grid item md={3} xs={5}>
                          <Typography variant="body2" fontStyle="italic">
                            Dog Allergies
                          </Typography>
                          <Typography variant="body1">{resident.allergies === true ? "Yes" : "No"}</Typography>
                        </Grid>
                        <Grid item xs={1} md={1} />
                        <Grid item md={5} xs={6}>
                          <Typography variant="body2" fontStyle="italic">
                            {md ? "Relationship To Applicant" : "Relationship"}
                          </Typography>
                          <Typography variant="body1">{resident.relationship}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                          <Typography variant="body2" fontStyle="italic">
                            Dog Experience
                          </Typography>
                          <Typography variant="body1">{resident.dogExperience?.value}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })
                : adminApplication?.history?.HouseholdResidents?.length! > 0 &&
                  renderTooltip(adminApplication?.history?.HouseholdResidents!)}
            </Grid>
          </Grid>
          <Grid item md={4} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["HouseholdType.Value"]}
              answerString={adminApplication?.householdType?.value}
              questionText="Which BEST Describes Your Household?"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.YardFenced}
              answerString={adminApplication?.yardFenced ? "Yes" : "No"}
              questionText="Is Yard Fenced?"
            />
          </Grid>
          {adminApplication?.yardFenced === true ? (
            <>
              <Grid item md={4} xs={12}>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["FenceType.Value"]}
                  answerString={adminApplication?.fenceType?.value}
                  questionText="Fence Type"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["FenceHeight.Value"]}
                  answerString={adminApplication?.fenceHeight?.value}
                  questionText="Fence Height at Lowest Point"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.["FenceSize.Value"]}
                  answerString={adminApplication?.fenceSize?.value}
                  questionText="Fenced-In Yard Size"
                />
              </Grid>
            </>
          ) : (
            <Grid item md={4} xs={12}>
              <HistoryAnswerView
                historyArray={adminApplication?.history?.ExerciseLocation}
                answerString={adminApplication?.exerciseLocation}
                questionText="How & Where Will Dog Exercise & Eliminate?"
              />
            </Grid>
          )}
          <Grid item md={4} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["LivingSituation.Value"]}
              answerString={adminApplication?.livingSituation?.value}
              questionText="Living Situation"
            />
          </Grid>
          {adminApplication?.livingSituation?.id === 2 && (
            <>
              <Grid item md={4} xs={12}>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.LandlordAllowDogs}
                  answerString={adminApplication.landlordAllowDogs ? "Yes" : "No"}
                  questionText="Does Landlord Allow Dogs?"
                />
              </Grid>
              {adminApplication?.landlordAllowDogs === true && (
                <>
                  <Grid item md={4} xs={6}>
                    <HistoryAnswerView
                      historyArray={adminApplication?.history?.LandlordName}
                      answerString={adminApplication.landlordName}
                      questionText="Landlord Name"
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="body1">
                      <HistoryAnswerView
                        historyArray={adminApplication?.history?.LandlordPhone}
                        answerString={adminApplication.landlordPhone}
                        questionText="Landlord Phone"
                        tel
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <HistoryAnswerView
                      historyArray={adminApplication?.history?.DogSizeRestrictions}
                      answerString={adminApplication.dogSizeRestrictions ? "Yes" : "No"}
                      questionText="Dog Size Restrictions"
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <HistoryAnswerView
                      historyArray={adminApplication?.history?.DogBreedRestrictions}
                      answerString={adminApplication.dogBreedRestrictions ? "Yes" : "No"}
                      questionText="Breed Restrictions"
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Modal open={menu} onClose={() => setMenu(false)}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: 5,
          }}
        >
          <Typography textAlign={"center"}>Additional Residents in Home</Typography>
          <Box
            sx={{
              maxHeight: "250px",
              overflow: "auto",
              padding: "5px",
            }}
          >
            {adminApplication?.history?.HouseholdResidents?.map((item) => {
              const res = JSON.parse(item.value);

              return (
                <Box color={theme.palette.primary.main} sx={{ textDecoration: "underline", padding: "5px" }}>
                  <Typography onClick={() => dispatch(setActiveApp(item.id))}>
                    {`${res.FirstName} ${res.LastName}- `}

                    <Moment format="MM/DD/YYYY">{item.date}</Moment>
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default HomeView;
