import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, DialogActions, Grid, Tooltip, Typography } from "@mui/material";
import StyledTable from "../base/StyledTable";
import TableActions from "../base/TableActions";
import Search from "../base/Search";
import { Container } from "@mui/system";
import ActionButton from "../base/ActionButton";
import { deleteUser, getUserId, getUserSearch, resetUserValues, toggleActive } from "../../slices/users";
import Dialog from "../base/Dialog";
import { debounce } from "lodash";
import KeyValue from "../../types/keyValue";
import { useNavigate } from "react-router-dom";
import { getRoleOptions } from "../../slices/options";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../forms";
import User from "../../types/user";
import Switch from "../base/Switch";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../helpers/getAddressLink";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import { resetUserFilter, setShowApplicants, setUserFilter } from "../../slices/filters";

interface Props {}

const AdminUsersTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userSearch, user, status } = useSelector((store) => store.users);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { userFilter, showApplicants } = useSelector((store) => store.filters);
  const { userRoles } = useSelector((store) => store.options);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [reactivateModal, setReactivateModal] = useState(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getRoleOptions({ query: "", pageNumber: 1, pageSize: 10 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserSearch({ ...userFilter }));
  }, [dispatch, userFilter]);

  const handleDeleteOpen = (id: number) => {
    setDeleteModal(true);
    dispatch(getUserId(id));
    setDeleteId(id);
  };
  const handleDeleteUser = async (id: number) => {
    await dispatch(deleteUser(id)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(handleSuccessToastState(true));
        dispatch(setSuccessMessage("Person Successfully Deleted"));
      }
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
    dispatch(resetUserValues());
    setDeleteId(0);
  };

  const handleDeactivateOpen = (id: number) => {
    setDeactivateModal(true);
    dispatch(getUserId(id));
  };

  const handleDeactivateUser = async (id: number) => {
    await dispatch(toggleActive({ id: id, active: false })).then((result) => {
      dispatch(getUserSearch({ ...userFilter, query: "", pageNumber: 1, pageSize: 10 }));
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(handleSuccessToastState(true));
        dispatch(setSuccessMessage("Person Successfully Deactivated"));
      }
    });
  };

  const cancelDeactivate = () => {
    setDeactivateModal(false);
    dispatch(resetUserValues());
  };

  const handleReactivateOpen = (id: number) => {
    setReactivateModal(true);
    dispatch(getUserId(id));
  };

  const handleReactivateUser = async (id: number) => {
    await dispatch(toggleActive({ id: id, active: true })).then((result) => {
      dispatch(getUserSearch({ ...userFilter, query: "", pageNumber: 1, pageSize: 10 }));
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(handleSuccessToastState(true));
        dispatch(setSuccessMessage("Person Successfully Reactivated"));
      }
    });
  };

  const cancelReactivate = () => {
    setReactivateModal(false);
    dispatch(resetUserValues());
  };

  const updateSearch = debounce((query: string) => {
    dispatch(setUserFilter({ ...userFilter, pageNumber: 1, pageSize: 10, query: query }));
  }, 250);

  const handleFiltering = (e: KeyValue[], filterName: string) => {
    let filters = userFilter?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (e) {
      let newFilters: string[] = [];

      e.forEach((filter: KeyValue) => {
        let newFilterName = `${filterName}:${filter.id}`;
        newFilters.push(newFilterName);
      });

      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      dispatch(setUserFilter({ ...userFilter, pageNumber: 1, pageSize: 10, filters: newList }));
    } else {
      dispatch(setUserFilter({ ...userFilter, pageNumber: 1, pageSize: 10, filters: otherFilters }));
    }
  };

  const handleSort = (e: any) => {
    // if (e.target.id.includes("Operator")) {
    //   dispatch(
    //     getLogSearch({
    //       ...filterForm,
    //       orderBy: "operator",
    //       orderDirection: operatorOrderDirection === 1 ? "Descending" : "Ascending",
    //     })
    //   ).then(() => {
    //     if (operatorOrderDirection === 1) {
    //       setOperatorOrderDirection(2);
    //       setDateOrderDirection(1);
    //       setFilterForm({ ...filterForm, orderBy: "operator", orderDirection: "Descending" });
    //     } else if (operatorOrderDirection === 2) {
    //       setOperatorOrderDirection(1);
    //       setDateOrderDirection(1);
    //       setFilterForm({ ...filterForm, orderBy: "operator", orderDirection: "Ascending" });
    //     }
    //   });
    // } else if (e.target.id.includes("Date")) {
    //   dispatch(
    //     getLogSearch({
    //       ...filterForm,
    //       orderBy: "date",
    //       orderDirection: dateOrderDirection === 1 ? "Descending" : "Ascending",
    //     })
    //   ).then(() => {
    //     if (dateOrderDirection === 1) {
    //       setDateOrderDirection(2);
    //       setOperatorOrderDirection(1);
    //       setFilterForm({ ...filterForm, orderBy: "date", orderDirection: "Descending" });
    //     } else if (dateOrderDirection === 2) {
    //       setDateOrderDirection(1);
    //       setOperatorOrderDirection(1);
    //       setFilterForm({ ...filterForm, orderBy: "date", orderDirection: "Ascending" });
    //     }
    //   });
    // }
  };

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setUserFilter({ ...userFilter, pageNumber: pageNumber, pageSize: pageSize }));
  };

  const handleCheckbox = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    let filter: KeyValue = {
      id: checked ? 2 : 1,
      value: "",
    };
    handleFiltering([filter], "admin");
    dispatch(setShowApplicants(checked));
  };

  const headers = ["Actions", "ID", "Name", "Location", "Phone", "Email", "Roles", "Status"];

  const userStatus = [
    { id: 1, value: "Active" },
    { id: 2, value: "Inactive" },
  ];

  const rows =
    userSearch?.results.map((user: User) => {
      return {
        actions:
          user.active === true ? (
            <TableActions
              id={user.id}
              handleEdit={
                checkUserRole(loggedInUser!, UserRoles.Admin) ||
                (checkUserRole(loggedInUser!, UserRoles.Personnel) && loggedInUser?.id !== user.id)
                  ? async () => {
                      await dispatch(getUserId(user.id));
                      await navigate(`/admin/user/${user.id}`, { state: { edit: true } });
                    }
                  : undefined
              }
              handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
              handleView={async () => {
                await dispatch(getUserId(user.id));
                await navigate(`/admin/user/${user.id}`);
              }}
              handleDeactivate={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeactivateOpen : undefined}
            />
          ) : (
            <TableActions
              id={user.id}
              handleEdit={
                checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel)
                  ? async () => {
                      await dispatch(getUserId(user.id));
                      await navigate(`/admin/user/${user.id}`, { state: { edit: true } });
                    }
                  : undefined
              }
              handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
              handleView={async () => {
                await dispatch(getUserId(user.id));
                await navigate(`/admin/user/${user.id}`);
              }}
              handleReactivate={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleReactivateOpen : undefined}
            />
          ),
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        location:
          user.address?.line1 && user.address.city && user.address.state && user.address.postalCode ? (
            <Box>
              {user.address?.city}, {user.address?.state?.value}{" "}
              <Tooltip
                title={
                  <Box>
                    <Box display="flex" flexDirection="row">
                      {user.address.line1}
                    </Box>
                    {user.address.line2 && <Box>{user.address.line2}</Box>}
                    <Box display="flex" flexDirection="row">
                      {user.address?.city}, {user.address?.state?.value} {user.address.postalCode}
                    </Box>
                  </Box>
                }
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faLocationDot}
                  size="sm"
                  onClick={() => window.open(getAddressLink(user.address!), "_blank")}
                />
              </Tooltip>
            </Box>
          ) : (
            ""
          ),
        phone: user.phone,
        email: user.email,
        roles: user.roles.map((role) => {
          return <Box key={role.id}>{role.value}</Box>;
        }),
        status: user.active === true ? "Active" : "Inactive",
      };
    }) || [];

  const handleRoleFilter = (value: KeyValue[]) => {
    handleFiltering(value, "role");
  };

  const handleClear = () => {
    dispatch(resetUserFilter());
  };

  const handleReset = (resetForm: () => void) => {
    resetForm();
  };

  const initialStatus = () => {
    if (userFilter.filters?.length) {
      const statusFilter = userFilter.filters.filter((filter) => filter.includes("active"));

      const status = userStatus.find((status) => status.id === parseInt(statusFilter.toString().split(":")[1]));
      return status;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: -1 }}>
      <Container>
        <Formik
          initialValues={{ role: [], active: !!initialStatus() ? [initialStatus()] : [] }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {(formProps: any) => {
            return (
              <Form noValidate>
                <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
                  <Container>
                    <Grid container spacing={1} alignItems="center" sx={{ paddingBottom: 1 }}>
                      <Grid item sm={9} xs={12} justifyContent="flex-start" display="flex">
                        <Switch label="Include Applicants" checked={showApplicants} onChange={handleCheckbox} />
                      </Grid>
                      <Grid item sm={3} display="flex" justifyContent="flex-end" gap={2}>
                        {(checkUserRole(loggedInUser!, UserRoles.Admin) ||
                          checkUserRole(loggedInUser!, UserRoles.Personnel)) && (
                          <ActionButton
                            type="button"
                            text="Add New"
                            color="primary"
                            onClick={() => {
                              dispatch(resetUserValues());
                              navigate(`/admin/user/0`);
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item md={5} justifyContent="flex-end" sx={{ display: { sm: "none", xs: "flex" } }}>
                        <ActionButton
                          text={showFilters ? "Hide Filters" : "Show Filters"}
                          onClick={() => setShowFilters(!showFilters)}
                          type="button"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        justifyContent="flex-end"
                        display="flex"
                        gap={2}
                        sx={{ display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}
                      >
                        <ActionButton
                          type="reset"
                          text="Reset"
                          color="success"
                          onClick={async (event) => {
                            await handleClear();
                            await handleReset.bind(formProps.resetForm);
                            await handleCheckbox(event, false);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Grid container sx={{ paddingBottom: 1, display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="role"
                      label="Role"
                      component={Inputs.OptionDropdown}
                      searchFunction={getRoleOptions}
                      onSelected={handleRoleFilter}
                      multiple
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="active"
                      label="Status"
                      component={Inputs.Dropdown}
                      options={userStatus}
                      multiple
                      onSelected={(event: React.SyntheticEvent, value: any | Array<any>) =>
                        handleFiltering(value, "active")
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Search label="Search" handleChange={updateSearch} value={userFilter.query} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
      <Box margin={1}>
        {status === "loading" && !userSearch ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" sx={{ textAlign: "center", marginY: status === "loading" ? 0 : "29px" }}>
              {status === "loading" ? `Loading, please wait...` : <></>}
            </Typography>
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={userFilter.pageNumber}
                rowsPerPage={userFilter.pageSize}
                totalRows={userSearch?.totalResults}
                changePage={changePage}
                sortOptions={handleSort}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No people to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you would like to delete ${user?.firstName} ${user?.lastName}? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={async () => {
                setDeleteModal(false);
                await handleDeleteUser(deleteId);
                dispatch(resetUserValues());
                setDeleteId(0);
                await dispatch(getUserSearch({ query: "" }));
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={deactivateModal}
        title={`Are you sure you would like to deactivate ${user?.firstName} ${user?.lastName}?`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDeactivate} />
            <ActionButton
              type="button"
              text={"Deactivate"}
              disabled={status === "loading" ? true : false}
              color="secondary"
              onClick={async () => {
                setDeactivateModal(false);
                await handleDeactivateUser(user.id);
                dispatch(resetUserValues());
                await dispatch(getUserSearch({ query: "" }));
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={reactivateModal}
        title={`Are you sure you would like to reactivate ${user?.firstName} ${user?.lastName}?`}
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelReactivate} />
            <ActionButton
              type="button"
              text={"Reactivate"}
              disabled={status === "loading" ? true : false}
              color="secondary"
              onClick={async () => {
                setReactivateModal(false);
                await handleReactivateUser(user.id);
                dispatch(resetUserValues());
                await dispatch(getUserSearch({ query: "" }));
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AdminUsersTable;
