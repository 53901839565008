import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Tooltip, Typography } from "@mui/material";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApplicationSimple from "../../types/applicationSimple";
import StyledTable from "../base/StyledTable";
import Moment from "react-moment";
import TableActions from "../base/TableActions";
import { getApplication, getApplicationsSearch, setActiveApp } from "../../slices/applications";
import { useNavigate } from "react-router-dom";
import getAddressLink from "../../helpers/getAddressLink";
import SearchParams from "../../types/searchParams";
import Adoption from "../../types/adoption";

interface Props {
  dogId: number;
}

const DogApplicationTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { applicationsSearchResults, status } = useSelector((store) => store.applications);

  const [filterForm, setFilterForm] = useState<SearchParams>({
    query: "",
    filters: [`dog:${props.dogId}`, "finished:1"],
    pageNumber: 1,
    pageSize: 5,
  });

  useEffect(() => {
    dispatch(
      getApplicationsSearch({
        search: { ...filterForm },
      })
    );
  }, [dispatch, filterForm]);

  const changePage = (pageNumber: number, pageSize: number) => {
    setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
  };

  const handleView = (app: ApplicationSimple) => {
    dispatch(setActiveApp(app.id));
    window.open(`/admin/applications/${app.id}`, "_blank");
  };

  const applicationHeaders = ["Actions", "Date", "Name", "Location", "Dog Interest", "Status"];

  const applicationRows =
    (!!props.dogId &&
      applicationsSearchResults?.results.map((app: ApplicationSimple) => {
        return {
          actions: <TableActions id={app.id} handleView={() => handleView(app)} disableView={app.status?.id === 1} />,
          submitted: app.submittedDate && <Moment format="MM/DD/YYYY">{app.submittedDate!}</Moment>,
          name: `${app.applicant.firstName} ${app.applicant.lastName}`,
          location: app.applicant.address ? (
            <Box>
              {app.applicant.address?.city}, {app.applicant.address?.state?.value}{" "}
              <Tooltip
                title={
                  <Box>
                    <Box display="flex" flexDirection="row">
                      {app.applicant.address.line1}
                    </Box>
                    {app.applicant.address.line2 && <Box>{app.applicant.address.line2}</Box>}
                    <Box display="flex" flexDirection="row">
                      {app.applicant.address?.city}, {app.applicant.address?.state?.value}{" "}
                      {app.applicant.address.postalCode}
                    </Box>
                  </Box>
                }
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faLocationDot}
                  size="sm"
                  onClick={() => window.open(getAddressLink(app.applicant.address!), "_blank")}
                />
              </Tooltip>
            </Box>
          ) : (
            ""
          ),
          interest: app.selectedDog?.id === props.dogId ? "Primary" : "Secondary",
          status: app.status?.value,
        };
      })) ||
    [];

  return !!props.dogId ? (
    <Box sx={{ marginTop: 3 }}>
      {status === "loading" && <Typography>Loading applications, please wait...</Typography>}
      {applicationsSearchResults?.results.length && status === "idle" ? (
        <StyledTable
          headers={applicationHeaders}
          rows={applicationRows}
          defaultPaging
          paging={true}
          sizeVariant="small"
          title={
            <Typography variant="body2" padding="6.5px">
              Adoption Applications
            </Typography>
          }
          totalRows={applicationsSearchResults?.totalResults}
          page={applicationsSearchResults.page}
          changePage={changePage}
        />
      ) : null}
    </Box>
  ) : (
    <></>
  );
};

export default DogApplicationTable;
