import { Box, Container, Grid } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import ActionButton from "../../base/ActionButton";
import { Inputs } from "../../forms";
import * as Yup from "yup";
import { getAlterStatus, getDogColors, getDogWeights, getMicrochipSearch } from "../../../slices/options";
import { useDispatch, useSelector } from "../../../store";
import theme from "../../../theme";
import { clearAdoption } from "../../../slices/adoptions";
import SearchParams from "../../../types/searchParams";
import SnackBarToast from "../../base/SnackbarToast";
import { getDogsSearch } from "../../../slices/dogs";
import DogSimple from "../../../types/dogSimple";
import { getUserSearch } from "../../../slices/users";

interface Props {
  initialValues: any;
  handleSubmit: (values: any) => Promise<void>;
  loading: boolean;
  handleCancelBack: () => void;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
  formikRef: React.RefObject<FormikProps<any>>;
  setDirtyForm: React.Dispatch<React.SetStateAction<boolean>>;
  dirtyForm: boolean;
}

const AdditionalDogInfoTab: React.FC<Props> = (props) => {
  const { initialValues, handleSubmit, loading, handleCancelBack, formikRef, setDirtyForm, dirtyForm } = props;
  const dispatch = useDispatch();
  const { status, dogSearch } = useSelector((store) => store.dogs);
  const [saveReminder, setSaveReminder] = useState(false);
  const [filterForm, setFilterForm] = useState<SearchParams>({
    query: "",
    pageNumber: 1,
    pageSize: 10,
    filters: [],
  });

  useEffect(() => {
    return () => {
      dispatch(clearAdoption());
    };
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    microchipCompany: Yup.object().nullable(),
    microchipNumber: Yup.string(),
    dogMother: Yup.object().nullable(),
    location: Yup.object().nullable(),
    weight: Yup.object().nullable(),
    markings: Yup.string().nullable(),
    coatColors: Yup.array().of(Yup.object().typeError("Delete typed input and select an option").required("Required")),
    paLicense: Yup.number().nullable(),
    rabiesDue: Yup.date().nullable(),
    alterStatus: Yup.object().nullable(),
  });

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }) => (
          <Form
            noValidate
            onChange={() => {
              if (!dirtyForm) {
                setDirtyForm(true);
              }
            }}
          >
            {/* microchip, and license */}
            <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
              <Grid item md={4} xs={12}>
                <Field
                  name="microchipCompany"
                  label="Chip Company"
                  component={Inputs.OptionDropdown}
                  searchFunction={getMicrochipSearch}
                  onSelected={() => {
                    if (!dirtyForm) {
                      setDirtyForm(true);
                    }
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field name="microchipNumber" label="Chip Number" component={Inputs.Text} />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="location"
                  label="Location"
                  component={Inputs.OptionDropdown}
                  searchFunction={getUserSearch}
                  filterForm={{
                    pageNumber: 1,
                    pageSize: 10,
                    filters: ["role:3"],
                  }}
                />{" "}
              </Grid>
            </Grid>
            {/* rabies, and alter status */}
            <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
              <Grid item md={4} xs={12}>
                <Field name="rabiesDue" label="Rabies Due" component={Inputs.Datepicker} />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="alterStatus"
                  label="Alter Status (spay/neuter)"
                  component={Inputs.OptionDropdown}
                  searchFunction={getAlterStatus}
                  onSelected={(e: React.SyntheticEvent, v: any) => {
                    setFieldValue("alterDue", undefined);
                    if (!dirtyForm) {
                      setDirtyForm(true);
                    }
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                {values.alterStatus && values.alterStatus.id === 2 ? (
                  <Field
                    name="alterDue"
                    label="Alter Due Date"
                    component={Inputs.Datepicker}
                    handleChange={() => {
                      if (!dirtyForm) {
                        setDirtyForm(true);
                      }
                    }}
                  />
                ) : (
                  <Field name="alterDue" label="Alter Due Date" component={Inputs.Text} disabled />
                )}
              </Grid>
            </Grid>
            {/* weight, markings, and colors */}
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item md={4} xs={12} sx={{ mb: { sm: "8px", md: 0 } }}>
                <Field
                  name="weight"
                  label="Weight"
                  component={Inputs.OptionDropdown}
                  searchFunction={getDogWeights}
                  onSelected={() => {
                    if (!dirtyForm) {
                      setDirtyForm(true);
                    }
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12} sx={{ mb: { sm: "8px", md: 0 } }}>
                <Field name="markings" label="Markings" component={Inputs.Text} />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="coatColors"
                  label="Coat Colors"
                  component={Inputs.OptionDropdown}
                  searchFunction={getDogColors}
                  multiple
                  onSelected={() => {
                    if (!dirtyForm) {
                      setDirtyForm(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
            {/* mother */}
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item md={4} xs={12} sx={{ mb: { sm: "8px", md: 0 } }}>
                <Field
                  name="dogMother"
                  label="Mother"
                  options={
                    dogSearch?.results && filterForm.query
                      ? dogSearch.results.map((dog: DogSimple) => {
                          return {
                            id: dog.id,
                            value: `${dog.id} - ${dog.name} (${dog.primaryBreed.value})`,
                          };
                        })
                      : []
                  }
                  component={Inputs.Dropdown}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFilterForm({ ...filterForm, query: e.currentTarget.value });
                    dispatch(getDogsSearch({ search: { ...filterForm, query: e.currentTarget.value } }));
                  }}
                  onSelected={() => setFilterForm({ ...filterForm, query: "" })}
                  noOptionsText={filterForm.query === "" ? "Start typing to search" : undefined}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 2,
                position: "sticky",
                bottom: 0,
                backgroundColor: theme.palette.primary.contrastText,
                paddingY: 2,
                zIndex: 1000,
              }}
            >
              <ActionButton type="button" text="Cancel" onClick={handleCancelBack} color="secondary" />
              <Inputs.Submit isSubmitting={status === "loading"} text="Save" color="primary" />
            </Box>
          </Form>
        )}
      </Formik>
      <SnackBarToast
        open={saveReminder}
        message={"Adoption record added! Remember to click the save button!"}
        type="success"
        handleClose={() => setSaveReminder(false)}
        warningText
      />
    </Container>
  );
};

export default AdditionalDogInfoTab;
