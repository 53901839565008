import { Card, CardContent, Typography, Box, CardActionArea, Grid } from "@mui/material";
import React, { useState } from "react";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";
import { Female, CalendarMonth, Favorite } from "@mui/icons-material";
import IconButton from "./IconButton";

interface Props {
  image: string;
}

const DogCardExample: React.FC<Props> = (props) => {
  const { image } = props;
  const [hover, setHover] = useState(false);

  return (
    <Card
      sx={{
        maxWidth: 275,
        maxHeight: 275,
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <CardActionArea onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} disableRipple>
        {hover && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                color: theme.palette.primary.contrastText,
                textAlign: "center",
                paddingX: 5,
                paddingTop: "75px",
              }}
            >
              Example
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            height: 165,
            zIndex: 5000,
            width: 275,
            left: 0,
            right: 0,
            overflow: "hidden",
            backgroundColor: "#000",
            border: `2px solid ${theme.palette.primary.contrastText}`,
            borderRadius: "28% 28% 100% 100%/50% 50% 30% 30%",
          }}
        >
          <Box
            component="img"
            alt="Example photo"
            src={image}
            sx={{
              width: 275,
              height: 275,
              opacity: hover ? 0.25 : 1,
              objectFit: "cover",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 15,
            left: 15,
            backgroundColor: theme.palette.primary.contrastText,
            borderRadius: 25,
            display: { xs: "none", sm: "flex" },
          }}
          onClick={(e) => {}}
        >
          <Box
            sx={{
              userSelect: "none",
              display: "flex",
              justifyContent: "center",
              borderRadius: 20,
              backgroundColor: theme.palette.primary.main,
              width: "fit-content",
              border: "2px solid white",
            }}
          >
            <Typography variant="body1" marginX="8px" color={theme.palette.primary.contrastText}>
              Adopt
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 15,
            right: 15,
            color: `${theme.palette.error.main}50`,
            backgroundColor: theme.palette.primary.contrastText,
            borderRadius: 25,
            display: { xs: "none", sm: "flex" },
          }}
          onClick={(e) => {}}
        >
          <IconButton icon={<Favorite />} label="Favorite" onClick={() => {}} size={"12px"} />
        </Box>
      </CardActionArea>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          cursor: "pointer",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Grid container direction={"row"} alignItems="center" justifyContent={"space-around"}>
          <Grid item>
            <Box alignSelf={"center"}>
              <Typography align="center" variant="h1" sx={{ color: theme.palette.primary.contrastText }}>
                Example
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                flex: 1,
                whiteSpace: "nowrap",
              }}
            >
              <Icon type={IconType.DogsFilled} color={theme.palette.primary.contrastText} size={"20px"} />
              <Typography variant="caption" sx={{ color: theme.palette.primary.contrastText }}>
                Example
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginY: 1, gap: 1 }}>
              <Female fontSize="small" sx={{ color: theme.palette.primary.contrastText }} />
              <Typography variant="caption" sx={{ color: theme.palette.primary.contrastText }}>
                Example
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
              <CalendarMonth fontSize="small" sx={{ color: theme.palette.primary.contrastText }} />
              <Typography variant="caption" sx={{ color: theme.palette.primary.contrastText }}>
                Example
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DogCardExample;
