import { Delete, Edit } from "@mui/icons-material";
import { DialogActions, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Note from "../../types/note";
import IconButton from "../base/IconButton";
import { useDispatch, useSelector } from "../../store";
import theme from "../../theme";
import Moment from "react-moment";
import Dialog from "../base/Dialog";
import ActionButton from "../base/ActionButton";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import NoteUpload from "../base/NoteUpload";
import { editNote } from "../../slices/notes";

interface Props {
  note: Note;
  onDelete: (note: Note) => void;
  onNoteEdit?: () => void;
}

const NoteDisplay: React.FC<Props> = (props) => {
  const { note, onDelete, onNoteEdit } = props;
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const [noteDeleteModal, setNoteDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const cancelDelete = () => {
    setNoteDeleteModal(false);
  };

  return (
    <Grid item sx={{ marginY: 2 }}>
      <Grid
        container
        direction={"column"}
        sx={{
          backgroundColor: theme.palette.background.default,
          paddingTop: "15px",
          paddingLeft: "15px",
          borderRadius: "10px",
        }}
      >
        {editMode ? (
          <Grid item sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <NoteUpload
              onNoteAdd={(editedNote: string) => {
                dispatch(editNote({ ...note, value: editedNote })).then(() => {
                  if (onNoteEdit) {
                    onNoteEdit();
                  }
                });
                setEditMode(false);
              }}
              editNote={note}
              onCancelEdit={() => setEditMode(false)}
            />
          </Grid>
        ) : (
          <>
            <Grid item sx={{ marginBottom: 2 }}>
              <Typography variant="h3">
                Added by: {note.addedByUser?.firstName} {note.addedByUser?.lastName} on{" "}
                <Moment format="MM/DD/YYYY">{note.addedDate}</Moment>
              </Typography>
            </Grid>

            <Grid item sx={{ paddingRight: "15px" }}>
              <Typography variant="body1" component={"span"}>
                {note?.value?.split("\n").map((i, key) => {
                  return (
                    <p key={key} style={{ marginTop: 0, marginBottom: 0 }}>
                      {i}
                    </p>
                  );
                })}
              </Typography>
            </Grid>
            <Grid item alignSelf={"flex-end"}>
              <Grid container>
                <Grid item sx={{ padding: "5px" }}>
                  {checkUserRole(loggedInUser!, UserRoles.Admin) && loggedInUser?.id === note.addedByUser?.id ? (
                    <IconButton
                      onClick={() => {
                        setEditMode(true);
                      }}
                      icon={<Edit color="primary" />}
                      label="Edit note"
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item sx={{ padding: "5px" }}>
                  {checkUserRole(loggedInUser!, UserRoles.Admin) || loggedInUser?.id === note.addedByUser?.id ? (
                    <IconButton
                      onClick={() => {
                        setNoteDeleteModal(true);
                      }}
                      icon={<Delete color="error" />}
                      label="Delete note"
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Dialog open={noteDeleteModal} title={`Are you sure you would like to delete this note?`} warning>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" sx={{ marginY: 4 }} textAlign={"center"}>
            {note.value}
          </Typography>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              color="error"
              onClick={() => {
                onDelete(note);
                setNoteDeleteModal(false);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default NoteDisplay;
