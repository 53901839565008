import React from "react";
import TitleBar from "../base/TitleBar";
import VetsView from "../views/Vets/VetsView";

interface Props {}

const VetsPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Vets" />
      <VetsView />
    </>
  );
};

export default VetsPage;
