import React, { ReactElement } from "react";

export interface TabProps {
  label: string;
  children: ReactElement | Array<ReactElement>;
  hideTab?: boolean;
}

const Tab: React.FC<TabProps> = (props) => {
  return <>{props.label}</>;
};

export default Tab;
