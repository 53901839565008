import FormikPasswordField from "./FormikPasswordField";
import FormikTextField from "./FormikTextField";
import FormikSubmitButton from "./FormikSubmitButton";
import FormikCheckbox from "./FormikCheckbox";
import FormikAutocomplete from "./FormikAutocomplete";
import FormikAutocompleteOption from "./FormikAutocompleteOption";
import FormikNumberField from "./FormikNumberField";
import FormikRadio from "./FormikRadio";
import FormikTextArea from "./FormikTextArea";
import FormikDatepicker from "./FormikDatepicker";
import FormikDropzone from "./FormikDropzone";
import FormikPhoneField from "./FormikPhoneField";
import FormikSwitch from "./FormikSwitch";
import FormikDatetimePicker from "./FormikDatetimePicker";
import FormikTimePicker from "./FormikTimePicker";

export const Inputs = {
  Text: FormikTextField,
  Password: FormikPasswordField,
  Submit: FormikSubmitButton,
  Checkbox: FormikCheckbox,
  Dropdown: FormikAutocomplete,
  OptionDropdown: FormikAutocompleteOption,
  Number: FormikNumberField,
  Radio: FormikRadio,
  TextArea: FormikTextArea,
  Datepicker: FormikDatepicker,
  Dropzone: FormikDropzone,
  Phone: FormikPhoneField,
  Switch: FormikSwitch,
  Timepicker: FormikTimePicker,
};
