import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, DialogActions, Typography } from "@mui/material";
import StyledTable from "../base/StyledTable";
import { clearAdoption, getAdoptionsSearch, deleteAdoption } from "../../slices/adoptions";
import SearchParams from "../../types/searchParams";
import Adoption from "../../types/adoption";
import Moment from "react-moment";
import TableActions from "../base/TableActions";
import ActionButton from "../base/ActionButton";
import Modal from "../base/Modal";
import AddAdoptionRecord from "./AddAdoptionRecord";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import { getDogById, getDogSimpleById } from "../../slices/dogs";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import Dialog from "../base/Dialog";
import Application from "../../types/application";
import { getApplicationsSearch, getArchivedApplications, setActiveApp } from "../../slices/applications";

interface Props {
  id: number;
  type: "dog" | "user";
}

const DogAdoptionRecord: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { adoptionSearchResults, status: adoptionStatus } = useSelector((store) => store.adoptions);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { dog } = useSelector((store) => store.dogs);

  type ActiveModal = "none" | "edit" | "delete";
  const [modalOpen, setModalOpen] = useState<ActiveModal>("none");
  const [adoptionRecord, setAdoptionRecord] = useState<Adoption>();
  const [filterForm, setFilterForm] = useState<SearchParams>({
    query: "",
    filters: [`${props.type}:${props.id}`],
    pageNumber: 1,
    pageSize: 5,
    orderBy: "id",
    orderDirection: "Descending",
  });

  useEffect(() => {
    dispatch(
      getAdoptionsSearch({
        search: { ...filterForm },
      })
    );
  }, [dispatch, filterForm]);

  const checkForAndSaveAdoption = async () => {
    await dispatch(
      getAdoptionsSearch({
        search: { ...filterForm },
      })
    );
    if (props.type === "dog") {
      await dispatch(getDogById(dog.id));
      await dispatch(getDogSimpleById(dog.id));
    }
    dispatch(handleSuccessToastState(true));
    dispatch(setSuccessMessage("Adoption record successfully updated"));
    setModalOpen("none");
    setAdoptionRecord(undefined);
  };

  const deleteAdoptionRecord = async (id: number) => {
    dispatch(deleteAdoption(id));
  };

  const closeModal = () => {
    setModalOpen("none");
    dispatch(clearAdoption());
    setAdoptionRecord(undefined);
  };

  const changePage = (pageNumber: number, pageSize: number) => {
    setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
  };

  const headers = [
    "Date",
    "Name",
    "Contract Received",
    "Fee Received",
    "Amount",
    "Donation",
    "Form of Payment",
    "Check #",
  ];

  if (checkUserRole(loggedInUser!, UserRoles.Admin)) {
    headers.unshift("Actions");
  }

  const handleView = (adoption: Adoption) => {
    dispatch(setActiveApp(adoption.applicationId));
    const userId = adoption?.adopter?.id;
    dispatch(getArchivedApplications(userId!)).then((result: any) => {
      if (result && result.payload && result.payload.length) {
        const application = result.payload[0];
        window.open(`/admin/applications/${application.id}`, "_blank");
      } else {
        window.open(`/admin/applications/${adoption.applicationId}`, "_blank");
      }
    });
  };

  const rows =
    (!!props.id &&
      adoptionSearchResults?.results.map((adoption: Adoption) => {
        const hasActions = checkUserRole(loggedInUser!, UserRoles.Admin);

        let rowObject = {
          date: adoption.adoptionDate ? <Moment format="MM/DD/YYYY">{adoption.adoptionDate!}</Moment> : "",
          adopter:
            props.type === "dog" ? (
              adoption.adopter ? (
                <Box
                  onClick={() => window.open(`/admin/user/${adoption.adopter?.id}`, "_blank")}
                  sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                >
                  <Typography variant="body1">
                    {adoption.adopter?.firstName} {adoption.adopter?.lastName}
                  </Typography>
                </Box>
              ) : (
                ""
              )
            ) : (
              adoption.dog?.name
            ),
          contractReceived: adoption.contractSigned ? "Yes" : "No",
          feeReceived: adoption.feeReceived ? "Yes" : "No",
          amount: adoption.paymentDetails?.amount ? `$${adoption.paymentDetails?.amount?.toFixed(2)}` : "",
          donation: adoption.paymentDetails?.donation ? `$${adoption.paymentDetails?.donation?.toFixed(2)}` : "",
          payment: adoption.paymentDetails?.paymentMethod?.value,
          check: adoption.paymentDetails?.checkNumber,
        };

        return !hasActions
          ? rowObject
          : {
              actions: (
                <TableActions
                  id={adoption.id!}
                  handleEdit={
                    checkUserRole(loggedInUser!, UserRoles.Admin)
                      ? () => {
                          setModalOpen("edit");
                          setAdoptionRecord(adoption);
                        }
                      : undefined
                  }
                  handleViewApplication={
                    !!adoption.applicationId && adoption.applicationId > 0 ? () => handleView(adoption) : undefined
                  }
                  handleDelete={
                    checkUserRole(loggedInUser!, UserRoles.Admin)
                      ? () => {
                          setModalOpen("delete");
                          setAdoptionRecord(adoption);
                        }
                      : undefined
                  }
                />
              ),
              date: adoption.adoptionDate ? <Moment format="MM/DD/YYYY">{adoption.adoptionDate!}</Moment> : "",
              adopter:
                props.type === "dog" ? (
                  adoption.adopter ? (
                    <Box
                      onClick={() => window.open(`/admin/user/${adoption.adopter?.id}`, "_blank")}
                      sx={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
                    >
                      <Typography variant="body1">
                        {adoption.adopter?.firstName} {adoption.adopter?.lastName}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                ) : (
                  adoption.dog?.name
                ),
              contractReceived: adoption.contractSigned ? "Yes" : "No",
              feeReceived: adoption.feeReceived ? "Yes" : "No",
              amount: !!adoption.paymentDetails?.amount
                ? `$${adoption.paymentDetails?.amount?.toFixed(2)}`
                : adoption.feeReceived
                ? "N/A"
                : "",
              donation: !!adoption.paymentDetails?.donation ? `$${adoption.paymentDetails?.donation?.toFixed(2)}` : "",
              payment: adoption.paymentDetails?.paymentMethod?.value,
              check: adoption.paymentDetails?.checkNumber,
            };
      })) ||
    [];

  return (
    <Box sx={{ marginTop: 3 }}>
      {checkUserRole(loggedInUser!, UserRoles.Admin) && props.type !== "user" && (
        <Box marginY={3}>
          <ActionButton
            type="button"
            text="Add Adoption Record"
            onClick={() => {
              dispatch(clearAdoption());
              setModalOpen("edit");
            }}
          />
        </Box>
      )}
      {adoptionSearchResults?.results.length && adoptionStatus === "idle" ? (
        <StyledTable
          headers={headers}
          rows={rows}
          defaultPaging
          paging={true}
          page={adoptionSearchResults.page}
          sizeVariant="small"
          title={<Typography variant="body2">Adoption Records</Typography>}
          totalRows={adoptionSearchResults?.totalResults}
          changePage={changePage}
        />
      ) : null}
      <Modal
        open={modalOpen === "edit"}
        onClose={() => {
          setModalOpen("none");
        }}
      >
        <AddAdoptionRecord
          user={props.type === "user"}
          id={adoptionRecord?.id}
          onCancelClose={closeModal}
          onSuccessClose={checkForAndSaveAdoption}
        />
      </Modal>
      <Dialog
        open={modalOpen === "delete"}
        title={`Are you sure you would like to delete the adoption record for ${adoptionRecord?.adopter?.firstName} ${adoptionRecord?.adopter?.lastName}? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={closeModal} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={adoptionStatus === "loading" ? true : false}
              color="error"
              onClick={async () => {
                closeModal();
                await deleteAdoptionRecord(adoptionRecord?.id!);
                await checkForAndSaveAdoption();
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default DogAdoptionRecord;
