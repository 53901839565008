import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActionArea,
  Grid,
  Button,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";
import { Male, Female, CalendarMonth, Favorite, Scale } from "@mui/icons-material";
import IconButton from "./IconButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "../../store";
import { setAuthModal, setAuthRoute } from "../../slices/auth";
import { handleErrorToastState, setErrorMessage } from "../../slices/toast";
import DogSimple from "../../types/dogSimple";

interface Props {
  dog: DogSimple;
  userFavorite?: boolean;
  saveFavorite?: (id: number) => void;
  maxDogsApplied?: boolean;
}

const DogCard: React.FC<Props> = (props) => {
  const { dog, userFavorite, saveFavorite, maxDogsApplied } = props;
  const [favorite, setFavorite] = useState(userFavorite);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { loggedInUser } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setFavorite(userFavorite);
  }, [userFavorite]);

  const handleFavorite = () => {
    setFavorite(!favorite);
    saveFavorite!(dog.id);
  };

  return (
    <Card
      elevation={10}
      sx={{
        height: 0,
        width: "90%",
        paddingBottom: "90%",
        backgroundColor: theme.palette.primary.main,
        borderRadius: { xs: 5, sm: 10 },
        marginY: 1,
        marginX: "5%",
      }}
      onClick={() => window.open(`/dog/${dog.id}`, "_blank")}
    >
      <CardActionArea onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} disableRipple>
        {hover && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                color: theme.palette.primary.contrastText,
                textAlign: "center",
                paddingX: "5%",
                paddingTop: "35%",
              }}
            >
              {dog.headline}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            zIndex: 5000,
            width: "100%",
            height: 0,
            paddingBottom: "66%",
            overflow: "hidden",
            backgroundColor: "#000",
            borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
            borderRadius: "0% 0% 100% 100%/0% 0% 30% 30%",
          }}
        >
          {dog.mainPhoto && dog.mainPhoto !== "00000000-0000-0000-0000-000000000000" ? (
            <Box
              component="img"
              alt={dog.name}
              src={dog.mainPhoto}
              sx={{
                position: "relative",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: "100%",
                opacity: hover ? 0.25 : 1,
                // objectFit: "cover",
              }}
            />
          ) : (
            !hover && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    position: "absolute",
                    color: theme.palette.primary.contrastText,
                    textAlign: "center",
                    paddingX: "5%",
                    paddingTop: "35%",
                  }}
                >
                  Image coming soon!
                </Typography>
              </Box>
            )
          )}
        </Box>
        {!maxDogsApplied && (
          <Box
            sx={{
              position: "absolute",
              top: "4%",
              left: "4%",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Button
              color="success"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/adopt/${dog.id}`);
              }}
            >
              <Box
                sx={{
                  userSelect: "none",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 20,
                  backgroundColor: theme.palette.primary.main,
                  width: "fit-content",
                  border: "2px solid white",
                }}
              >
                <Tooltip title={<Typography>Apply to Adopt</Typography>}>
                  <Typography variant="body1" marginX="8px">
                    Adopt
                  </Typography>
                </Tooltip>
              </Box>
            </Button>
          </Box>
        )}
        <Box
          sx={{
            position: "absolute",
            top: "6%",
            right: "6%",
            color: favorite ? theme.palette.error.main : `${theme.palette.error.main}50`,
            backgroundColor: theme.palette.primary.contrastText,
            borderRadius: 25,
            display: { xs: "none", sm: "flex" },
          }}
          onClick={(e) => {
            if (loggedInUser) {
              e.stopPropagation();
              handleFavorite();
            } else {
              dispatch(handleErrorToastState(true));
              dispatch(setErrorMessage("You must log in to add a dog to your favorites"));
              e.stopPropagation();
              dispatch(setAuthRoute("login"));
              dispatch(setAuthModal(true));
            }
          }}
        >
          <IconButton icon={<Favorite />} label="Favorite" onClick={() => {}} size={"12px"} />
        </Box>
      </CardActionArea>
      <CardContent
        sx={{
          height: 0,
          width: "100%",
          cursor: "pointer",
          backgroundColor: theme.palette.primary.main,
          display: "flex",
          alignItems: "center",
          marginTop: {
            xs: "5px",
            sm: "12px",
            md: "32px",
            lg: "30px",
            xl: "43px",
          },
        }}
      >
        <Grid container direction="row" display={{ xs: "none", md: "flex" }}>
          <Grid
            item
            direction="column"
            xs={6}
            display="flex"
            alignItems="center"
            // sx={{ marginTop: { md: "10px", lg: "15px", xl: "20px" } }}
          >
            <Typography
              align="center"
              sx={{
                color: theme.palette.primary.contrastText,
                wordBreak: "break-word",
                whiteSpace: "break-spaces",
                fontSize: { xs: "12px", sm: "12px", md: "22px", lg: "20px", xl: "25px" },
              }}
            >
              {dog.name.length > 20 ? dog.name.slice(0, 20) + "..." : dog.name}
            </Typography>
          </Grid>
          <Grid
            item
            direction="column"
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ marginBottom: { md: "10px", lg: "5px", xl: "3px" } }}
          >
            <Box>
              <Grid
                item
                direction="row"
                display="flex"
                sx={{ alignItems: "center", gap: 0.2, flex: 1, whiteSpace: "nowrap" }}
              >
                <Icon type={IconType.DogsFilled} color={theme.palette.primary.contrastText} size={"20px"} />
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: { xs: "12px", sm: "12px", md: "12px", lg: "13px", xl: "18px" },
                  }}
                >
                  {dog.primaryBreed?.value?.length! > 22
                    ? dog.primaryBreed.value?.slice(0, 22) + "..."
                    : dog.primaryBreed.value}
                </Typography>
              </Grid>
              <Grid
                item
                direction="row"
                display="flex"
                sx={{ alignItems: "center", gap: 0.2, flex: 1, whiteSpace: "nowrap" }}
              >
                {dog.gender.value === "Male" ? (
                  <Male fontSize="small" sx={{ color: theme.palette.primary.contrastText }} />
                ) : (
                  <Female fontSize="small" sx={{ color: theme.palette.primary.contrastText }} />
                )}
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: { xs: "12px", sm: "12px", md: "12px", lg: "13px", xl: "18px" },
                  }}
                >
                  {dog.gender.value}
                </Typography>
              </Grid>
              <Grid
                item
                direction="row"
                display="flex"
                sx={{ alignItems: "center", gap: 0.2, flex: 1, whiteSpace: "nowrap" }}
              >
                <CalendarMonth fontSize="small" sx={{ color: theme.palette.primary.contrastText }} />
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: { xs: "12px", sm: "12px", md: "12px", lg: "13px", xl: "18px" },
                  }}
                >
                  {dog.ageGroup ? dog.ageGroup?.value?.split(" ")[0] : ""}
                </Typography>
              </Grid>

              {dog.weight && (
                <Grid
                  item
                  direction="row"
                  display="flex"
                  sx={{ alignItems: "center", gap: 0.2, flex: 1, whiteSpace: "nowrap" }}
                >
                  <Scale fontSize="inherit" sx={{ color: theme.palette.primary.contrastText }} />
                  <Typography
                    sx={{
                      color: theme.palette.primary.contrastText,
                      fontSize: { xs: "12px", sm: "12px", md: "12px", lg: "13px", xl: "18px" },
                    }}
                  >
                    {dog.weight ? dog.weight?.value : ""}
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="column" display={{ xs: "flex", md: "none" }}>
          <Box display="flex" flexDirection="column" marginTop={{ sm: 5, xs: 1.5 }} alignItems="center">
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <Typography
                align="center"
                fontSize={{ sm: "12px", xs: "15px" }}
                sx={{ fontWeight: 500, overflow: "hidden" }}
                color={theme.palette.primary.contrastText}
              >
                {dog.name.length > 20 ? dog.name.slice(0, 20) + "..." : dog.name}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" fontSize="15px">
              <Icon type={IconType.DogsFilled} color={theme.palette.primary.contrastText} size={"18px"} />
              <Typography fontSize={{ sm: "10px", xs: "12px" }} color={theme.palette.primary.contrastText}>
                {dog.primaryBreed?.value?.length! > 8
                  ? dog.primaryBreed.value?.slice(0, 8) + "..."
                  : dog.primaryBreed.value}
              </Typography>
              {dog.gender.value === "Male" ? (
                <Male fontSize="inherit" sx={{ color: theme.palette.primary.contrastText, marginLeft: "5px" }} />
              ) : (
                <Female fontSize="inherit" sx={{ color: theme.palette.primary.contrastText, marginLeft: "5px" }} />
              )}
              <Typography fontSize={{ sm: "10px", xs: "12px" }} color={theme.palette.primary.contrastText}>
                {dog.gender.value}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" fontSize="15px">
              <CalendarMonth fontSize="inherit" sx={{ color: theme.palette.primary.contrastText }} />
              <Typography fontSize={{ sm: "10px", xs: "12px" }} color={theme.palette.primary.contrastText}>
                {dog.ageGroup ? dog.ageGroup?.value?.split(" ")[0] : ""}
              </Typography>

              {dog.weight && (
                <>
                  <Scale fontSize="inherit" sx={{ color: theme.palette.primary.contrastText, marginLeft: "5px" }} />
                  <Typography fontSize={{ sm: "10px", xs: "12px" }} color={theme.palette.primary.contrastText}>
                    {dog.weight ? dog.weight?.value : ""}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DogCard;
