import React from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import theme from "../../theme";
import FormikTextField, { CustomInputProps } from "./FormikTextField";

interface Props {}

const FormikPhoneField: React.FC<FieldProps & TextFieldProps & CustomInputProps & Props> = (props) => {
  const { setFieldValue } = useFormikContext();

  const handlePhoneInput = (e: any) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFieldValue(props.field.name, formattedPhone);
  };

  const formatPhoneNumber = (value: any) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <FormikTextField
      {...props}
      onKeyPress={(e) => {
        if (!/^\d$/.test(e.key)) {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        handlePhoneInput(e);
      }}
    />
  );
};

export default FormikPhoneField;
