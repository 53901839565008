import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  useMediaQuery,
  MenuItem,
  ListItemText,
  Typography,
  Divider,
  Drawer,
  Button,
  Menu,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";
import { useDispatch, useSelector } from "../../store";
import { handleMenuState } from "../../slices/admin";
import PopoverMenu from "./PopoverMenu";
import CloseIcon from "@mui/icons-material/Close";
import { logout, setAuthModal, setAuthRoute } from "../../slices/auth";
import { resetApplication } from "../../slices/applications";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import { resetUserValues } from "../../slices/users";

interface Props {}

const AdminNavbar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [logoutDisable, setLogoutDisable] = useState<boolean>(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const { menuOpen } = useSelector((store) => store.admin);
  const { loggedInUser } = useSelector((store) => store.auth);

  const handleClose = () => {
    dispatch(handleMenuState(!menuOpen));
  };

  const md = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (!md) {
      dispatch(handleMenuState(false));
    }

    if (md) {
      setMobileMenuOpen(false);
    }
  }, [md, dispatch]);

  const handleMenuClose = () => {
    setUserMenuOpen(false);
  };

  const menuItems = [
    // { label: "Dashboard", value: "dashboard", icon: IconType.Dashboard },
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Inventory) ||
    checkUserRole(loggedInUser!, UserRoles.Foster) ||
    checkUserRole(loggedInUser!, UserRoles.Document) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer)
      ? [{ label: "Dogs", value: "dogs", icon: IconType.Dogs }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Foster) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer)
      ? [
          {
            label: "Applications",
            value: "applications",
            icon: IconType.Applications,
          },
        ]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Marketing)
      ? [{ label: "Events", value: "events", icon: IconType.Events }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Report)
      ? [{ label: "Reports", value: "reports", icon: IconType.Reports }]
      : []),
    { label: "Documents", value: "documents", icon: IconType.Documents },
    ...(checkUserRole(loggedInUser!, UserRoles.Admin)
      ? [{ label: "Sources", value: "sources", icon: IconType.Heart }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer) ||
    checkUserRole(loggedInUser!, UserRoles.Foster)
      ? [{ label: "Vets", value: "vets", icon: IconType.Vets }]
      : []),
    {
      label: "People",
      value: "users",
      icon: IconType.Users,
    },
  ];

  const handleProfileRoute = () => {
    navigate("/user-profile", { state: { id: loggedInUser?.id } });
  };

  const handleLogout = async () => {
    setLogoutDisable(true);
    if (!!loggedInUser) {
      dispatch(logout());
      dispatch(resetApplication());
      dispatch(resetUserValues());
      navigate("/home");
      dispatch(setAuthModal(true));
      dispatch(setAuthRoute("login"));
      setLogoutDisable(false);
    }
  };

  return (
    <>
      <AppBar>
        <Box>
          <Toolbar sx={{ minHeight: "100px", backgroundColor: theme.palette.secondary.main }}>
            <Box sx={{ py: "4px", cursor: "pointer" }} onClick={() => navigate("/")}>
              <img height={90} src="/static/images/hallogo.png" alt="logo" />
            </Box>
            <Box
              sx={{
                px: 3,
                marginLeft: "45px",
                alignItems: "center",
                display: { md: "flex", xs: "none" },
                flexDirection: "column",
              }}
            >
              <IconButton onClick={handleClose}>
                <Icon type={IconType.Menu} color={theme.palette.primary.main} size="30px" />
              </IconButton>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                ml: 2,
              }}
            />
            <Box
              onClick={() => setUserMenuOpen(!userMenuOpen)}
              ref={anchorRef}
              sx={{
                paddingRight: "0",
                marginLeft: "auto",
                marginTop: "5px",
                marginRight: -2,
                zIndex: 1250,
                display: { xs: "none", md: "flex" },
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: "60px",
                  width: "60px",
                  marginBottom: "5px",
                  marginLeft: "15px",
                  ":hover": { cursor: "pointer" },
                }}
              >
                <Icon type={IconType.DogsFilled} color="#fff" size="52px" />
              </Avatar>
            </Box>
            <Box
              sx={{
                px: 3,
                marginLeft: "45px",
                alignItems: "center",
                display: { md: "none", xs: "flex" },
                flexDirection: "column",
              }}
            >
              <IconButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <Icon type={IconType.Menu} color={theme.palette.primary.main} size="30px" />
              </IconButton>
            </Box>
            <Box ref={popoverRef}>
              <Menu
                anchorEl={anchorRef.current}
                open={userMenuOpen}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
                onClose={handleMenuClose}
              >
                <PopoverMenu admin />
              </Menu>
            </Box>
            <Drawer
              anchor="right"
              open={mobileMenuOpen}
              onClose={() => {
                setMobileMenuOpen(false);
              }}
              sx={{
                "& .MuiPaper-root": {
                  border: "none",
                  backgroundColor: "transparent",
                  paddingBottom: "25px",
                },
              }}
            >
              <Box
                sx={{
                  p: 2,
                  height: 1,
                  backgroundColor: theme.palette.background.paper,
                  width: "300px",
                  paddingBottom: "90px",
                }}
              >
                <IconButton
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Divider sx={{ mb: 2 }} />
                <Box
                  sx={{
                    mb: 2,
                    height: "100%",
                    overflow: "scroll",
                    paddingBottom: "25px",
                  }}
                >
                  {/* <Box sx={{ alignSelf: "center" }}>
                    <Typography variant="h2">Admin Menu</Typography>
                  </Box> */}
                  {menuItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      sx={{ width: "100%", gap: 1, paddingX: "20px" }}
                      onClick={() => {
                        navigate(`/admin/${item.value}`);
                        setMobileMenuOpen(false);
                      }}
                    >
                      <IconButton>
                        <Icon type={item.icon} color={theme.palette.primary.main} size="35px" />
                      </IconButton>
                      <ListItemText
                        primary={
                          <Typography variant="body2" color="textPrimary">
                            {item.label}
                          </Typography>
                        }
                      />
                    </MenuItem>
                  ))}
                  <MenuItem
                    sx={{ width: "100%", gap: 1, paddingX: "20px", display: { xs: "flex", md: "none" } }}
                    onClick={() => navigate("/")}
                  >
                    <IconButton>
                      <Icon type={IconType.Dogs} color={theme.palette.primary.main} size="35px" />
                    </IconButton>
                    <ListItemText
                      primary={
                        <Typography variant="body2" color="textPrimary">
                          Marketing Site
                        </Typography>
                      }
                    />
                  </MenuItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      bottom: "100px",
                      marginTop: "25px",
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <Button size="small" variant="contained" sx={{ m: 1, width: 0.5 }} onClick={handleProfileRoute}>
                      Profile
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ m: 1, width: 0.5 }}
                      onClick={handleLogout}
                      disabled={logoutDisable}
                    >
                      Logout
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
};

export default AdminNavbar;
