import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "../../../store";
import HistoryReferenceView from "./HistoryReferenceView";
import HistoryAnswerView from "./HistoryAnswerView";

interface Props {}

const ReferencesView: React.FC<Props> = (props) => {
  const { adminApplication } = useSelector((store) => store.applications);

  const hasPets =
    adminApplication?.numberOfDogs! > 0 ||
    adminApplication?.numberOfCats! > 0 ||
    adminApplication?.numberOfOtherPets! > 0 ||
    adminApplication?.numberOfPastPets! > 0;

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"baseline"} mb={3}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: { md: "10px", xs: 0 } }}>
              Personal References
            </Typography>
          </Grid>
          <HistoryReferenceView adminApplication={adminApplication!} personal />
          {!!hasPets &&
          !!adminApplication?.petsSeeVet &&
          adminApplication?.vetReferences &&
          adminApplication.vetReferences.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: { md: "10px", xs: 0 } }}>
                  Vet References
                </Typography>
              </Grid>
              <HistoryReferenceView adminApplication={adminApplication!} vet />
            </>
          ) : (
            hasPets && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: { md: "10px", xs: 0 } }}>
                    Vet Information
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <HistoryAnswerView
                    historyArray={adminApplication?.history?.["PetsSeeVet"]}
                    answerString={!!adminApplication?.petsSeeVet ? "Yes" : "No"}
                    questionText="Did/Do your pets see a vet?"
                  />
                </Grid>
                <Grid item xs={12}>
                  <HistoryAnswerView
                    historyArray={adminApplication?.history?.["WhyNoVet"]}
                    answerString={adminApplication?.whyNoVet}
                    questionText="Why not?"
                  />
                </Grid>
              </>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReferencesView;
