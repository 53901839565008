import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import theme from "../../theme";

const Root = styled.div<Props>`
  width: ${(props) => props?.size || "25px"};
  height: ${(props) => props?.size || "25px"};

  path {
    fill: ${(props) => props?.color || theme.palette.primary.main};
  }

  polygon {
    fill: ${(props) => props?.color || theme.palette.primary.main};
  }
`;

export const enum IconType {
  Menu = "Menu_White.svg",
  Dashboard = "Dashboard_White.svg",
  Dogs = "Dog_White.svg",
  Applications = "Applications_White.svg",
  Volunteer = "Volunteer Applications_White.svg",
  Events = "Events_White.svg",
  Reports = "Reports_White.svg",
  Documents = "Documents_White.svg",
  Vets = "Vets_White.svg",
  Users = "Users_White.svg",
  DogsFilled = "Dog_Filled.svg",
  Magnify = "Mag_Glass.svg",
  Arrow = "Arrow_White.svg",
  Edit = "Edit_White.svg",
  Excel = "Excel_White.svg",
  Expand = "Expand_White.svg",
  Notification = "Notification_White.svg",
  Pdf = "PDF_White.svg",
  Eye = "View_White.svg",
  Heart = "Hand_Heart.svg",
  User = "User_White.svg",
  Key = "key.svg",
  Bookmark = "Bookmark.svg",
  TikTok = "tiktok.svg",
}

interface Props {
  type: IconType;
  size?: string;
  color?: string;
  transform?: string;
}

const Icon: React.FC<Props> = (props) => {
  return (
    <Root {...props}>
      <ReactSVG
        style={{ height: "100%", fontSize: "1px", transform: props.transform }}
        src={`/static/icons/${props.type}`}
      ></ReactSVG>
    </Root>
  );
};

export default Icon;
