import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import getSearchQueryString from "../helpers/getSearchQueryString";
import SearchParams from "../types/searchParams";
import SearchResults from "../types/searchResults";
import Application from "../types/application";
import DogSimple from "../types/dogSimple";
import ApplicationSimple from "../types/applicationSimple";
import ApplicationHistory from "../types/applicationHistory";
import Adoption from "../types/adoption";

interface ApplicationsState {
  status: "idle" | "loading" | "failed";
  saveStatus: "idle" | "loading" | "failed";
  myApplication?: Application;
  adminApplication?: ApplicationHistory;
  adminApplicationHistory?: ApplicationHistory;
  applicationsSearchResults?: SearchResults;
  otherDog?: DogSimple;
  userApplications: ApplicationSimple[];
  activeAppId?: number;
}

const initialState: ApplicationsState = {
  status: "idle",
  saveStatus: "idle",
  userApplications: [],
};

export const saveApplication = createAsyncThunk<Application, Application>(
  "/applications/post",
  async (app: Application) => {
    const response = await http.post<Application>(`/applications`, app);
    return response.data;
  }
);

export const submitApplication = createAsyncThunk<Application, Application>(
  "/applications/submit",
  async (app: Application) => {
    const response = await http.post<Application>(`/applications?complete=true`, app);
    return response.data;
  }
);

export const getApplication = createAsyncThunk<ApplicationHistory, { id: number; history?: boolean }>(
  "/applications/get/id",
  async ({ id, history }) => {
    const response = await http.get<ApplicationHistory>(
      `/applications/${id}${!!history ? `?includeHistory=true` : ""}`
    );
    return response.data;
  }
);

export const getApplicationHistory = createAsyncThunk<ApplicationHistory, number>(
  "/applications/get-history/id",
  async (id: number) => {
    const response = await http.get<ApplicationHistory>(`/applications/${id}?includeHistory=true`);
    return response.data;
  }
);

export const getMyApplication = createAsyncThunk<Application>("/applications/get", async () => {
  const response = await http.get<Application>(`/applications`);
  return response.data;
});

export const editApplication = createAsyncThunk<Application, Application>(
  "applications/edit",
  async (app: Application) => {
    const response = await http.put<Application>(`/applications/${app.id}`, app);
    return response.data;
  }
);

export const deleteApplication = createAsyncThunk<Application, number>("applications/delete", async (id: number) => {
  const response = await http.delete<Application>(`/applications/${id}`);
  return response.data;
});

export const viewApplication = createAsyncThunk<Application, number>("applications/view", async (id: number) => {
  const response = await http.patch<Application>(`/applications/${id}`);
  return response.data;
});

export const selectApplication = createAsyncThunk<Application, { id: number; adoption: Adoption }>(
  "applications/select",
  async ({ id, adoption }) => {
    const response = await http.patch<Application>(`/applications/${id}/select`, adoption);
    return response.data;
  }
);

export const updateApplicationStatus = createAsyncThunk<Application, { id: number; statusId: number }>(
  "applications/update",
  async ({ id, statusId }) => {
    const response = await http.patch<Application>(`/applications/${id}/status?statusId=${statusId}`);
    return response.data;
  }
);

export const getArchivedApplications = createAsyncThunk<ApplicationSimple[], number>(
  "/applications/get/archived",
  async (id: number) => {
    const response = await http.get<ApplicationSimple[]>(`/applications/users/${id}`);
    return response.data;
  }
);

export const getApplicationsSearch = createAsyncThunk<
  SearchResults,
  {
    search: SearchParams;
    startDate?: Date;
    endDate?: Date;
  }
>("applications/search", async ({ search, startDate, endDate }) => {
  let qs = getSearchQueryString(search);
  let date = "";
  function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  date += !!startDate && isValidDate(startDate) ? `&startDate=${startDate.toUTCString()}` : "";
  date += !!endDate && isValidDate(endDate) ? `&endDate=${endDate.toUTCString()}` : "";
  const response = await http.get<SearchResults>(`/applications/search?${qs}${date}`);
  return response.data;
});

const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    addDogToApplication(state, action) {
      const application: Application = {
        ...state.myApplication,
        selectedDog: !state.myApplication?.selectedDog ? action.payload : state.myApplication?.selectedDog,
      };

      if (state.myApplication?.selectedDog) {
        let foundDog = !!state.myApplication?.otherDogs?.find((d) => d.id === action.payload.id);

        if (!foundDog && state.myApplication.selectedDog.id !== action.payload.id) {
          application.otherDogs?.push(action.payload);
        }

        if (!state.otherDog) {
          state.otherDog = action.payload;
        }
      }

      state.myApplication = application;
    },
    clearOtherDog(state) {
      state.otherDog = undefined;
    },
    saveApplicantInfoStep(state, action) {
      const application: Application = {
        ...state.myApplication,
        applicant: {
          id: action.payload?.id,
          firstName: action.payload?.firstName,
          lastName: action.payload?.lastName,
          age: action.payload?.age,
          email: action.payload?.email,
          phone: action.payload?.phone,
          secondaryPhone: action.payload?.phone2,
          address: {
            line1: action.payload?.line1,
            line2: action.payload?.line2,
            city: action.payload?.city,
            state: action.payload?.state,
            postalCode: action.payload?.postalCode,
          },
        },
        occupation: action.payload?.occupation,
        workSchedule: action.payload?.workSchedule,
        adoptionReason: action.payload?.adoptionReason,
        adoptionTimeline: action.payload?.adoptionTimeline,
      };
      state.myApplication = application;
    },
    saveHomeInfoStep(state, action) {
      const application: Application = {
        ...state.myApplication,
        ...action.payload,
      };
      state.myApplication = application;
    },
    savePetInfoStep(state, action) {
      const application: Application = {
        ...state.myApplication,
        ...action.payload,
      };
      state.myApplication = application;
    },
    saveCareStep(state, action) {
      const application: Application = {
        ...state.myApplication,
        ...action.payload,
      };
      state.myApplication = application;
    },
    saveReferencesStep(state, action) {
      let application: Application = {
        ...state.myApplication,
        petsSeeVet: action.payload.petsSeeVet,
        whyNoVet: action.payload.whyNoVet,
        personalReferences: [action.payload.personalReference1, action.payload.personalReference2],
        vetReferences: [action.payload.vetReference1, action.payload.vetReference2],
      };

      if (
        !!action.payload.vetReference1.name ||
        !!action.payload.vetReference1.phone ||
        !!action.payload.vetReference1.city ||
        !!action.payload.vetReference1.state ||
        !!action.payload.vetReference1.petsOnFile
      ) {
        application.vetReferences = [action.payload.vetReference1];
      }

      if (
        !!action.payload.vetReference2.name ||
        !!action.payload.vetReference2.phone ||
        !!action.payload.vetReference2.city ||
        !!action.payload.vetReference2.state ||
        !!action.payload.vetReference2.petsOnFile
      ) {
        application.vetReferences?.push(action.payload.vetReference2);
      }
      state.myApplication = application;
    },
    savePreferencesStep(state, action) {
      const application: Application = {
        ...state.myApplication,
        ...action.payload,
        addressBehaviors:
          !!action.payload?.acceptBarking ||
          !!action.payload?.acceptChewing ||
          !!action.payload?.acceptDigging ||
          !!action.payload?.acceptHouseTraining ||
          !!action.payload?.acceptSocial ||
          !!action.payload?.acceptSeparation ||
          !!action.payload?.acceptOther
            ? action.payload.addressBehaviors
            : [],
        whyNoBehaviors:
          !action.payload?.acceptBarking &&
          !action.payload?.acceptChewing &&
          !action.payload?.acceptDigging &&
          !action.payload?.acceptHouseTraining &&
          !action.payload?.acceptSocial &&
          !action.payload?.acceptSeparation &&
          !action.payload?.acceptOther
            ? action.payload.whyNoBehaviors
            : "",
      };
      state.myApplication = application;
    },
    saveOtherStep(state, action) {
      const application: Application = {
        ...state.myApplication,
        ...action.payload,
      };
      state.myApplication = application;
    },
    resetApplication(state) {
      state.myApplication = undefined;
      state.activeAppId = undefined;
    },
    setActiveApp(state, action) {
      state.activeAppId = action.payload;
    },
    resetApplicationView(state) {
      state.adminApplication = undefined;
    },
    replaceSelectedDog(state, action) {
      const application: Application = {
        ...state.myApplication,
        selectedDog: action.payload,
      };
      state.otherDog = action.payload;
      state.myApplication = application;
    },
    replaceOtherDogs(state, action) {
      const application: Application = {
        ...state.myApplication,
        otherDogs: action.payload,
      };
      state.myApplication = application;
    },
    emptyUserApps(state) {
      state.userApplications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveApplication.pending, (state) => {
        state.saveStatus = "loading";
      })
      .addCase(saveApplication.fulfilled, (state, action) => {
        state.saveStatus = "idle";
        state.myApplication = action.payload;
      })
      .addCase(saveApplication.rejected, (state, action) => {
        state.saveStatus = "failed";
      })
      .addCase(submitApplication.pending, (state) => {
        state.saveStatus = "loading";
      })
      .addCase(submitApplication.fulfilled, (state, action) => {
        state.saveStatus = "idle";
        state.myApplication = action.payload;
      })
      .addCase(submitApplication.rejected, (state, action) => {
        state.saveStatus = "failed";
      })
      .addCase(getApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminApplication = action.payload;
      })
      .addCase(getApplication.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getApplicationHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getApplicationHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminApplicationHistory = action.payload;
      })
      .addCase(getApplicationHistory.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getMyApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMyApplication.fulfilled, (state, action) => {
        state.status = "idle";
        // if (!state.application) {
        state.myApplication = action.payload;
        // }
      })
      .addCase(getMyApplication.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(editApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editApplication.fulfilled, (state, action) => {
        state.status = "idle";
        state.myApplication = action.payload;
      })
      .addCase(editApplication.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(deleteApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteApplication.fulfilled, (state, action) => {
        state.status = "idle";
        state.myApplication = undefined;
      })
      .addCase(deleteApplication.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(viewApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(viewApplication.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminApplication = action.payload;
      })
      .addCase(viewApplication.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(selectApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(selectApplication.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(selectApplication.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateApplicationStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateApplicationStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.adminApplication = action.payload;
      })
      .addCase(updateApplicationStatus.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getApplicationsSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getApplicationsSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.applicationsSearchResults = action.payload;
      })
      .addCase(getApplicationsSearch.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getArchivedApplications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getArchivedApplications.fulfilled, (state, action) => {
        state.status = "idle";
        state.userApplications = action.payload;
      })
      .addCase(getArchivedApplications.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const {
  addDogToApplication,
  clearOtherDog,
  saveApplicantInfoStep,
  saveHomeInfoStep,
  savePetInfoStep,
  saveCareStep,
  saveReferencesStep,
  savePreferencesStep,
  saveOtherStep,
  resetApplication,
  resetApplicationView,
  replaceSelectedDog,
  replaceOtherDogs,
  emptyUserApps,
  setActiveApp,
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
