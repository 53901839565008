import React from "react";
import ChangePasswordView from "../views/ChangePassword";

interface Props {}

const ChangePassword: React.FC<Props> = (props) => {
  return (
    <>
      <ChangePasswordView />
    </>
  );
};

export default ChangePassword;
