import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import theme from "../../theme";

interface Props {
  colorVariant: "white" | "blue";
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  label?: string;
}

const CheckboxButton: React.FC<Props> = (props) => {
  const { colorVariant, checked, onChange, id, label } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return label ? (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          id={id}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            color: colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main,
            "&.Mui-checked": {
              outline: colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main,
            },
            paddingLeft: 0,
          }}
          disableFocusRipple
          disableRipple
        />
      }
      label={label}
    />
  ) : (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      id={id}
      inputProps={{ "aria-label": "controlled" }}
      sx={{
        color: colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main,
        "&.Mui-checked": {
          outline: colorVariant === "blue" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        paddingLeft: 0,
      }}
      disableFocusRipple
      disableRipple
    />
  );
};

export default CheckboxButton;
