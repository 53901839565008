import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../../theme";
import Icon, { IconType } from "../../base/Icon";
import Moment from "react-moment";
import { useDispatch, useSelector } from "../../../store";
import FileVM from "../../../types/fileVM";
import { startVideo, stopVideo } from "../../../slices/file";

interface Props {}

const EventDisplay: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { event } = useSelector((store) => store.events);

  const [viewImage, setViewImage] = useState("");

  const getMainPhoto = (photos: FileVM[]) => {
    let mainPhoto = photos.find((p) => p.id === event.mainPhotoId);
    if (!!mainPhoto) {
      setViewImage(mainPhoto.url);
    }
  };

  useEffect(() => {
    getMainPhoto(event.photos);
  }, [event, dispatch]);

  return (
    <Box>
      {!!event.id && event.id > 0 && (
        <Box sx={{ position: "relative" }}>
          <Grid item sx={{ cursor: "pointer" }} onClick={() => window.open(`/event/${event.id}`, "_blank")}>
            {event.featured && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  backgroundColor: theme.palette.secondary.main,
                  width: "100%",
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                <Typography textAlign={"center"} fontWeight={"600"}>
                  Featured Event!
                </Typography>
              </Box>
            )}
            {event.status.id === 2 ? (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    backgroundColor: theme.palette.secondary.main,
                    width: "100%",
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                  }}
                >
                  <Typography textAlign={"center"} fontWeight={"600"}>
                    Ongoing Event!
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ position: "absolute", top: -6, left: 5 }}>
                  <Icon type={IconType.Bookmark} color={theme.palette.primary.main} size={"90px"} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 28,
                    width: 40,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
                      <Moment format="MMM" style={{ textTransform: "uppercase" }}>
                        {event.start}
                      </Moment>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2" sx={{ color: theme.palette.primary.contrastText, marginTop: "-5px" }}>
                      <Moment format="D">{event.start}</Moment>
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                backgroundColor: theme.palette.primary.main,
                width: "100%",
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                textAlign={"center"}
                color={theme.palette.primary.main}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  width: "100%",
                }}
              >
                Click for details
              </Typography>
              <Typography
                textAlign={"center"}
                sx={{ padding: 1, typography: { lg: "h2", md: "h3" } }}
                color={theme.palette.primary.contrastText}
              >
                {event.name}
              </Typography>
            </Box>
            <Box
              component="img"
              alt={`event picture`}
              src={viewImage || event.photos[0]?.url}
              sx={{
                height: "100%",
                width: "100%",
                borderRadius: 6,
              }}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default EventDisplay;
