import React from "react";
import { FieldProps, getIn } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import theme from "../../theme";
import { Box, Typography } from "@mui/material";
import FormikTextField from "./FormikTextField";

interface Props {
  rows?: number;
}

const FormikTextArea: React.FC<FieldProps & TextFieldProps & Props> = (props) => {
  return <FormikTextField {...props} multiline rows={props.rows ?? 4} />;
};

export default FormikTextArea;
