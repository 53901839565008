import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";

// Sentry.init({
//   dsn: "https://1098f842424440a0b5ab220aa6ce2cd5@o1122606.ingest.sentry.io/4504713882304512",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

ReactGA.initialize("G-23B34C8B8J");

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

serviceWorker.unregister();
