import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon, { IconType } from "./Icon";
import theme from "../../theme";

interface Props {
  size: "small" | "large";
}

const DonateButton: React.FC<Props> = (props) => {
  const { size } = props;
  const navigate = useNavigate();

  return (
    <Button
      color="error"
      onClick={() => navigate(`/donate`)}
      size={size}
      type="button"
      variant="contained"
      sx={{
        paddingTop: size === "small" ? "1px" : "5px",
        paddingBottom: 0,
        height: size === "large" ? "45px" : "",
        paddingLeft: size === "large" ? "8px" : "",
      }}
    >
      <Icon type={IconType.Heart} color={theme.palette.background.paper} size={size === "small" ? "30px" : "50px"} />
      <Typography
        color="inherit"
        variant={size === "small" ? "caption" : "button"}
        sx={{ paddingBottom: size === "small" ? "2px" : "7px" }}
      >
        Donate
      </Typography>
    </Button>
  );
};

export default DonateButton;
