import { createTheme } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";

const theme = createTheme({
  direction: "ltr",
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {},
    },
  },
  typography: {
    fontFamily: `'Lato', sans-serif`,
    h1: {
      fontSize: "27px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    h2: {
      fontSize: "24px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    h3: {
      fontSize: "19px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    h4: {
      fontSize: "21px",
      fontWeight: 300,
      fontStyle: "light",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 300,
      fontStyle: "light",
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: 300,
      fontStyle: "italic",
    },
    h5: {
      fontSize: "15px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    body1: {
      fontSize: "19px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    body2: {
      fontSize: "16px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    button: {
      fontSize: "21px",
      fontWeight: 400,
      fontStyle: "normal",
      textTransform: "none",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      fontStyle: "normal",
      textTransform: "none",
    },
  },
  palette: {
    background: {
      default: "#EAF0F5", // light blue
      paper: "#FFFFFF", // white,
    },
    primary: {
      contrastText: "#FFFFFF", // white
      main: "#2A3887", // dark blue
    },
    secondary: {
      contrastText: "#2A3887", // dark blue
      main: "#BED6E5", // medium blue
    },
    success: {
      contrastText: "#2A3887", // dark blue
      main: "#EAF0F5", // light blue
    },
    info: {
      contrastText: "#FFFFFF", // white
      main: "#D6D6D6", // light gray
    },
    error: {
      contrastText: "#FFFFFF", // white
      main: "#E41E26", // red
    },
    warning: {
      contrastText: "#103444", // dark blue
      main: "#F1F2F1", // lightest gray
    },
    divider: "#777777", // "rgba(145, 158, 171, 0.24)",
    text: {
      primary: "#2A3887", // dark blue
      secondary: "#ffffff", // white
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
