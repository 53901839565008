import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import theme from "../../../theme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../../../UserPool";
import {
  setResetPasswordEmail,
  setAuthModal,
  getLoggedInUser,
  setAuthRoute,
  setResetPasswordCode,
} from "../../../slices/auth";
import {
  handleErrorToastState,
  handleSuccessToastState,
  setErrorMessage,
  setSuccessMessage,
} from "../../../slices/toast";
import ActionButton from "../../base/ActionButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import getEmailRegex from "../../../helpers/emailRegex";

interface Props {}

const ResetPasswordView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetPasswordCode } = useSelector((store) => store.auth);
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [queryParams] = useSearchParams();
  const code = queryParams.get("code");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (code) {
      dispatch(setResetPasswordCode(code));
    }

    navigate("/home");
    dispatch(setAuthRoute(`reset`));
    dispatch(setAuthModal(true));
  }, [code, dispatch, navigate]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("You must include your email to reset your password").typeError("Required"),
    password: Yup.string()
      .required("You must enter a valid password")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, "Your password must have at least 1 numerical character")
      .matches(/[a-z]/, "Your password must have at least 1 lowercase character")
      .matches(/[A-Z]/, "Your password must have at least 1 uppercase character")
      .typeError("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords do not match")
      .typeError("Required"),
  });

  const getUser = (username: string) => {
    return new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
  };

  const resetPassword = (values: any) => {
    if (values.password === values.confirmPassword) {
      let email = values.username.trim();
      getUser(email).confirmPassword(`${values.code}`, values.password, {
        onSuccess: () => {
          dispatch(setResetPasswordEmail(""));
          dispatch(handleSuccessToastState(true));
          dispatch(setSuccessMessage("Password changed successfully"));

          const authDetails = new AuthenticationDetails({
            Username: email,
            Password: values.password,
          });

          getUser(email).authenticateUser(authDetails, {
            onSuccess: () => {
              dispatch(getLoggedInUser());
              dispatch(setAuthModal(false));
              navigate("/home");
            },
            onFailure: (err) => {
              dispatch(setErrorMessage(err.message));
              dispatch(handleErrorToastState(true));
            },
          });
        },
        onFailure: (err) => {
          dispatch(handleErrorToastState(true));
          dispatch(setErrorMessage(err.message));
        },
      });
    } else {
      dispatch(handleErrorToastState(true));
      dispatch(setErrorMessage("Password values do not match"));
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: { xs: 25, sm: 2, md: 0 },
        marginX: { lg: 35, md: 10, sm: 10 },
      }}
    >
      <Grid
        container
        direction={"row"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ paddingY: { xs: 25, sm: 0 } }}
      >
        <Grid item md={4} sm={12} alignSelf="center">
          <Box
            sx={{
              display: " flex",
              justifyContent: "center",
              paddingX: { md: "35px", sm: "0px" },
            }}
          >
            <Box
              component="img"
              alt="logo"
              src="/static/images/hallogo.png"
              sx={{
                height: 200,
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={8}
          sm={12}
          sx={{
            backgroundColor: { md: theme.palette.background.paper, sm: theme.palette.secondary.main },
            paddingY: { md: "55px", xs: "0px" },
            paddingX: { lg: "35px", xs: "20px" },
            textAlign: "center",
          }}
        >
          <Grid container sx={{ display: "flex", flexDirection: "column", marginTop: 1, textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography variant="h1" sx={{ color: theme.palette.primary.main, marginY: 2, wordWrap: "break-word" }}>
                Reset Password
              </Typography>
            </Grid>
            <Formik
              initialValues={{ username: "", code: resetPasswordCode, password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={resetPassword}
            >
              <Form>
                <Grid container justifyContent={"center"}>
                  <Grid item sm={8} xs={12}>
                    <Field
                      name="username"
                      label="Email Address"
                      component={Inputs.Text}
                      secondary={md ? false : true}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Field
                      name="password"
                      label="New Password"
                      autoComplete="off"
                      component={Inputs.Password}
                      secondary={md ? false : true}
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                    />
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Field
                      name="confirmPassword"
                      label="Confirm Password"
                      component={Inputs.Password}
                      secondary={md ? false : true}
                      showPassword={showPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-around", my: 2 }}>
                      <Box sx={{ display: { sm: "flex", md: "none" } }}>
                        <ActionButton
                          type="button"
                          text="Cancel"
                          color="success"
                          onClick={() => dispatch(setAuthModal(false))}
                        />
                      </Box>
                      <Grid item>
                        <Inputs.Submit text="Reset" isSubmitting={false} color="primary" />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPasswordView;
