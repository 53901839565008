import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import { PDFViewer } from "@react-pdf/renderer";
import { useSelector } from "../../../store";
import PdfDogView from "./PdfDogView";
import UserSimple from "../../../types/userSimple";

interface Props {
  adopter?: UserSimple;
}

const PdfDogPreview: React.FC<Props> = (props) => {
  const { adopter } = props;
  const { dog, status } = useSelector((store) => store.dogs);
  const { user } = useSelector((store) => store.users);
  const { source } = useSelector((store) => store.sources);

  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: { md: 600, sm: 500, xs: 500 },
        width: { md: 800, sm: 600, xs: 300 },
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {status === "idle" && (
        <PDFViewer height={sm ? 500 : xs ? 500 : 600} width={xs ? 300 : sm ? 600 : 800}>
          <PdfDogView dog={dog} foster={user} source={source} adopter={adopter} />
        </PDFViewer>
      )}
    </Box>
  );
};

export default PdfDogPreview;
