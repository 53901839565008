import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ShoppingCart } from "@mui/icons-material";

interface Props {}

const ShopButton: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      color="primary"
      onClick={() => window.open(`https://homeatlastswag.myshopify.com/`, "_blank")}
      size="large"
      type="button"
      variant="contained"
      sx={{
        paddingTop: "5px",
        paddingBottom: "7px",
        height: "45px",
        paddingLeft: "8px",
        fontSize: "30px",
      }}
    >
      <ShoppingCart fontSize="inherit" />
      <Typography color="inherit" variant={"button"} sx={{ paddingLeft: 1 }}>
        Shop
      </Typography>
    </Button>
  );
};

export default ShopButton;
