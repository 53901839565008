import React from "react";
import Application from "../../../../types/application";
import ReactPDF, { Text, View, Image } from "@react-pdf/renderer";

interface Props {
  application?: Application;
  styles: ReactPDF.Styles;
}

const ApplicantPage: React.FC<Props> = (props) => {
  const { application, styles } = props;
  return (
    <View style={styles.page}>
      <View>
        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "25px" }}>
          <Image src={"/static/images/hallogo.png"} style={{ height: 80, width: 80, objectFit: "cover" }} />
          <View style={{ marginLeft: "10px" }}>
            <Text style={styles.addressText}>Home at Last Dog Rescue</Text>
            <Text style={styles.addressText}>P.O. Box 1341</Text>
            <Text style={styles.addressText}>North Wales, PA 19454</Text>
            <Text style={styles.addressText}>info@homeatlastdogrescue.com</Text>
          </View>
        </View>
        <View>
          <Text style={styles.header}>
            {`${application?.applicant?.firstName} ${application?.applicant?.lastName} application`}
            {application?.selectedDog ? ` for ${application?.selectedDog?.name}` : ""}
          </Text>
        </View>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Email:</Text>
          <Text style={styles.answerText}>{application?.applicant?.email}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Age:</Text>
          <Text style={styles.answerText}>{application?.applicant?.age}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Phone Number(s):</Text>
          <Text style={styles.answerText}>{application?.applicant?.phone}</Text>
          {application?.applicant?.secondaryPhone && (
            <Text style={styles.answerText}>{application?.applicant?.phone}</Text>
          )}
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Address:</Text>
          <Text style={styles.answerText}>{application?.applicant?.address?.line1}</Text>
          {application?.applicant?.address?.line2 && (
            <Text style={styles.answerText}>{application?.applicant?.age}</Text>
          )}
          <Text
            style={styles.answerText}
          >{`${application?.applicant?.address?.city}, ${application?.applicant?.address?.state?.value} ${application?.applicant?.address?.postalCode}`}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Occupation:</Text>
          <Text style={styles.answerText}>{application?.occupation}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Work Schedule:</Text>
          <Text style={styles.answerText}>{application?.workSchedule?.value}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Why do you want a dog?</Text>
          <Text style={styles.answerText}>{application?.adoptionReason}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>When do you want a dog?</Text>
          <Text style={styles.answerText}>{application?.adoptionTimeline?.value}</Text>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <View>
          <Text style={styles.mapQuestionText}>Other dogs of interest:</Text>
        </View>
        <View style={{ display: "flex", flexDirection: "column" }}>
          {application?.selectedDog && application?.otherDogs?.length ? (
            application.otherDogs.map((dog) => {
              return (
                <View key={dog.id}>
                  <Text style={styles.basicMapText}>{dog.name}</Text>
                </View>
              );
            })
          ) : (
            <View>
              <Text style={styles.basicMapText}>None</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default ApplicantPage;
