import React from "react";
import { FieldProps, getIn } from "formik";
import { RadioProps, Radio, Typography } from "@mui/material";
import theme from "../../theme";

interface Props {
  checked: boolean;
  onChange: () => {};
}

const FormikRadio: React.FC<FieldProps & RadioProps & Props> = (props) => {
  const { checked, field, onChange, ...rest } = props;
  const isTouched = getIn(props.form.touched, props.field.name);
  const submitCount = getIn(props.form.submitCount, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  return (
    <>
      <Radio checked={checked} onChange={onChange} sx={{ color: theme.palette.primary.main }} />
      <Typography
        sx={{
          fontSize: "16px",
          letterSpacing: "0em",
          fontWeight: 800,
          textAlign: "left",
        }}
        color={theme.palette.primary.main}
      >
        {(!!isTouched || submitCount !== 0) && errorMessage ? errorMessage : ""}
      </Typography>
    </>
  );
};

export default FormikRadio;
