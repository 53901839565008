import React from "react";

interface Props {}

const DonorWall: React.FC<Props> = (props) => {
  return (
    <>
      <script src="https://donorbox.org/widget.js" data-paypalExpress={true}></script>
      <iframe
        title="donor-wall"
        name="donorbox"
        src="https://donorbox.org/embed/homeatlastdogrescue?donor_wall_color=%2341a2d8&amp;only_donor_wall=true"
        style={{
          padding: "20px",
          width: "100%",
          maxWidth: "1000px",
          minWidth: "310px",
          minHeight: "100%",
          border: "0px",
          backgroundColor: "#fff",
        }}
      ></iframe>
    </>
  );
};

export default DonorWall;
