import axios, { AxiosError } from "axios";
import { logout, setAuthModal, setAuthRoute } from "../slices/auth";
import store from "../store";
import { handleErrorToastState, setErrorMessage } from "../slices/toast";

const axiosErrorInterceptor = async (error: Error | AxiosError) => {
  const { dispatch } = store;

  let message = "Something happened! Please try again.";

  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      message = "Must be logged in to do that!";
      dispatch(logout());
    } else if (error.code === "ERR_NETWORK") {
      if (!!error.config?.headers.Authorization) {
        message = "Not authorized to do that!";
      } else {
        message = "Please sign in!";
        window.location.replace("/home");
        dispatch(logout());
        dispatch(setAuthModal(true));
        dispatch(setAuthRoute("login"));
      }
    } else if (error?.response?.data?.message) {
      message = error.response?.data.message;
    } else {
      message = error.message;
    }
  } else {
    message = error.message;
  }
  dispatch(setErrorMessage("Error: " + message));
  dispatch(handleErrorToastState(true));
};

export default axiosErrorInterceptor;
