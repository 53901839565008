import React, { useRef } from "react";
import { useDispatch, useSelector } from "../../../store";
import { Box, Typography, useMediaQuery } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import { EventClickArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Star } from "@mui/icons-material";
import theme from "../../../theme";
import { getEvent, getEvents } from "../../../slices/events";
import Moment from "react-moment";
import getEventColor from "../../../helpers/getEventColor";
import GetEventIcon from "../../../helpers/GetEventIcon";
import EventVM from "../../../types/event";

interface Props {
  handleScroll: () => void;
}

const CalendarView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { events } = useSelector((store) => store.events);
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const getMainPhoto = (event: EventVM) => {
    let mainPhoto = event.photos.find((p) => p.id === event.mainPhotoId);
    if (!!mainPhoto) {
      return mainPhoto.url;
    } else {
      return event.photos[0]?.url;
    }
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <Typography
        variant="h5"
        color={theme.palette.primary.contrastText}
        sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}
      >
        {eventInfo.event.extendedProps.featured === true && <Star color="primary" fontSize={xs ? "small" : "medium"} />}
        <GetEventIcon id={eventInfo.event.extendedProps.eventType} />
        {xs ? null : eventInfo.event.title}
      </Typography>
    );
  };

  const handleDateClick = (e: EventClickArg) => {
    e.jsEvent.preventDefault();
    dispatch(getEvent(parseInt(e.event.id)));
    props.handleScroll();
  };

  const calendarRef = useRef<any>();

  const handleToday = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    dispatch(getEvents());
  };

  const handlePrev = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    dispatch(getEvents());
  };

  const handleNext = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    dispatch(getEvents());
  };

  const ellipsisName = (name: string, featured: boolean, start: Date, end: Date) => {
    const length = name.length;
    const endToday = new Date().toISOString().split("T")[0] === end.toString().split("T")[0];
    const startedInPast = new Date().toISOString().split("T")[0] > start.toString().split("T")[0];
    if (startedInPast && !endToday) {
      return name;
    } else if (featured && length > 7) {
      return name.slice(0, 4) + "...";
    } else if (!featured && length > 10) {
      return name.slice(0, 7) + "...";
    } else {
      return name;
    }
  };
  return (
    <Box
      sx={{
        "& .fc-toolbar-title": {
          fontSize: { md: "30px", xs: "20px" },
          fontWeight: "500",
        },
        "& .fc-toolbar": {
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
        },
      }}
    >
      <FullCalendar
        ref={calendarRef}
        contentHeight={550}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        events={events.map((event) => {
          return {
            id: event.id?.toString(),
            start: event.start,
            end: event.end,
            title: ellipsisName(event.name, event.featured, event?.start!, event?.end!),
            url: getMainPhoto(event),
            display: "block",
            extendedProps: {
              eventType: event.eventType?.id,
              featured: event.featured,
            },
            color: getEventColor(event?.eventType?.id!),
          };
        })}
        eventContent={renderEventContent}
        dayMaxEventRows={true}
        views={{
          dayGridMonth: {
            duration: { weeks: 4 },
            dayMaxEventRows: xs ? 2 : 3,
          },
        }}
        dayCellContent={(info) => {
          return <Moment format="MMM DD">{info.date}</Moment>;
        }}
        validRange={{
          start: new Date(),
        }}
        headerToolbar={{
          left: "title",
          right: "customToday customPrev,customNext",
        }}
        customButtons={{
          customToday: {
            text: "today",
            click: handleToday,
          },
          customPrev: {
            icon: "chevron-left",
            click: handlePrev,
          },
          customNext: {
            icon: "chevron-right",
            click: handleNext,
          },
        }}
        fixedWeekCount={false}
        eventClick={(eventInfo) => handleDateClick(eventInfo)}
      />
    </Box>
  );
};

export default CalendarView;
