import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Grid, DialogActions, Tooltip, useMediaQuery, Typography } from "@mui/material";
import StyledTable from "../base/StyledTable";
import TableActions from "../base/TableActions";
import Search from "../base/Search";
import { Container } from "@mui/system";
import ActionButton from "../base/ActionButton";
import { getUserSearch, resetUserValues } from "../../slices/users";
import { debounce } from "lodash";
import KeyValue from "../../types/keyValue";
import { useNavigate } from "react-router-dom";
import { getApplicationStatuses } from "../../slices/options";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../forms";
import Moment from "react-moment";
import {
  deleteApplication,
  emptyUserApps,
  getApplication,
  getApplicationsSearch,
  resetApplication,
} from "../../slices/applications";
import ApplicationSimple from "../../types/applicationSimple";
import Dialog from "../base/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../helpers/getAddressLink";
import Switch from "../base/Switch";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import theme from "../../theme";
import { getDogsSearch } from "../../slices/dogs";
import Modal from "../base/Modal";
import PdfAppPreview from "./ApplicationView/PdfAppPreview";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfAppView from "./ApplicationView/PdfAppView";
import {
  resetApplicationEnd,
  resetApplicationFilter,
  resetApplicationStart,
  setApplicationDog,
  setApplicationEnd,
  setApplicationFilter,
  setApplicationPastDog,
  setApplicationStart,
  setFosterApplication,
} from "../../slices/filters";
import SearchParams from "../../types/searchParams";
import DogSimple from "../../types/dogSimple";
import SearchResults from "../../types/searchResults";

interface Props {}

const AdminApplicationsTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUser } = useSelector((store) => store.auth);
  const { dogSearch } = useSelector((store) => store.dogs);
  const { adminApplication, applicationsSearchResults, status } = useSelector((store) => store.applications);
  const {
    applicationFilter,
    applicationDog,
    applicationPastDog,
    fosterApplications,
    applicationStart,
    applicationEnd,
    applicationFoster,
    applicationStatus,
  } = useSelector((store) => store.filters);
  const { applicationStatuses } = useSelector((store) => store.options);
  const { userSearch } = useSelector((store) => store.users);

  const [currentDogSearch, setCurrentDogSearch] = useState<SearchResults>();
  const [pastDogSearch, setPastDogSearch] = useState<SearchResults>();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [filterForm, setFilterForm] = useState<SearchParams>({
    query: "",
    pageNumber: 1,
    pageSize: 10,
    orderBy: "name",
    orderDirection: "Ascending",
  });

  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  //clear user, application, and user Applications
  useEffect(() => {
    dispatch(emptyUserApps());
    dispatch(resetApplication());
    dispatch(resetUserValues());
  }, [dispatch]);

  //reset dates if it's cleared and set to december 31, 1969
  useEffect(() => {
    if (applicationStart?.toDateString() === "Wed Dec 31 1969") {
      dispatch(setApplicationStart(null));
    }
    if (applicationEnd?.toDateString() === "Wed Dec 31 1969") {
      dispatch(setApplicationEnd(null));
    }
  }, [applicationStart, applicationEnd]);

  var date = new Date();

  //only run search with date if the start date is valid
  useEffect(() => {
    if ((date instanceof Date && !isNaN(applicationStart?.valueOf()!)) || applicationStart === null) {
      dispatch(
        getApplicationsSearch({
          search: { ...applicationFilter },
          startDate: applicationStart!,
          endDate: applicationEnd!,
        })
      );
    }
  }, [dispatch, applicationStart]);

  //only run search with date if the end date is valid
  useEffect(() => {
    if ((date instanceof Date && !isNaN(applicationEnd?.valueOf()!)) || applicationEnd === null) {
      dispatch(
        getApplicationsSearch({
          search: { ...applicationFilter },
          startDate: applicationStart!,
          endDate: applicationEnd!,
        })
      );
    }
  }, [dispatch, applicationEnd]);

  //run search when the filter form updates
  useEffect(() => {
    dispatch(
      getApplicationsSearch({
        search: { ...applicationFilter },
        startDate: applicationStart!,
        endDate: applicationEnd!,
      })
    );
  }, [dispatch, applicationFilter]);

  const handleDeleteOpen = (id: number) => {
    setDeleteModal(true);
    dispatch(getApplication({ id: id }));
  };

  const handleDeleteApp = async (id: number) => {
    await dispatch(deleteApplication(id));
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const updateSearch = debounce((query: string) => {
    dispatch(setApplicationFilter({ ...applicationFilter, pageNumber: 1, pageSize: 10, query: query }));
  }, 500);

  const handleFiltering = (e: KeyValue[], filterName: string) => {
    let filters = applicationFilter.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (!!e) {
      let newFilters: string[] = [];

      e.forEach((filter: KeyValue) => {
        let newFilterName = `${filterName}:${filter.id}`;
        newFilters.push(newFilterName);
      });

      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      dispatch(setApplicationFilter({ ...applicationFilter, pageNumber: 1, pageSize: 10, filters: newList }));
    } else {
      dispatch(setApplicationFilter({ ...applicationFilter, pageNumber: 1, pageSize: 10, filters: otherFilters }));
    }
  };

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setApplicationFilter({ ...applicationFilter, pageNumber: pageNumber, pageSize: pageSize }));
  };

  const cancelPdf = () => {
    setPdfModal(false);
  };

  const headers = ["Actions", "Status", "Submitted", "Applicant", "Email", "Phone", "Location", "Dog", "Foster"];

  const rows =
    applicationsSearchResults?.results.map((application: ApplicationSimple) => {
      return {
        actions: (
          <TableActions
            handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
            id={application.id}
            handlePrint={() => {
              dispatch(getApplication({ id: application.id })).then(() => {
                setPdfModal(true);
              });
            }}
            handleView={() => {
              navigate(`/admin/applications/${application.id}`);
            }}
          />
        ),
        status: (
          <span style={{ color: application.status?.id === 9 ? theme.palette.error.main : theme.palette.primary.main }}>
            {application.status?.value}
          </span>
        ),
        submitted: application.submittedDate && <Moment format="MM/DD/YYYY">{application.submittedDate!}</Moment>,
        applicant: `${application.applicant.firstName} ${application.applicant.lastName}`,
        email: application.applicant.email,
        phone: sm ? (
          application.applicant.phone
        ) : (
          <a href={`tel:${application.applicant.phone}`} style={{ color: "#000000" }}>
            {application.applicant.phone}
          </a>
        ),
        location:
          application.applicant.address &&
          application.applicant.address?.city &&
          application.applicant.address?.state ? (
            <Box>
              {application.applicant.address?.city}, {application.applicant.address?.state?.value}{" "}
              <Tooltip
                title={
                  <Box>
                    <Box display="flex" flexDirection="row">
                      {application.applicant.address.line1}
                    </Box>
                    {application.applicant.address.line2 && <Box>{application.applicant.address.line2}</Box>}
                    <Box display="flex" flexDirection="row">
                      {application.applicant.address?.city}, {application.applicant.address?.state?.value}{" "}
                      {application.applicant.address.postalCode}
                    </Box>
                  </Box>
                }
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faLocationDot}
                  size="sm"
                  onClick={() => window.open(getAddressLink(application.applicant.address!), "_blank")}
                />
              </Tooltip>
            </Box>
          ) : (
            ""
          ),
        dogName:
          application.status?.id === 9 && !application.selectedDog ? (
            <Tooltip title={<Box>No dogs attached to this application</Box>}>
              <FontAwesomeIcon style={{ color: theme.palette.error.main }} icon={faTriangleExclamation} size="sm" />
            </Tooltip>
          ) : (
            application.selectedDog?.name
          ),
        foster: application.selectedDog?.foster?.value,
      };
    }) || [];

  const handleCheckbox = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (!!checked) {
      let filter: KeyValue = {
        id: loggedInUser?.id!,
        value: "",
      };
      handleFiltering([filter], "foster");
    } else {
      dispatch(setApplicationFilter({ ...applicationFilter, pageNumber: 1, pageSize: 10, filters: ["finished:1"] }));
    }
    dispatch(setFosterApplication(checked));
  };

  const handleStatusFilter = (value: KeyValue[]) => {
    handleFiltering(value, "status");
  };

  const handleFosterFilter = (value: KeyValue[]) => {
    handleFiltering(value, "foster");
  };

  const handleDogFilter = (value: KeyValue[]) => {
    handleFiltering(value, "dog");
  };

  const handleAllDogFilter = (value: KeyValue[]) => {
    handleFiltering(value, "dog-history");
  };

  const handleClear = () => {
    dispatch(resetApplicationFilter());
    dispatch(resetApplicationStart());
    dispatch(resetApplicationEnd());
    dispatch(setApplicationDog([]));
    dispatch(setApplicationPastDog([]));
    setCurrentDogSearch(undefined);
    setPastDogSearch(undefined);
  };

  const handleReset = (resetForm: () => void) => {
    resetForm();
  };

  const initialStatus = () => {
    if (applicationFilter.filters?.length) {
      const statusFilters: string[] = applicationFilter.filters.filter((filter) => filter.includes("status"));
      if (statusFilters?.length) {
        let idList = statusFilters.map((f) => {
          return parseInt(f.toString().split(":")[1]);
        });
        const statusList = applicationStatuses?.results.filter((status) => idList.find(status.id));
        return statusList;
      } else return [];
    }
  };

  const initialFosterId = applicationFilter?.filters
    ?.filter((filter) => filter.includes("foster"))
    .toString()
    .split(":")[1];

  const initialFoster = () => {
    if (applicationFilter.filters?.length) {
      const fosterFilter = applicationFilter.filters.filter((filter) => filter.includes("foster"));
      if (fosterFilter.length && userSearch?.results.length === 1) {
        const foster = userSearch?.results[0];
        return foster;
      } else return undefined;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: -1 }}>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            startDate: applicationStart,
            endDate: applicationEnd,
            status: applicationStatus,
            foster: applicationFoster,
            dog: applicationDog,
            pastDog: applicationPastDog,
          }}
          onSubmit={() => {}}
        >
          {(formProps: any) => {
            return (
              <Form noValidate>
                <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
                  <Container>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ paddingBottom: 1 }}
                    >
                      <Grid item display="flex" sm={9} xs={12} justifyContent="flex-start">
                        {checkUserRole(loggedInUser!, UserRoles.Foster) && (
                          <Switch
                            label="Only Show My Fosters' Applications"
                            checked={fosterApplications}
                            onChange={handleCheckbox}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} justifyContent="flex-end" sx={{ display: { sm: "none", xs: "flex" } }}>
                        <ActionButton
                          text={showFilters ? "Hide Filters" : "Show Filters"}
                          onClick={() => setShowFilters(!showFilters)}
                          type="button"
                        />
                      </Grid>
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}
                      >
                        <ActionButton
                          type="reset"
                          text="Reset"
                          color="success"
                          onClick={async (event) => {
                            await handleClear();
                            await handleReset.bind(formProps.resetForm);
                            await handleCheckbox(event, false);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ paddingBottom: 1, display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}
                >
                  <Grid item md={3} xs={12}>
                    <Field
                      name="status"
                      label="Status"
                      component={Inputs.OptionDropdown}
                      searchFunction={getApplicationStatuses}
                      onSelected={handleStatusFilter}
                      multiple
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="foster"
                      label="Fosters"
                      component={Inputs.OptionDropdown}
                      searchFunction={getUserSearch}
                      onSelected={handleFosterFilter}
                      // filterForm={{
                      //   id: initialFosterId ? parseInt(initialFosterId) : undefined,
                      //   pageNumber: 1,
                      //   pageSize: 10,
                      //   filters: ["role:3"],
                      // }}
                      hideLargeResults
                      multiple
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="dog"
                      label={`Dog`}
                      multiple
                      options={
                        currentDogSearch?.results && filterForm.query
                          ? currentDogSearch.results.map((dog: DogSimple) => {
                              return {
                                id: dog.id,
                                value: `${dog.id} - ${dog.name} (${dog.primaryBreed.value})`,
                              };
                            })
                          : []
                      }
                      component={Inputs.Dropdown}
                      onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFilterForm({ ...filterForm, query: e.currentTarget.value });
                        const list = await dispatch(
                          getDogsSearch({ search: { ...filterForm, query: e.currentTarget.value } })
                        );
                        setCurrentDogSearch(list.payload as SearchResults);
                      }}
                      onSelected={(event: React.SyntheticEvent, value: any | Array<any>) => {
                        setFilterForm({ ...filterForm, query: "" });
                        handleDogFilter(value);
                      }}
                      noOptionsText={filterForm.query === "" ? "Start typing to search" : undefined}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="pastDog"
                      label={`Dog (Past Interest)`}
                      multiple
                      options={
                        pastDogSearch?.results && filterForm.query
                          ? pastDogSearch.results.map((dog: DogSimple) => {
                              return {
                                id: dog.id,
                                value: `${dog.id} - ${dog.name} (${dog.primaryBreed.value})`,
                              };
                            })
                          : []
                      }
                      component={Inputs.Dropdown}
                      onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                        setFilterForm({ ...filterForm, query: e.currentTarget.value });
                        const list = await dispatch(
                          getDogsSearch({ search: { ...filterForm, query: e.currentTarget.value } })
                        );
                        setPastDogSearch(list.payload as SearchResults);
                      }}
                      onSelected={(event: React.SyntheticEvent, value: any | Array<any>) => {
                        setFilterForm({ ...filterForm, query: "" });
                        handleAllDogFilter(value);
                      }}
                      noOptionsText={filterForm.query === "" ? "Start typing to search" : undefined}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      name="startDate"
                      label="Submitted Start Date"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setApplicationStart(new Date(value)));
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      name="endDate"
                      label="Submitted End Date"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setApplicationEnd(new Date(value)));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Search label="Search" handleChange={updateSearch} value={applicationFilter.query} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
      <Box margin={1}>
        {status === "loading" && !applicationsSearchResults ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" sx={{ textAlign: "center", marginY: status === "loading" ? 0 : "29px" }}>
              {status === "loading" ? `Loading, please wait...` : <></>}
            </Typography>
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={applicationFilter.pageNumber}
                rowsPerPage={applicationFilter.pageSize}
                totalRows={applicationsSearchResults?.totalResults}
                changePage={changePage}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No applications to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you would like to delete the application for ${adminApplication?.applicant?.firstName} ${adminApplication?.applicant?.lastName}? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={async () => {
                setDeleteModal(false);
                await handleDeleteApp(adminApplication?.id!);
                await dispatch(getApplicationsSearch({ search: applicationFilter }));
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Modal open={pdfModal} onClose={cancelPdf}>
        <Box
          sx={{
            borderRadius: 5,
            backgroundColor: theme.palette.background.default,
            padding: 2,
          }}
        >
          <PdfAppPreview />
          <Grid container direction={"row"} justifyContent={"space-between"} pt={2}>
            <Grid item sx={{ p: 1 }}>
              <ActionButton text="Cancel" type="button" onClick={cancelPdf} />
            </Grid>
            <Grid item sx={{ p: 1 }}>
              <PDFDownloadLink
                document={<PdfAppView application={adminApplication} />}
                fileName={`${adminApplication?.applicant?.firstName}-${adminApplication?.applicant?.lastName}-application`}
              >
                <ActionButton text="Download" type="button" onClick={() => {}} />
              </PDFDownloadLink>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminApplicationsTable;
