import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEvent } from "../../slices/events";
import { useDispatch, useSelector } from "../../store";
import Moment from "react-moment";
import theme from "../../theme";
import ActionButton from "../base/ActionButton";
import Icon, { IconType } from "../base/Icon";
import { atcb_action } from "add-to-calendar-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../helpers/getAddressLink";
import FileVM from "../../types/fileVM";
import { startVideo, stopVideo } from "../../slices/file";
import ReactPlayer from "react-player";
import Carousel from "../base/Carousel";

interface Props {}

const EventView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { event, status } = useSelector((store) => store.events);
  const { videoPlaying } = useSelector((store) => store.file);

  const [video, setVideo] = useState<FileVM>();
  const [viewImage, setViewImage] = useState("");

  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const getMainPhoto = (photos: FileVM[]) => {
    let mainPhoto = photos.find((p) => p.id === event.mainPhotoId);
    if (!!mainPhoto) {
      setViewImage(mainPhoto.url);
    }
  };

  const setViewPhoto = (id: number) => {
    let viewPhoto = event.photos.find((p) => p.id === id);
    if (!!viewPhoto) {
      setViewImage(viewPhoto.url);
    }
  };

  const handleVideoClick = (video: FileVM) => {
    setVideo(video);
    setViewPhoto(video.id!);
    dispatch(startVideo(video.id));
  };

  const handleImageClick = (id: number) => {
    setVideo(undefined);
    setViewPhoto(id);
    dispatch(stopVideo(0));
  };

  useEffect(() => {
    if (+id! !== event.id) {
      dispatch(getEvent(+id!));
    }
  }, [event.id, id, dispatch]);

  useEffect(() => {
    getMainPhoto(event.photos);
  }, [event, dispatch]);

  return (
    <Container maxWidth="xl">
      {status === "loading" ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography>Loading, please wait...</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ textAlign: { md: "left", xs: "center" }, mb: 3 }}>
            <Typography variant="h1">{event.name}</Typography>
          </Box>
          <Grid
            container
            justifyContent={"space-between"}
            sx={{
              backgroundColor: theme.palette.background.paper,
              flexDirection: { md: "row", xs: "column" },
              borderRadius: 10,
            }}
            spacing={0}
          >
            {/* Image */}
            <Grid item sx={{ marginBottom: "-7px" }}>
              <Grid container direction={"column"}>
                <Grid item sx={{ alignSelf: { md: "flex-start", xs: "stretch" }, position: "relative" }}>
                  <Box sx={{ position: "absolute", top: -6, left: 37 }}>
                    <Icon type={IconType.Bookmark} color={theme.palette.primary.main} size={"90px"} />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 61,
                      width: 40,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
                        <Moment format="MMM" style={{ textTransform: "uppercase" }}>
                          {event.start}
                        </Moment>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h2" sx={{ color: theme.palette.primary.contrastText, marginTop: "-5px" }}>
                        <Moment format="D">{event.start}</Moment>
                      </Typography>
                    </Box>
                  </Box>
                  {video && videoPlaying?.id === video.id ? (
                    <Box
                      sx={{
                        objectFit: "cover",
                        width: { lg: 700, md: 525, xs: "100%" },
                        borderRadius: 10,
                      }}
                    >
                      <ReactPlayer
                        onEnded={() => dispatch(stopVideo(video.id))}
                        height="100%"
                        width="100%"
                        playing
                        controls={true}
                        url={video.url}
                      />
                    </Box>
                  ) : viewImage ? (
                    <Box
                      component="img"
                      alt={`event picture`}
                      src={event.photos ? viewImage : ""}
                      sx={{
                        objectFit: "cover",
                        width: { lg: 700, md: 525, xs: "100%" },
                        borderRadius: 10,
                      }}
                    />
                  ) : null}

                  {/* <Box
                    component="img"
                    alt={`event picture`}
                    src={event.photos[0]?.url ? event.photos[0].url : ""}
                    sx={{
                      objectFit: "cover",

                      width: { lg: 700, md: 525, xs: "100%" },
                      borderRadius: 10,
                    }}
                  /> */}
                </Grid>
                <Grid item>
                  <Carousel
                    files={event.photos}
                    filesPerView={4}
                    onPictureClick={handleImageClick}
                    onVideoClick={handleVideoClick}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Info */}
            <Grid item sx={{ flex: 1 }}>
              <Grid
                container
                direction={"column"}
                sx={{
                  minHeight: { lg: 480, md: 365, xs: "fit-content" },
                  mb: { lg: "15px", md: "11px" },
                  pt: 2,
                  pr: 2,
                  pl: 4,
                }}
              >
                <Grid item>
                  {event.id !== 43 && (
                    <>
                      <Grid item>
                        <Typography variant="button">
                          From: <Moment format="dddd, MMMM Do">{event.start}</Moment> at{" "}
                          <Moment format="h:mmA">{event.start}</Moment>
                        </Typography>
                      </Grid>
                      <Grid item pb={3}>
                        <Typography variant="button">
                          To: <Moment format="dddd, MMMM Do">{event.end}</Moment> at{" "}
                          <Moment format="h:mmA">{event.end}</Moment>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="button">
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faLocationDot}
                            size="sm"
                            onClick={() => window.open(getAddressLink(event?.address!), "_blank")}
                          />{" "}
                          {event.address.line1}
                        </Typography>
                      </Grid>
                      <Grid item>{event.address.line2 && <Typography>{event.address.line2}</Typography>}</Grid>
                      <Grid item>
                        <Typography variant="button">
                          {event.address.city}, {event.address.state?.value} {event.address.postalCode}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Typography component={"span"} variant="body1" pt={1}>
                    {event.details.split("\n").map((i, key) => {
                      return <p key={key}>{i}</p>;
                    })}
                  </Typography>
                </Grid>
                {event.id === 43 && (
                  <Grid item alignSelf={"center"}>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                      <input type="hidden" name="cmd" value="_s-xclick" />
                      <input type="hidden" name="hosted_button_id" value="MA62HVL6QY8XS" />
                      <div style={{ textAlign: "center" }}>
                        <table>
                          <tr>
                            <td>
                              <input type="hidden" name="on0" value="Quantity" />
                              Quantity
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <select name="os0" style={{ maxWidth: xs ? "275px" : "350px" }}>
                                <option value="1 HAL 2023 Calendar + $3.00 shipping">
                                  1 HAL 2023 Calendar + $3.00 shipping $23.00 USD
                                </option>
                                <option value="2 HAL 2023 Calendars + $3.50 shipping">
                                  2 HAL 2023 Calendars + $3.50 shipping $43.50 USD
                                </option>
                                <option value="3 HAL 2023 Calendars + $4.00 shipping">
                                  3 HAL 2023 Calendars + $4.00 shipping $64.00 USD
                                </option>
                                <option value="4 HAL 2023 Calendars + $4.50 shipping">
                                  4 HAL 2023 Calendars + $4.50 shipping $84.50 USD
                                </option>
                                <option value="5 HAL 2023 Calendars + $5.00 shipping">
                                  5 HAL 2023 Calendars + $5.00 shipping $105.00 USD
                                </option>
                              </select>
                            </td>
                          </tr>
                        </table>
                        <input type="hidden" name="currency_code" value="USD" />
                        <input
                          type="image"
                          src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                          name="submit"
                          title="PayPal - The safer, easier way to pay online!"
                          alt="Buy Now"
                        />
                      </div>
                    </form>
                  </Grid>
                )}
              </Grid>
              {event.id !== 43 && (
                <Grid container direction={"column"} sx={{ pb: { md: 2, xs: "15px" } }}>
                  <Grid item alignSelf={"center"}>
                    <ActionButton
                      type="button"
                      text="Add to Calendar"
                      onClick={() => {
                        atcb_action({
                          name: event.name,
                          description: event.details,
                          location: `${event.address.line1} ${event.address.line2}, ${event.address.city}, ${event.address.state?.value} ${event.address.postalCode}`,
                          startDate: `${event.start?.toString().split("T")[0]}`,
                          startTime: `${new Date(event?.start!).toTimeString().split(":")[0]}:${
                            new Date(event?.start!).toTimeString().split(":")[1]
                          }`,
                          endDate: `${event.end?.toString().split("T")[0]}`,
                          endTime: `${new Date(event?.end!).toTimeString().split(":")[0]}:${
                            new Date(event?.end!).toTimeString().split(":")[1]
                          }`,
                          timeZone: "America/New_York",
                          options: ["Apple", "Google", "Yahoo", "Outlook.com"],
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default EventView;
