import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";

interface Props {
  secondary?: boolean;
  label: string;
  value?: string;
  handleChange: (value: string) => void;
  small?: boolean;
  handleSubmit?: () => void;
  dontSearchOnLoad?: boolean;
}

const Search: React.FC<Props> = (props) => {
  const { secondary, label, value, handleChange, small, handleSubmit, dontSearchOnLoad } = props;
  const [searchValue, setSearchValue] = useState<string>();

  let inputProps = {};

  if (!!secondary) {
    inputProps = {
      autoComplete: "off",
      disableUnderline: true,
      style: {
        color: theme.palette.primary.main,
        background: theme.palette.background.paper,
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
        borderRadius: "10px",
      },
      endAdornment: (
        <InputAdornment
          position="end"
          sx={{ cursor: handleSubmit && searchValue ? "pointer" : "default" }}
          onClick={() => {
            if (handleSubmit && searchValue) {
              handleSubmit();
              setSearchValue("");
            }
          }}
        >
          <Icon type={IconType.Magnify} color={theme.palette.primary.main} size={"20px"} />
        </InputAdornment>
      ),
    };
  } else {
    inputProps = {
      autoComplete: "off",
      style: {
        color: theme.palette.primary.main,
        background: "transparent",
      },
      endAdornment: (
        <InputAdornment
          position="end"
          sx={{ cursor: handleSubmit && searchValue ? "pointer" : "default" }}
          onClick={() => {
            if (handleSubmit && searchValue) {
              handleSubmit();
              setSearchValue("");
            }
          }}
        >
          <Icon type={IconType.Magnify} color={theme.palette.primary.main} size={"20px"} />
        </InputAdornment>
      ),
    };
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);
    handleChange(e.target.value);
  };

  useEffect(() => {
    if (value !== "" && !dontSearchOnLoad) {
      setSearchValue(value);
      handleChange(value!);
    }
  }, []);

  useEffect(() => {
    if (value === "" && !dontSearchOnLoad) {
      setSearchValue(value);
      handleChange(value!);
    }
  }, [value]);

  return (
    <Box sx={{ px: "10px" }}>
      <TextField
        autoComplete="off"
        size={!!small ? "small" : "medium"}
        variant={!!secondary ? "filled" : "outlined"}
        sx={{
          "& .MuiFormLabel-root": {
            color: theme.palette.primary.main,
            fontStyle: "italic",
            fontSize: "20px",
          },
          "& .MuiFormHelperText-root": {
            margin: "0px 14px 10px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: theme.palette.primary.main,
              borderWidth: "2px",
              borderRadius: "10px",
            },
            "&:hover fieldset": {
              borderColor: theme.palette.primary.main,
              borderWidth: "2px",
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.main,
              borderWidth: "2px",
            },
          },
        }}
        inputProps={{
          "data-form-type": "other",
          "data-lpignore": " true",
        }}
        fullWidth
        InputProps={inputProps}
        label={label}
        value={searchValue || ""}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSearch(e)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            if (handleSubmit) {
              handleSubmit();
              setSearchValue("");
            }
          }
        }}
      />
    </Box>
  );
};

export default Search;
