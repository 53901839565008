import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";

interface Props {
  title: string;
}

const TitleBar: React.FC<Props> = (props) => {
  return (
    <Box>
      <Box sx={{ mt: "10px", py: 1, backgroundColor: theme.palette.primary.main, marginBottom: 3 }}>
        <Typography variant="h1" color="textSecondary" textAlign={"center"}>
          {props.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default TitleBar;
