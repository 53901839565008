import React from "react";
import AdoptionProcessView from "../views/AdoptionProcessView";
import { Box } from "@mui/material";

interface Props {}

const AdoptionProcessPage: React.FC<Props> = (props) => {
  return (
    <Box>
      <AdoptionProcessView />
    </Box>
  );
};

export default AdoptionProcessPage;
