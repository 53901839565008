import React, { ReactNode } from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import {
  Autocomplete,
  TextFieldProps,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  TextField,
  Box,
  Typography,
  Popper,
} from "@mui/material";
import theme from "../../theme";
import { ExpandMore } from "@mui/icons-material";
import { CustomInputProps } from "./FormikTextField";

interface Props {
  options: any[];
  handleChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  onSelected?: (
    event: React.SyntheticEvent,
    value: any | Array<any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => void;
  multiple?: boolean;
  secondary?: boolean;
  noOptionsText?: string;
  renderOption?: (props: React.HtmlHTMLAttributes<HTMLLIElement>, option: any) => ReactNode;
}

const FormikAutocomplete: React.FC<FieldProps & TextFieldProps & CustomInputProps & Props> = (props) => {
  const {
    options,
    handleChange,
    onSelected,
    multiple,
    field,
    secondary,
    InputProps,
    helperText,
    error,
    noOptionsText,
    renderOption,
    disabled,
    ...rest
  } = props;
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { setFieldValue } = useFormikContext();

  const onChange = (event: React.ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason) => {
    setFieldValue(props.field.name, value || null);

    if (reason === "clear") {
      value = "";
    }

    if (handleChange) {
      handleChange(event, value);
    }
  };

  const handleSelected = (
    event: React.SyntheticEvent,
    value: any | Array<any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    setFieldValue(props.field.name, value || null);
    onSelected?.(event, value, reason, details);
  };

  const defaultRenderOption = (props: React.HtmlHTMLAttributes<HTMLLIElement>, option: any) => {
    return (
      <li {...props} key={option?.id} style={{ backgroundColor: `${theme.palette.background.paper}` }}>
        {option?.value || option?.name || ""}
      </li>
    );
  };

  const customPopper = function (props: any) {
    return <Popper {...props} placement="bottom" />;
  };

  return (
    <Autocomplete
      {...field}
      readOnly={disabled}
      PopperComponent={customPopper}
      multiple={!!multiple}
      value={field.value || null}
      filterOptions={(x) => x}
      options={options || []}
      autoSelect
      noOptionsText={
        <Typography color="primary">
          {noOptionsText
            ? noOptionsText
            : "No results for provided search term. Backspace and try searching something else."}
        </Typography>
      }
      getOptionLabel={(option) => {
        return option?.name || option?.value || option?.firstName + " " + option?.lastName || "";
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      popupIcon={<ExpandMore fontSize="medium" color="primary" />}
      onChange={
        onSelected
          ? handleSelected
          : (e: React.ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason) => onChange(e, value, reason)
      }
      renderOption={renderOption || defaultRenderOption}
      renderInput={(params) => (
        <Box sx={{ px: "10px" }}>
          <TextField
            {...params}
            variant={!!secondary ? "filled" : "outlined"}
            sx={{
              "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
                fontStyle: "italic",
                fontSize: "20px",
              },
              "& .MuiFormHelperText-root": {
                color: theme.palette.error.main,
                margin: "-3px 10px 5px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.primary.main,
                  borderWidth: "2px",
                  borderRadius: "10px",
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.primary.main,
                  borderWidth: "2px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main,
                  borderWidth: "2px",
                },
              },
              "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
            }}
            helperText={helperText ?? (isTouched && errorMessage) ? errorMessage : " "}
            error={error ?? Boolean(isTouched && errorMessage)}
            {...rest}
            size="medium"
          />
        </Box>
      )}
    />
  );
};

export default FormikAutocomplete;
