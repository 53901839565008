import React from "react";
import EditCreateDogView from "../views/EditCreateDogView";
import TitleBar from "../base/TitleBar";
import { useSelector } from "../../store";
import { useParams } from "react-router-dom";

interface Props {}

const EditCreateDogPage: React.FC<Props> = (props) => {
  const { dog, status } = useSelector((store) => store.dogs);
  const { id } = useParams();

  return (
    <>
      <TitleBar title={status === "loading" ? "Loading, please wait..." : +id! > 0 ? `${dog.name}` : "Create Dog"} />
      <EditCreateDogView />
    </>
  );
};

export default EditCreateDogPage;
