import User from "../types/user";
import checkUserRole, { UserRoles } from "./checkUserRole";

const adminReroute = (loggedInUser: User) => {
  if (
    checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Document) ||
    checkUserRole(loggedInUser!, UserRoles.Inventory) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer)
  ) {
    return "/admin/dogs";
  } else if (checkUserRole(loggedInUser!, UserRoles.Foster)) {
    return "/admin/applications";
  } else if (checkUserRole(loggedInUser!, UserRoles.Marketing)) {
    return "/admin/events";
  } else if (checkUserRole(loggedInUser!, UserRoles.Personnel)) {
    return "/admin/users";
  } else {
    return "/admin/reports";
  }
};

export default adminReroute;
