import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../../helpers/getAddressLink";
import theme from "../../../theme";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import Moment from "react-moment";

interface Props {}

const ReadOnlyUser: React.FC<Props> = (props) => {
  const { user } = useSelector((store) => store.users);

  return (
    <>
      <Paper
        sx={{
          background: theme.palette.background.default,
          padding: {
            xs: "15px",
            sm: "30px",
          },
          height: "100%",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <Grid container spacing={3} display="flex" flexDirection="row" justifyContent="center" alignItems={"baseline"}>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Name
            </Typography>
            <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
              {user.firstName} {user.lastName}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Status
            </Typography>
            <Typography variant="body1">{user.active ? "Active" : "Inactive"}</Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Kennel License
            </Typography>
            <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
              {user.kennelLicense}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Roles
            </Typography>
            <Grid container justifyContent={"flex-start"} gap={1} my={1}>
              {user.roles.map((role, index) => {
                return (
                  <Box
                    sx={{
                      backgroundColor: theme.palette.info.main,
                      borderRadius: "100px",
                      width: "fit-content",
                      padding: "0px 10px",
                    }}
                  >
                    <Typography textAlign={"center"} variant="body2">
                      {role.value}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Foster Start
            </Typography>
            {user.fosterStart && <Moment format="MM/DD/YYYY">{user.fosterStart}</Moment>}
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Foster End
            </Typography>
            {user.fosterEnd && <Moment format="MM/DD/YYYY">{user.fosterEnd}</Moment>}
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          background: theme.palette.background.default,
          padding: {
            xs: "15px",
            sm: "30px",
          },
          height: "100%",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <Grid container spacing={3} display="flex" flexDirection="row" justifyContent="center" alignItems={"baseline"}>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Facebook Name
            </Typography>
            <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
              {user.facebookName}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Email
            </Typography>
            <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
              {user.email}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Secondary Email
            </Typography>
            <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
              {user.secondaryEmail}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Address
            </Typography>
            {!!user.address && !!user.address.line1 && (
              <>
                <Typography>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faLocationDot}
                    size="sm"
                    onClick={() => window.open(getAddressLink(user?.address!), "_blank")}
                  />{" "}
                  {user.address?.line1}
                </Typography>
                {user.address?.line2 && <Typography>{user.address?.line2}</Typography>}
                <Typography>
                  {user.address?.city}, {user.address?.state?.value} {user.address?.postalCode}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Phone
            </Typography>
            <Typography variant="body1">{user.phone}</Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Secondary Phone
            </Typography>
            <Typography variant="body1">{user.secondaryPhone}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ReadOnlyUser;
