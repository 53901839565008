import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import Vet from "../types/vet";
import SearchResults from "../types/searchResults";
import getSearchQueryString from "../helpers/getSearchQueryString";
import SearchParams from "../types/searchParams";

interface VetState {
  vet: Vet;
  vetList?: Vet[];
  searchResults?: SearchResults;
  status: "idle" | "loading" | "failed";
}

const initialState: VetState = {
  vet: {
    id: 0,
    clinicName: "",
    vetName: "",
    mainPhone: "",
    email: "",
    contactName: "",
    address: {},
  },
  status: "idle",
};

export const fetchVets = createAsyncThunk<Vet[]>("vets/fetch", async () => {
  const response = await http.get<Vet[]>("/vets");
  return response.data;
});

export const createVet = createAsyncThunk<Vet, Vet>("vets/post", async (vet: Vet) => {
  const response = await http.post<Vet>("/vets", vet);
  return response.data;
});

export const editVet = createAsyncThunk<Vet, Vet>("vets/put", async (vet: Vet) => {
  const response = await http.put<Vet>(`/vets/${vet.id}`, vet);
  return response.data;
});

export const getVet = createAsyncThunk<Vet, number>("vets/get", async (id: number) => {
  const response = await http.get<Vet>(`/vets/${id}`);
  return response.data;
});

export const deleteVet = createAsyncThunk<null, number>("vets/delete", async (id: number) => {
  return await http.delete(`/vets/${id}`);
});

export const searchVet = createAsyncThunk<SearchResults, SearchParams>("vets/search", async (search: SearchParams) => {
  let qs = getSearchQueryString(search);
  const response = await http.get<SearchResults>(`/vets/search?${qs}`);
  return response.data;
});

const vetSlice = createSlice({
  name: "vets",
  initialState,
  reducers: {
    clearState: (state) => {
      state.vetList = undefined;
      state.vet = initialState.vet;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVets.fulfilled, (state, action) => {
        state.status = "idle";
        state.vetList = action.payload;
      })
      .addCase(fetchVets.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(createVet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createVet.fulfilled, (state, action) => {
        state.status = "idle";
        state.vet = action.payload;
      })
      .addCase(createVet.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(editVet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editVet.fulfilled, (state, action) => {
        state.status = "idle";
        state.vet = action.payload;
      })
      .addCase(editVet.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getVet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVet.fulfilled, (state, action) => {
        state.status = "idle";
        state.vet = action.payload;
      })
      .addCase(getVet.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(deleteVet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteVet.fulfilled, (state, action) => {
        state.status = "idle";
        state.vet = initialState.vet;
      })
      .addCase(deleteVet.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(searchVet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchVet.fulfilled, (state, action) => {
        state.status = "idle";
        state.searchResults = action.payload;
      })
      .addCase(searchVet.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { clearState } = vetSlice.actions;

export default vetSlice.reducer;
