import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "../../../store";
import { Box, DialogActions, Typography } from "@mui/material";
import StyledTable from "../../base/StyledTable";
import Moment from "react-moment";
import TableActions from "../../base/TableActions";
import { deleteFile, getOneFileById } from "../../../slices/file";
import FileVM from "../../../types/fileVM";
import Dropzone from "../../base/Dropzone";
import UploadView from "../FileUploadView";
import Modal from "../../base/Modal";
import Dialog from "../../base/Dialog";
import ActionButton from "../../base/ActionButton";
import { getFilesById } from "../../../slices/file";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import { handleSuccessToastState, setSuccessMessage } from "../../../slices/toast";
import EmailDocumentView from "../EmailDocumentView";

interface Props {}

const UserDocumentTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { fileSearch, newFile, status: fileStatus } = useSelector((store) => store.file);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { user } = useSelector((store) => store.users);
  const [uploadModal, setUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [fileUpload, setFileUpload] = useState<FileVM>({
    id: 0,
    fileType: { id: 6, value: "" },
    entityId: user.id,
    url: "",
    fileName: "",
  });

  useEffect(() => {
    getFiles();
  }, [dispatch]);

  const getFiles = () => {
    dispatch(getFilesById({ entityId: user.id, typeId: 6 }));
  };

  const handleSelectFile = (file: File[]) => {
    if (file && file[0] && file[0].name) {
      setFileUpload({ ...fileUpload, upload: file[0], fileName: file[0].name });
      setUploadModal(true);
    }
  };

  const handleEditFile = async (id: number) => {
    const result: any = await dispatch(getOneFileById(id));
    setFileUpload(result.payload);
    setUploadModal(true);
  };

  const handleDeleteModal = (id: number) => {
    setDeleteModal(true);
    dispatch(getOneFileById(id));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteFile(id)).then(() => {
      getFiles();
      setDeleteModal(false);
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const close = () => {
    setUploadModal(false);
    setFileUpload({ id: 0, fileType: { id: 6, value: "" }, entityId: user.id, url: "", fileName: "" });
  };

  const closeEmail = () => {
    setEmailModal(false);
  };

  const closeEmailSuccess = () => {
    dispatch(handleSuccessToastState(true));
    dispatch(setSuccessMessage("Document successfully sent!"));
    setEmailModal(false);
  };

  const handleEmail = async (id: number) => {
    await dispatch(getOneFileById(id));
    await setEmailModal(true);
  };

  const headers = ["Actions", "Date", "Name", "Type"];

  const rows =
    (!!user.id &&
      fileSearch
        .slice()
        .reverse()
        .map((file: FileVM) => {
          return {
            actions: (
              <TableActions
                id={file.id!}
                handleView={() => {
                  window.open(file.url, "_blank");
                }}
                handleEdit={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel)
                    ? handleEditFile
                    : undefined
                }
                handleDelete={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel)
                    ? handleDeleteModal
                    : undefined
                }
                handleEmailDocument={handleEmail}
              />
            ),
            uploaded: file.uploadedDate && <Moment format="MM/DD/YYYY">{file.uploadedDate!}</Moment>,
            name: file.fileName,
            type: file.documentType?.value,
          };
        })) ||
    [];

  return (
    <Box sx={{ borderRadius: "25px" }}>
      {checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel) ? (
        <Box>
          <Typography textAlign={"center"}>Upload Documents</Typography>
          <Dropzone
            onDrop={(file: File[]) => {
              handleSelectFile(file);
            }}
          />
        </Box>
      ) : null}
      {fileSearch.length ? (
        <StyledTable
          headers={headers}
          rows={rows}
          defaultPaging
          paging={true}
          sizeVariant="small"
          title={<Typography variant="body2">Documents</Typography>}
          totalRows={fileSearch.length}
        />
      ) : null}
      <Modal open={uploadModal} onClose={close}>
        <UploadView
          onCancel={close}
          upload={fileUpload}
          afterSave={() => {
            getFiles();
            close();
          }}
          submittingStatus={fileStatus === "loading"}
          type="people"
        />
      </Modal>
      <Dialog
        open={deleteModal}
        title={`Are you sure you want to delete ${
          newFile.fileName ? newFile.fileName : `this ${newFile.documentType?.value!}`
        }? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" color="secondary" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={fileStatus === "loading"}
              color="error"
              onClick={() => {
                handleDelete(newFile.id!);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Modal open={emailModal} onClose={closeEmail}>
        <EmailDocumentView
          onCancel={closeEmail}
          onSuccessClose={closeEmailSuccess}
          submittingStatus={fileStatus === "loading"}
          fileIds={[newFile?.id!]}
        />
      </Modal>
    </Box>
  );
};

export default UserDocumentTable;
