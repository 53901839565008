import React, { ChangeEvent } from "react";
import { Grid, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import { Inputs } from "../../forms";
import * as Yup from "yup";
import {
  getDogExperiences,
  getFenceHeights,
  getFenceSizes,
  getFenceTypes,
  getHouseholdTypes,
  getLivingSituation,
  getResidenceTypes,
} from "../../../slices/options";
import { AddHome } from "@mui/icons-material";
import { useDispatch, useSelector } from "../../../store";
import HomeInfo from "../../../types/application/homeInfo";
import { saveHomeInfoStep } from "../../../slices/applications";
import KeyValue from "../../../types/keyValue";

interface Props {
  formikRef?: React.RefObject<FormikProps<any>>;
}

const HomeInfoStep: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const initialState: HomeInfo = useSelector((store) => ({
    residenceType: store.applications.myApplication?.residenceType || undefined,
    residentsInHome:
      store.applications.myApplication?.residentsInHome == null
        ? undefined
        : store.applications.myApplication?.residentsInHome,
    householdResidents: store.applications.myApplication?.householdResidents || undefined,
    livingSituation: store.applications.myApplication?.livingSituation || undefined,
    yardFenced: store.applications.myApplication?.yardFenced || undefined,
    householdType: store.applications.myApplication?.householdType || undefined,
    exerciseLocation: store.applications.myApplication?.exerciseLocation || undefined,
    landlordAllowDogs:
      store.applications.myApplication?.livingSituation?.id === 2
        ? store.applications.myApplication?.landlordAllowDogs
        : undefined,
    landlordName:
      store.applications.myApplication?.livingSituation?.id === 2
        ? store.applications.myApplication?.landlordName
        : undefined,
    landlordPhone:
      store.applications.myApplication?.livingSituation?.id === 2
        ? store.applications.myApplication?.landlordPhone
        : undefined,
    dogSizeRestrictions:
      store.applications.myApplication?.livingSituation?.id === 2
        ? store.applications.myApplication?.dogSizeRestrictions
        : undefined,
    dogBreedRestrictions:
      store.applications.myApplication?.livingSituation?.id === 2
        ? store.applications.myApplication?.dogBreedRestrictions
        : undefined,
    fenceType: store.applications.myApplication?.fenceType || undefined,
    fenceHeight: store.applications.myApplication?.fenceHeight || undefined,
    fenceSize: store.applications.myApplication?.fenceSize || undefined,
  }));

  const handleSubmit = (values: HomeInfo) => {
    dispatch(saveHomeInfoStep(values));
  };

  const validationSchema = Yup.object().shape(
    {
      residenceType: Yup.object().typeError("Delete typed input and select an option").required("Required"),
      residentsInHome: Yup.number()
        .required("Required")
        .test("residents", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
      householdResidents: Yup.array().of(
        Yup.object()
          .shape({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            age: Yup.number()
              .required("Required")
              .test("age", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
            relationship: Yup.string().required("Required"),
            allergies: Yup.boolean().required("Required"),
            dogExperience: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          })
          .required("Required")
      ),
      livingSituation: Yup.object().typeError("Delete typed input and select an option").required("Required"),
      householdType: Yup.object().typeError("Delete typed input and select an option").required("Required"),
      yardFenced: Yup.boolean().nullable(),
      fenceType: Yup.object().when("yardFenced", {
        is: (value: boolean) => !!value,
        then: (schema) => schema.typeError("Delete typed input and select an option").required("Required"),
      }),
      fenceHeight: Yup.object().when("yardFenced", {
        is: (value: boolean) => !!value,
        then: (schema) => schema.typeError("Delete typed input and select an option").required("Required"),
      }),
      fenceSize: Yup.object().when("yardFenced", {
        is: (value: boolean) => !!value,
        then: (schema) => schema.typeError("Delete typed input and select an option").required("Required"),
      }),
      exerciseLocation: Yup.string().when("yardFenced", {
        is: (value: boolean) => !value,
        then: (schema) => schema.required("Required"),
      }),
      landlordName: Yup.string().when("landlordAllowDogs", {
        is: (value: boolean) => !!value,
        then: (schema) => schema.required("Required"),
      }),
      landlordPhone: Yup.string().when("landlordAllowDogs", {
        is: (value: boolean) => !!value,
        then: (schema) => schema.required("Required").min(14, "Must be a valid phone number"),
      }),
    },
    [
      ["yardFenced", "fenceType"],
      ["yardFenced", "fenceHeight"],
      ["yardFenced", "fenceSize"],
      ["yardFenced", "exerciseLocation"],
    ]
  );

  const handleResidentChange = (
    setFieldValue: (field: string, value: any) => void,
    numberOfResidents: number,
    curentNumberOfResidents: number,
    push: (obj: any) => void,
    remove: <T>(index: number) => T | undefined
  ) => {
    if (numberOfResidents < 0) {
      numberOfResidents = 0;
      setFieldValue("residentsInHome", 0);
    }

    if (numberOfResidents > 8) {
      numberOfResidents = 8;
      setFieldValue("residentsInHome", 8);
    }

    let change = numberOfResidents - curentNumberOfResidents;

    if (change < 0) {
      change = change * -1;
      for (let i = 0; i < change; i++) {
        remove(curentNumberOfResidents - 1 - i);
      }
    } else {
      for (let i = 0; i < change; i++) {
        push({
          firstName: "",
          lastName: "",
          age: "",
          relationship: "",
          allergies: false,
          dogExperience: undefined,
        });
      }
    }
  };

  const renderResident = (resident: any, index: number) => {
    return (
      <Grid container key={`resident${index}`} sx={{}}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
          >{`Additional Resident # ${index + 1}`}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
        >
          <AddHome fontSize="large" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.Text}
            name={`householdResidents[${index}].firstName`}
            label={`First Name`}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field component={Inputs.Text} name={`householdResidents[${index}].lastName`} label={`Last Name`} required />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field component={Inputs.Number} name={`householdResidents[${index}].age`} label={`Age`} required />
        </Grid>
        <Grid item xs={1} md={1}></Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.Text}
            name={`householdResidents[${index}].relationship`}
            label={`Relationship to You`}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdResidents[${index}].dogExperience`}
            label={`Dog Experience`}
            searchFunction={getDogExperiences}
            required
          />
        </Grid>
        <Grid item xs={11} md={3}>
          <Field component={Inputs.Switch} name={`householdResidents[${index}].allergies`} label={`Dog Allergies`} />
        </Grid>
      </Grid>
    );
  };

  return (
    initialState && (
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={props.formikRef}
        validateOnMount
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            <Grid container sx={{ paddingTop: "5px", marginBottom: values.livingSituation?.id === 2 ? 15 : 10 }}>
              <Grid item xs={12} md={7}>
                <Field
                  name="residenceType"
                  label="Type of Residence"
                  component={Inputs.OptionDropdown}
                  searchFunction={getResidenceTypes}
                  required
                />
              </Grid>

              <FieldArray name="householdResidents">
                {({ push, remove }) => (
                  <>
                    <Grid item xs={12} md={5}>
                      <Field
                        component={Inputs.Number}
                        name="residentsInHome"
                        label="# of Additional Residents In Home"
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          if (event.currentTarget.value.includes(".")) {
                            event.preventDefault();
                          } else {
                            handleResidentChange(
                              setFieldValue,
                              +event.currentTarget.value,
                              values?.householdResidents?.length || 0,
                              push,
                              remove
                            );
                          }
                        }}
                        required
                      />
                    </Grid>

                    {(values?.householdResidents || []).map((resident, index) => renderResident(resident, index))}
                  </>
                )}
              </FieldArray>

              <Grid item xs={12} md={3}>
                <Field
                  name="livingSituation"
                  label="Living Situation"
                  component={Inputs.OptionDropdown}
                  searchFunction={getLivingSituation}
                  required
                  onSelected={(value: KeyValue) => {
                    if (value.id === 1) {
                      setFieldValue("landlordAllowDogs", false);
                      setFieldValue("landlordName", "");
                      setFieldValue("landlordPhone", "");
                      setFieldValue("dogSizeRestrictions", false);
                      setFieldValue("dogBreedRestrictions", false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="householdType"
                  label="Which BEST describes your household?"
                  component={Inputs.OptionDropdown}
                  searchFunction={getHouseholdTypes}
                  required
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  component={Inputs.Switch}
                  name="yardFenced"
                  label="Is Yard Fenced?"
                  onChange={(value: boolean) => {
                    if (value) {
                      setFieldValue("exerciseLocation", undefined);
                    } else {
                      setFieldValue("fenceType", undefined);
                      setFieldValue("fenceHeight", undefined);
                      setFieldValue("fenceSize", undefined);
                    }
                  }}
                />
              </Grid>
              {!values.yardFenced && (
                <>
                  <Grid item xs={12} md={12}>
                    <Field
                      name="exerciseLocation"
                      label="How & where will the dog exercise & eliminate?"
                      component={Inputs.Text}
                      required
                    />
                  </Grid>
                </>
              )}

              {values.yardFenced && (
                <>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="fenceType"
                      label="Fence Type"
                      component={Inputs.OptionDropdown}
                      searchFunction={getFenceTypes}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="fenceHeight"
                      label="Fence Height at Lowest Point"
                      component={Inputs.OptionDropdown}
                      searchFunction={getFenceHeights}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="fenceSize"
                      label="Fenced-In Yard Size"
                      component={Inputs.OptionDropdown}
                      searchFunction={getFenceSizes}
                      required
                    />
                  </Grid>
                </>
              )}
              {(values.livingSituation as any)?.id === 2 && (
                <>
                  <Grid item xs={12} md={6}>
                    <Field name="landlordAllowDogs" label="Does Landlord Allow Dogs?" component={Inputs.Switch} />
                  </Grid>
                  <Grid item xs={0} md={6}></Grid>
                </>
              )}
              {(values.livingSituation as any)?.id === 2 && !!values.landlordAllowDogs && (
                <>
                  <Grid item xs={12} md={6}>
                    <Field name="landlordName" label="Landlord Name" component={Inputs.Text} required />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="landlordPhone" label="Landlord Phone" component={Inputs.Phone} required />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="dogSizeRestrictions"
                      label="Dog Size Restrictions"
                      component={Inputs.Switch}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field name="dogBreedRestrictions" label="Breed Restrictions" component={Inputs.Switch} required />
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    )
  );
};

export default HomeInfoStep;
