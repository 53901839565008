import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { editEvent, getEvent, getEventTypes, postEvent } from "../../../slices/events";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import { Box, Container, Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import ActionButton from "../../base/ActionButton";
import { Inputs } from "../../forms";
import { getStatesSearch } from "../../../slices/options";
import { handleErrorToastState, setErrorMessage } from "../../../slices/toast";
import GetEventIcon from "../../../helpers/GetEventIcon";

interface Props {
  setDirtyForm: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  dirtyForm: boolean;
  formikRef: React.RefObject<FormikProps<any>>;
}

const EditCreateEventTab: React.FC<Props> = (props) => {
  const { setDirtyForm, setActiveTab, dirtyForm, formikRef } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { event, status, eventTypes } = useSelector((store) => store.events);
  const [loading, setLoading] = useState(true);
  //   const editMode = window.location.href.includes("?edit=true");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    dateTime: Yup.object().shape({
      startDate: Yup.date().required("Start date is required").nullable().typeError("required"),
      startTime: Yup.date().required("Start time is required").nullable().typeError("required"),
      endDate: Yup.string().required("End date is required").nullable().typeError("required"),
      endTime: Yup.string().required("End time is required").nullable().typeError("required"),
    }),
    address: Yup.object().shape({
      line1: Yup.string().required("Line 1 is required").typeError("Required"),
      line2: Yup.string().nullable(),
      city: Yup.string().required("City is required").nullable().typeError("Required"),
      state: Yup.object().required("State is required").nullable().typeError("Required"),
      postalCode: Yup.string()
        .min(5, "Must be a valid zip code")
        .max(5, "Must be a valid zip code")
        .required("Zip code is required")
        .nullable()
        .typeError("Required"),
    }),
    details: Yup.string().required("Details are required").nullable().typeError("Required"),
    featured: Yup.boolean(),
    status: Yup.object().nullable(),
    eventType: Yup.object().required("Event type is required").nullable().typeError("Required"),
  });

  const formatDateTime = (date: any, time: any) => {
    const formatDate = new Date(date);
    const formatTime = new Date(time);
    return new Date(
      formatDate.getFullYear(),
      formatDate.getMonth(),
      formatDate.getDate(),
      formatTime.getHours(),
      formatTime.getMinutes().toString().length === 1 ? 0 + formatTime.getMinutes() : formatTime.getMinutes(),
      0
    );
  };

  const handleSubmit = async (values: any) => {
    const startDate = values.dateTime.startDate;
    const startTime = values.dateTime.startTime;
    const endDate = values.dateTime.endDate;
    const endTime = values.dateTime.endTime;
    if (event?.id === 0) {
      const fullStart = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes().toString().length === 1 ? 0 + startTime.getMinutes() : startTime.getMinutes(),
        0
      );
      const fullEnd = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endTime.getHours(),
        endTime.getMinutes().toString().length === 1 ? 0 + endTime.getMinutes() : endTime.getMinutes(),
        0
      );
      if (
        event?.id! === 0 &&
        new Date(`${values.dateTime?.startDate.toISOString().split("T")[0]}`) >
          new Date(`${values.dateTime?.endDate.toISOString().split("T")[0]}`)
      ) {
        dispatch(handleErrorToastState(true));
        dispatch(setErrorMessage("Start date cannot be after end date"));
        return;
      } else if (fullStart.getTime() > fullEnd.getTime()) {
        dispatch(handleErrorToastState(true));
        dispatch(setErrorMessage("End time cannot be before start time"));
        return;
      } else {
        await dispatch(
          postEvent({
            ...values,
            address: { ...values.address, line2: values.address.line2 ? values.address.line2 : "" },
            start: formatDateTime(startDate, startTime),
            end: formatDateTime(endDate, endTime),
          })
        ).then((result: any) => {
          navigate(`/admin/events/${result.payload.id}`);
          dispatch(getEvent(result.payload.id));
          setActiveTab(3);
          setDirtyForm(false);
        });
      }
    } else if (event?.id! > 0) {
      if (
        formatDateTime(values.dateTime.startDate, values.dateTime.startTime) >
        formatDateTime(values.dateTime.endDate, values.dateTime.endTime)
      ) {
        dispatch(handleErrorToastState(true));
        dispatch(setErrorMessage("End date and time cannot be before start date and time"));
      } else {
        await dispatch(
          editEvent({
            ...values,
            start: formatDateTime(startDate, startTime),
            end: formatDateTime(endDate, endTime),
          })
        );
        setLoading(true);
        await dispatch(getEvent(event?.id!));
        navigate(`/admin/events/${event?.id}`);
        setLoading(false);
        setActiveTab(1);
        setDirtyForm(false);
      }
    }
  };

  const handleCancelBack = async () => {
    setLoading(true);
    await dispatch(getEvent(event?.id!));
    navigate("/admin/events");
    await setLoading(false);
  };

  const renderOption = (props: React.HtmlHTMLAttributes<HTMLLIElement>, option: any) => {
    return (
      <li {...props} key={option?.id} style={{ backgroundColor: `${theme.palette.background.paper}`, gap: 5 }}>
        <GetEventIcon id={option.id} dropdown />
        <Typography>{option.value}</Typography>
      </li>
    );
  };

  return (
    <>
      <Container>
        <Formik
          initialValues={
            event.id === 0
              ? {
                  ...event,
                  dateTime: {
                    startDate: undefined,
                    startTime: undefined,
                    endDate: undefined,
                    endTime: undefined,
                  },
                  address: {
                    line1: "",
                    city: "",
                    state: undefined,
                    postalCode: undefined,
                  },
                  eventType: undefined,
                }
              : {
                  ...event,
                  dateTime: {
                    startDate: event?.start,
                    startTime: event.start,
                    endDate: event?.end,
                    endTime: event.end,
                  },
                }
          }
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={loading}
          innerRef={formikRef}
          // validateOnChange={false}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form
                noValidate
                onChange={() => {
                  if (!dirtyForm && event?.id! > 0) {
                    setDirtyForm(true);
                  }
                }}
              >
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems={"center"}>
                  <Grid item md={5} xs={12}>
                    <Field name="name" label="Name" required component={Inputs.Text} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      name="eventType"
                      label="Event Type"
                      required
                      component={Inputs.Dropdown}
                      options={eventTypes}
                      renderOption={renderOption}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field name="featured" label="Feature Event" required component={Inputs.Switch} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field name="dateTime.startDate" label="Start Date" required component={Inputs.Datepicker} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field name="dateTime.startTime" label="Start Time" required component={Inputs.Timepicker} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field name="dateTime.endDate" label="End Date" required component={Inputs.Datepicker} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field name="dateTime.endTime" label="End Time" required component={Inputs.Timepicker} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="details" label="Event Details" required component={Inputs.TextArea} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Field name="address.line1" label="Address Line 1" required component={Inputs.Text} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field name="address.line2" label="Address Line 2" component={Inputs.Text} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field name="address.city" label="City" required component={Inputs.Text} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      name="address.state"
                      label="State"
                      required
                      component={Inputs.OptionDropdown}
                      searchFunction={getStatesSearch}
                      filterForm={{ pageSize: 50 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field name="address.postalCode" label="Zip Code" required component={Inputs.Text} />
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 2,
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: theme.palette.primary.contrastText,
                    paddingY: 2,
                    zIndex: 1000,
                  }}
                >
                  <ActionButton type="button" text="Cancel" onClick={handleCancelBack} color="secondary" />
                  <Inputs.Submit isSubmitting={status === "loading"} text="Save" color="primary" />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default EditCreateEventTab;
