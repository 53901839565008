import { Button, Typography } from "@mui/material";
import React, { ReactElement } from "react";

export interface StepProps {
  label: string;
  children: ReactElement | Array<ReactElement>;
}

const Step: React.FC<StepProps> = (props) => {
  return <>{props.label}</>;
};

export default Step;
