import React from "react";
import TitleBar from "../base/TitleBar";
import AdminApplicationsTable from "../views/AdminApplicationsTable";

interface Props {}

const ApplicationsPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Applications" />
      <AdminApplicationsTable />
    </>
  );
};

export default ApplicationsPage;
