import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import SearchParams from "../types/searchParams";
import SearchResults from "../types/searchResults";
import getSearchQueryString from "../helpers/getSearchQueryString";

interface OptionState {
  breeds?: SearchResults;
  ages?: SearchResults;
  genders?: SearchResults;
  fees?: SearchResults;
  statuses?: SearchResults;
  shelters?: SearchResults;
  sourceTypes?: SearchResults;
  states?: SearchResults;
  userRoles?: SearchResults;
  microchipCompanies?: SearchResults;
  applicationStatuses?: SearchResults;
  documentTypes?: SearchResults;
  eventStatuses?: SearchResults;
  paymentMethods?: SearchResults;
  behaviorSolutions?: SearchResults;
  timelines?: SearchResults;
  alterStatus?: SearchResults;
  dogColors?: SearchResults;
  subStatus?: SearchResults;
  dogWeights?: SearchResults;
  status: "idle" | "loading" | "failed";
}

const initialState: OptionState = {
  status: "idle",
};

export const getBreedOptions = createAsyncThunk<SearchResults, SearchParams>(
  "options/breeds",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`options/breeds?${qs}`);
    return response.data;
  }
);

export const getAgeOptions = createAsyncThunk<SearchResults, SearchParams>(
  "options/ages",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`options/ages?${qs}`);
    return response.data;
  }
);

export const getGenderOptions = createAsyncThunk<SearchResults, SearchParams>(
  "options/genders",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`options/genders?${qs}`);
    return response.data;
  }
);

export const getFees = createAsyncThunk<SearchResults, SearchParams>("options/fees", async (search: SearchParams) => {
  let qs = getSearchQueryString(search);
  const response = await http.get<SearchResults>(`options/fees?${qs}`);
  return response.data;
});

export const getStatesSearch = createAsyncThunk<SearchResults, SearchParams>(
  "options/states",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/states?${qs}`);
    return response.data;
  }
);

export const getStatusOptions = createAsyncThunk<SearchResults, SearchParams>(
  "options/statuses",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/statuses?${qs}`);
    return response.data;
  }
);

export const getShelterSearch = createAsyncThunk<SearchResults, SearchParams>(
  "options/shelters",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/shelters?${qs}`);
    return response.data;
  }
);

export const getSourceTypes = createAsyncThunk<SearchResults, SearchParams>(
  "options/shelter-types",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/shelter-types?${qs}`);
    return response.data;
  }
);

export const getMicrochipSearch = createAsyncThunk<SearchResults, SearchParams>(
  "options/microchips",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/microchips?${qs}`);
    return response.data;
  }
);

export const getRoleOptions = createAsyncThunk<SearchResults, SearchParams>(
  "options/roles",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/user-roles?${qs}`);
    return response.data;
  }
);

export const getApplicationStatuses = createAsyncThunk<SearchResults, SearchParams>(
  "options/application-statuses",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/application-statuses?${qs}`);
    return response.data;
  }
);

export const getWorkSchedule = createAsyncThunk<SearchResults, SearchParams>(
  "options/work-schedules",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/work-schedule?${qs}`);
    return response.data;
  }
);

export const getLivingSituation = createAsyncThunk<SearchResults, SearchParams>(
  "options/living-situations",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/living-situations?${qs}`);
    return response.data;
  }
);

export const getResidenceTypes = createAsyncThunk<SearchResults, SearchParams>(
  "options/residence-types",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/residence-types?${qs}`);
    return response.data;
  }
);

export const getDogExperiences = createAsyncThunk<SearchResults, SearchParams>(
  "options/dog-experience",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/dog-experience?${qs}`);
    return response.data;
  }
);

export const getHouseholdTypes = createAsyncThunk<SearchResults, SearchParams>(
  "options/household-types",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/household-types?${qs}`);
    return response.data;
  }
);

export const getGiveUpReasons = createAsyncThunk<SearchResults, SearchParams>(
  "options/give-up-reasons",
  async (search: SearchParams) => {
    let qs = getSearchQueryString({ ...search, pageSize: 50 });
    const response = await http.get<SearchResults>(`/options/give-up-reasons?${qs}`);
    return response.data;
  }
);

export const getDogSizes = createAsyncThunk<SearchResults, SearchParams>(
  "options/dog-sizes",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/dog-sizes?${qs}`);
    return response.data;
  }
);

export const getDocumentTypes = createAsyncThunk<SearchResults, SearchParams>(
  "options/document-types",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/document-types?${qs}`);
    return response.data;
  }
);

export const getReferralSources = createAsyncThunk<SearchResults, SearchParams>(
  "options/referral-sources",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/referral-sources?${qs}`);
    return response.data;
  }
);

export const getEventStatus = createAsyncThunk<SearchResults, SearchParams>(
  "options/event-status",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/event-status?${qs}`);
    return response.data;
  }
);

export const getFenceTypes = createAsyncThunk<SearchResults, SearchParams>(
  "options/fence-type",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/fence-type?${qs}`);
    return response.data;
  }
);

export const getFenceHeights = createAsyncThunk<SearchResults, SearchParams>(
  "options/fence-heights",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/fence-heights?${qs}`);
    return response.data;
  }
);

export const getFenceSizes = createAsyncThunk<SearchResults, SearchParams>(
  "options/fence-sizes",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/fence-sizes?${qs}`);
    return response.data;
  }
);

export const getPaymentMethods = createAsyncThunk<SearchResults, SearchParams>(
  "options/payment-methods",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/payment-methods?${qs}`);
    return response.data;
  }
);

export const getTemperaments = createAsyncThunk<SearchResults, SearchParams>(
  "options/temperaments",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/temperaments?${qs}`);
    return response.data;
  }
);

export const getSleepLocations = createAsyncThunk<SearchResults, SearchParams>(
  "options/sleep-locations",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/sleep-locations?${qs}`);
    return response.data;
  }
);

export const getAdoptionOrigins = createAsyncThunk<SearchResults, SearchParams>(
  "options/adoption-origin",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/adoption-origin?${qs}`);
    return response.data;
  }
);

export const getCatAges = createAsyncThunk<SearchResults, SearchParams>(
  "options/cat-ages",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/cat-ages?${qs}`);
    return response.data;
  }
);

export const getAnimalTypes = createAsyncThunk<SearchResults, SearchParams>(
  "options/animal-types",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/animal-types?${qs}`);
    return response.data;
  }
);

export const getPetTypes = createAsyncThunk<SearchResults, SearchParams>(
  "options/pet-types",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/pet-types?${qs}`);
    return response.data;
  }
);

export const getBehaviorSolutions = createAsyncThunk<SearchResults, SearchParams>(
  "options/behavior",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/behavior-solutions?${qs}`);
    return response.data;
  }
);

export const getTimelines = createAsyncThunk<SearchResults, SearchParams>(
  "options/timelines",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/timelines?${qs}`);
    return response.data;
  }
);

export const getAlterStatus = createAsyncThunk<SearchResults, SearchParams>(
  "options/alter-status",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/alter-status?${qs}`);
    return response.data;
  }
);

export const getDogColors = createAsyncThunk<SearchResults, SearchParams>(
  "options/dog-colors",
  async (search: SearchParams) => {
    let qs = getSearchQueryString({ ...search, pageSize: 30 });

    const response = await http.get<SearchResults>(`/options/dog-colors?${qs}`);
    return response.data;
  }
);

export const getSubStatus = createAsyncThunk<SearchResults, SearchParams>(
  "options/sub-status",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/sub-status?${qs}`);
    return response.data;
  }
);

export const getDogWeights = createAsyncThunk<SearchResults, SearchParams>(
  "options/dog-weights",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/options/dog-weights?${qs}`);
    return response.data;
  }
);

const optionSlice = createSlice({
  name: "option",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBreedOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBreedOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.breeds = action.payload;
      })
      .addCase(getBreedOptions.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getAgeOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAgeOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.ages = action.payload;
      })
      .addCase(getAgeOptions.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getGenderOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGenderOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.genders = action.payload;
      })
      .addCase(getGenderOptions.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getFees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFees.fulfilled, (state, action) => {
        state.status = "idle";
        state.fees = action.payload;
      })
      .addCase(getFees.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getStatesSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatesSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.states = action.payload;
      })
      .addCase(getStatesSearch.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getStatusOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatusOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.statuses = action.payload;

        if (window.location.href.includes("dog/")) {
          if (action.payload.results.length) {
            let filteredStatus = action.payload.results.filter((id) => id.id !== 10);
            state.statuses.results = filteredStatus;
            state.statuses.totalResults = state.statuses.totalResults - 1;
          }
        }
      })
      .addCase(getStatusOptions.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getShelterSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getShelterSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.shelters = action.payload;
      })
      .addCase(getShelterSearch.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getSourceTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSourceTypes.fulfilled, (state, action) => {
        state.status = "idle";
        state.sourceTypes = action.payload;
      })
      .addCase(getSourceTypes.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getRoleOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRoleOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.userRoles = action.payload;
      })
      .addCase(getRoleOptions.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getMicrochipSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMicrochipSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.microchipCompanies = action.payload;
      })
      .addCase(getMicrochipSearch.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getApplicationStatuses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getApplicationStatuses.fulfilled, (state, action) => {
        state.status = "idle";
        state.applicationStatuses = action.payload;
        if (action.payload.results.length) {
          let filteredStatus = action.payload.results.filter((id) => id.id !== 10 && id.id !== 6);
          state.applicationStatuses.results = filteredStatus;
          state.applicationStatuses.totalResults = state.applicationStatuses.totalResults - 1;
        }
      })
      .addCase(getApplicationStatuses.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getDocumentTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDocumentTypes.fulfilled, (state, action) => {
        state.status = "idle";
        state.documentTypes = action.payload;
      })
      .addCase(getDocumentTypes.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getEventStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEventStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.eventStatuses = action.payload;
      })
      .addCase(getEventStatus.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getPaymentMethods.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.status = "idle";
        state.paymentMethods = action.payload;
      })
      .addCase(getPaymentMethods.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getBehaviorSolutions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBehaviorSolutions.fulfilled, (state, action) => {
        state.status = "idle";
        state.behaviorSolutions = action.payload;
      })
      .addCase(getBehaviorSolutions.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getTimelines.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTimelines.fulfilled, (state, action) => {
        state.status = "idle";
        state.timelines = action.payload;
      })
      .addCase(getTimelines.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAlterStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAlterStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.alterStatus = action.payload;
      })
      .addCase(getAlterStatus.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getDogColors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDogColors.fulfilled, (state, action) => {
        state.status = "idle";
        state.dogColors = action.payload;
      })
      .addCase(getDogColors.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getSubStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.subStatus = action.payload;
      })
      .addCase(getSubStatus.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getDogWeights.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDogWeights.fulfilled, (state, action) => {
        state.status = "idle";
        state.dogWeights = action.payload;
      })
      .addCase(getDogWeights.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default optionSlice.reducer;
