import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ActionButton from "../base/ActionButton";
import theme from "../../theme";
import { Field, FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "../../store";
import { editAdoption, getAdoption, saveAdoption } from "../../slices/adoptions";
import { Inputs } from "../forms";
import { getUserSearch } from "../../slices/users";
import SearchParams from "../../types/searchParams";
import * as Yup from "yup";
import { getDogsSearch } from "../../slices/dogs";
import { getPaymentMethods } from "../../slices/options";
import KeyValue from "../../types/keyValue";
import { selectApplication } from "../../slices/applications";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import DogSimple from "../../types/dogSimple";
import Adoption from "../../types/adoption";

interface Props {
  id?: number;
  onCancelClose: () => void;
  onSuccessClose: () => void;
  pending?: boolean;
  user?: boolean;
}

const AddAdoptionRecord: React.FC<Props> = (props) => {
  const { id, onCancelClose, onSuccessClose, pending, user } = props;
  const { dog, dogSimple, dogSearch } = useSelector((store) => store.dogs);
  const { adminApplication, status } = useSelector((store) => store.applications);
  const dispatch = useDispatch();
  const { status: adoptionStatus, adoption } = useSelector((store) => store.adoptions);
  const [filterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
    orderDirection: "Ascending",
    query: "",
    filters: ["admin:2", "active:1"],
  });
  const [dogFilterForm, setDogFilterForm] = useState<SearchParams>({
    query: "",
    pageNumber: 1,
    pageSize: 10,
    filters: ["status:1", "status:2", "status:4", "status:5", "status:6", "status:8", "status:9", "status:10"],
  });

  useEffect(() => {
    if (!user) {
      dispatch(getUserSearch(filterForm));
    }
  }, [dispatch, user, filterForm, pending]);

  useEffect(() => {
    if (!!id) {
      dispatch(getAdoption(id));
    }
  }, [dispatch, id]);

  const handleSaveAdoptionRecord = (values: any) => {
    if (pending) {
      dispatch(selectApplication({ id: adminApplication?.id!, adoption: values })).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          onSuccessClose();
        }
      });
    } else {
      if (adoption?.id! > 0) {
        dispatch(
          editAdoption({
            ...values,
            applicationId: adoption.applicationId,
            paymentDetails: values.feeReceived
              ? {
                  ...values.paymentDetails,
                  id: 0,
                  checkNumber:
                    values.paymentDetails.paymentMethod && values.paymentDetails.paymentMethod.id === 2
                      ? values.paymentDetails.checkNumber
                      : undefined,
                }
              : undefined,
            status: adoption?.status?.id === 4 ? adoption.status : { id: 3, value: "Completed" },
          })
        ).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            onSuccessClose();
          }
        });
      } else {
        dispatch(
          saveAdoption({
            ...values,
            paymentDetails: !values.feeReceived ? undefined : values.paymentDetails,
            status: { id: 3, value: "Completed" },
          })
        ).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            dispatch(handleSuccessToastState(true));
            dispatch(setSuccessMessage("Adoption record added!"));
            onSuccessClose();
          }
        });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    adopter: Yup.object().required("Required").typeError("Required"),
    adoptionDate: Yup.date().nullable().typeError("required"),
    feeReceived: Yup.boolean().nullable(),
    paymentDetails: Yup.object().when("feeReceived", {
      is: (feeReceived: boolean) => !!feeReceived,
      then: (schema) =>
        Yup.object().shape({
          amount: Yup.number().required("Required").typeError("Required").nullable(),
          donation: Yup.number().nullable(),
          paymentMethod: Yup.object()
            .nullable()
            .when("amount", {
              is: (val: number) => val > 0,
              then: (schema) => schema.typeError("Required").required("Required"),
            }),
          checkNumber: Yup.number()
            .nullable()
            .when("paymentMethod", {
              is: (paymentMethod: KeyValue) => paymentMethod?.id === 2,
              then: (schema) => schema.typeError("Required").required("Required"),
            }),
        }),
    }),
  });

  const pendingValidationSchema = Yup.object().shape({
    dog: Yup.object().required("You must select a dog").typeError("Required"),
  });

  const yesNo = [
    { id: 1, label: "Yes", value: true },
    { id: 2, label: "No", value: false },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: 400,
        p: 2,
        borderRadius: 10,
        textAlign: "center",
      }}
    >
      {pending ? (
        <>
          <Typography mb={2}>Accept Application for Adoption</Typography>
          <Formik
            enableReinitialize
            initialValues={{
              dog: dogSimple?.id > 0 ? dogSimple : undefined,
              adoptionDate: adoption?.adoptionDate || new Date(),
            }}
            validationSchema={pendingValidationSchema}
            onSubmit={handleSaveAdoptionRecord}
          >
            {({ values }) => {
              return (
                <Form noValidate>
                  <Grid container direction={"row"}>
                    <Grid item xs={12}>
                      <Field
                        name="dog"
                        label={`Dog to be Adopted`}
                        options={
                          dogSearch?.results && dogFilterForm.query
                            ? dogSearch.results.map((dog: DogSimple) => {
                                return {
                                  id: dog.id,
                                  value: `${dog.id} - ${dog.name} (${dog.primaryBreed.value})`,
                                };
                              })
                            : []
                        }
                        component={Inputs.Dropdown}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setDogFilterForm({ ...dogFilterForm, query: e.currentTarget.value });
                          dispatch(getDogsSearch({ search: { ...dogFilterForm, query: e.currentTarget.value } }));
                        }}
                        noOptionsText={
                          values.dog
                            ? "To change the dog being adopted, search for a different one and select it"
                            : !values.dog && dogFilterForm.query === ""
                            ? "Start typing to search"
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="adoptionDate" label="Adoption Date" required component={Inputs.Datepicker} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction={"row"} justifyContent={"space-around"}>
                        <Grid item>
                          <ActionButton type="button" color="secondary" onClick={onCancelClose} text="Cancel" />
                        </Grid>
                        <Grid item>
                          <Inputs.Submit
                            isSubmitting={adoptionStatus === "loading" || status === "loading"}
                            color="primary"
                            text="Save"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </>
      ) : (
        <>
          <Typography mb={2}>{"Add Adoption Record"}</Typography>
          <Formik
            enableReinitialize={adoption?.id! > 0}
            initialValues={{
              ...adoption,
              dog: adoption?.dog || dogSimple,
              application: adoption?.application || undefined,
              adoptionDate: adoption?.adoptionDate || new Date(),
              adopter: adoption?.adopter || undefined,
              feeReceived: adoption.feeReceived || false,
              contractSigned: adoption.contractSigned || false,
              paymentDetails: {
                ...adoption.paymentDetails,
                amount: adoption?.paymentDetails?.amount || dog.adoptionFee,
                donation: adoption?.paymentDetails?.donation || 0,
              },
              status: adoption.status || { id: 3, value: "Completed" },
            }}
            validationSchema={validationSchema}
            onSubmit={handleSaveAdoptionRecord}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form noValidate>
                  <Grid container direction={"row"}>
                    <Grid item xs={12}>
                      {user ? (
                        <Field
                          name="dog"
                          label={`Dog to be Adopted`}
                          options={
                            dogSearch?.results && dogFilterForm.query
                              ? dogSearch.results.map((dog: DogSimple) => {
                                  return {
                                    id: dog.id,
                                    value: `${dog.id} - ${dog.name} (${dog.primaryBreed.value})`,
                                  };
                                })
                              : []
                          }
                          component={Inputs.Dropdown}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDogFilterForm({ ...dogFilterForm, query: e.currentTarget.value });
                            dispatch(getDogsSearch({ search: { ...dogFilterForm, query: e.currentTarget.value } }));
                          }}
                          noOptionsText={
                            values.dog
                              ? "To change the dog being adopted, search for a different one and select it"
                              : !values.dog && dogFilterForm.query === ""
                              ? "Start typing to search"
                              : undefined
                          }
                        />
                      ) : (
                        <Field
                          name="adopter"
                          label="Adopter"
                          component={Inputs.OptionDropdown}
                          searchFunction={getUserSearch}
                          required
                          showId
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="adoptionDate" label="Adoption Date" required component={Inputs.Datepicker} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Contract Signed?</Typography>
                      <FieldArray
                        name="contractSigned"
                        render={() =>
                          yesNo.map((contractSigned) => {
                            return (
                              <FormControlLabel
                                key={contractSigned.id}
                                label={<Typography sx={{ fontSize: "18px" }}>{contractSigned.label}</Typography>}
                                control={
                                  <Field
                                    name="contractSigned"
                                    value={contractSigned.value}
                                    checked={values.contractSigned?.valueOf() === contractSigned.value}
                                    onChange={() => {
                                      setFieldValue("contractSigned", contractSigned.value);
                                    }}
                                    component={Inputs.Radio}
                                  />
                                }
                              />
                            );
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Fee Received?</Typography>
                      <Grid item xs={12}>
                        <FieldArray
                          name="feeReceived"
                          render={() =>
                            yesNo.map((feeReceived) => {
                              return (
                                <FormControlLabel
                                  key={feeReceived.id}
                                  label={<Typography sx={{ fontSize: "18px" }}>{feeReceived.label}</Typography>}
                                  control={
                                    <Field
                                      name="feeReceived"
                                      value={feeReceived.value}
                                      checked={values.feeReceived?.valueOf() === feeReceived.value}
                                      onChange={() => {
                                        setFieldValue("feeReceived", feeReceived.value);
                                      }}
                                      component={Inputs.Radio}
                                    />
                                  }
                                />
                              );
                            })
                          }
                        />
                      </Grid>
                      <Typography variant="caption">Select "Yes" if N/A</Typography>
                    </Grid>
                    <Grid container display={values.feeReceived ? "flex" : "none"}>
                      <Grid item xs={12}>
                        <Typography textAlign={"center"}>
                          Total amount paid: ${values?.paymentDetails?.amount! + values?.paymentDetails?.donation!}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={Inputs.Number}
                          name="paymentDetails.amount"
                          label="Adoption Fee"
                          required={values.feeReceived}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field component={Inputs.Number} name="paymentDetails.donation" label="Donation?" />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} display={values.feeReceived ? "inline" : "none"}>
                      <Field
                        component={Inputs.OptionDropdown}
                        name="paymentDetails.paymentMethod"
                        label="Payment Method"
                        searchFunction={getPaymentMethods}
                        required={values.feeReceived && values.paymentDetails?.amount! > 0}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={values.feeReceived && values.paymentDetails?.paymentMethod?.id === 2 ? "inline" : "none"}
                    >
                      <Field
                        component={Inputs.Number}
                        name="paymentDetails.checkNumber"
                        label="Check Number"
                        required={values.paymentDetails?.paymentMethod?.id === 2}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <Grid container direction={"row"} justifyContent={"space-around"}>
                        <Grid item>
                          <ActionButton type="button" color="secondary" onClick={onCancelClose} text="Cancel" />
                        </Grid>
                        <Grid item>
                          <Inputs.Submit
                            isSubmitting={adoptionStatus === "loading" || status === "loading"}
                            color="primary"
                            text="Save"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Box>
  );
};

export default AddAdoptionRecord;
