import { Box, CircularProgress, Container, DialogActions, Grid, LinearProgress, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { editDog, getDogById } from "../../../slices/dogs";
import {
  deleteFile,
  getFilesById,
  getOneFileById,
  orderFiles,
  postFile,
  putFile,
  resetFile,
  startVideo,
  stopVideo,
} from "../../../slices/file";
import { useDispatch, useSelector } from "../../../store";
import FileVM from "../../../types/fileVM";
import getCroppedImg from "../../../utils/cropImage";
import ActionButton from "../../base/ActionButton";
import Dialog from "../../base/Dialog";
import Modal from "../../base/Modal";
import { Inputs } from "../../forms";
import ImageCropView from "../ImageCropView";
import theme from "../../../theme";
import ReactPlayer from "react-player";
import DragDropList from "../../base/DragDropList";
import LinkUploadView from "../LinkUploadView";

interface Props {
  viewImage: string;
  setViewImage: React.Dispatch<React.SetStateAction<string>>;
  getMainPhoto: (photos: FileVM[]) => void;
}

const DogPicturesTab: React.FC<Props> = (props) => {
  const { viewImage, setViewImage, getMainPhoto } = props;
  const dispatch = useDispatch();
  const { status: fileStatus, uploadComplete, videoPlaying, fileSearch, newFile } = useSelector((store) => store.file);
  const { dog, status, photo } = useSelector((store) => store.dogs);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(0);
  const [deleteImageUrl, setDeleteImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState<"none" | "crop" | "link">("none");
  const [mainImageId, setMainImageId] = useState(dog.mainPhotoId ? dog.mainPhotoId : -1);
  const [cropImage, setCropImage] = useState("");
  const [croppedArea, setCroppedArea] = useState();
  const [cropImagePreview, setCropImagePreview] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [video, setVideo] = useState<FileVM>();
  const [deleteVideo, setDeleteVideo] = useState<FileVM>();

  useEffect(() => {
    getMainPhoto(fileSearch);
  }, [fileSearch]);

  useEffect(() => {
    dispatch(getFilesById({ entityId: dog.id, typeId: 1 }));
  }, [dispatch, dog]);

  const updatePhotos = (photoId: number) => {
    let newFile: FileVM = { ...photo, id: photoId, entityId: dog.id };
    dispatch(putFile(newFile)).then(() => {
      dispatch(getFilesById({ entityId: dog.id, typeId: 1 })).then((result) => {
        if (result && result.payload) {
          let photos = result.payload as FileVM[];
          let selectedPhoto = photos.find((p: FileVM) => p.id === photoId);
          setViewImage(selectedPhoto!.url);
          if (selectedPhoto?.fileType?.id === 5) {
            setVideo(selectedPhoto);
            dispatch(startVideo(selectedPhoto?.id));
          }
        }
      });
      dispatch(getDogById(dog.id));
    });
  };

  const postLink = async (url: string) => {
    await dispatch(postFile({ ...photo, url: url })).then((result: any) => {
      let photoId = result.payload as number;
      updatePhotos(photoId);
    });
  };

  const post = async (file: any) => {
    await dispatch(postFile({ ...photo, upload: file })).then((result: any) => {
      let photoId = result.payload as number;
      if ((mainImageId === 0 || dog.mainPhotoId === null) && file.type.split("/")[0] !== "video") {
        setMainImageId(photoId);
        dispatch(editDog({ dog: { ...dog, mainPhotoId: photoId } })).then(() => {
          dispatch(getDogById(dog.id));
        });
      }
      updatePhotos(photoId);
    });
  };

  const uploadFiles = async () => {
    setModalOpen("none");
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    const { file, url } = await getCroppedImg(cropImage, croppedArea);
    await post(file);
  };

  const handleDeleteImage = async () => {
    await dispatch(deleteFile(deleteImageId)).then(() => {
      dispatch(getFilesById({ entityId: dog.id, typeId: 1 })).then((result: any) => {
        if (result.payload.length) {
          const mainPhoto = result.payload.find((photo: FileVM) => photo.id === mainImageId);
          if (mainPhoto) {
            if (deleteImageId === video?.id) {
              setVideo(undefined);
              setViewImage(mainPhoto.url);
            }
            return;
          } else {
            for (let i = 0; i < result.payload.length; i++) {
              if (result.payload[i].fileType.id === 1) {
                setMainImageId(result.payload[i].id!);
                dispatch(editDog({ dog: { ...dog, mainPhotoId: result.payload[i].id } })).then(() => {
                  dispatch(getDogById(dog.id));
                  setViewImage(result.payload[i].url);
                });
                break;
              } else {
                dispatch(editDog({ dog: { ...dog, mainPhotoId: undefined } })).then(() => {
                  dispatch(getDogById(dog.id));
                });
                setMainImageId(0);
                setViewImage("");
              }
            }
          }
        } else {
          dispatch(editDog({ dog: { ...dog, mainPhotoId: undefined } })).then(() => {
            dispatch(getDogById(dog.id));
          });
          setMainImageId(0);
          setViewImage("");
          setVideo(undefined);
        }
      });
    });
    setDeleteModal(false);
    setDeleteImageId(0);
    dispatch(resetFile());
    setDeleteVideo(undefined);
    dispatch(getDogById(dog.id));
  };

  const onSelectFile = (files: File[]) => {
    let avatar = URL.createObjectURL(files[0]);
    setCropImage(avatar);
  };

  const onCropComplete = async (croppedAreaPercentage: any, croppedAreaPixels: any) => {
    await setCroppedArea(croppedAreaPixels);
    const { file, url } = await getCroppedImg(cropImage, croppedAreaPixels);
    await setCropImagePreview(url);
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const closeModal = () => {
    setModalOpen("none");
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  };

  const handleMainImageChange = (id: number, url: string) => {
    dispatch(editDog({ dog: { ...dog, mainPhotoId: id } })).then(() => {
      dispatch(getDogById(dog.id));
    });
    setMainImageId(id);
    setViewImage(url);
  };

  const handleVideoClick = (video: FileVM) => {
    setVideo(video);
    setViewImage(video.url);
    dispatch(startVideo(video.id));
  };

  const handleImageClick = (id: number, url: string) => {
    setVideo(undefined);
    setViewImage(url);
    dispatch(stopVideo(0));
  };

  const handleFileOrdering = async (files: FileVM[]) => {
    await dispatch(orderFiles(files));
    await dispatch(getFilesById({ entityId: dog.id, typeId: 1 }));
    await dispatch(getDogById(dog.id));
  };

  const handleAddLink = (values: { url: string }) => {
    console.log(values);
    closeModal();
    postLink(values.url);
  };

  return (
    <Box>
      <Container>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            {fileStatus === "loading" && !!uploadComplete && (
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                <Box sx={{ width: "100%", marginRight: 2 }}>
                  <LinearProgress
                    value={uploadComplete || 0}
                    color="primary"
                    variant="determinate"
                    style={{
                      height: 25,
                      backgroundColor: theme.palette.info.main,
                      borderRadius: 8,
                    }}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.primary">{`${uploadComplete || 0}%`}</Typography>
                </Box>
              </Box>
            )}
            <Box id="dropzone">
              <Field
                component={Inputs.Dropzone}
                label="Pictures"
                name="upload"
                onDrop={(files: File[]) => {
                  if (files[0].type.split("/")[0] === "video") {
                    post(files[0]);
                  } else {
                    setModalOpen("crop");
                    onSelectFile(files);
                  }
                }}
              />
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <ActionButton text={"Add Link"} type="button" onClick={() => setModalOpen("link")} />
            </Box>
            <Grid container direction={"column"}>
              <Grid item xs={12}>
                {fileStatus === "loading" && !uploadComplete && !fileSearch.length ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress color="primary" size="60px" />
                  </Box>
                ) : fileSearch.length > 0 ? (
                  <DragDropList
                    files={dog.id > 0 && fileSearch.length ? fileSearch : []}
                    onUpdateFileOrder={handleFileOrdering}
                    onVideoClick={handleVideoClick}
                    onPictureClick={handleImageClick}
                    onDeleteClick={(id: number, url: string) => {
                      dispatch(getOneFileById(id)).then((response: any) => {
                        if (
                          response &&
                          response.payload &&
                          response.payload.fileType &&
                          response.payload.fileType.id === 5
                        ) {
                          setDeleteModal(true);
                          setDeleteImageId(response.payload.id);
                          setDeleteVideo(response.payload);
                          dispatch(startVideo(response.payload.id));
                        } else {
                          setDeleteModal(true);
                          setDeleteImageId(id);
                          setDeleteImageUrl(url);
                        }
                      });
                    }}
                    onHeartClick={(id: number, url: string) => {
                      setMainImageId(id);
                      setViewImage(url);
                      handleMainImageChange(id, url);
                    }}
                    mainPhotoId={mainImageId > 0 ? mainImageId : dog.mainPhotoId}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item alignSelf={"center"}>
                {video && videoPlaying?.id === video.id ? (
                  <ReactPlayer
                    controls={true}
                    url={video.url}
                    pip={false}
                    onEnded={() => dispatch(stopVideo(video.id))}
                  />
                ) : viewImage ? (
                  <Box
                    component="img"
                    alt={`${dog.name} picture`}
                    src={dog.photos ? viewImage : ""}
                    sx={{
                      objectFit: "cover",
                      height: { lg: "400px", md: "350px", xs: "258px" },
                      width: { lg: "400px", md: "350px", xs: "258px" },
                      borderRadius: "25px",
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Container>
      <Modal open={modalOpen === "crop"} onClose={closeModal}>
        <ImageCropView
          cropImage={cropImage}
          crop={crop}
          zoom={zoom}
          setCrop={setCrop}
          setZoom={setZoom}
          onCropComplete={onCropComplete}
          closeCropModal={closeModal}
          uploadFiles={uploadFiles}
          cropImagePreview={cropImagePreview}
        />
      </Modal>
      <Modal open={modalOpen === "link"} onClose={closeModal}>
        <LinkUploadView handleSave={handleAddLink} handleClose={closeModal} />
      </Modal>
      <Dialog
        open={deleteModal}
        title={`Are you sure you would like to delete this ${
          newFile?.fileType?.id === 5 ? "video" : newFile?.fileType?.id === 13 ? "link" : "image"
        }? This cannot be undone.`}
        warning
      >
        <Grid container direction={"column"}>
          <Grid item alignSelf={"center"}>
            {deleteVideo && videoPlaying?.id === deleteVideo.id ? (
              <ReactPlayer
                width={"100%"}
                controls={true}
                url={deleteVideo.url}
                onEnded={() => dispatch(stopVideo(deleteVideo.id))}
              />
            ) : newFile?.fileType?.id === 13 ? (
              <Typography>{newFile.url}</Typography>
            ) : (
              <img
                alt="upload"
                width={"150px"}
                height={"150px"}
                src={deleteImageUrl}
                style={{
                  borderRadius: "25px",
                  objectFit: "cover",
                  alignSelf: "center",
                }}
              />
            )}
          </Grid>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={handleDeleteImage}
            />
          </DialogActions>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default DogPicturesTab;
