import React, { useEffect } from "react";
import { useDispatch } from "../../store";
import { fetchSample } from "../../slices/sample";
import { Box, Container, Grid, Typography } from "@mui/material";
import theme from "../../theme";
import { Form, Field, Formik } from "formik";
import { Inputs } from "../forms";
import ActionButton from "../base/ActionButton";
import * as Yup from "yup";

interface Props {
  handleSave: (values: any) => void;
  handleClose: () => void;
}

const LinkUploadView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSample());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .nullable()
      .required("Required")
      .typeError("Required")
      .matches(
        new RegExp(
          /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
        ),
        "Must be a valid YouTube link"
      ),
  });

  return (
    <Box
      sx={{ backgroundColor: theme.palette.background.paper, borderRadius: "15px", padding: "15px", width: "400px" }}
    >
      <Typography textAlign={"center"} variant="h4">
        Add New Video Link
      </Typography>
      <Container sx={{ marginTop: "15px" }}>
        <Formik initialValues={{ url: "" }} validationSchema={validationSchema} onSubmit={props.handleSave}>
          {() => (
            <Form noValidate>
              <Grid container justifyContent={"space-around"}>
                <Grid item xs={12}>
                  <Field component={Inputs.Text} name="url" required label="YouTube URL" />
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                ></Box>
                <Grid container justifyContent={"space-around"} sx={{ my: 2 }}>
                  <Grid item>
                    <ActionButton type="button" text="Cancel" color="success" onClick={props.handleClose} />
                  </Grid>
                  <Grid item>
                    <Inputs.Submit text="Save" isSubmitting={false} />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default LinkUploadView;
