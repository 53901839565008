import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import theme from "../../theme";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "../../store";
import { handleErrorToastState, setErrorMessage } from "../../slices/toast";

export interface Props {
  onDrop: (acceptedFiles: File[]) => void;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 3,
  borderRadius: "15px",
  borderColor: theme.palette.info.main,
  borderStyle: "dashed",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: theme.palette.primary.main,
};

const acceptStyle = {
  borderColor: theme.palette.success.main,
};

const rejectStyle = {
  borderColor: theme.palette.error.main,
};

const Dropzone: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".xlsx", ".pdf", ".docx", ".doc"],
      "video/*": [".mov", ".mpv", ".mp4", ".wmv", ".wav"],
    },
    maxSize: 524288000,
    multiple: false,
    maxFiles: 1,
    onDrop: async (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          dispatch(handleErrorToastState(true));

          if (err.code === "file-too-large") {
            dispatch(setErrorMessage("Error: File is too large! Please resize and try again."));
          }
          if (err.code === "file-invalid-type") {
            dispatch(
              setErrorMessage("Error: File type not accepted! Please convert to another file type and try again.")
            );
          }
        });
      });
      await props.onDrop(acceptedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      <Box className="container" display="flex" flexDirection="row" sx={{ marginBottom: 2 }}>
        <Box {...getRootProps({ style })} display="flex" flexDirection="column">
          <input {...getInputProps()} />
          <Box display="flex" flexDirection="row" sx={{ marginBottom: 1 }}>
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCloudArrowUp} size="xl" />
          </Box>
          <Typography variant="subtitle1">Drag and drop to upload a file here, or click to select</Typography>
        </Box>
      </Box>
    </>
  );
};

export default Dropzone;
