import React from "react";
import Application from "../../../../types/application";
import ReactPDF, { Text, View } from "@react-pdf/renderer";

interface Props {
  application?: Application;
  styles: ReactPDF.Styles;
}

const HomePage: React.FC<Props> = (props) => {
  const { application, styles } = props;
  return (
    <View style={styles.page}>
      <View>
        <Text style={styles.header}>Home</Text>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Which BEST Describes Your Household?</Text>
          <Text style={styles.answerText}>{application?.householdType?.value}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Living Situation:</Text>
          <Text style={styles.answerText}>{application?.livingSituation?.value}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Is Yard Fenced?</Text>
          <Text style={styles.answerText}>{application?.yardFenced ? "Yes" : "No"}</Text>
        </View>
        {!application?.yardFenced && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>How & Where Will Dog Exercise & Eliminate?:</Text>
            <Text style={styles.answerText}>{application?.exerciseLocation}</Text>
          </View>
        )}
        {application?.yardFenced && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Fence Type:</Text>
            <Text style={styles.answerText}>{application?.fenceType?.value}</Text>
          </View>
        )}
        {application?.yardFenced && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Fence Height at Lowest Point:</Text>
            <Text style={styles.answerText}>{application?.fenceHeight?.value}</Text>
          </View>
        )}
        {application?.yardFenced && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Fenced-In Yard Size:</Text>
            <Text style={styles.answerText}>{application?.fenceSize?.value}</Text>
          </View>
        )}
        {application?.livingSituation?.id === 2 && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Does Landlord Allow Dogs?</Text>
            <Text style={styles.answerText}>{application?.landlordAllowDogs ? "Yes" : "No"}</Text>
          </View>
        )}
        {application?.livingSituation?.id === 2 && application?.landlordAllowDogs && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Landlord Contact:</Text>
            <Text style={styles.answerText}>
              {application?.landlordName}: {application.landlordPhone}
            </Text>
          </View>
        )}
        {application?.livingSituation?.id === 2 && application?.landlordAllowDogs && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Dog Size Restrictions?</Text>
            <Text style={styles.answerText}>{application?.dogSizeRestrictions ? "Yes" : "No"}</Text>
          </View>
        )}
        {application?.livingSituation?.id === 2 && application?.landlordAllowDogs && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Breed Restrictions?</Text>
            <Text style={styles.answerText}>{application?.dogBreedRestrictions ? "Yes" : "No"}</Text>
          </View>
        )}
      </View>
      {application?.householdResidents && application?.householdResidents?.length > 0 && (
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View>
            <Text style={styles.mapQuestionText}>Additional Household Residents:</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {application.householdResidents.map((res, index) => {
              return (
                <View style={{ marginBottom: "25px", borderBottom: "1px solid black" }} key={res.id}>
                  <View style={{ marginBottom: "10px" }}>
                    <Text style={styles.answerText}>{`Resident ${index + 1}: ${res.firstName} ${res.lastName}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Age: ${res.age}`}</Text>
                    <Text style={styles.answerText}>{`Dog Allergies: ${res.allergies ? "Yes" : "No"}`}</Text>
                    <Text style={styles.answerText}>{`Dog Experience: ${res.dogExperience?.value}`}</Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={styles.answerText}>{`Relationship to Applicant: ${res.relationship}`}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
};

export default HomePage;
