import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import {
  Box,
  CircularProgress,
  Container,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  emptyUserApps,
  getApplication,
  getArchivedApplications,
  resetApplicationView,
  setActiveApp,
  updateApplicationStatus,
  viewApplication,
} from "../../slices/applications";
import Tabs from "../base/Tabs";
import Tab from "../base/Tab";
import ApplicantView from "./ApplicationView/ApplicantView";
import HomeView from "./ApplicationView/HomeView";
import PetInfoView from "./ApplicationView/PetInfoView";
import CareView from "./ApplicationView/CareView";
import ReferencesView from "./ApplicationView/ReferencesView";
import PreferencesView from "./ApplicationView/PreferencesView";
import OtherView from "./ApplicationView/OtherView";
import ApplicationNotes from "./ApplicationView/ApplicationNotes";
import DocumentView from "./ApplicationView/DocumentView";
import MediaView from "./ApplicationView/MediaView";
import IconButton from "../base/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import theme from "../../theme";
import ActionButton from "../base/ActionButton";
import Dialog from "../base/Dialog";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import { useNavigate } from "react-router-dom";
import { getDogSimpleById, resetSimpleDog } from "../../slices/dogs";
import { Check, Home, ThumbDown, ThumbUp } from "@mui/icons-material";
import Modal from "../base/Modal";
import AddAdoptionRecord from "./AddAdoptionRecord";
import Moment from "react-moment";
import { clearAdoptionSearch, getAdoptionsSearch } from "../../slices/adoptions";
import { getUserId, resetUserValues } from "../../slices/users";

interface Props {
  applicationId: number;
}

const ApplicationView: React.FC<Props> = (props) => {
  const { applicationId } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUser } = useSelector((store) => store.auth);
  const { user } = useSelector((store) => store.users);
  const { adminApplication, status, userApplications, activeAppId } = useSelector((store) => store.applications);

  const [activeTab, setActiveTab] = useState(1);
  const [rejectModal, setRejectModal] = useState(false);
  const [pendingModal, setPendingModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [menu, setMenu] = useState<boolean>(false);
  const [pendingAdopter, setPendingAdopter] = useState<boolean>(false);
  const [primaryDogFoster, setPrimaryDogFoster] = useState<boolean>(false);

  const md = useMediaQuery(theme.breakpoints.up("md"));
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  // applicationId is url id
  // activeAppId is currently viewing id

  const applicationTabs = [
    {
      id: 1,
      value: "Applicant",
      element: <ApplicantView />,
    },
    {
      id: 2,
      value: "Home",
      element: <HomeView />,
    },
    {
      id: 3,
      value: "Pets",
      element: <PetInfoView />,
    },
    {
      id: 4,
      value: "Care",
      element: <CareView />,
    },
    {
      id: 5,
      value: "References",
      element: <ReferencesView />,
    },
    {
      id: 6,
      value: "Preferences",
      element: <PreferencesView />,
    },
    {
      id: 7,
      value: "Other",
      element: <OtherView />,
    },
    {
      id: 8,
      value: "Notes",
      element: <ApplicationNotes />,
    },
    {
      id: 9,
      value: "Media",
      element: <MediaView applicantId={adminApplication?.applicant?.id!} />,
    },
    {
      id: 10,
      value: "Documents",
      element: <DocumentView applicantId={adminApplication?.applicant?.id!} />,
    },
  ];

  // Load application
  useEffect(() => {
    if (!!activeAppId) {
      if (activeAppId === applicationId) {
        dispatch(getApplication({ id: applicationId, history: true }));
      } else {
        dispatch(getApplication({ id: activeAppId! }));
      }
    } else {
      dispatch(setActiveApp(applicationId));
    }

    return () => {
      dispatch(resetApplicationView());
    };
  }, [dispatch, applicationId, activeAppId]);

  // Load archived apps
  useEffect(() => {
    if (
      adminApplication &&
      adminApplication?.applicant?.id &&
      (checkUserRole(loggedInUser!, UserRoles.Admin) ||
        checkUserRole(loggedInUser!, UserRoles.Foster) ||
        checkUserRole(loggedInUser!, UserRoles.Volunteer))
    ) {
      dispatch(getArchivedApplications(adminApplication.applicant.id));
    }

    return () => {
      dispatch(emptyUserApps());
    };
  }, [dispatch, loggedInUser, adminApplication]);

  // Mark application as viewed
  useEffect(() => {
    if (adminApplication?.selectedDog) {
      dispatch(getDogSimpleById(adminApplication?.selectedDog?.id!)).then((response: any) => {
        if (response.payload && response.payload.foster.id === loggedInUser?.id) {
          setPrimaryDogFoster(true);

          if (adminApplication?.status?.id === 2 || adminApplication?.status?.id === 8) {
            dispatch(viewApplication(applicationId));
          }
        }
      });
    }

    return () => {
      dispatch(resetSimpleDog());
    };
  }, [dispatch, adminApplication?.selectedDog, adminApplication?.status?.id, applicationId, loggedInUser?.id]);

  // Get the applicant details to make sure user is active
  useEffect(() => {
    if (adminApplication?.applicant) {
      dispatch(getUserId(adminApplication.applicant.id!));
    }

    return () => {
      dispatch(resetUserValues());
    };
  }, [dispatch, adminApplication]);

  // Determine if dog is pending adoption to hide/show options
  useEffect(() => {
    if (!!adminApplication?.selectedDog) {
      dispatch(
        getAdoptionsSearch({
          search: {
            query: "",
            filters: [`dog:${adminApplication?.selectedDog?.id}`],
            pageNumber: 1,
            pageSize: 5,
            orderBy: "id",
            orderDirection: "Descending",
          },
        })
      ).then((result: any) => {
        if (result && result.payload && result.payload.results && result.payload.results.length) {
          let adoption = result.payload.results[0];
          if (adoption.adopter.id === adminApplication?.applicant?.id) {
            setPendingAdopter(true);
          } else {
            setPendingAdopter(false);
          }
        }
      });
    }

    return () => {
      dispatch(clearAdoptionSearch());
    };
  }, [dispatch, adminApplication?.applicant?.id, adminApplication?.selectedDog]);

  const handleChange = (event: SelectChangeEvent) => {
    setActiveTab(+event.target.value);
  };

  const handleAppChange = (event: SelectChangeEvent) => {
    dispatch(setActiveApp(+event.target.value));
  };

  const stepContainerRef = createRef<HTMLDivElement>();

  const handleScroll = () => {
    stepContainerRef.current?.scrollTo({ behavior: "smooth", top: 0 });
  };

  const updateStatus = async (id: number) => {
    await dispatch(updateApplicationStatus({ id: applicationId, statusId: id })).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(handleSuccessToastState(true));
        dispatch(setSuccessMessage("Application status updated"));
      }
    });
  };

  return (
    <Container
      sx={{
        position: "absolute",
        top: { md: "58px", xs: adminApplication?.status?.id === 10 ? "121px" : "90px" },
        right: 0,
        bottom: 0,
        width: "100%",
        overflow: "auto",
        padding: 2,
      }}
      ref={stepContainerRef}
      maxWidth={false}
    >
      {status !== "loading" &&
        !!adminApplication &&
        (checkUserRole(loggedInUser!, UserRoles.Admin) ||
          checkUserRole(loggedInUser!, UserRoles.Foster) ||
          checkUserRole(loggedInUser!, UserRoles.Volunteer)) && (
          <Grid
            container
            spacing={3}
            direction={"row"}
            justifyContent={{ md: "space-between", xs: "center" }}
            alignContent={{ md: "flex-end", xs: "center" }}
            alignItems={"center"}
            mt={{ md: -3, xs: -5 }}
            mb={2}
          >
            {(checkUserRole(loggedInUser!, UserRoles.Admin) ||
              checkUserRole(loggedInUser!, UserRoles.Foster) ||
              checkUserRole(loggedInUser!, UserRoles.Volunteer)) &&
              userApplications.length > 1 && (
                <Grid item md={4} xs={11} sx={{ mt: { md: 0, xs: 2 } }}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: theme.palette.primary.main }}>Previous Application Versions</InputLabel>
                    <Select
                      value={activeAppId?.toString()}
                      label="Previous Application versions"
                      onChange={handleAppChange}
                      sx={{
                        color: theme.palette.primary.main,
                        background: theme.palette.background.paper,
                        borderColor: theme.palette.primary.main,
                        borderWidth: "2px",
                        borderRadius: "10px",
                      }}
                    >
                      {userApplications.map((app, index) => (
                        <MenuItem value={app.id} key={app.id}>
                          <Moment format="MMMM DD, YYYY">{app.startedDate}</Moment>
                          {app?.id === applicationId && app.status?.id !== 10
                            ? " - Current Version"
                            : `- ${userApplications.length - index}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            <Grid item md={3} xs={12}>
              <Typography textAlign={"center"}>
                {!user.active
                  ? "This user has been deactivated. The status of the application cannot be changed"
                  : pendingAdopter
                  ? "This application has been selected"
                  : `App Status: ${adminApplication?.status?.value}`}
              </Typography>
            </Grid>
            {(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Foster)) &&
              user.active &&
              adminApplication?.status?.id !== 7 && (
                <Grid item sx={{ my: { sm: 0, xs: 2 } }}>
                  <Box ref={anchorRef}>
                    <ActionButton
                      text={
                        adminApplication?.status?.id === 10
                          ? !!userApplications.some((f) => f.id > adminApplication?.id!)
                            ? `Return to current version`
                            : `No Current Version Available`
                          : "Change App Status"
                      }
                      type="button"
                      color="primary"
                      disabled={
                        adminApplication?.status?.id === 10 &&
                        !userApplications.some((f) => f.id > adminApplication?.id!)
                      }
                      onClick={() => {
                        if (adminApplication?.status?.id === 10) {
                          let currentAppId = userApplications.find(
                            (f) => f.status?.id !== 10 && f.status?.id !== 1
                          )?.id;
                          if (currentAppId !== activeAppId) {
                            dispatch(setActiveApp(currentAppId));
                            navigate(`/admin/applications/${currentAppId}`);
                          }
                        } else {
                          setMenu(true);
                        }
                      }}
                    />
                  </Box>
                </Grid>
              )}
            <Menu
              anchorEl={anchorRef.current}
              open={menu}
              onClose={() => setMenu(false)}
              anchorOrigin={{
                horizontal: "center",
                vertical: "bottom",
              }}
            >
              {adminApplication?.status?.id !== 6 && !!primaryDogFoster && (
                <MenuItem
                  onClick={() => {
                    setMenu(false);
                    setRejectModal(true);
                  }}
                  sx={{ display: "flex", gap: "10px" }}
                >
                  <ThumbDown />
                  <Typography>Defer Application</Typography>
                </MenuItem>
              )}
              {adminApplication?.status?.id !== 4 && (
                <MenuItem
                  onClick={() => {
                    setMenu(false);
                    setPendingModal(true);
                  }}
                  sx={{ display: "flex", gap: "10px" }}
                >
                  <Home />
                  <Typography>Needs Home Visit</Typography>
                </MenuItem>
              )}

              {adminApplication?.status?.id !== 5 && (
                <MenuItem
                  onClick={() => {
                    setMenu(false);
                    setCompleteModal(true);
                  }}
                  sx={{ display: "flex", gap: "10px" }}
                >
                  <Check />
                  <Typography>Complete Review</Typography>
                </MenuItem>
              )}
              {!pendingAdopter && (
                <MenuItem
                  onClick={() => {
                    setMenu(false);
                    setAcceptModal(true);
                  }}
                  sx={{ display: "flex", gap: "10px" }}
                >
                  <ThumbUp />
                  <Typography>Accept Application</Typography>
                </MenuItem>
              )}
            </Menu>
          </Grid>
        )}
      {status === "loading" ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" size="60px" />
        </Box>
      ) : md && status === "idle" ? (
        <Box>
          {!adminApplication?.selectedDog && adminApplication?.status?.id !== 7 && (
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                gap={2}
                sx={{ mt: "10px", py: 1, marginBottom: 3 }}
              >
                <FontAwesomeIcon style={{ color: theme.palette.error.main }} icon={faTriangleExclamation} />
                <Typography variant="h3" color="error">
                  {!adminApplication
                    ? `The application could not be loaded. Please try again or contact your administrator.`
                    : `There are no dogs selected for this application. The applicant has been notified that an update is
                  needed.`}
                </Typography>
              </Box>
            </Box>
          )}
          {adminApplication && (
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
              {applicationTabs.map((tab) => (
                <Tab label={tab.value} key={tab.id}>
                  {tab.element}
                </Tab>
              ))}
            </Tabs>
          )}
          <Grid item xs={12} display="flex" justifyContent="flex-end" marginRight="20px">
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                hideTooltip
                icon={<FontAwesomeIcon icon={faAnglesUp} />}
                label="Back to Top"
                onClick={handleScroll}
              />
              <Typography variant="overline">Back to Top</Typography>
            </Box>
          </Grid>
        </Box>
      ) : (
        <Container>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel sx={{ color: theme.palette.primary.main }}>Application Sections</InputLabel>
            <Select
              value={activeTab.toString()}
              label="Application Sections"
              onChange={handleChange}
              sx={{
                color: theme.palette.primary.main,
                background: theme.palette.background.paper,
                borderColor: theme.palette.primary.main,
                borderWidth: "2px",
                borderRadius: "10px",
              }}
            >
              {applicationTabs.map((tab) => (
                <MenuItem value={tab.id} key={tab.id}>
                  {tab.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container direction="row" justifyContent="space-between" pb={3}>
            <Grid item>
              <ActionButton
                text="Previous"
                onClick={() => {
                  if (activeTab === 1) {
                    setActiveTab(10);
                  } else {
                    setActiveTab(activeTab - 1);
                  }
                }}
                type="button"
              />
              <Typography textAlign={"center"} variant="h5">
                {activeTab === 1 ? applicationTabs[9].value : applicationTabs[activeTab - 2].value}
              </Typography>
            </Grid>
            <Grid item>
              <ActionButton
                text="Next"
                onClick={() => {
                  if (activeTab === 10) {
                    setActiveTab(1);
                  } else {
                    setActiveTab(activeTab + 1);
                  }
                }}
                type="button"
              />
              <Typography textAlign={"center"} variant="h5">
                {activeTab === 10 ? applicationTabs[0].value : applicationTabs[activeTab].value}
              </Typography>
            </Grid>
          </Grid>
          {activeTab === 1 && <ApplicantView />}
          {activeTab === 2 && <HomeView />}
          {activeTab === 3 && <PetInfoView />}
          {activeTab === 4 && <CareView />}
          {activeTab === 5 && <ReferencesView />}
          {activeTab === 6 && <PreferencesView />}
          {activeTab === 7 && <OtherView />}
          {activeTab === 8 && <ApplicationNotes />}
          {activeTab === 9 && <MediaView applicantId={adminApplication?.applicant?.id!} />}
          {activeTab === 10 && <DocumentView applicantId={adminApplication?.applicant?.id!} />}
          <Grid item xs={12} display="flex" justifyContent="flex-end" marginRight="20px">
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                hideTooltip
                icon={<FontAwesomeIcon icon={faAnglesUp} />}
                label="Back to Top"
                onClick={handleScroll}
              />
              <Typography variant="overline">Back to Top</Typography>
            </Box>
          </Grid>
        </Container>
      )}
      <Dialog
        open={rejectModal}
        title={`Are you sure you would like to defer ${adminApplication?.applicant?.firstName} ${adminApplication?.applicant?.lastName}'s application for ${adminApplication?.selectedDog?.name}?`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" color="secondary" text="Cancel" onClick={() => setRejectModal(false)} />
            <ActionButton
              type="button"
              text={"Defer"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={async () => {
                await updateStatus(6);
                setRejectModal(false);
                navigate("/admin/applications");
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={pendingModal}
        title={`Are you sure you would like to set ${adminApplication?.applicant?.firstName} ${adminApplication?.applicant?.lastName}'s application for ${adminApplication?.selectedDog?.name} to pending a home visit?`}
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" color="secondary" text="Cancel" onClick={() => setPendingModal(false)} />
            <ActionButton
              type="button"
              text={"Confirm"}
              disabled={status === "loading" ? true : false}
              color="primary"
              onClick={async () => {
                await updateStatus(4);
                setPendingModal(false);
                navigate("/admin/applications");
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={completeModal}
        title={`Are you sure you would like to mark ${adminApplication?.applicant?.firstName} ${adminApplication?.applicant?.lastName}'s application for ${adminApplication?.selectedDog?.name} as complete?`}
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" color="secondary" text="Cancel" onClick={() => setCompleteModal(false)} />
            <ActionButton
              type="button"
              text={"Complete"}
              disabled={status === "loading" ? true : false}
              color="primary"
              onClick={async () => {
                await updateStatus(5);
                setCompleteModal(false);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Modal open={acceptModal} onClose={() => setAcceptModal(false)}>
        <AddAdoptionRecord
          pending
          onCancelClose={() => setAcceptModal(false)}
          onSuccessClose={() => {
            setAcceptModal(false);
            navigate("/admin/applications");
            dispatch(handleSuccessToastState(true));
            dispatch(setSuccessMessage("Application successfully updated!"));
          }}
        />
      </Modal>
    </Container>
  );
};

export default ApplicationView;
