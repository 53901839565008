import { Container } from "@mui/material";
import React from "react";
import DogDocumentTable from "../DogDocumentTable";

interface Props {
  dogId: number;
}

const DogDocumentTab: React.FC<Props> = (props) => {
  return (
    <Container>
      <DogDocumentTable dogId={props.dogId} />
    </Container>
  );
};

export default DogDocumentTab;
