import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getFilesById } from "../../../slices/file";
import FileVM from "../../../types/fileVM";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Tabs from "../../base/Tabs";
import Tab from "../../base/Tab";
import {
  Box,
  CircularProgress,
  Container,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import theme from "../../../theme";
import SearchParams from "../../../types/searchParams";
import { FormikProps } from "formik";
import Dialog from "../../base/Dialog";
import ActionButton from "../../base/ActionButton";
import { getEvent, getEventTypes } from "../../../slices/events";
import ReadOnlyEvent from "./ReadOnlyEvent";
import EditCreateEventTab from "./EditCreateEventTab";
import EventPhotoTab from "./EventPhotoTab";

interface Props {}

const EditCreateDogView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const tab = queryParams.get("tab");
  const { id } = useParams();
  const { event, status } = useSelector((store) => store.events);
  const infoFormRef = useRef<FormikProps<any>>(null);
  const [viewImage, setViewImage] = useState("");
  const [dirtyForm, setDirtyForm] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [nextTab, setNextTab] = useState(0);
  const { state } = useLocation();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [activeTab, setActiveTab] = useState(event.id! > 0 && state?.edit === true ? 2 : !!tab ? +tab : 1);

  const getMainPhoto = (photos: FileVM[]) => {
    let mainPhoto = photos.find((p) => p.id === event.mainPhotoId);
    if (!!mainPhoto) {
      setViewImage(mainPhoto.url);
    }
  };

  const setViewPhoto = (id: number) => {
    let viewPhoto = event.photos.find((p) => p.id === id);
    if (!!viewPhoto) {
      setViewImage(viewPhoto.url);
    }
  };

  useEffect(() => {
    dispatch(getEventTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFilesById({ entityId: +id!, typeId: 2 }));
    if (event.id! !== +id!) {
      dispatch(getEvent(+id!)).then(() => {
        dispatch(getFilesById({ entityId: +id!, typeId: 2 }));
      });
      dispatch(getEvent(+id!));
    }
  }, [dispatch]);

  const handleChange = (event: SelectChangeEvent) => {
    if (dirtyForm) {
      setWarningModal(true);
      setNextTab(parseInt(event.target.value));
    } else {
      setActiveTab(parseInt(event.target.value));
    }
  };

  const eventTabs = [
    {
      id: 1,
      value: "Details",
      element: <ReadOnlyEvent viewImage={viewImage} getMainPhoto={getMainPhoto} setViewPhoto={setViewPhoto} />,
    },
    {
      id: 2,
      value: "Edit Info",
      element: (
        <EditCreateEventTab
          setDirtyForm={setDirtyForm}
          setActiveTab={setActiveTab}
          dirtyForm={dirtyForm}
          formikRef={infoFormRef}
        />
      ),
    },
    {
      id: 3,
      value: "Pictures",
      element: <EventPhotoTab viewImage={viewImage} setViewImage={setViewImage} getMainPhoto={getMainPhoto} />,
    },
  ];

  const continueTabSwitch = () => {
    setActiveTab(nextTab);
  };

  const saveAndContinue = async () => {
    await infoFormRef.current?.submitForm();
    continueTabSwitch();
  };

  return (
    <>
      {status === "loading" ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" size="60px" />
        </Box>
      ) : event.id! > 0 && md ? (
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          dirty={false}
          setWarningModal={() => {}}
          setNextTab={setNextTab}
        >
          {eventTabs.map((tab) => (
            <Tab label={tab.value} key={tab.id}>
              {tab.element}
            </Tab>
          ))}
        </Tabs>
      ) : event.id! > 0 && !md ? (
        <>
          <Container>
            {eventTabs.length > 1 && (
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel sx={{ color: theme.palette.primary.main }}>Options</InputLabel>
                <Select
                  value={activeTab.toString()}
                  label="Options"
                  onChange={handleChange}
                  sx={{
                    color: theme.palette.primary.main,
                    background: theme.palette.background.paper,
                    borderColor: theme.palette.primary.main,
                    borderWidth: "2px",
                    borderRadius: "10px",
                  }}
                >
                  {eventTabs.map((tab) => (
                    <MenuItem value={tab.id} key={tab.id}>
                      {tab.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Container>
          {activeTab === 1 && (
            <ReadOnlyEvent viewImage={viewImage} getMainPhoto={getMainPhoto} setViewPhoto={setViewPhoto} />
          )}
          {activeTab === 2 && (
            <EditCreateEventTab
              setActiveTab={setActiveTab}
              setDirtyForm={setDirtyForm}
              dirtyForm={dirtyForm}
              formikRef={infoFormRef}
            />
          )}
          {activeTab === 3 && (
            <EventPhotoTab viewImage={viewImage} setViewImage={setViewImage} getMainPhoto={getMainPhoto} />
          )}
        </>
      ) : (
        <>
          <EditCreateEventTab
            setActiveTab={setActiveTab}
            setDirtyForm={setDirtyForm}
            dirtyForm={dirtyForm}
            formikRef={infoFormRef}
          />
        </>
      )}
      <Dialog open={warningModal} title="You have unsaved changes. Do you want to save before continuing?" warning>
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" color="error" text="No" onClick={continueTabSwitch} />
            <ActionButton
              type="button"
              text={"Yes"}
              disabled={status === "loading" ? true : false}
              onClick={saveAndContinue}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditCreateDogView;
