import React from "react";
import TitleBar from "../base/TitleBar";
import UserProfileView from "../views/UserProfileView";
import { Box } from "@mui/material";

interface Props {}

const UserProfilePage: React.FC<Props> = (props) => {
  return (
    <Box>
      <UserProfileView />
    </Box>
  );
};

export default UserProfilePage;
