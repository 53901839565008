import React from "react";
import LoginView from "../views/Auth/LoginView";

interface Props {}

const LoginPage: React.FC<Props> = (props) => {
  return (
    <>
      <LoginView />
    </>
  );
};

export default LoginPage;
