import React from "react";
import { useParams } from "react-router-dom";
import DogView from "../views/DogView";
import { Box } from "@mui/material";

interface Props {}

const DogPage: React.FC<Props> = (props) => {
  const { id } = useParams();

  return (
    <Box sx={{ marginTop: "24px" }}>
      <DogView dogId={+id!} />
    </Box>
  );
};

export default DogPage;
