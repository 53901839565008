import { Box, FormControlLabel, Switch as MuiSwitch, Typography } from "@mui/material";
import React, { SyntheticEvent } from "react";
import theme from "../../theme";

export interface StepProps {
  label: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange: (event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  disabled?: boolean;
}

const Switch: React.FC<StepProps> = (props) => {
  return (
    <Box sx={{ px: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
      <FormControlLabel
        sx={{
          "& .MuiTypography-root": {
            color: theme.palette.primary.main,
            fontStyle: "italic",
            fontSize: "20px",
          },
          "& .MuiTypography-root.Mui-disabled": {
            WebkitTextFillColor: theme.palette.primary.main,
          },
        }}
        disabled={props.disabled}
        control={
          <MuiSwitch
            sx={{
              "& .MuiButtonBase-root": {
                color: theme.palette.info.main,
              },
              "& .MuiSwitch-thumb": {
                "&:after, &:before": {
                  color: props.checked ? theme.palette.primary.contrastText : theme.palette.primary.main,
                  fontSize: "15px",
                  fontWeight: "bold",
                  position: "absolute",
                  top: "8px",
                },
                "&:after": {
                  content: props.checked ? "'Y'" : "'N'",
                  left: props.checked ? "14px" : "13px",
                },
              },
            }}
            defaultChecked={props.defaultChecked}
          />
        }
        label={props.label}
        color="primary"
        checked={props.checked}
        onChange={props.onChange}
      />
    </Box>
  );
};

export default Switch;
