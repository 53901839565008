import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "../../../store";
import HistoryAnswerView from "./HistoryAnswerView";

interface Props {}

const PreferencesView: React.FC<Props> = (props) => {
  const { adminApplication } = useSelector((store) => store.applications);

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"baseline"} mb={3}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              Which Of These Behaviors Are You Willing To Tolerate?
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AcceptBarking}
              answerString={adminApplication?.acceptBarking ? "Yes" : "No"}
              questionText="Barking (Excessive)"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AcceptChewing}
              answerString={adminApplication?.acceptChewing ? "Yes" : "No"}
              questionText="Chewing (Inappropriate)"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AcceptDigging}
              answerString={adminApplication?.acceptDigging ? "Yes" : "No"}
              questionText="Digging"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AcceptHouseTraining}
              answerString={adminApplication?.acceptHouseTraining ? "Yes" : "No"}
              questionText="Needs House Training"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AcceptSocial}
              answerString={adminApplication?.acceptSocial ? "Yes" : "No"}
              questionText="Lack of Social Skills"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AcceptSeparation}
              answerString={adminApplication?.acceptSeparation ? "Yes" : "No"}
              questionText="Separation Anxiety"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.AcceptOther}
              answerString={adminApplication?.acceptOther ? "Yes" : "No"}
              questionText="Other"
            />
          </Grid>
          {adminApplication?.acceptOther === true && (
            <Grid item md={4} xs={12}>
              <HistoryAnswerView
                historyArray={adminApplication?.history?.OtherText}
                answerString={adminApplication.otherText}
                questionText="Enter Other"
              />
            </Grid>
          )}
          {!!adminApplication?.addressBehaviors && adminApplication.addressBehaviors.length > 0 && (
            <Grid item md={4} xs={12}>
              <Typography variant="body2" fontStyle="italic">
                How Will You Address These Behaviors?
              </Typography>
              {adminApplication.addressBehaviors.map((behavior, index) => {
                return (
                  <Box key={behavior.id}>
                    <HistoryAnswerView
                      historyArray={adminApplication.history?.AddressBehaviors}
                      answerString={behavior.value}
                      questionText=""
                      modalQuestionText="How Will You Address These Behaviors?"
                    />
                  </Box>
                );
              })}
            </Grid>
          )}
          {!adminApplication?.acceptBarking &&
            !adminApplication?.acceptChewing &&
            !adminApplication?.acceptDigging &&
            !adminApplication?.acceptHouseTraining &&
            !adminApplication?.acceptSocial &&
            !adminApplication?.acceptSeparation &&
            !adminApplication?.acceptOther && (
              <Grid item md={8} xs={12}>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.WhyNoBehaviors}
                  answerString={adminApplication?.whyNoBehaviors}
                  questionText="If None, Why Not?"
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              Would You Consider Adopting a Dog That:
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.ConsiderMixedBreed}
              answerString={adminApplication?.considerMixedBreed ? "Yes" : "No"}
              questionText="Is Mixed Breed"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.ConsiderOnlyPet}
              answerString={adminApplication?.considerOnlyPet ? "Yes" : "No"}
              questionText="Needs to Be the Only Pet"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.ConsiderAfraid}
              answerString={adminApplication?.considerAfraid ? "Yes" : "No"}
              questionText="Is Afraid of People"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.ConsiderOnMeds}
              answerString={adminApplication?.considerOnMeds ? "Yes" : "No"}
              questionText="Is On Meds"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.ConsiderHealthCondition}
              answerString={adminApplication?.considerHealthCondition ? "Yes" : "No"}
              questionText="Has a Health Condition"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.ConsiderUntrained}
              answerString={adminApplication?.considerUntrained ? "Yes" : "No"}
              questionText="Needs Training"
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.ConsiderSpecialNeeds}
              answerString={adminApplication?.considerSpecialNeeds ? "Yes" : "No"}
              questionText=" Is Special Needs"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              New Dog Preferences
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["Gender.Value"]}
              answerString={adminApplication?.genderPreference?.value || "No Preference"}
              questionText="Gender"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["AgeGroup.Value"]}
              answerString={adminApplication?.agePreference?.value || "No Preference"}
              questionText="Age"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["DogSize.Value"]}
              answerString={adminApplication?.sizePreference?.value || "No Preference"}
              questionText="Size"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PreferencesView;
