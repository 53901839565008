import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import Note from "../types/note";

interface NoteState {
  notes: Note[];
  note: Note;
  status: "idle" | "loading" | "failed";
}

const initialState: NoteState = {
  notes: [],
  note: {
    id: 0,
    value:undefined,
    addedDate: new Date(),
  },
  status: "idle",
};

export const getNotes = createAsyncThunk<Note[], {entityId: number, typeId: number}>("notes/get", async ({entityId, typeId}) => {
  const response = await http.get<Note[]>(`/notes?entityId=${entityId}&typeId=${typeId}`);
  return response.data;
});

export const postNotes = createAsyncThunk<Note, Note>("note/post", async (note: Note) => {
    const response = await http.post<Note>(`/notes`, note);
    return response.data;
});

export const deleteNote = createAsyncThunk<Note, number>("note/delete", async (id: number) => {
  const response = await http.delete<Note>(`/notes/${id}`);
  return response.data;
});

export const editNote = createAsyncThunk<Note, Note>("note/edit", async (note: Note) => {
  const response = await http.put<Note>(`/notes/${note.id}`, note);
  return response.data;
});

const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        state.status = "idle";
        state.notes = action.payload;
      })
      .addCase(getNotes.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(postNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postNotes.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(postNotes.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteNote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteNote.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(deleteNote.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editNote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editNote.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(editNote.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default noteSlice.reducer;
