import React from "react";
import DogApplicationView from "../views/DogApplicationView";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

interface Props {}

const DogApplicationPage: React.FC<Props> = (props) => {
  const { id } = useParams();

  return (
    <Box sx={{ height: "100%" }}>
      <DogApplicationView dogId={+id!} />
    </Box>
  );
};

export default DogApplicationPage;
