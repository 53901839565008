import React from "react";
import EventView from "../views/EventView";
import { Box } from "@mui/material";

interface Props {}

const EventPage: React.FC<Props> = (props) => {
  return (
    <Box>
      <EventView />
    </Box>
  );
};

export default EventPage;
