import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import theme from "../../theme";
import {
  Edit,
  Visibility,
  Delete,
  Dangerous,
  Check,
  Download,
  ContentCopy,
  RemoveCircle,
  Send,
  Print,
  Assignment,
} from "@mui/icons-material";
import Icon, { IconType } from "./Icon";

interface Props {
  id: number;
  disableView?: boolean;
  handleEdit?: (id: number) => void;
  handleDelete?: (id: number) => void;
  handleView?: (id: number) => void;
  handleDeactivate?: (id: number) => void;
  handleReactivate?: (id: number) => void;
  handleDownload?: (id: number) => void;
  handleCopy?: (id: number) => void;
  handleRemoveDog?: (id: number) => void;
  handleEmailDocument?: (id: number) => void;
  handlePrint?: (id: number) => void;
  handleViewApplication?: (id: number) => void;
  disabled?: boolean;
}

const TableActions: React.FC<Props> = (props) => {
  const {
    id,
    disableView,
    handleEdit,
    handleDelete,
    handleView,
    handleDeactivate,
    handleReactivate,
    handleDownload,
    handleCopy,
    handleRemoveDog,
    handleEmailDocument,
    handlePrint,
    handleViewApplication,
    disabled,
  } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      {handleView ? (
        <Tooltip title={"View (Read Only)"}>
          <IconButton
            disabled={disableView}
            sx={{ color: `${theme.palette.primary.main}` }}
            onClick={() => {
              handleView(id);
            }}
          >
            <Visibility fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleViewApplication ? (
        <Tooltip title={"View Application"}>
          <IconButton
            disabled={disableView}
            sx={{ color: `${theme.palette.primary.main}` }}
            onClick={() => {
              handleViewApplication(id);
            }}
          >
            <Assignment fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleDownload ? (
        <Tooltip title={"Download"}>
          <IconButton
            sx={{ color: `${theme.palette.primary.main}` }}
            onClick={() => {
              handleDownload(id);
            }}
          >
            <Download fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handlePrint ? (
        <Tooltip title={"Print/Download PDF"}>
          <IconButton
            sx={{ color: `${theme.palette.primary.main}` }}
            onClick={() => {
              handlePrint(id);
            }}
          >
            <Print fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleEmailDocument ? (
        <Tooltip title={"Email document"}>
          <IconButton
            sx={{ color: `${theme.palette.primary.main}` }}
            onClick={() => handleEmailDocument(id)}
            disabled={disabled}
          >
            <Send fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleEdit ? (
        <Tooltip title={"Edit"}>
          <IconButton sx={{ color: `${theme.palette.primary.main}` }} onClick={() => handleEdit(id)}>
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleCopy ? (
        <Tooltip title={"Clone"}>
          <IconButton sx={{ color: `${theme.palette.primary.main}` }} onClick={() => handleCopy(id)}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleDeactivate ? (
        <Tooltip title={"Deactivate"}>
          <IconButton sx={{ color: `${theme.palette.primary.main}` }} onClick={() => handleDeactivate(id)}>
            <Dangerous fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleReactivate ? (
        <Tooltip title={"Reactivate"}>
          <IconButton sx={{ color: `${theme.palette.primary.main}` }} onClick={() => handleReactivate(id)}>
            <Check fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleDelete ? (
        <Tooltip title={"Delete"}>
          <IconButton
            sx={{ color: `${theme.palette.error.main}`, marginLeft: 2, marginRight: -2 }}
            onClick={() => handleDelete(id)}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}

      {handleRemoveDog ? (
        <Tooltip title={"Remove dog from application"}>
          <IconButton
            sx={{ color: `${theme.palette.error.main}` }}
            onClick={() => handleRemoveDog(id)}
            disabled={disabled}
          >
            <RemoveCircle fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default TableActions;
