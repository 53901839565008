import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "../../../store";
import HistoryAnswerView from "./HistoryAnswerView";

interface Props {}

const CareView: React.FC<Props> = (props) => {
  const { adminApplication } = useSelector((store) => store.applications);

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"baseline"} mb={3}>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.FamilyWantsDog}
              answerString={adminApplication?.familyWantsDog ? "Yes" : "No"}
              questionText="Do All Family Members Want a Dog?"
            />
          </Grid>
          {adminApplication?.familyWantsDog !== true && (
            <Grid item md={6} xs={12}>
              <HistoryAnswerView
                historyArray={adminApplication?.history?.OppositionExplanation}
                answerString={adminApplication?.oppositionExplanation}
                questionText="Explain Who Does Not Want a Dog and Why"
              />
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.DogLocationDay}
              answerString={adminApplication?.dogLocationDay}
              questionText="Where Will the Dog Be Kept During the Day?"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.DogLocationNight}
              answerString={adminApplication?.dogLocationNight}
              questionText="Where Will the Dog Be Kept At Night?"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.MaxHoursAlone}
              answerString={adminApplication?.maxHoursAlone?.toString()}
              questionText="Max Hours Alone in 24 Hrs"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.VacationHelp}
              answerString={adminApplication?.vacationHelp}
              questionText="Who Will Care for the Dog While on Vacation?"
            />
          </Grid>
          <Grid item xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.DogCarePlan}
              answerString={adminApplication?.dogCarePlan}
              questionText="What Provisions Have You Made If You Are No Longer Able to Care For Dog?"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" fontStyle="italic">
              What Would Make You Give Up a Dog?
            </Typography>
            {adminApplication?.giveUpDogReasons &&
              adminApplication.giveUpDogReasons.length > 0 &&
              adminApplication.giveUpDogReasons.map((giveUp) => {
                return (
                  <HistoryAnswerView
                    historyArray={adminApplication?.history?.GiveUpDogReasons}
                    answerString={giveUp.value}
                    questionText=""
                    modalQuestionText="What Would Make You Give Up a Dog?"
                  />
                );
              })}
          </Grid>
          {adminApplication?.giveUpDogReasons &&
            adminApplication.giveUpDogReasons.length > 0 &&
            !!adminApplication.giveUpDogReasons.find((r) => r.id === 17) && (
              <Grid item xs={6}>
                <HistoryAnswerView
                  historyArray={adminApplication?.history?.GiveUpOther}
                  answerString={adminApplication?.giveUpOther}
                  questionText="Enter Other"
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CareView;
