import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import {
  Box,
  Button,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
  useMediaQuery,
  Fab,
  styled,
  InputBase,
  alpha,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HouseIcon from "@mui/icons-material/House";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarItem from "../../types/navbarItem";
import { logout, setAuthRoute, setAuthModal } from "../../slices/auth";
import theme from "../../theme";
import DonateButton from "../base/DonateButton";
import Icon, { IconType } from "../base/Icon";
import PopoverMenu from "./PopoverMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { setSearchString } from "../../slices/search";
import { resetApplication } from "../../slices/applications";
import { resetUserValues } from "../../slices/users";
import adminReroute from "../../helpers/adminReroute";
import { Close } from "@mui/icons-material";
import ShopButton from "../base/ShopButton";
import SearchIcon from "@mui/icons-material/Search";

interface Props {}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 25,
  backgroundColor: theme.palette.background.default,
  "&:hover": {
    backgroundColor: alpha(theme.palette.background.default, 0.5),
  },
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "primary",
  width: "100%",
  "& .MuiInputBase-input": {
    fontWeight: 600,
    fontSize: "20px",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingTop: "8px",
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
    },
  },
}));

const MainNavbar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const [logoutDisable, setLogoutDisable] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showSearch, setShowSearch] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const { loggedInUser } = useSelector((state) => state.auth);
  const pathname = window.location.pathname;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const navbarItems: NavbarItem[] = [
    { title: "Home", path: "/home", icon: <HouseIcon sx={{ color: "primary.main" }} /> },
    { title: "About Us", path: "/about-us", icon: <InfoOutlinedIcon sx={{ color: "primary.main" }} /> },
    { title: "Events", path: "/events", icon: <Icon type={IconType.Events} color={theme.palette.primary.main} /> },
    {
      title: "Adoptable Dogs",
      path: "/dogs",
      icon: <Icon type={IconType.DogsFilled} color={theme.palette.primary.main} />,
    },
    {
      title: "Help Us",
      path: "/help-us",
      icon: <Icon type={IconType.Volunteer} color={theme.palette.primary.main} />,
    },
  ];

  const isActive = (path: string) => {
    const active = `${path}` === pathname;

    return active;
  };

  const handleLogin = () => {
    dispatch(setAuthRoute("login"));
    dispatch(setAuthModal(true));
  };

  const handleRegister = () => {
    dispatch(setAuthRoute("register"));
    dispatch(setAuthModal(true));
  };

  const handleProfileRoute = () => {
    setMobileMenuOpen(false);
    navigate("/user-profile", { state: { id: loggedInUser?.id } });
  };

  const handleLogout = async () => {
    setLogoutDisable(true);
    if (!!loggedInUser) {
      dispatch(logout());
      dispatch(resetApplication());
      dispatch(resetUserValues());
      navigate("/home");
      dispatch(setAuthModal(true));
      dispatch(setAuthRoute("login"));
      setMobileMenuOpen(false);
      setLogoutDisable(false);
    }
  };

  const handleMenuClose = () => {
    setUserMenuOpen(false);
  };

  return (
    <AppBar>
      <Toolbar sx={{ minHeight: sm ? "90px" : "70px", backgroundColor: theme.palette.secondary.main }}>
        <Box sx={{ py: "3px", cursor: "pointer" }} onClick={() => navigate("/")}>
          <img height={sm ? 80 : 70} src="/static/images/hallogo.png" alt="logo" />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ py: "4px", display: { xs: "none", lg: "flex" }, flexDirection: "row", alignItems: "center" }}>
          {navbarItems.map((navbarItem, index) => (
            <React.Fragment key={index}>
              <Button
                disableRipple
                onClick={() => {
                  navigate(navbarItem.path);
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: isActive(navbarItem.path) ? "700" : "400",
                    fontSize: "18px",
                    fontStyle: isActive(navbarItem.path) ? "bold" : "normal",
                    "&:hover": { fontWeight: "700", fontStyle: "bold" },
                  }}
                >
                  {navbarItem.title}
                </Typography>
              </Button>
              {index + 1 < navbarItems.length && (
                <Typography variant="h4" sx={{ color: theme.palette.primary.main, marginX: { md: 1, lg: 2 } }}>
                  |
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />

        <Box sx={{ marginRight: 2, display: "flex" }}>
          <ShopButton />
        </Box>
        <Box sx={{ display: { xs: "none", lg: "flex" } }}>
          <DonateButton size="large" />
        </Box>
        {location.pathname === "/home" && (
          <>
            {showSearch && (
              <Box sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", marginLeft: 2 }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon color="primary" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={async (e) => {
                      if (e.key === "Enter") {
                        await dispatch(setSearchString(searchTerm));
                        await setSearchTerm("");
                        await navigate("/search");
                      }
                    }}
                  />
                </Search>
              </Box>
            )}

            <Box sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center" }}>
              <Tooltip title="Search for dogs by their names and headline descriptions, and events by their names and descriptions.">
                <Fab
                  onClick={() => setShowSearch(!showSearch)}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    height: "60px",
                    width: "60px",
                    fontSize: "30px",
                    marginLeft: 2,
                  }}
                >
                  {showSearch ? (
                    <Close fontSize="inherit" color="success" />
                  ) : (
                    <Icon type={IconType.Magnify} color="#fff" size={"30px"} />
                  )}
                </Fab>
              </Tooltip>
            </Box>
          </>
        )}
        <Box
          onClick={loggedInUser ? () => setUserMenuOpen(!userMenuOpen) : handleLogin}
          ref={anchorRef}
          sx={{
            marginLeft: "12px",
            marginRight: -2,
            zIndex: 5000,
            display: { lg: "flex", xs: "none" },
          }}
        >
          <Fab
            sx={{
              backgroundColor: "#fff",
              height: "60px",
              width: "60px",
              paddingRight: loggedInUser ? "" : "5px",
            }}
          >
            {loggedInUser ? <Icon type={IconType.DogsFilled} size="52px" /> : <Icon type={IconType.Key} size="52px" />}
          </Fab>
        </Box>
        {loggedInUser && (
          <Box ref={popoverRef}>
            <Menu
              anchorEl={anchorRef.current}
              open={userMenuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                horizontal: "center",
                vertical: "bottom",
              }}
            >
              <PopoverMenu />
            </Menu>
          </Box>
        )}
        <IconButton
          edge="start"
          color="inherit"
          sx={{ mr: 2, display: { xs: "block", lg: "none" } }}
          onClick={() => {
            setMobileMenuOpen(true);
          }}
        >
          <Icon type={IconType.Menu} color={theme.palette.primary.main} size="30px" />
        </IconButton>

        <Drawer
          anchor="right"
          open={mobileMenuOpen}
          onClose={() => {
            setMobileMenuOpen(false);
          }}
          sx={{
            "& .MuiPaper-root": {
              border: "none",
              backgroundColor: "transparent",
              paddingBottom: "25px",
            },
          }}
        >
          <Box
            sx={{
              p: 2,
              height: 1,
              backgroundColor: theme.palette.background.paper,
              width: "300px",
              paddingBottom: "90px",
            }}
          >
            <IconButton
              sx={{ mb: 2 }}
              onClick={() => {
                setMobileMenuOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>

            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 2, height: "100%", overflow: "scroll", paddingBottom: "25px" }}>
              {navbarItems.map((item, index) => {
                return (
                  <ListItemButton
                    onClick={() => {
                      navigate(item.path);
                      setMobileMenuOpen(false);
                    }}
                    key={index}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                );
              })}
              <ListItemButton
                onClick={() => {
                  navigate("/search");
                  setMobileMenuOpen(false);
                }}
              >
                <ListItemIcon>
                  <Icon type={IconType.Magnify} color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText primary={"Search"} />
              </ListItemButton>
              {loggedInUser?.roles?.length ? (
                <ListItemButton
                  onClick={() => {
                    navigate(adminReroute(loggedInUser!));
                    setMobileMenuOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCog} color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary={"Admin Site"} />
                </ListItemButton>
              ) : null}
              {loggedInUser ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    bottom: "100px",
                    marginTop: "25px",
                  }}
                >
                  <Button variant="contained" size="small" sx={{ m: 1, width: 0.5 }} onClick={handleProfileRoute}>
                    Profile
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ m: 1, width: 0.5 }}
                    disabled={logoutDisable}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    bottom: "100px",
                  }}
                >
                  <Button variant="contained" size="small" sx={{ m: 1, width: 0.5 }} onClick={handleRegister}>
                    Register
                  </Button>
                  <Button variant="outlined" size="small" sx={{ m: 1, width: 0.5 }} onClick={handleLogin}>
                    Login
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavbar;
