import React from "react";
import { useParams } from "react-router-dom";
import ApplicationView from "../views/ApplicationView";
import { useSelector } from "../../store";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";

interface Props {}

const ApplicationViewPage: React.FC<Props> = (props) => {
  const { id } = useParams();
  const { adminApplication, status } = useSelector((store) => store.applications);

  return (
    <>
      <Box sx={{ mt: "10px", py: 1, backgroundColor: theme.palette.primary.main, marginBottom: 3 }}>
        <Typography variant="h1" color="textSecondary" textAlign={"center"}>
          {status === "loading" ? (
            "Loading, please wait..."
          ) : (
            <>
              {adminApplication?.applicant?.firstName} {adminApplication?.applicant?.lastName}{" "}
              {!!adminApplication?.selectedDog ? (
                <>
                  Application for{" "}
                  <Box
                    sx={{ cursor: "pointer", display: "inline", textDecoration: "underline" }}
                    onClick={() => window.open(`/admin/dog/${adminApplication?.selectedDog?.id}`, "_blank")}
                  >
                    {adminApplication?.selectedDog?.name}
                  </Box>
                </>
              ) : (
                ``
              )}
              {adminApplication?.status?.id === 10 ? " - Archived version" : ""}
            </>
          )}
        </Typography>
      </Box>
      <ApplicationView applicationId={+id!} />
    </>
  );
};

export default ApplicationViewPage;
