import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";

interface Props {}

const VolunteerView: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const volunteerCards = [
    {
      id: 1,
      title: "'Holding' a dog at events",
      text: "Our Meet & Greet events are an excellent way for our dogs to get out and in front of people looking for dogs. Potential adopters can interact with & find out more about the history, personality, wants/ needs, and temperament of each dog in attendance. Because many of our families have multiple fosters, we use volunteers to “hold” dogs to provide a one-on-one arrangement - one volunteer responsible for one dog - handling introductions to attendees, sharing info about the dog and helping to find his/her forever home.",
    },
    {
      id: 2,
      title: "Transportation",
      text: "We have foster families who need help getting their foster dogs to/ from vet appointments, to/from meet & greets, to other volunteers or to their forever homes.",
    },
    {
      id: 3,
      title: "Foster Buddy",
      text: "As a buddy you are paired with a dog's foster parent to help with the process of finding the dog's forever home. Usually this includes administrative tasks like contacting applicants & calling references. However, if you want hands-on involvement with the dog, you can help with exercise, socialization, transportation, and meet & greets with potential adopters.",
    },
    {
      id: 4,
      title: "Operation and Administrative Teams",
      text: "Join a team that:",
      subtext: [
        "organizes fundraisers",
        "shares HAL news on social media",
        "manages & distributes food and supplies",
        "takes pictures/ videos which are shared on our website",
        "handles administrative tasks like writing dog bios, uploading documents to our database, and calling references",
      ],
    },
  ];

  return (
    <Box sx={{ paddingBottom: 5 }}>
      <Typography textAlign={"center"} variant="h1" mb={5}>
        Volunteer
      </Typography>
      <Grid container direction={"column"}>
        <Grid item alignSelf={"center"} mb={3}>
          <Box
            component="img"
            alt={`dog laying in the grass`}
            src="/static/images/volunteer2.jpeg"
            sx={{
              objectFit: "cover",
              height: { sm: "300px", xs: "200px" },
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingX: { lg: 10, md: 5, xs: 5 } }} mb={2}>
          <Typography variant="body1" sx={{ mb: 2 }} textAlign={"center"}>
            We have outlined numerous activities below, but if you are ready to become a HAL volunteer and help our dogs
            on a regular basis, get the approval process started by{" "}
            <Box
              component={"span"}
              sx={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
              onClick={() => window.open("https://form.jotform.com/homeatlastdogrescue/volunteerapp")}
            >
              by submitting a volunteer application.
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            {volunteerCards.map((card) => (
              <Grid
                md={8}
                xs={12}
                key={card.id}
                item
                sx={{ px: { md: 10, sm: 5, xs: 3 }, width: "fit-content" }}
                mx={{ md: 3, xs: 0 }}
                mb={5}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      padding: 1,
                      borderRadius: 2,
                      marginBottom: "-25px",
                      zIndex: 100,
                      alignSelf: "center",
                    }}
                  >
                    <Typography color={theme.palette.primary.contrastText} variant="h2" textAlign={"center"}>
                      {card.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingX: { md: 10, xs: 5 },
                      borderRadius: 5,
                      paddingY: 3,
                    }}
                  >
                    <Typography
                      textAlign={"center"}
                      sx={{
                        marginBlockStart: "1em",
                      }}
                    >
                      {card.text}
                    </Typography>
                    {card.subtext && (
                      <ul>
                        {card.subtext.map((li, index) => (
                          <li key={index}>
                            <Grid
                              container
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              my={1}
                            >
                              <Grid item>
                                <Typography>{li}</Typography>
                              </Grid>
                            </Grid>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid item px={{ md: 0, xs: 2 }}>
            <Typography textAlign={"center"} variant="h1">
              For more opportunities, info, & to apply{" "}
              <a
                href="mailto:HomeAtLastVolunteerInfo@gmail.com?subject=Interested in Volunteering"
                style={{ color: theme.palette.primary.main }}
              >
                contact us
              </a>{" "}
              or{" "}
              <Box
                component={"span"}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => window.open("https://form.jotform.com/homeatlastdogrescue/volunteerapp")}
              >
                submit a volunteer application.
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VolunteerView;
