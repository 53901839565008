import React, { useEffect } from "react";
import { useDispatch } from "../../store";
import { Box, Container, Grid, Typography } from "@mui/material";
import theme from "../../theme";
import { Formik, Field, Form } from "formik";
import ActionButton from "../base/ActionButton";
import { Inputs } from "../forms";
import { getDocumentTypes } from "../../slices/options";
import * as Yup from "yup";
import FileVM from "../../types/fileVM";
import { postFile, putFile } from "../../slices/file";

interface Props {
  upload: FileVM;
  onCancel: () => void;
  afterSave: () => void;
  submittingStatus?: boolean;
  adminDocuments?: boolean;
  type: "dog" | "people" | "none";
}

const UploadView: React.FC<Props> = (props) => {
  const { upload, onCancel, afterSave, submittingStatus, adminDocuments } = props;
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  const initialValues = {
    ...upload,
    documentType: upload.documentType,
    fileName: upload.fileName,
  };

  const validationSchema = Yup.object().shape({
    fileName: Yup.string().required("Please include a name for this file").typeError("Required"),
    documentType: Yup.object().required("Please select a document type").typeError("Required"),
  });

  const adminValidation = Yup.object().shape({
    fileName: Yup.string().required("Please include a name for this file").typeError("Required"),
  });

  const handleSave = (file: FileVM) => {
    dispatch(postFile(file)).then(() => {
      afterSave();
      onCancel();
    });
  };

  const handleEdit = (file: FileVM) => {
    dispatch(putFile(file)).then(() => {
      afterSave();
      onCancel();
    });
  };

  return (
    <Box
      sx={{ backgroundColor: theme.palette.background.paper, borderRadius: "15px", padding: "15px", width: "400px" }}
    >
      <Typography textAlign={"center"} variant="h4">
        Add New File
      </Typography>
      <Container sx={{ marginTop: "15px" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={adminDocuments ? adminValidation : validationSchema}
          onSubmit={upload?.id! > 0 ? handleEdit : handleSave}
        >
          <Form noValidate>
            <Grid container justifyContent={"space-around"}>
              {!adminDocuments && (
                <Grid item xs={12}>
                  <Field
                    name="documentType"
                    label="Document Type"
                    required
                    component={Inputs.OptionDropdown}
                    searchFunction={getDocumentTypes}
                    filterForm={{ filters: props.type === "dog" ? ["entity:1"] : ["entity:2"] }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Field name="fileName" label="File Name" component={Inputs.Text} required />
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></Box>
              <Grid container justifyContent={"space-around"} sx={{ my: 2 }}>
                <Grid item>
                  <ActionButton type="button" text="Cancel" color="success" onClick={onCancel} />
                </Grid>
                <Grid item>
                  <Inputs.Submit text="Save" isSubmitting={submittingStatus ? submittingStatus : false} />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Container>
    </Box>
  );
};

export default UploadView;
