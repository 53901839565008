import React from "react";
import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import theme from "../../../theme";
import Application from "../../../types/application";
import ApplicantPage from "./PdfSections/ApplicantPage";
import HomePage from "./PdfSections/HomePage";
import PetsPage from "./PdfSections/PetsPage";
import CarePage from "./PdfSections/CarePage";
import ReferencesPage from "./PdfSections/ReferencesPage";
import PreferencesPage from "./PdfSections/PreferencesPage";

interface Props {
  application?: Application;
}

const PdfAppView: React.FC<Props> = (props) => {
  const { application } = props;

  return (
    <Document>
      <Page size="A4" style={styles.container}>
        {/* Applicant */}
        <ApplicantPage application={application} styles={styles} />
      </Page>
      <Page size="A4" style={styles.container}>
        {/* Home */}
        <HomePage application={application} styles={styles} />
        {/* Pets */}
        <PetsPage application={application} styles={styles} />
        {/* Care */}
        <CarePage application={application} styles={styles} />
        {/* References */}
        <ReferencesPage application={application} styles={styles} />
        {/* Preferences */}
        <PreferencesPage application={application} styles={styles} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: "40px",
  },
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  addressText: {
    fontSize: "12px",
    marginBottom: "5px",
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    marginVertical: "10px",
    textAlign: "center",
    borderBottom: "1px solid black",
  },
  botMargin: {
    marginBottom: "35px",
  },
  questionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "calc(100% - 80px)",
  },
  separator: {
    width: "45%",
    marginBottom: "35px",
  },
  answerText: {
    fontSize: "14px",
  },
  questionText: {
    fontSize: "18px",
    marginBottom: "5px",
  },
  smallerQuestionText: {
    fontSize: "16px",
    marginBottom: "5px",
  },
  mapQuestionText: {
    fontSize: "18px",
    marginBottom: "5px",
    textDecoration: "underline",
  },
  basicMapText: {
    fontSize: "16px",
    marginBottom: "5px",
  },
});

export default PdfAppView;
