import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { deleteNote, getNotes, postNotes } from "../../../slices/notes";
import { useDispatch, useSelector } from "../../../store";
import Note from "../../../types/note";
import NoteUpload from "../../base/NoteUpload";
import NoteDisplay from "../NoteDisplay";
import checkRole, { UserRoles } from "../../../helpers/checkUserRole";

interface Props {}

const DogNoteTab: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { dog } = useSelector((store) => store.dogs);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { notes } = useSelector((store) => store.notes);

  useEffect(() => {
    dispatch(getNotes({ entityId: dog.id, typeId: 1 }));
  }, [dispatch, dog.id]);

  const addNote = async (note: string) => {
    const newNote: Note = {
      id: 0,
      value: note,
      addedByUser: { ...loggedInUser!, address: undefined },
      addedDate: new Date(),
      noteType: { id: 1, value: "" },
      entityId: dog.id,
    };
    await dispatch(postNotes(newNote));
    await dispatch(getNotes({ entityId: dog.id, typeId: 1 }));
  };

  const removeNote = async (value: Note) => {
    await dispatch(deleteNote(value.id));
    await dispatch(getNotes({ entityId: dog.id, typeId: 1 }));
  };
  return (
    <Container sx={{ marginY: 3 }}>
      {(checkRole(loggedInUser!, UserRoles.Admin) ||
        checkRole(loggedInUser!, UserRoles.Foster) ||
        checkRole(loggedInUser!, UserRoles.Inventory)) && <NoteUpload onNoteAdd={(note: string) => addNote(note)} />}
      <Grid container direction={"column"}>
        {notes &&
          notes.length > 0 &&
          notes.map((note, index) => {
            return (
              <React.Fragment key={index}>
                <NoteDisplay
                  note={note}
                  onDelete={() => removeNote(note)}
                  onNoteEdit={() => dispatch(getNotes({ entityId: dog.id, typeId: 1 }))}
                />
              </React.Fragment>
            );
          })}
      </Grid>
    </Container>
  );
};

export default DogNoteTab;
