import React from "react";
import { useDispatch } from "../../../store";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import theme from "../../../theme";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../../../UserPool";
import { setAuthModal, setAuthRoute } from "../../../slices/auth";
import {
  handleErrorToastState,
  handleSuccessToastState,
  setErrorMessage,
  setSuccessMessage,
} from "../../../slices/toast";
import getEmailRegex from "../../../helpers/emailRegex";

interface Props {}

const ForgotPasswordView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("You must include your email to reset your password").typeError("Required"),
  });

  const getUser = (email: string) => {
    return new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
  };

  const sendCode = async (values: any) => {
    let email = values.email.trim();
    getUser(email).forgotPassword({
      onSuccess: (data) => {
        dispatch(handleSuccessToastState(true));
        dispatch(setSuccessMessage("Success"));
      },
      onFailure: (err) => {
        dispatch(handleErrorToastState(true));
        dispatch(setErrorMessage(err.message));
      },
      inputVerificationCode: (data) => {
        dispatch(handleSuccessToastState(true));
        dispatch(setSuccessMessage("Successfully sent code to email"));
        dispatch(setAuthModal(false));
      },
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: { xs: 25, sm: 2, md: 0 },
        marginX: { lg: 35, md: 10, sm: 10 },
      }}
    >
      <Grid
        container
        direction={"row"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ paddingY: { xs: 25, sm: 0 } }}
      >
        <Grid item md={4} sm={12} alignSelf="center">
          <Box
            sx={{
              display: " flex",
              justifyContent: "center",
              paddingX: { md: "35px", sm: "0px" },
            }}
          >
            <Box
              component="img"
              alt="logo"
              src="/static/images/hallogo.png"
              sx={{
                height: 200,
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={8}
          sm={12}
          sx={{
            backgroundColor: { md: theme.palette.background.paper, sm: theme.palette.secondary.main },
            paddingY: { md: "100px", xs: "0px" },
            paddingX: { lg: "70px", md: "35px", xs: "20px" },
            textAlign: "center",
          }}
        >
          <Grid container sx={{ display: "flex", flexDirection: "column", marginTop: 1, textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography variant="h1" sx={{ color: theme.palette.primary.main, marginY: 2 }}>
                Forgot Password?
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12} alignSelf="center">
              <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main, marginY: 2 }}>
                Enter your email below and we'll send you a verification code to reset your password.
              </Typography>
            </Grid>
            <Formik initialValues={{ email: "" }} validationSchema={validationSchema} onSubmit={sendCode}>
              <Form>
                <Grid container justifyContent={"center"}>
                  <Grid item md={10} sm={8} xs={10}>
                    <Field name="email" label="Email Address" component={Inputs.Text} secondary={md ? false : true} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-around", my: 2 }}>
                      <Grid item>
                        <Inputs.Submit text="Send" isSubmitting={false} color="primary" />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, justifyContent: "center" }}>
            <Typography
              variant="h5"
              sx={{
                marginTop: 1,
                textDecoration: "underline",
                cursor: "pointer",
                color: theme.palette.primary.main,
              }}
              onClick={() => dispatch(setAuthRoute("login"))}
            >
              Back to Login
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPasswordView;
