import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import SearchResults from "../types/searchResults";
import getSearchQueryString from "../helpers/getSearchQueryString";
import SearchParams from "../types/searchParams";
import DogSource from "../types/dogSource";

interface SourceState {
  source: DogSource;
  sourceList?: DogSource[];
  searchResults?: SearchResults;
  status: "idle" | "loading" | "failed";
}

const initialState: SourceState = {
  source: {
    id: 0,
    name: "",
    address: {
      line1: "",
      city: "",
      state: undefined,
      postalCode: undefined,
    },
  },
  status: "idle",
};

export const createSource = createAsyncThunk<DogSource, DogSource>("sources/post", async (source: DogSource) => {
  const response = await http.post<DogSource>("/sources", source);
  return response.data;
});

export const getSource = createAsyncThunk<DogSource, number>("sources/get", async (id: number) => {
  const response = await http.get<DogSource>(`/sources/${id}`);
  return response.data;
});

export const editSource = createAsyncThunk<DogSource, DogSource>("sources/put", async (source: DogSource) => {
  const response = await http.put<DogSource>(`/sources/${source.id}`, source);
  return response.data;
});

export const deleteSource = createAsyncThunk<null, number>("sources/delete", async (id: number) => {
  return await http.delete(`/sources/${id}`);
});

export const fetchSources = createAsyncThunk<DogSource[]>("sources/fetch", async () => {
  const response = await http.get<DogSource[]>("/sources");
  return response.data;
});

export const searchSources = createAsyncThunk<SearchResults, SearchParams>(
  "sources/search",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/sources/search?${qs}`);
    return response.data;
  }
);

const sourceSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {
    clearState: (state) => {
      state.sourceList = undefined;
      state.source = initialState.source;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSource.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSource.fulfilled, (state, action) => {
        state.status = "idle";
        state.source = action.payload;
      })
      .addCase(createSource.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getSource.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSource.fulfilled, (state, action) => {
        state.status = "idle";
        state.source = action.payload;
      })
      .addCase(getSource.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(editSource.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editSource.fulfilled, (state, action) => {
        state.status = "idle";
        state.source = action.payload;
      })
      .addCase(editSource.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(deleteSource.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSource.fulfilled, (state, action) => {
        state.status = "idle";
        state.source = initialState.source;
      })
      .addCase(deleteSource.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchSources.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSources.fulfilled, (state, action) => {
        state.status = "idle";
        state.sourceList = action.payload;
      })
      .addCase(fetchSources.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(searchSources.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchSources.fulfilled, (state, action) => {
        state.status = "idle";
        state.searchResults = action.payload;
      })
      .addCase(searchSources.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { clearState } = sourceSlice.actions;

export default sourceSlice.reducer;
