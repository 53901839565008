import React, { useEffect, useState } from "react";
import { useDispatch } from "../../../store";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../../../UserPool";
import theme from "../../../theme";
import {
  getLoggedInUser,
  setAuthRoute,
  setAuthModal,
  setTemporaryPassword,
  setAwsUser,
  setUserAttributes,
} from "../../../slices/auth";
import { handleErrorToastState, setErrorMessage } from "../../../slices/toast";
import ActionButton from "../../base/ActionButton";
import IconButton from "../../base/IconButton";
import { Close } from "@mui/icons-material";
import Cookies from "universal-cookie";
import Login from "../../../types/login";
import User from "../../../types/user";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import adminReroute from "../../../helpers/adminReroute";

interface Props {
  reroute?: string;
  setReroute?: React.Dispatch<React.SetStateAction<string>>;
}

const LoginView: React.FC<Props> = (props) => {
  const { reroute, setReroute } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userLogin, setUserLogin] = useState<Login>({ email: "", password: "", rememberMe: false });
  const [formReady, setFormReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [queryParams] = useSearchParams();
  const username = queryParams.get("username");
  const password = queryParams.get("password");
  const [showPassword, setShowPassword] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    if (!!username && !!password) {
      handleSubmit({ email: username, password: password });
    }
  }, [username, password]);

  useEffect(() => {
    if (cookies.get("rememberMe")) {
      setUserLogin({
        email: cookies.get("rememberMe"),
        password: "",
        rememberMe: true,
      });
    }
    setFormReady(true);
  }, []);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const user = new CognitoUser({
      Username: values.email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: values.email,
      Password: values.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: () => {
        dispatch(getLoggedInUser()).then(async (result) => {
          if (userLogin.rememberMe) {
            cookies.set("rememberMe", values.email, { path: "/" });
          } else {
            cookies.remove("rememberMe");
          }
          setLoading(false);
          dispatch(setAuthModal(false));
          if (result?.payload) {
            let user = result.payload as User;
            if (reroute) {
              navigate(reroute, { state: { prevPath: undefined, loginForRedirect: false } });
            } else if (user?.roles?.length! > 0) {
              navigate(adminReroute(user!));
            }
            if (setReroute) {
              setReroute("");
            }
          }
        });

        dispatch(setTemporaryPassword(false));
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        dispatch(setUserAttributes(userAttributes));
        dispatch(setTemporaryPassword(true));
        navigate(`/home`);
        dispatch(setAuthRoute("change"));
        dispatch(setAuthModal(true));
        setLoading(false);
      },
      onFailure: (err) => {
        dispatch(setErrorMessage(err.message));
        dispatch(handleErrorToastState(true));
        setLoading(false);
      },
    });

    dispatch(setAwsUser(user));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required("Required").typeError("Required"),
    password: Yup.string().required("Required").typeError("Required"),
  });

  return formReady ? (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: { xs: 25, sm: 0 },
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={() => dispatch(setAuthModal(false))} icon={<Close color="primary" />} label={"Close"} />
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingY: "50px",
            paddingX: { md: "50px", xs: "25px" },
          }}
        >
          <Box>
            <img height={175} src="/static/images/hallogo.png" alt="logo" />
          </Box>

          <Formik
            initialValues={userLogin}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            <Form noValidate>
              <Grid container justifyContent={"center"}>
                <Grid item sm={8} xs={12}>
                  <Field name="email" label="Email" required component={Inputs.Text} secondary />
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Field
                    name="password"
                    label="Password"
                    required
                    component={Inputs.Password}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    secondary
                  />
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={{ xs: "space-between", sm: "space-around" }}
                    alignItems={"center"}
                    sx={{ marginY: 1 }}
                  >
                    <Grid item sx={{ color: theme.palette.primary.main }}>
                      <Field
                        component={Inputs.Checkbox}
                        name="rememberMe"
                        label="Remember Me"
                        colorVariant="blue"
                        fontVariant="h5"
                        checked={userLogin.rememberMe}
                        onChange={(e: boolean) => {
                          setUserLogin({ ...userLogin, rememberMe: e });
                        }}
                        tabIndex={-1}
                      />
                    </Grid>
                    <Grid item sx={{ paddingBottom: "7px" }}>
                      <Typography
                        color={theme.palette.primary.main}
                        variant="h5"
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => dispatch(setAuthRoute("forgot"))}
                      >
                        Forgot Password?
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container justifyContent={"space-around"} sx={{ my: 2 }}>
                  <Grid
                    item
                    sx={{
                      display: { xs: "flex", sm: "none" },
                    }}
                  >
                    <ActionButton
                      type="button"
                      text="Cancel"
                      color="success"
                      onClick={() => dispatch(setAuthModal(false))}
                    />
                  </Grid>
                  <Grid item>
                    <Inputs.Submit text="Login" isSubmitting={loading} color="primary" />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>

          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Typography color={theme.palette.primary.main} variant="h3">
              New here?
            </Typography>
            <Typography
              color={theme.palette.primary.main}
              variant="h3"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => dispatch(setAuthRoute("register"))}
            >
              Create an Account!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default LoginView;
