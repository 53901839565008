import React from "react";
import { FieldProps } from "formik";
import { TextFieldProps } from "@mui/material/TextField";
import Dropzone from "../base/Dropzone";

interface Props {
  onDrop: (acceptedFiles: File[]) => void;
}

const FormikDropzone: React.FC<Props & FieldProps & TextFieldProps> = (props) => {
  const { onDrop } = props;

  return <Dropzone onDrop={onDrop} />;
};

export default FormikDropzone;
