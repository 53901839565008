import React from "react";
import ReactPDF, { Text, View, Image } from "@react-pdf/renderer";
import DogDetail from "../../../types/dogDetail";
import User from "../../../types/user";
import DogSource from "../../../types/dogSource";
import UserSimple from "../../../types/userSimple";

interface Props {
  dog?: DogDetail;
  foster?: User;
  source?: DogSource;
  adopter?: UserSimple;
  styles: ReactPDF.Styles;
}

const DogPdfView: React.FC<Props> = (props) => {
  const { dog, adopter, foster, source, styles } = props;
  return (
    <View style={styles.page}>
      <View>
        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "15px" }}>
          <Image src={"/static/images/hallogo.png"} style={{ height: 50, width: 50, objectFit: "cover" }} />
          <View style={{ marginLeft: "10px" }}>
            <Text style={styles.addressText}>Home at Last Dog Rescue</Text>
            <Text style={styles.addressText}>P.O. Box 1341</Text>
            <Text style={styles.addressText}>North Wales, PA 19454</Text>
            <Text style={styles.addressText}>info@homeatlastdogrescue.com</Text>
          </View>
        </View>
        <View>
          <Text style={styles.header}>{`${dog?.id} - ${dog?.name}`}</Text>
        </View>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Primary Breed:</Text>
          <Text style={styles.answerText}>{dog?.primaryBreed?.value}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Secondary Breed:</Text>
          <Text style={styles.answerText}>{dog?.secondaryBreed?.value}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Also Known As:</Text>
          <Text style={styles.answerText}>{dog?.alsoKnownAs}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Gender:</Text>
          <Text style={styles.answerText}>{dog?.gender?.value}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Adoption Fee:</Text>
          <Text style={styles.answerText}>{`$${dog?.adoptionFee}.00`}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Intake Date:</Text>
          <Text style={styles.answerText}>
            {!!dog?.intakeDate ? new Date(dog?.intakeDate)?.toLocaleDateString() : ""}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Approximate Birth Date:</Text>
          <Text style={styles.answerText}>
            {!!dog?.approximateBirthdate ? new Date(dog?.approximateBirthdate)?.toLocaleDateString() : ""}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Rabies Due:</Text>
          <Text style={styles.answerText}>
            {!!dog?.rabiesDue ? new Date(dog?.rabiesDue)?.toLocaleDateString() : ""}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Microchip Company:</Text>
          <Text style={styles.answerText}>{dog?.microchipCompany?.value ?? "None"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Microchip Number:</Text>
          <Text style={styles.answerText}>{dog?.microchipNumber ?? "None"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Alter Status:</Text>
          <Text style={styles.answerText}>{dog?.alterStatus?.value ?? "None"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Alter Due Date:</Text>
          <Text style={styles.answerText}>{!!dog?.alterDue ? new Date(dog.alterDue)?.toLocaleDateString() : ""}</Text>
        </View>

        <View style={styles.separator}>
          <Text style={styles.questionText}>Weight:</Text>
          <Text style={styles.answerText}>{dog?.weight?.value ?? "None"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Markings:</Text>
          <Text style={styles.answerText}>{dog?.markings ?? "None"}</Text>
        </View>

        <View style={styles.separator}>
          <Text style={styles.questionText}>Coat Colors:</Text>
          <Text style={styles.answerText}>
            {!!dog?.coatColors?.length
              ? dog?.coatColors?.map(
                  (color, index) => `${color.value} ${index + 1 !== dog?.coatColors?.length ? ", " : ""}`
                )
              : "No Colors Chosen"}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Mother:</Text>
          <Text style={styles.answerText}>{dog?.dogMother?.name ?? "None"}</Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Status:</Text>
          <Text style={styles.answerText}>
            {dog?.status?.value + (!!dog?.subStatus ? ` - ${dog.subStatus.value}` : "")}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Status Change Date:</Text>
          <Text style={styles.answerText}>
            {!!dog?.statusUpdated ? new Date(dog.statusUpdated)?.toLocaleDateString() : ""}
          </Text>
        </View>
        <View style={styles.separator}>
          <Text style={styles.questionText}>Disposition Date:</Text>
          <Text style={styles.answerText}>
            {!!dog?.dispositionDate ? new Date(dog.dispositionDate)?.toLocaleDateString() : ""}
          </Text>
        </View>
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.addressSeparator}>
          <Text style={styles.questionText}>Foster:</Text>
          {!!foster ? (
            <>
              <Text style={styles.answerText}>{`${foster.firstName} ${foster.lastName}`}</Text>
              {!!foster?.address && !!foster.address.state ? (
                <>
                  <Text style={styles.answerText}>{foster.address.line1}</Text>
                  <Text style={styles.answerText}>{foster.address.line2}</Text>
                  <Text style={styles.answerText}>{`${foster.address.city}, ${foster.address.state?.value}`}</Text>
                  <Text style={styles.answerText}>{foster.address.postalCode}</Text>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <Text style={styles.answerText}>None</Text>
          )}
        </View>
        {!!foster ? (
          <View style={styles.addressSeparator}>
            <Text style={styles.answerText}>{foster.email}</Text>
            <Text style={styles.answerText}>{foster.phone}</Text>
            <Text style={styles.answerText}>{foster.secondaryEmail}</Text>
            <Text style={styles.answerText}>{foster.secondaryPhone}</Text>
          </View>
        ) : (
          ""
        )}
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.addressSeparator}>
          <Text style={styles.questionText}>Source:</Text>
          {!!source ? (
            <>
              <Text style={styles.answerText}>{source.name}</Text>
              {!!source?.address && !!source.address.state ? (
                <>
                  <Text style={styles.answerText}>{source.address.line1}</Text>
                  <Text style={styles.answerText}>{source.address.line2}</Text>
                  <Text style={styles.answerText}>{`${source.address.city}, ${source.address.state?.value}`}</Text>
                  <Text style={styles.answerText}>{source.address.postalCode}</Text>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <Text style={styles.answerText}>None</Text>
          )}
        </View>
        {!!source ? (
          <View style={styles.addressSeparator}>
            <Text style={styles.answerText}>{source.primaryContactName}</Text>
            <Text style={styles.answerText}>{source.primaryContactEmail}</Text>
            <Text style={styles.answerText}>{source.primaryContactPhoneNumber}</Text>
            <Text style={styles.answerText}>{source.secondaryContactName}</Text>
            <Text style={styles.answerText}>{source.secondaryContactEmail}</Text>
            <Text style={styles.answerText}>{source.secondaryContactPhoneNumber}</Text>
          </View>
        ) : (
          ""
        )}
      </View>
      <View style={styles.questionContainer}>
        <View style={styles.addressSeparator}>
          <Text style={styles.questionText}>Adopter:</Text>
          {!!adopter ? (
            <>
              <Text style={styles.answerText}>{`${adopter.firstName} ${adopter.lastName}`}</Text>
              {!!adopter?.address && !!adopter.address.state ? (
                <>
                  <Text style={styles.answerText}>{adopter.address.line1}</Text>
                  <Text style={styles.answerText}>{adopter.address.line2}</Text>
                  <Text style={styles.answerText}>{`${adopter.address.city}, ${adopter.address.state?.value}`}</Text>
                  <Text style={styles.answerText}>{adopter.address.postalCode}</Text>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <Text style={styles.answerText}>N/A</Text>
          )}
        </View>
        {!!adopter ? (
          <View style={styles.addressSeparator}>
            <Text style={styles.answerText}>{adopter.email}</Text>
            <Text style={styles.answerText}>{adopter.phone}</Text>
          </View>
        ) : (
          ""
        )}
      </View>
    </View>
  );
};

export default DogPdfView;
