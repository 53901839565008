import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "../../../store";
import StarIcon from "@mui/icons-material/Star";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../../helpers/getAddressLink";
import FileVM from "../../../types/fileVM";
import { startVideo, stopVideo } from "../../../slices/file";
import ReactPlayer from "react-player";
import Carousel from "../../base/Carousel";

interface Props {
  viewImage: string;
  getMainPhoto: (photos: FileVM[]) => void;
  setViewPhoto: (id: number) => void;
}

const ReadOnlyEvent: React.FC<Props> = (props) => {
  const { event } = useSelector((store) => store.events);
  const { menuOpen } = useSelector((store) => store.admin);
  const { videoPlaying } = useSelector((store) => store.file);
  const { viewImage, getMainPhoto, setViewPhoto } = props;
  const [video, setVideo] = useState<FileVM>();
  const dispatch = useDispatch();

  useEffect(() => {
    getMainPhoto(event.photos);
  }, [event, dispatch]);

  const handleVideoClick = (video: FileVM) => {
    setVideo(video);
    setViewPhoto(video.id!);
    dispatch(startVideo(video.id));
  };

  const handleImageClick = (id: number) => {
    setVideo(undefined);
    setViewPhoto(id);
    dispatch(stopVideo(0));
  };

  return (
    <>
      <Grid container direction={"row"} columnSpacing={5}>
        <Grid item xl={6} lg={menuOpen ? 7 : 6} md={menuOpen ? 12 : 6} xs={12}>
          <Grid container direction={"column"} px="5px">
            <Grid item alignSelf={"center"}>
              {video && videoPlaying?.id === video.id ? (
                <Box
                  sx={{
                    objectFit: "cover",
                    width: { lg: 700, md: 525, xs: "100%" },
                    borderRadius: 10,
                  }}
                >
                  <ReactPlayer
                    onEnded={() => dispatch(stopVideo(video.id))}
                    height="100%"
                    width="100%"
                    playing
                    controls={true}
                    url={video.url}
                  />
                </Box>
              ) : viewImage ? (
                <Box
                  component="img"
                  alt={`${event.name} picture`}
                  src={event.photos ? viewImage : ""}
                  sx={{
                    objectFit: "cover",
                    width: { lg: 700, md: 525, xs: "100%" },
                    borderRadius: 10,
                  }}
                />
              ) : (
                <Box>
                  <Typography>Select or upload photos in Pictures tab.</Typography>
                </Box>
              )}
            </Grid>
            <Grid item>
              <Carousel
                files={event.photos}
                filesPerView={4}
                onPictureClick={handleImageClick}
                onVideoClick={handleVideoClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={6} lg={menuOpen ? 5 : 6} md={menuOpen ? 12 : 6} xs={12}>
          <Grid container direction={"column"}>
            <Grid item pb={1}>
              <Typography variant="h2">
                From: <Moment format="dddd, MMMM Do">{event.start}</Moment> at{" "}
                <Moment format="h:mmA">{event.start}</Moment>
              </Typography>
            </Grid>
            <Grid item pb={3}>
              <Typography variant="h2">
                To: <Moment format="dddd, MMMM Do">{event.end}</Moment> at <Moment format="h:mmA">{event.end}</Moment>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faLocationDot}
                  size="sm"
                  onClick={() => window.open(getAddressLink(event?.address!), "_blank")}
                />{" "}
                {event.address.line1}
              </Typography>
              {event.address.line2 && <Typography>{event.address.line2}</Typography>}
              <Typography>
                {event.address.city}, {event.address.state?.value} {event.address.postalCode}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography component={"span"}>
              {event.details.split("\n").map((i, key) => {
                return <p key={key}>{i}</p>;
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ReadOnlyEvent;
