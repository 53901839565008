import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Grid, Typography } from "@mui/material";
import { Inputs } from "../forms";
import ActionButton from "./ActionButton";
import { useSelector } from "../../store";
import * as Yup from "yup";
import Note from "../../types/note";

interface Props {
  onNoteAdd: (note: string) => void;
  editNote?: Note;
  onCancelEdit?: () => void;
}

const NoteUpload: React.FC<Props> = (props) => {
  const { onNoteAdd, editNote, onCancelEdit } = props;
  const { note } = useSelector((store) => store.notes);
  const [errorMessage, setErrorMessage] = useState("");

  const noteSchema = Yup.object().shape({
    values: Yup.string().required("Notes cannot be blank").typeError("Required"),
  });

  return (
    <Formik
      initialValues={editNote ? editNote : note}
      validationSchema={noteSchema}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form noValidate>
          <Grid container justifyContent={errorMessage || editNote ? "space-between" : "flex-end"}>
            <Grid item xs={12}>
              <Field name="value" label={editNote ? "Edit note" : "Add a new note"} component={Inputs.TextArea} />
            </Grid>
            <Grid item xs={12} sx={{ marginLeft: "14px", marginTop: "-29px" }}>
              <Typography variant="caption" color="error">
                {errorMessage}
              </Typography>
            </Grid>
            <Grid item xs={12} flex={1}>
              <Grid container direction={"row"} justifyContent="space-between" flex={1}>
                <Grid item>
                  {editNote && (
                    <ActionButton
                      type="button"
                      text={"Cancel"}
                      color="primary"
                      onClick={async () => {
                        if (onCancelEdit) {
                          onCancelEdit();
                        }
                      }}
                    />
                  )}
                </Grid>
                <Grid item>
                  <ActionButton
                    type="button"
                    text={editNote ? "Save Note" : "Post Note"}
                    color="primary"
                    onClick={async () => {
                      if (values.value && values.value.length > 0) {
                        await onNoteAdd(values.value);
                        await setFieldValue("value", "");
                        setErrorMessage("");
                      } else {
                        setErrorMessage("Notes can't be blank");
                        return;
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
export default NoteUpload;
