import React from "react";
import MuiCarousel from "react-material-ui-carousel";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import { CardActionArea, Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../theme";
import FileVM from "../../types/fileVM";
import Icon, { IconType } from "./Icon";
import IconButton from "./IconButton";
import { Delete, Favorite } from "@mui/icons-material";
import VideoPlayer from "./VideoPlayer";

interface Props {
  files: FileVM[];
  filesPerView: 1 | 2 | 3 | 4 | 6 | 12;
  onPictureClick?: (id: number, url: string) => void;
  onHeartClick?: (id: number, url: string) => void;
  onDeleteClick?: (id: number, url: string) => void;
  onVideoClick?: (video: FileVM) => void;
  mainPhotoId?: number;
}

const Carousel: React.FC<Props & CarouselProps> = (props) => {
  const { files, filesPerView, onPictureClick, onVideoClick, ...rest } = props;
  const sliderItems: number = files.length > filesPerView ? filesPerView : files.length;
  const items: Array<any> = [];
  const md = useMediaQuery(theme.breakpoints.up("md"));

  for (let i = 0; i < files.length; i += sliderItems) {
    if (i % sliderItems === 0) {
      items.push(
        <Grid container key={i} justifyContent="center" alignItems={"center"} direction={"row"}>
          {files.slice(i, i + sliderItems).map((file, index) => {
            return (
              <Grid item sx={{ display: "flex", alignItems: "center" }} key={index} padding={1}>
                <CardActionArea
                  disableRipple
                  sx={{
                    width: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                    height: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                  }}
                >
                  {!!file.url &&
                  (file.fileType?.id === 1 ||
                    file.fileType?.id === 2 ||
                    file.fileType?.id === 7 ||
                    file.fileType?.id === 10) ? (
                    <Box
                      onClick={() => {
                        if (onPictureClick) {
                          onPictureClick(file?.id!, file?.url!);
                        }
                      }}
                    >
                      <Box
                        component="img"
                        alt={file.url}
                        src={file.url}
                        sx={{
                          objectFit: "cover",
                          height: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                          width: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                          borderRadius: { xs: "15px" },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        objectFit: "cover",
                        height: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                        width: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                      }}
                    >
                      <VideoPlayer video={file} onVideoClick={onVideoClick} />
                    </Box>
                  )}
                  {props.onHeartClick && (file.fileType?.id === 1 || file.fileType?.id === 2) && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: { sm: 0, xs: -5 },
                        left: { sm: 0, xs: -8 },
                        color:
                          props.mainPhotoId === file.id ? theme.palette.error.main : theme.palette.primary.contrastText,
                        borderRadius: 25,
                      }}
                    >
                      <IconButton
                        icon={<Favorite />}
                        label="Make Main Image"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (props.onHeartClick) {
                            props.onHeartClick(file?.id!, file?.url!);
                          }
                        }}
                        size={"10px"}
                      />
                    </Box>
                  )}
                  {props.onDeleteClick && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: { sm: 0, xs: -5 },
                        right: { sm: 0, xs: -10 },
                        color: theme.palette.primary.contrastText,
                        borderRadius: 25,
                      }}
                    >
                      <IconButton
                        icon={<Delete color={"error"} />}
                        label="Delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (props.onDeleteClick) {
                            props.onDeleteClick(file?.id!, file.url);
                          }
                        }}
                        size={"10px"}
                      />
                    </Box>
                  )}
                </CardActionArea>
              </Grid>
            );
          })}
        </Grid>
      );
    }
  }

  return (
    <Box>
      <MuiCarousel
        {...rest}
        autoPlay={rest.autoPlay || false}
        fullHeightHover={false}
        navButtonsAlwaysVisible={rest.navButtonsAlwaysVisible || true}
        indicators={files.length > filesPerView}
        navButtonsProps={{
          style: {
            color: theme.palette.primary.main,
            background: "#ffffff00",
          },
        }}
        navButtonsWrapperProps={{
          style: {
            top: "0",
            height: md ? "60px" : "30px",
            display: files.length > filesPerView ? "initial" : "none",
          },
        }}
        NextIcon={
          <Box sx={{ marginX: "-40px" }}>
            <Icon type={IconType.Arrow} transform={"rotate(-90deg)"} size={"70px"} />
          </Box>
        }
        PrevIcon={
          <Box sx={{ marginX: "-40px" }}>
            <Icon type={IconType.Arrow} transform={"rotate(90deg)"} size={"70px"} />
          </Box>
        }
      >
        {items}
      </MuiCarousel>
    </Box>
  );
};

export default Carousel;
