import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../store";
import { fetchSample } from "../../slices/sample";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {}

const VolunteerApplicationsView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { sample } = useSelector((store) => store.sample);
  const navigate = useNavigate();

  return (
    <Box>
      <Typography textAlign={"center"} sx={{ fontSize: 30 }}>
        Volunteer Applications Page
      </Typography>
    </Box>
  );
};

export default VolunteerApplicationsView;
