import React, { ChangeEvent } from "react";
import { Grid, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import { Pets } from "@mui/icons-material";
import {
  getAdoptionOrigins,
  getAgeOptions,
  getAnimalTypes,
  getBreedOptions,
  getCatAges,
  getGenderOptions,
  getPetTypes,
  getSleepLocations,
  getTemperaments,
} from "../../../slices/options";
import { useDispatch, useSelector } from "../../../store";
import PetInfo from "../../../types/application/petInfo";
import { savePetInfoStep } from "../../../slices/applications";
import PreviousPet from "../../../types/application/previousPet";
import OtherPet from "../../../types/application/otherPet";
import Cat from "../../../types/application/cat";
import Dog from "../../../types/application/dog";

interface Props {
  formikRef?: React.RefObject<FormikProps<any>>;
}

const PetInfoStep: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const initialState: PetInfo = useSelector((store) => ({
    currentPets: store.applications.myApplication?.currentPets || undefined,
    numberOfPastPets:
      store.applications.myApplication?.numberOfPastPets == null
        ? 0
        : store.applications.myApplication?.numberOfPastPets,
    givenUpPet: store.applications.myApplication?.givenUpPet || undefined,
    reasonForGivingUp: store.applications.myApplication?.reasonForGivingUp || undefined,
    numberOfDogs:
      store.applications.myApplication?.numberOfDogs == null ? 0 : store.applications.myApplication?.numberOfDogs,
    numberOfCats:
      store.applications.myApplication?.numberOfCats == null ? 0 : store.applications.myApplication?.numberOfCats,
    numberOfOtherPets:
      store.applications.myApplication?.numberOfOtherPets == null
        ? 0
        : store.applications.myApplication?.numberOfOtherPets,
    householdDogs: store.applications.myApplication?.householdDogs || undefined,
    householdCats: store.applications.myApplication?.householdCats || undefined,
    householdOtherPets: store.applications.myApplication?.householdOtherPets || undefined,
    householdPreviousPets: store.applications.myApplication?.householdPreviousPets || undefined,
  }));

  const handleSubmit = (values: PetInfo) => {
    dispatch(
      savePetInfoStep({ ...values, reasonForGivingUp: !!values.givenUpPet ? values.reasonForGivingUp : undefined })
    );
  };

  const validationSchema = Yup.object().shape({
    currentPets: Yup.boolean().nullable(),
    numberOfPastPets: Yup.number()
      .required("Required")
      .test("pastPets", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
    numberOfDogs: Yup.number()
      .required("Required")
      .test("currentDogs", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
    numberOfCats: Yup.number()
      .required("Required")
      .test("currentCats", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
    numberOfOtherPets: Yup.number()
      .required("Required")
      .test("currentOther", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
    householdPreviousPets: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required("Required"),
          previousPetType: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          yearsOwned: Yup.string().nullable(),
          reasonNoLongerOwned: Yup.string().required("Required"),
        })
        .when("numberOfPastPets", {
          is: (value: number) => value > 0,
          then: (schema) => schema.required("Required"),
        })
    ),
    householdDogs: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required("Required"),
          age: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          gender: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          breed: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          adoptionYear: Yup.number()
            .required("Required")
            .max(new Date().getFullYear(), "Cannot select future date")
            .test("dogYear", "Enter a valid date", (value) => !/[.]/.test(value.toString())),
          temperament: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          sleepLocation: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          dogOrigin: Yup.object().typeError("Delete typed input and select an option").required("Required"),
        })
        .when("numberOfDogs", {
          is: (value: number) => value > 0,
          then: (schema) => schema.required("Required"),
        })
    ),
    householdCats: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required("Required"),
          age: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          gender: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          yearAdopted: Yup.number()
            .required("Required")
            .max(new Date().getFullYear(), "Cannot select future date")
            .test("catYear", "Enter a valid date", (value) => !/[.]/.test(value.toString())),
        })
        .when("numberOfCats", {
          is: (value: number) => value > 0,
          then: (schema) => schema.required("Required"),
        })
    ),
    householdOtherPets: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required("Required"),
          animalType: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          yearAdopted: Yup.number()
            .required("Required")
            .max(new Date().getFullYear(), "Cannot select future date")
            .test("otherYear", "Enter a valid date", (value) => !/[.]/.test(value.toString())),
        })
        .when("numberOfOtherPets", {
          is: (value: number) => value > 0,
          then: (schema) => schema.required("Required"),
        })
    ),
    givenUpPet: Yup.boolean().nullable(),
    reasonForGivingUp: Yup.string().when("givenUpPet", {
      is: (value: boolean) => !!value,
      then: (schema) => schema.required("Required"),
    }),
  });

  const handleDogsChange = (
    setFieldValue: (field: string, value: any) => void,
    numberOfItems: number,
    curentNumberOfItems: number,
    push: (obj: any) => void,
    remove: <T>(index: number) => T | undefined
  ) => {
    if (numberOfItems < 0) {
      numberOfItems = 0;
      setFieldValue("numberOfDogs", 0);
    }

    if (numberOfItems > 8) {
      numberOfItems = 8;
      setFieldValue("numberOfDogs", 8);
    }

    let change = numberOfItems - curentNumberOfItems;

    if (change < 0) {
      change = change * -1;
      for (let i = 0; i < change; i++) {
        remove(curentNumberOfItems - 1 - i);
      }
    } else {
      for (let i = 0; i < change; i++) {
        push({
          name: "",
          age: undefined,
          gender: undefined,
          breed: undefined,
          adoptionYear: undefined,
          temperament: undefined,
          sleepLocation: undefined,
          dogOrigin: undefined,
        } as Dog);
      }
    }
  };

  const handleCatsChange = (
    setFieldValue: (field: string, value: any) => void,
    numberOfItems: number,
    curentNumberOfItems: number,
    push: (obj: any) => void,
    remove: <T>(index: number) => T | undefined
  ) => {
    if (numberOfItems < 0) {
      numberOfItems = 0;
      setFieldValue("numberOfCats", 0);
    }

    if (numberOfItems > 8) {
      numberOfItems = 8;
      setFieldValue("numberOfCats", 8);
    }

    let change = numberOfItems - curentNumberOfItems;

    if (change < 0) {
      change = change * -1;
      for (let i = 0; i < change; i++) {
        remove(curentNumberOfItems - 1 - i);
      }
    } else {
      for (let i = 0; i < change; i++) {
        push({
          name: "",
          age: undefined,
          gender: undefined,
          yearAdopted: undefined,
        } as Cat);
      }
    }
  };

  const handlePetsChange = (
    setFieldValue: (field: string, value: any) => void,
    numberOfItems: number,
    curentNumberOfItems: number,
    push: (obj: any) => void,
    remove: <T>(index: number) => T | undefined
  ) => {
    if (numberOfItems < 0) {
      numberOfItems = 0;
      setFieldValue("numberOfOtherPets", 0);
    }

    if (numberOfItems > 8) {
      numberOfItems = 8;
      setFieldValue("numberOfOtherPets", 8);
    }

    let change = numberOfItems - curentNumberOfItems;

    if (change < 0) {
      change = change * -1;
      for (let i = 0; i < change; i++) {
        remove(curentNumberOfItems - 1 - i);
      }
    } else {
      for (let i = 0; i < change; i++) {
        push({
          name: "",
          animalType: undefined,
          yearAdopted: undefined,
        } as OtherPet);
      }
    }
  };

  const handlePastPetChange = (
    setFieldValue: (field: string, value: any) => void,
    numberOfItems: number,
    curentNumberOfItems: number,
    push: (obj: any) => void,
    remove: <T>(index: number) => T | undefined
  ) => {
    if (numberOfItems < 0) {
      numberOfItems = 0;
      setFieldValue("numberOfPastPets", 0);
    }

    if (numberOfItems > 8) {
      numberOfItems = 8;
      setFieldValue("numberOfPastPets", 8);
    }

    let change = numberOfItems - curentNumberOfItems;

    if (change < 0) {
      change = change * -1;
      for (let i = 0; i < change; i++) {
        remove(curentNumberOfItems - 1 - i);
      }
    } else {
      for (let i = 0; i < change; i++) {
        push({
          name: "",
          previousPetType: undefined,
          yearsOwned: undefined,
          reasonNoLongerOwned: "",
        } as PreviousPet);
      }
    }
  };

  const renderDogs = (dog: any, index: number) => {
    return (
      <Grid container key={`dog${index}`} sx={{}}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}>{`Dog Number ${
            index + 1
          }`}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
        >
          <Pets fontSize="large" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Field component={Inputs.Text} name={`householdDogs[${index}].name`} label={`Name`} required />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.Number}
            name={`householdDogs[${index}].adoptionYear`}
            label={`Year Joined Family`}
            required
          />
        </Grid>
        <Grid item xs={0} md={1} />

        <Grid item xs={12} md={3}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdDogs[${index}].age`}
            label={`Age`}
            searchFunction={getAgeOptions}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdDogs[${index}].gender`}
            label={`Gender`}
            searchFunction={getGenderOptions}
            required
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdDogs[${index}].breed`}
            label={`Breed`}
            searchFunction={getBreedOptions}
            required
            filterForm={{ pageSize: 50 }}
            hideLargeResults
          />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdDogs[${index}].temperament`}
            label={`Temperament`}
            searchFunction={getTemperaments}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdDogs[${index}].sleepLocation`}
            label={`Sleep Location`}
            searchFunction={getSleepLocations}
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdDogs[${index}].dogOrigin`}
            label={`Dog Origin`}
            searchFunction={getAdoptionOrigins}
            required
          />
        </Grid>
      </Grid>
    );
  };

  const renderCats = (cat: any, index: number) => {
    return (
      <Grid container key={`cat${index}`} sx={{}}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}>{`Cat Number ${
            index + 1
          }`}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
        >
          <Pets fontSize="large" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Field component={Inputs.Text} name={`householdCats[${index}].name`} label={`Name`} required />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.Number}
            name={`householdCats[${index}].yearAdopted`}
            label={`Year Joined Family`}
            required
          />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={5}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdCats[${index}].age`}
            label={`Age`}
            searchFunction={getCatAges}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdCats[${index}].gender`}
            label={`Gender`}
            searchFunction={getGenderOptions}
            required
          />
        </Grid>
      </Grid>
    );
  };

  const renderPets = (pet: any, index: number) => {
    return (
      <Grid container key={`pet${index}`} sx={{}}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
          >{`Other Pet Number ${index + 1}`}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
        >
          <Pets fontSize="large" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Field component={Inputs.Text} name={`householdOtherPets[${index}].name`} label={`Name`} required />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            component={Inputs.Number}
            name={`householdOtherPets[${index}].yearAdopted`}
            label={`Year Joined Family`}
            required
          />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={6}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdOtherPets[${index}].animalType`}
            label={`Animal Type`}
            searchFunction={getAnimalTypes}
            required
          />
        </Grid>
        <Grid item xs={0} md={5} />
      </Grid>
    );
  };

  const renderPastPet = (pastPets: any, index: number) => {
    return (
      <Grid container key={`pastpet${index}`} sx={{}}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
          >{`Previous Pet Number ${index + 1}`}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
        >
          <Pets fontSize="large" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Field component={Inputs.Text} name={`householdPreviousPets[${index}].name`} label={`Name`} required />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field component={Inputs.Text} name={`householdPreviousPets[${index}].yearsOwned`} label={`Years Owned`} />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={5}>
          <Field
            component={Inputs.OptionDropdown}
            name={`householdPreviousPets[${index}].previousPetType`}
            label={`Animal Type`}
            searchFunction={getPetTypes}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={Inputs.Text}
            name={`householdPreviousPets[${index}].reasonNoLongerOwned`}
            label={`Reason No Longer Owned`}
            required
          />
        </Grid>
        <Grid item xs={0} md={5} />
      </Grid>
    );
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      innerRef={props.formikRef}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ values, setFieldValue }) => (
        <Form noValidate>
          <Grid container sx={{ paddingTop: "5px" }}>
            <FieldArray name="householdDogs">
              {({ push, remove }) => (
                <>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={Inputs.Number}
                      name="numberOfDogs"
                      label="Number of Current Dogs?"
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        if (event.currentTarget.value.includes(".")) {
                          event.preventDefault();
                        } else {
                          handleDogsChange(
                            setFieldValue,
                            +event.currentTarget.value,
                            values?.householdDogs?.length || 0,
                            push,
                            remove
                          );
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={0} md={6} />
                  {values.numberOfDogs! > 0 &&
                    (values?.householdDogs || []).map((dog: any, index: any) => renderDogs(dog, index))}
                </>
              )}
            </FieldArray>

            <FieldArray name="householdCats">
              {({ push, remove }) => (
                <>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={Inputs.Number}
                      name="numberOfCats"
                      label="Number of Current Cats?"
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        if (event.currentTarget.value.includes(".")) {
                          event.preventDefault();
                        } else {
                          handleCatsChange(
                            setFieldValue,
                            +event.currentTarget.value,
                            values?.householdCats?.length || 0,
                            push,
                            remove
                          );
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={0} md={6} />

                  {(values?.householdCats || []).map((cat: any, index: any) => renderCats(cat, index))}
                </>
              )}
            </FieldArray>

            <FieldArray name="householdOtherPets">
              {({ push, remove }) => (
                <>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={Inputs.Number}
                      name="numberOfOtherPets"
                      label="Number of Other Current Pets?"
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        if (event.currentTarget.value.includes(".")) {
                          event.preventDefault();
                        } else {
                          handlePetsChange(
                            setFieldValue,
                            +event.currentTarget.value,
                            values?.householdOtherPets?.length || 0,
                            push,
                            remove
                          );
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={0} md={6} />

                  {(values?.householdOtherPets || []).map((pet: any, index: any) => renderPets(pet, index))}
                </>
              )}
            </FieldArray>

            <FieldArray name="householdPreviousPets">
              {({ push, remove }) => (
                <>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={Inputs.Number}
                      name="numberOfPastPets"
                      label="# of pets in the past 15 years? (excluding current)"
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        if (event.currentTarget.value.includes(".")) {
                          event.preventDefault();
                        } else {
                          handlePastPetChange(
                            setFieldValue,
                            +event.currentTarget.value,
                            values?.householdPreviousPets?.length || 0,
                            push,
                            remove
                          );
                        }
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={0} md={6} />

                  {(values?.householdPreviousPets || []).map((pastPet: any, index: any) =>
                    renderPastPet(pastPet, index)
                  )}
                </>
              )}
            </FieldArray>

            <Grid item xs={12} md={12}>
              <Field component={Inputs.Switch} name="givenUpPet" label="Have you ever given up a pet?" />
            </Grid>
            {!!values.givenUpPet && (
              <>
                <Grid item xs={12} md={12}>
                  <Field component={Inputs.Text} name="reasonForGivingUp" label="Reason for giving up?" required />
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PetInfoStep;
