  const getEventColor = (id: number) => {
    switch (id) {
      case 1: //meet and greet
        return "#0C7BDC";
      case 2: //fundraiser
        return "#009E73";
      case 3: //medical dog
        return "#DC3220";
      case 4: //Other
        return "#403f4a";
      default:
        return "teal";
    }
  };

  export default getEventColor;