import * as React from "react";
import { TextFieldProps } from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FieldProps, useFormikContext } from "formik";
import FormikTextField, { CustomInputProps } from "./FormikTextField";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface Props {}

const FormikTimePicker: React.FC<FieldProps & TextFieldProps & CustomInputProps & Props> = (props) => {
  const formikProps = useFormikContext();

  const { ...rest } = props;

  function handleChange(value: any, keyboardInputValue?: string | undefined): void {
    formikProps.setFieldValue(props.field.name, value);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label=""
        value={props.field.value || null}
        onChange={handleChange}
        renderInput={(params) => (
          <FormikTextField
            {...params}
            size={!!props.secondary ? "medium" : "small"}
            onChange={handleChange}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FormikTimePicker;
