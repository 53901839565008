import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";
import Icon, { IconType } from "./Icon";
import Moment from "react-moment";

interface Props {
  image: string;
}

const EventExample: React.FC<Props> = (props) => {
  const { image: cropImagePreview } = props;

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: -6, left: 5 }}>
        <Icon type={IconType.Bookmark} color={theme.palette.primary.main} size={"90px"} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 28,
          width: 40,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
            <Moment format="MMM" style={{ textTransform: "uppercase" }}>
              {new Date()}
            </Moment>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h2" sx={{ color: theme.palette.primary.contrastText, marginTop: "-5px" }}>
            <Moment format="D">{new Date()}</Moment>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{ padding: 1, typography: { lg: "h2", md: "h3" } }}
          color={theme.palette.primary.contrastText}
        >
          Example
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 44,
          backgroundColor: theme.palette.secondary.main,
          width: "100%",
        }}
      >
        <Typography textAlign={"center"} color={theme.palette.primary.main}>
          Click for details
        </Typography>
      </Box>
      <Box
        component="img"
        alt={`example picture`}
        src={cropImagePreview}
        sx={{
          aspectRatio: 16 / 13,
          objectFit: "cover",
          height: "325px",
          width: "400px",
          borderRadius: 6,
        }}
      />
    </Box>
  );
};

export default EventExample;
