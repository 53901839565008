import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";
import AdminNavbar from "../views/AdminNavbar";
import AdminSidebar from "../views/AdminSidebar";
import Footer from "../views/Footer";
import store, { useDispatch, useSelector } from "../../store";
import { setResetDogFilterBool } from "../../slices/filters";

interface Props {}

const state = store.getState();

const Root = styled.div`
  background-color: ${theme.palette.background.paper};
  display: flex;
  height: 100%;
  width: 100%;
  padding-bottom: 62px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  padding-top: 100px;
`;

const Background = styled.div`
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  position: absolute;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

const AdminLayout: React.FC<Props> = (props) => {
  const { menuOpen } = useSelector((store) => store.admin);
  const { resetDogFilterBool } = useSelector((store) => store.filters);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location.pathname.includes("/dog/") && !location.pathname.includes("/dogs") && !resetDogFilterBool) {
      dispatch(setResetDogFilterBool(true));
    }
  }, [location.pathname]);

  return (
    <Root>
      <AdminNavbar />
      <Wrapper>
        <Background />
        <Container>
          {<AdminSidebar />}
          <Content style={{ marginLeft: menuOpen ? "220px" : "0px" }}>
            <Outlet />
          </Content>
        </Container>
      </Wrapper>
      <Footer includeDonate={false} />
    </Root>
  );
};

export default AdminLayout;
