import { combineReducers } from "@reduxjs/toolkit";
import sampleReducer from "../slices/sample";
import dogsReducer from "../slices/dogs";
import authReducer from "../slices/auth";
import toastReducer from "../slices/toast";
import usersReducer from "../slices/users";
import adminReducer from "../slices/admin";
import fileReducer from "../slices/file";
import notesReducer from "../slices/notes";
import optionReducer from "../slices/options";
import applicationsReducer from "../slices/applications";
import adoptionReducer from "../slices/adoptions";
import eventReducer from "../slices/events";
import vetReducer from "../slices/vet";
import searchReducer from "../slices/search";
import reportReducer from "../slices/report";
import socialReducer from "../slices/social";
import filterReducer from "../slices/filters";
import sourcesReducer from "../slices/sources";

const rootReducer = combineReducers({
  sample: sampleReducer,
  auth: authReducer,
  toast: toastReducer,
  dogs: dogsReducer,
  users: usersReducer,
  admin: adminReducer,
  file: fileReducer,
  notes: notesReducer,
  options: optionReducer,
  applications: applicationsReducer,
  adoptions: adoptionReducer,
  events: eventReducer,
  vet: vetReducer,
  search: searchReducer,
  report: reportReducer,
  social: socialReducer,
  filters: filterReducer,
  sources: sourcesReducer,
});

export default rootReducer;
