import React from "react";
import RegisterView from "../views/Auth/RegisterView";

interface Props {}

const RegisterPage: React.FC<Props> = (props) => {
  return (
    <>
      <RegisterView />
    </>
  );
};

export default RegisterPage;
