import axios from "axios";
import axiosErrorInterceptor from "./axiosErrorInterceptor";
import axiosJwtInterceptor from "./axiosJwtInterceptor";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use(axiosJwtInterceptor, (e) => Promise.reject(e));

http.interceptors.response.use(
  (response) => response,
  (error) => axiosErrorInterceptor(error)
);

export default http;
