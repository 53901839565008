import React from "react";
import Application from "../../../../types/application";
import ReactPDF, { Text, View } from "@react-pdf/renderer";

interface Props {
  application?: Application;
  styles: ReactPDF.Styles;
}

const ReferencesPage: React.FC<Props> = (props) => {
  const { application, styles } = props;

  const hasPets =
    application?.numberOfDogs! > 0 ||
    application?.numberOfCats! > 0 ||
    application?.numberOfOtherPets! > 0 ||
    application?.numberOfPastPets! > 0;

  return (
    <View style={styles.page}>
      <View>
        <Text style={styles.header}>References</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <View>
          <Text style={styles.mapQuestionText}>Personal References:</Text>
        </View>
        <View style={{ display: "flex", flexDirection: "column" }}>
          {application?.personalReferences?.map((personal, index) => {
            return (
              <View style={{ marginBottom: "25px", borderBottom: "1px solid black" }} key={personal.id}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Text style={styles.answerText}>{`Reference #${index + 1}: ${personal.name}`}</Text>
                  <Text style={styles.answerText}>{`Phone: ${personal.phone}`}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Text style={styles.answerText}>{`Relationship: ${personal.relationship}`}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </View>
      {!!hasPets && !!application?.petsSeeVet && application?.vetReferences?.length! > 0 ? (
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View>
            <Text style={styles.mapQuestionText}>Vet References:</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {application?.vetReferences?.map((vet, index) => {
              return (
                <View style={{ marginBottom: "25px", borderBottom: "1px solid black" }} key={vet.id}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Reference #${index + 1}: ${vet.name}`}</Text>
                    <Text style={styles.answerText}>{`Phone: ${vet.phone}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Location: ${vet.city}, ${vet.state?.value}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Pets on File: ${vet.petsOnFile}`}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      ) : (
        hasPets && (
          <View style={{ display: "flex", flexDirection: "column" }}>
            <View>
              <Text style={styles.mapQuestionText}>Why do/did your pets not see a vet?:</Text>
              <Text style={styles.answerText}>{application?.whyNoVet}</Text>
            </View>
          </View>
        )
      )}
    </View>
  );
};

export default ReferencesPage;
