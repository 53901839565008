import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Container, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import KeyValue from "../../types/keyValue";
import DogCard from "../base/DogCard";
import { favoriteDog, getDogsSearch, resetDog } from "../../slices/dogs";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import Search from "../base/Search";
import { debounce } from "lodash";
import { getBreedOptions, getAgeOptions, getGenderOptions, getDogWeights } from "../../slices/options";
import { getUserId } from "../../slices/users";
import DogSimple from "../../types/dogSimple";
import InfiniteScroll from "react-infinite-scroll-component";
import ActionButton from "../base/ActionButton";
import {
  resetAdoptableFilter,
  setAdoptableFavorites,
  setAdoptableFilter,
  setAdoptableSort,
} from "../../slices/filters";
import { getMyApplication } from "../../slices/applications";
import theme from "../../theme";

interface Props {}

const AdoptableDogsView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector((store) => store.auth);
  const { user } = useSelector((store) => store.users);
  const { dogSearch, searchStatus } = useSelector((store) => store.dogs);
  const { adoptableDogsFilter, adoptableFavorites, adoptableSort } = useSelector((store) => store.filters);
  const { genders, ages, breeds, dogWeights } = useSelector((store) => store.options);

  const [dogCards, setDogCards] = useState<DogSimple[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [appliedForMaxDogs, setAppliedForMaxDogs] = useState(false);

  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!!loggedInUser) {
      dispatch(getUserId(loggedInUser.id));
    }
    dispatch(getBreedOptions({ query: "" }));
    dispatch(getAgeOptions({ query: "" }));
    dispatch(getGenderOptions({ query: "" }));
    loadInfiniteScroll();
    dispatch(resetDog());
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    if (!!loggedInUser) {
      dispatch(getMyApplication()).then((result: any) => {
        if (result && result.payload && result.payload.selectedDog && result.payload.otherDogs.length > 2) {
          setAppliedForMaxDogs(true);
        }
      });
    }
  }, [dispatch, loggedInUser]);

  const loadInfiniteScroll = () => {
    dispatch(getDogsSearch({ search: adoptableDogsFilter, includePhotos: true })).then((result: any) => {
      if (result && result.payload && result.payload.results) {
        setDogCards([...dogCards, ...result.payload.results]);
      }
      dispatch(setAdoptableFilter({ ...adoptableDogsFilter, pageNumber: adoptableDogsFilter?.pageNumber! + 1 }));
    });
  };

  const updateSearch = debounce((query: string) => {
    dispatch(
      getDogsSearch({
        search: { ...adoptableDogsFilter, pageNumber: 1, pageSize: 12, query: query },
        includePhotos: true,
      })
    ).then((result: any) => {
      dispatch(setAdoptableFilter({ ...adoptableDogsFilter, pageNumber: 2, pageSize: 12, query: query }));
      if (result && result.payload && result.payload.results) {
        setDogCards(result.payload.results);
      }
    });
  }, 250);

  const handleFiltering = (e: KeyValue, filterName: string) => {
    let filters = adoptableDogsFilter?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (e) {
      let newFilters: string[] = [`${filterName}:${e.id}`];
      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      dispatch(setAdoptableFilter({ ...adoptableDogsFilter, pageNumber: 1, pageSize: 12, filters: newList }));
      updateFilters(newList);
    } else {
      updateFilters(otherFilters!);
    }
  };

  const updateFilters = (filterList: string[]) => {
    dispatch(
      getDogsSearch({
        search: { ...adoptableDogsFilter, pageNumber: 1, pageSize: 12, filters: filterList },
        includePhotos: true,
      })
    ).then((result: any) => {
      if (result && result.payload && result.payload.results) {
        setDogCards(result.payload.results);
      }
      dispatch(setAdoptableFilter({ ...adoptableDogsFilter, pageNumber: 2, pageSize: 12, filters: filterList }));
    });
  };

  const handleBreedFilter = (value: KeyValue) => {
    handleFiltering(value, "breed");
  };

  const handleAgeFilter = (value: KeyValue) => {
    handleFiltering(value, "age");
  };

  const handleWeightFilter = (value: KeyValue) => {
    handleFiltering(value, "weight");
  };

  const handleGenderFilter = (value: KeyValue) => {
    handleFiltering(value, "gender");
  };

  const handleCheckbox = (e: boolean) => {
    let filter: KeyValue = {
      id: e ? 1 : 2,
      value: "",
    };
    handleFiltering(filter, "favorites");
    dispatch(setAdoptableFavorites(e));
  };

  const handleSortCheckbox = (e: boolean) => {
    if (e) {
      dispatch(
        getDogsSearch({
          search: { ...adoptableDogsFilter, pageNumber: 1, pageSize: 12, orderBy: "name", orderDirection: "Ascending" },
          includePhotos: true,
        })
      ).then((result: any) => {
        dispatch(
          setAdoptableFilter({
            ...adoptableDogsFilter,
            pageNumber: 2,
            pageSize: 12,
            query: "",
            orderBy: "name",
            orderDirection: "Ascending",
          })
        );
        if (result && result.payload && result.payload.results) {
          setDogCards(result.payload.results);
        }
        dispatch(setAdoptableSort(true));
      });
    } else {
      dispatch(
        getDogsSearch({
          search: {
            ...adoptableDogsFilter,
            pageNumber: 1,
            pageSize: 12,
            query: "",
            orderBy: undefined,
            orderDirection: undefined,
          },
          includePhotos: true,
        })
      ).then((result: any) => {
        dispatch(
          setAdoptableFilter({
            ...adoptableDogsFilter,
            pageNumber: 2,
            pageSize: 12,
            query: "",
            orderBy: undefined,
            orderDirection: undefined,
          })
        );
        if (result && result.payload && result.payload.results) {
          setDogCards(result.payload.results);
        }
        dispatch(setAdoptableSort(false));
      });
    }
  };

  const handleFavorite = (dogId: number) => {
    dispatch(favoriteDog(dogId)).then((results) => {
      if (results) {
        dispatch(getUserId(loggedInUser?.id!));
      }
    });
  };

  const handleClear = () => {
    dispatch(
      getDogsSearch({
        search: {
          query: "",
          pageNumber: 1,
          pageSize: 12,
          filters: ["status:2", "status:4"],
        },
        includePhotos: true,
      })
    ).then((result: any) => {
      dispatch(resetAdoptableFilter());
      if (result && result.payload && result.payload.results) {
        setDogCards(result.payload.results);
      }
    });
  };

  const handleReset = (resetForm: () => void) => {
    resetForm();
  };

  const initialBreedId = adoptableDogsFilter?.filters
    ?.filter((filter) => filter.includes("breed"))
    .toString()
    .split(":")[1];

  const initialBreed = () => {
    if (adoptableDogsFilter.filters?.length) {
      const breedFilter = adoptableDogsFilter.filters.filter((filter) => filter.includes("breed"));
      if (breedFilter.length === 1) {
        const breed = breeds?.results[0];
        return breed;
      } else return undefined;
    }
  };

  const initialAge = () => {
    if (adoptableDogsFilter.filters?.length) {
      const ageFilter = adoptableDogsFilter.filters.filter((filter) => filter.includes("age"));
      if (ageFilter) {
        const age = ages?.results.find((age) => age.id === parseInt(ageFilter.toString().split(":")[1]));
        return age;
      } else return undefined;
    }
  };

  const initialGender = () => {
    if (adoptableDogsFilter.filters?.length) {
      const genderFilter = adoptableDogsFilter.filters.filter((filter) => filter.includes("gender"));
      if (genderFilter) {
        const gender = genders?.results.find((gender) => gender.id === parseInt(genderFilter.toString().split(":")[1]));
        return gender;
      } else return undefined;
    }
  };

  const initialWeight = () => {
    if (adoptableDogsFilter.filters?.length) {
      const weightFilter = adoptableDogsFilter.filters.filter((filter) => filter.includes("weight"));
      if (weightFilter) {
        const weight = dogWeights?.results.find(
          (weight) => weight.id === parseInt(weightFilter.toString().split(":")[1])
        );
        return weight;
      } else return undefined;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Container>
        <Formik
          initialValues={{
            breed: initialBreed(),
            age: initialAge(),
            gender: initialGender(),
            weight: initialWeight(),
          }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {(formProps: any) => {
            return (
              <Form noValidate>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-around"}
                  sx={{
                    mb: 3,
                  }}
                >
                  <Grid item sx={{ display: { xs: loggedInUser ? "flex" : "none", md: "none" } }}>
                    <Field
                      component={Inputs.Checkbox}
                      name="favorites"
                      label="Show Favorites"
                      colorVariant="blue"
                      fontVariant="h5"
                      onChange={handleCheckbox}
                      checked={adoptableFavorites}
                    />
                  </Grid>
                  <Grid item sx={{ display: { xs: loggedInUser ? "flex" : "none", md: "none" } }}>
                    <Field
                      component={Inputs.Checkbox}
                      name="favorites"
                      label="Sort Alphabetically"
                      colorVariant="blue"
                      fontVariant="h5"
                      onChange={handleSortCheckbox}
                      checked={adoptableSort}
                    />
                  </Grid>
                  <Grid item sx={{ display: { xs: "flex", md: "none" } }}>
                    <ActionButton
                      text={showFilters ? "Hide Filters" : "Show Filters"}
                      onClick={() => setShowFilters(!showFilters)}
                      type="button"
                      small
                    />
                  </Grid>
                </Grid>
                <Box sx={{ marginBottom: 1, display: { xs: showFilters ? "inline" : "none", md: "inline" } }}>
                  <Grid container alignItems="center" justifyContent="center" padding={"-15px"}>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="age"
                        label="Age"
                        component={Inputs.OptionDropdown}
                        searchFunction={getAgeOptions}
                        onSelected={handleAgeFilter}
                        size="small"
                        // noHelper
                        secondary
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="weight"
                        label="Weight"
                        component={Inputs.OptionDropdown}
                        searchFunction={getDogWeights}
                        onSelected={handleWeightFilter}
                        size="small"
                        // noHelper
                        secondary
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="gender"
                        label="Gender"
                        component={Inputs.OptionDropdown}
                        searchFunction={getGenderOptions}
                        onSelected={handleGenderFilter}
                        size="small"
                        // noHelper
                        secondary
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="breed"
                        label="Breed (Type to Search)"
                        component={Inputs.OptionDropdown}
                        searchFunction={getBreedOptions}
                        onSelected={handleBreedFilter}
                        size="small"
                        // noHelper
                        secondary
                        filterForm={{
                          pageSize: 50,
                          id: initialBreedId ? parseInt(initialBreedId) : undefined,
                        }}
                        hideLargeResults
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ marginTop: { md: -3 } }}>
                      <Search label="Search" handleChange={updateSearch} secondary value={adoptableDogsFilter.query} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction={"row"} justifyContent={xs ? "center" : "space-between"}>
                        <Grid item sx={{ my: "15px" }}>
                          <ActionButton
                            type="reset"
                            text={"Reset Filters"}
                            onClick={async () => {
                              await handleClear();
                              await handleReset.bind(formProps.resetForm);
                              await handleCheckbox(false);
                            }}
                            small
                          />
                        </Grid>
                        <Grid item sx={{ display: { xs: "none", md: "flex" } }}>
                          <Field
                            component={Inputs.Checkbox}
                            name="favorites"
                            label="Sort Alphabetically"
                            colorVariant="blue"
                            fontVariant="body2"
                            onChange={handleSortCheckbox}
                            checked={adoptableSort}
                          />
                        </Grid>
                        <Grid item sx={{ display: { xs: "none", md: "flex" } }}>
                          <Field
                            component={Inputs.Checkbox}
                            name="favorites"
                            label="Show Favorites"
                            colorVariant="blue"
                            fontVariant="body2"
                            onChange={handleCheckbox}
                            checked={adoptableFavorites}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ textAlign: "center", mb: 1 }}>
                  <Typography variant={xs ? "h5" : "h3"}>
                    Click on a dog to learn more about them and apply. You can also use the filters to narrow down your
                    search.
                  </Typography>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Container>
      {searchStatus === "loading" && !dogCards.length ? (
        <Typography variant="h1" textAlign={"center"}>
          Loading dogs, please wait...
        </Typography>
      ) : (
        <InfiniteScroll
          dataLength={dogCards.length}
          next={loadInfiniteScroll}
          hasMore={dogCards.length >= dogSearch?.totalResults! ? false : true}
          loader={<Typography textAlign={"center"}>Loading Dogs...</Typography>}
          endMessage={
            <Typography textAlign={"center"}>
              {dogCards.length > 0
                ? "No more dogs to display. Scroll back up and modify your search to find your future best friend."
                : searchStatus === "loading"
                ? "Loading, please wait..."
                : "No dogs match that search"}
            </Typography>
          }
          height={`${window.innerHeight - 250}px`}
          scrollThreshold={0.6}
        >
          <Paper sx={{ borderRadius: { xs: 0, sm: 10 }, marginX: { xs: 0, sm: 2 }, paddingY: 2 }}>
            <Grid container direction={"row"} display="flex" justifyContent="center">
              {dogCards.map((dog, index) => {
                return (
                  <Grid key={index} item lg={3} md={4} sm={6} xs={6} justifyContent="center">
                    <DogCard
                      dog={dog}
                      userFavorite={!!loggedInUser && !!user?.favoriteDogs?.find((d) => d?.id === dog?.id)}
                      saveFavorite={(id: number) => handleFavorite(id)}
                      maxDogsApplied={appliedForMaxDogs}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </InfiniteScroll>
      )}
    </Box>
  );
};

export default AdoptableDogsView;
