import React from "react";
import DialogContent from "@mui/material/DialogContent";
import { Dialog as DialogContainer, Typography } from "@mui/material";
import theme from "../../theme";

interface Props {
  title: string;
  children: React.ReactNode;
  open: boolean;
  backgroundColor?: any;
  warning?: boolean;
}

const Dialog: React.FC<Props> = (props) => {
  const { title, children, open, backgroundColor, warning } = props;

  return (
    <DialogContainer open={open} fullWidth>
      {warning && (
        <Typography
          align="center"
          sx={{
            paddingTop: "10px",
            paddingX: "10px",
            backgroundColor: backgroundColor ? backgroundColor : theme.palette.background.default,
            color: theme.palette.error.main,
          }}
          variant="body1"
        >
          WARNING! PLEASE READ BEFORE CLICKING ANYTHING ELSE:
        </Typography>
      )}
      {title && (
        <Typography
          align="center"
          sx={{
            paddingTop: "10px",
            paddingX: "10px",
            backgroundColor: backgroundColor ? backgroundColor : theme.palette.background.default,
          }}
          variant="body1"
        >
          {title}
        </Typography>
      )}
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: backgroundColor ? backgroundColor : theme.palette.background.default,
        }}
      >
        {children}
      </DialogContent>
    </DialogContainer>
  );
};

export default Dialog;
