import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import User from "../types/user";
import Pool from "../UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";

interface AuthState {
  loggedInUser?: User;
  status: "idle" | "loading" | "failed";
  resetPasswordEmail: string;
  resetPasswordCode: string;
  authRoute: "login" | "register" | "forgot" | "reset" | "change";
  authModal: boolean;
  temporaryPassword?: boolean;
  awsUser?: CognitoUser;
  userAttributes?: any;
}

const initialState: AuthState = {
  loggedInUser: undefined,
  status: "idle",
  resetPasswordEmail: "",
  resetPasswordCode: "",
  authRoute: "login",
  authModal: false,
};

export const register = createAsyncThunk<User, User>("/users/register", async (user: User) => {
  const response = await http.post<User>(`/users`, user);
  return response.data;
});

export const getLoggedInUser = createAsyncThunk<User>("/auth/current", async () => {
  const response = await http.get<User>("/auth/current");
  return response.data;
});

export const updateProfile = createAsyncThunk<User, User>("auth/edit", async (model: User) => {
  const response = await http.put<User>(`/users/${model.id}`, model);
  return response.data;
});

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      let user = Pool.getCurrentUser();
      if (user) {
        user.signOut();
        try {
          user = Pool.getCurrentUser();
          console.log(user);
        } catch (error) {
          console.log(error);
        }
      } else {
        return;
      }
      state.loggedInUser = initialState.loggedInUser;
      localStorage.clear();
    },
    setAwsUser(state, action) {
      state.awsUser = action.payload;
    },
    setTemporaryPassword(state, action) {
      state.temporaryPassword = action.payload;
    },
    setUserAttributes(state, action) {
      state.userAttributes = action.payload;
    },
    resetUser(state) {
      state.temporaryPassword = false;
      state.userAttributes = initialState.userAttributes;
    },
    setResetPasswordEmail(state, action) {
      state.resetPasswordEmail = action.payload;
    },
    setResetPasswordCode(state, action) {
      state.resetPasswordCode = action.payload;
    },
    setAuthModal(state, action) {
      state.authModal = action.payload;
    },
    setAuthRoute(state, action) {
      state.authRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = "idle";
        if (state.loggedInUser === undefined) {
          state.loggedInUser = action.payload;
        }
      })
      .addCase(register.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getLoggedInUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLoggedInUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.loggedInUser = action.payload;
      })
      .addCase(getLoggedInUser.rejected, (state) => {
        state.status = "failed";
        state.loggedInUser = undefined;
      })
      .addCase(updateProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = "idle";
        if (state.loggedInUser === undefined) {
          state.loggedInUser = action.payload;
        }
      })
      .addCase(updateProfile.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  logout,
  setTemporaryPassword,
  setAwsUser,
  setUserAttributes,
  setResetPasswordEmail,
  setResetPasswordCode,
  setAuthModal,
  setAuthRoute,
  resetUser,
} = authSlice.actions;

export default authSlice.reducer;
