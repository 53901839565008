import React, { Dispatch, SetStateAction } from "react";
import { FieldProps } from "formik";
import { TextFieldProps } from "@mui/material/TextField";
import FormikTextField from "./FormikTextField";

interface Props {
  showPassword?: boolean;
  setShowPassword?: Dispatch<SetStateAction<boolean>>;
}

const FormikPasswordField: React.FC<FieldProps & TextFieldProps & Props> = (props) => {
  return (
    <FormikTextField {...props} type={props.showPassword ? "text" : "password"} showPassword={props.showPassword} />
  );
};

export default FormikPasswordField;
