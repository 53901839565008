import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "../../../store";
import HistoryAnswerView from "./HistoryAnswerView";

interface Props {}

const OtherView: React.FC<Props> = (props) => {
  const { adminApplication } = useSelector((store) => store.applications);

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"baseline"} mb={3}>
          <Grid item xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.["HalReferral.Value"]}
              answerString={adminApplication?.halReferral?.value}
              questionText="How Did You Hear About HAL?"
            />
          </Grid>
          <Grid item xs={12}>
            <HistoryAnswerView
              historyArray={adminApplication?.history?.OtherInfo}
              answerString={adminApplication?.otherInfo}
              questionText="Is There Any Other Info You'd Like to Share With Us?"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Other Dogs of Interest
            </Typography>
            {adminApplication?.otherDogs && adminApplication.otherDogs?.length > 0 ? (
              adminApplication?.otherDogs?.map((dog, index) => {
                return (
                  <Box key={dog.id}>
                    <HistoryAnswerView
                      historyArray={adminApplication.history?.Dogs}
                      answerString={dog.name}
                      questionText=""
                      otherDog
                      dogId={dog.id}
                      modalQuestionText="Other Dogs of Interest"
                    />
                  </Box>
                );
              })
            ) : !!adminApplication && adminApplication?.history?.Dogs ? (
              <Box>
                <HistoryAnswerView
                  historyArray={adminApplication.history?.Dogs}
                  answerString={"None"}
                  questionText=""
                  otherDog
                  dogId={0}
                  modalQuestionText="Other Dogs of Interest"
                />
              </Box>
            ) : (
              <Typography variant="body1">None</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OtherView;
