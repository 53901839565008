import React from "react";

interface Props {}

const DonationBox: React.FC<Props> = (props) => {
  return (
    <>
      <script async src={"https://donorbox.org/widget.js"} data-paypalexpress={true}></script>
      <iframe
        src={"https://donorbox.org/embed/homeatlastdogrescue"}
        name="donorbox"
        title="donorbox"
        allow="payment"
        height="800px"
        width="100%"
        style={{
          maxWidth: "425px",
          width: "100%",
          minWidth: "325px",
          maxHeight: "none!important",
          minHeight: "100%",
          border: "0px",
        }}
      />
    </>
  );
};

export default DonationBox;
