import React from "react";
import Application from "../../../../types/application";
import ReactPDF, { Text, View } from "@react-pdf/renderer";

interface Props {
  application?: Application;
  styles: ReactPDF.Styles;
}

const PetsPage: React.FC<Props> = (props) => {
  const { application, styles } = props;
  return (
    <View style={styles.page}>
      <View>
        <Text style={styles.header}>Pets</Text>
      </View>
      <View style={styles.questionContainer}>
        <View style={{ width: !application?.givenUpPet ? "100%" : "45%", marginBottom: "35px" }}>
          <Text style={styles.questionText}>Have You Ever Given Up a Pet?</Text>
          <Text style={styles.answerText}>{application?.givenUpPet ? "Yes" : "No"}</Text>
        </View>
        {application?.givenUpPet && (
          <View style={styles.separator}>
            <Text style={styles.questionText}>Reason for Giving Up:</Text>
            <Text style={styles.answerText}>{application?.reasonForGivingUp}</Text>
          </View>
        )}
      </View>
      {application?.currentPets && application?.householdDogs && application?.householdDogs?.length > 0 && (
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View>
            <Text style={styles.mapQuestionText}>Current Dogs:</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {application.householdDogs.map((dog, index) => {
              return (
                <View style={{ marginBottom: "25px", borderBottom: "1px solid black" }} key={index}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Dog #${index + 1}: ${dog.name}`}</Text>
                    <Text style={styles.answerText}>{`Gender: ${dog.gender?.value}`}</Text>
                    <Text style={styles.answerText}>{`Age: ${dog.age?.value}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Breed: ${dog.breed?.value}`}</Text>
                    <Text style={styles.answerText}>{`Temperment: ${dog.temperament?.value}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Dog Origin: ${dog.dogOrigin?.value}`}</Text>
                    <Text style={styles.answerText}>{`Year Joined Family: ${dog.adoptionYear}`}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      )}
      {application?.currentPets && application?.householdCats && application?.householdCats?.length > 0 && (
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View>
            <Text style={styles.mapQuestionText}>Current Cats:</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {application.householdCats.map((cat, index) => {
              return (
                <View style={{ marginBottom: "25px", borderBottom: "1px solid black" }} key={index}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Cat #${index + 1}: ${cat.name}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Gender: ${cat.gender?.value}`}</Text>
                    <Text style={styles.answerText}>{`Age: ${cat.age?.value}`}</Text>
                    <Text style={styles.answerText}>{`Year Joined Family: ${cat.yearAdopted}`}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      )}
      {application?.currentPets && application?.householdOtherPets && application?.householdOtherPets?.length > 0 && (
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View>
            <Text style={styles.mapQuestionText}>Current Other Pets:</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {application.householdOtherPets.map((other, index) => {
              return (
                <View style={{ marginBottom: "25px", borderBottom: "1px solid black" }} key={index}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Pet #${index + 1}: ${other.name}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Animal Type: ${other.animalType?.value}`}</Text>
                    <Text style={styles.answerText}>{`Year Joined Family: ${other.yearAdopted}`}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      )}
      {application?.householdPreviousPets && application?.householdPreviousPets?.length > 0 && (
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View>
            <Text style={styles.mapQuestionText}>Pets in the Past 15 Years:</Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {application.householdPreviousPets.map((past, index) => {
              return (
                <View style={{ marginBottom: "25px", borderBottom: "1px solid black" }} key={index}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Pet #${index + 1}: ${past.name}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Year Owned: ${past.yearsOwned}`}</Text>
                    <Text style={styles.answerText}>{`Animal Type: ${past.previousPetType?.value}`}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Text style={styles.answerText}>{`Reason no longer owned: ${past.reasonNoLongerOwned}`}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
};

export default PetsPage;
