import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { FieldInputProps, FieldProps, getIn, useFormikContext } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import theme from "../../theme";
import { Box, InputAdornment, Typography, useMediaQuery } from "@mui/material";
import IconButton from "../base/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export interface CustomInputProps {
  secondary?: boolean;
  isAutoComplete?: boolean;
  showPassword?: boolean;
  setShowPassword?: Dispatch<SetStateAction<boolean>>;
}

const FormikTextField: React.FC<FieldProps & TextFieldProps & CustomInputProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    isAutoComplete,
    secondary,
    showPassword,
    setShowPassword,
    helperText,
    InputProps,
    error,
    form,
    field,
    onChange,
    ...rest
  } = props;

  let inputProps = {};

  let fieldCopy = {};

  // I did this because autoComplete needs to remove field.name
  if (!isAutoComplete) {
    fieldCopy = { ...field };
  }

  if (setShowPassword) {
    inputProps = {
      ...InputProps,
      autoComplete: "off",
      disableUnderline: true,
      style: {
        color: theme.palette.primary.main,
        background: theme.palette.background.paper,
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
        borderRadius: "10px",
      },
      endAdornment:
        showPassword === false ? (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowPassword(true);
              }}
              label=""
              icon={<Visibility color="primary" />}
              hideTooltip
              tabIndex={-1}
            />
          </InputAdornment>
        ) : (
          !!showPassword && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setShowPassword(false);
                }}
                label=""
                icon={<VisibilityOff color="primary" />}
                hideTooltip
                tabIndex={-1}
              />
            </InputAdornment>
          )
        ),
    };
  } else if (!!secondary) {
    inputProps = {
      ...InputProps,
      autoComplete: "off",
      disableUnderline: true,
      style: {
        color: theme.palette.primary.main,
        background: theme.palette.background.paper,
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
        borderRadius: "10px",
      },
    };
  } else {
    inputProps = {
      ...InputProps,
      autoComplete: "off",
      style: {
        color: theme.palette.primary.main,
        background: "transparent",
      },
    };
  }

  function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    field.onChange(event);
    onChange?.(event);
  }

  return (
    <Box sx={{ px: "10px" }}>
      {!secondary && (
        <Typography variant="h5" sx={{ display: { xs: "flex", sm: "none" }, paddingLeft: 1, paddingBottom: 1 }}>
          {props.label}
        </Typography>
      )}
      <TextField
        {...fieldCopy}
        value={field.value === null || field.value === undefined ? "" : field.value}
        autoComplete="off"
        variant={!!secondary ? "filled" : "outlined"}
        sx={{
          "& .MuiFormLabel-root": {
            color: theme.palette.primary.main,
            fontStyle: "italic",
            fontSize: "20px",
            display: !secondary && xs ? "none" : "flex",
          },
          "& .MuiFormHelperText-root": {
            color: theme.palette.error.main,
            margin: "-3px 10px 5px",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: Boolean(isTouched && errorMessage) ? theme.palette.error.main : theme.palette.primary.main,
              borderWidth: "2px",
              borderRadius: "10px",
            },
            "&:hover fieldset": {
              borderColor: theme.palette.primary.main,
              borderWidth: "2px",
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.main,
              borderWidth: "2px",
            },
          },
          "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
          },
        }}
        inputProps={{
          "data-form-type": "other",
          "data-lpignore": " true",
        }}
        fullWidth
        helperText={helperText ?? (isTouched && errorMessage) ? errorMessage : " "}
        error={error ?? Boolean(isTouched && errorMessage)}
        {...rest}
        onChange={handleChange}
        InputProps={{ ...inputProps }}
        InputLabelProps={{ shrink: xs && !secondary ? false : undefined }}
        size="medium"
      />
    </Box>
  );
};

export default FormikTextField;
function useFormikConfig(): { validationSchema: any } {
  throw new Error("Function not implemented.");
}
