import React from "react";
import HomeView from "../views/HomeScreen/HomeView";

interface Props {}

const HomePage: React.FC<Props> = (props) => {
  return (
    <>
      <HomeView />
    </>
  );
};

export default HomePage;
