import React from "react";
import { useSelector } from "../../store";
import TitleBar from "../base/TitleBar";
import EditCreateUser from "../views/EditCreateUser";

interface Props {}

const UserDetailPage: React.FC<Props> = (props) => {
  const { user } = useSelector((store) => store.users);

  return (
    <>
      <TitleBar title={user.id === 0 ? "Create New Person" : `${user.firstName} ${user.lastName}`} />
      <EditCreateUser />
    </>
  );
};

export default UserDetailPage;
