import React from "react";
import { Box, Typography, IconButton, Drawer, MenuItem, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";
import { useSelector, useDispatch } from "../../store";
import { logout } from "../../slices/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import { resetApplication } from "../../slices/applications";
import { resetUserValues } from "../../slices/users";

interface Props {}

const AdminSidebar: React.FC<Props> = (props) => {
  const { menuOpen } = useSelector((store) => store.admin);
  const { loggedInUser } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProfileRoute = () => {
    navigate("/user-profile", { state: { id: loggedInUser?.id } });
  };

  const handleLogout = async () => {
    if (!!loggedInUser) {
      dispatch(logout());
      dispatch(resetApplication());
      dispatch(resetUserValues());
      navigate("/home");
    }
  };

  const menuItems = [
    // { label: "Dashboard", value: "dashboard", icon: IconType.Dashboard },
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Inventory) ||
    checkUserRole(loggedInUser!, UserRoles.Foster) ||
    checkUserRole(loggedInUser!, UserRoles.Document) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer)
      ? [{ label: "Dogs", value: "dogs", icon: IconType.Dogs }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Foster) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer)
      ? [
          {
            label: "Applications",
            value: "applications",
            icon: IconType.Applications,
          },
        ]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Marketing)
      ? [{ label: "Events", value: "events", icon: IconType.Events }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Report)
      ? [{ label: "Reports", value: "reports", icon: IconType.Reports }]
      : []),
    { label: "Documents", value: "documents", icon: IconType.Documents },
    ...(checkUserRole(loggedInUser!, UserRoles.Admin)
      ? [{ label: "Sources", value: "sources", icon: IconType.Heart }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer) ||
    checkUserRole(loggedInUser!, UserRoles.Foster)
      ? [{ label: "Vets", value: "vets", icon: IconType.Vets }]
      : []),
    {
      label: "People",
      value: "users",
      icon: IconType.Users,
    },
  ];

  return (
    <>
      <Drawer
        anchor="left"
        variant="persistent"
        open={menuOpen}
        sx={{
          "& .MuiPaper-root": {
            border: "none",
            backgroundColor: "transparent",
            marginTop: "105px",
            width: "220px",
            paddingBottom: "25px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: theme.palette.background.default,
            height: "100%",
            border: "none",
            overflow: "scroll",
            paddingBottom: "145px",
          }}
        >
          <Box sx={{ alignSelf: "center", marginTop: "25px", marginBottom: "30px" }}>
            <Typography variant="h2">Admin</Typography>
          </Box>
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              sx={{ width: "100%", gap: 1, paddingX: "20px" }}
              onClick={() => navigate(`/admin/${item.value}`)}
            >
              <IconButton>
                <Icon type={item.icon} color={theme.palette.primary.main} size="30px" />
              </IconButton>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textPrimary">
                    {item.label}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
          <MenuItem
            sx={{ width: "100%", gap: 1, paddingX: "20px", display: { xs: "flex", md: "none" } }}
            onClick={() => navigate("/")}
          >
            <IconButton>
              <Icon type={IconType.Dogs} color={theme.palette.primary.main} size="35px" />
            </IconButton>
            <ListItemText
              primary={
                <Typography variant="body2" color="textPrimary">
                  Marketing Site
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            sx={{ width: "100%", gap: 1, paddingX: "20px", display: { xs: "flex", md: "none" } }}
            onClick={handleProfileRoute}
          >
            <IconButton>
              <Icon type={IconType.User} color={theme.palette.primary.main} size="35px" />
            </IconButton>
            <ListItemText
              primary={
                <Typography variant="body2" color="textPrimary">
                  Profile
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            sx={{ width: "100%", gap: 1, paddingX: "20px", display: { xs: "flex", md: "none" } }}
            onClick={handleLogout}
          >
            <IconButton>
              <LogoutIcon color="primary" sx={{ fontSize: "35px" }} />
            </IconButton>
            <ListItemText
              primary={
                <Typography variant="body2" color="textPrimary">
                  Logout
                </Typography>
              }
            />
          </MenuItem>
        </Box>
      </Drawer>
    </>
  );
};

export default AdminSidebar;
