import { Box, Container, DialogActions, Grid, Typography } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import ActionButton from "../../base/ActionButton";
import { Inputs } from "../../forms";
import * as Yup from "yup";
import {
  getBreedOptions,
  getFees,
  getGenderOptions,
  getShelterSearch,
  getStatusOptions,
  getSubStatus,
} from "../../../slices/options";
import { useDispatch, useSelector } from "../../../store";
import { getUserSearch } from "../../../slices/users";
import theme from "../../../theme";
import Modal from "../../base/Modal";
import AddAdoptionRecord from "../AddAdoptionRecord";
import KeyValue from "../../../types/keyValue";
import { clearAdoption, getAdoption, getAdoptionsSearch } from "../../../slices/adoptions";
import SearchParams from "../../../types/searchParams";
import Dialog from "../../base/Dialog";
import ReturnDogModal from "./ReturnDogModal";
import { editDog, getDogById } from "../../../slices/dogs";
import SnackBarToast from "../../base/SnackbarToast";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";

interface Props {
  initialValues: any;
  handleSubmit: (values: any) => Promise<void>;
  loading: boolean;
  handleCancelBack: () => void;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
  formikRef: React.RefObject<FormikProps<any>>;
  setDirtyForm: React.Dispatch<React.SetStateAction<boolean>>;
  dirtyForm: boolean;
}

const EditCreateDogTab: React.FC<Props> = (props) => {
  const { initialValues, handleSubmit, loading, handleCancelBack, setActiveTab, formikRef, setDirtyForm, dirtyForm } =
    props;
  const dispatch = useDispatch();

  const { status } = useSelector((store) => store.dogs);
  const { subStatus } = useSelector((store) => store.options);
  const { loggedInUser } = useSelector((store) => store.auth);

  const [adoptionWarningModal, setAdoptionWarningModal] = useState(false);
  const [adoptionRecordModal, setAdoptionRecordModal] = useState(false);
  const [removeRecordModal, setRemoveRecordModal] = useState(false);
  const [saveReminder, setSaveReminder] = useState(false);
  // const [disposition, setDisposition] = useState(false);
  const [initialStatus] = useState(initialValues.status);
  const [dogStatus, setDogStatus] = useState(initialValues.status);
  const [filterForm] = useState<SearchParams>({
    query: "",
    filters: [`dog:${initialValues.id}`],
    pageNumber: 1,
    pageSize: 5,
    orderBy: "id",
    orderDirection: "Descending",
  });

  useEffect(() => {
    return () => {
      dispatch(clearAdoption());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getSubStatus({
        filters: !!dogStatus ? [`status:${dogStatus?.id!}`] : [],
      })
    );
  }, [dispatch, dogStatus]);

  const getPendingAdoption = () => {
    if (initialValues.id === 0) {
      setAdoptionWarningModal(true);
    } else {
      dispatch(
        getAdoptionsSearch({
          search: { ...filterForm },
        })
      ).then((result: any) => {
        if (result && result.payload && result.payload.results && result.payload.results.length) {
          if (result.payload.results[0].status.id === 2 || result.payload.results[0].status.id === 1) {
            dispatch(getAdoption(result.payload.results[0].id)).then(() => {
              setAdoptionRecordModal(true);
            });
          } else {
            setAdoptionWarningModal(true);
          }
        } else {
          setAdoptionWarningModal(true);
        }
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("You must include the dog's name").typeError("Required"),
    primaryBreed: Yup.object().required("You must select a primary breed").typeError("Required"),
    secondaryBreed: Yup.object().nullable(),
    alsoKnownAs: Yup.string().nullable(),
    intakeDate: Yup.date().required("You must enter the intake date").typeError("Valid date required"),
    foster: Yup.object().nullable(),
    gender: Yup.object().required("You must select a gender").typeError("Required"),
    approximateBirthdate: Yup.date()
      .required("You must enter the approximate birthday")
      .typeError("Valid date required"),
    adoptionFee: Yup.object().required("You must select an adoption fee").typeError("Required"),
    dogOrigin: Yup.object().required("You must select a dog source").typeError("Required"),
    status: Yup.object().required("You must select a status").typeError("Required"),
    subStatus: Yup.object().nullable().required("Required"),
    dispositionDate: Yup.date()
      .nullable()
      .when("status", {
        is: (val: KeyValue) => !!val && val?.id! === 7,
        then: (schema) => schema.required("Required").typeError("Required"),
      }),
    headline: Yup.string().required("You must include a headline for a dog").typeError("Required"),
    description: Yup.string().required("You must provide a description").typeError("Required"),
    mixedBreed: Yup.boolean(),
    medicalDog: Yup.boolean(),
    featured: Yup.boolean(),
  });

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }) => {
          const handleAdoptionRecordModalClose = () => {
            setFieldValue("status", initialStatus);
            setAdoptionWarningModal(false);
            setAdoptionRecordModal(false);
            setRemoveRecordModal(false);
            dispatch(clearAdoption());
          };
          return (
            <Form
              noValidate
              onChange={() => {
                if (!dirtyForm && initialValues.id > 0) {
                  setDirtyForm(true);
                }
              }}
            >
              {/* Medical Dog */}
              <Grid container sx={{ display: "flex", justifyContent: { md: "space-between", xs: "flex-start" } }}>
                {checkUserRole(loggedInUser!, UserRoles.Admin) && (
                  <Grid item>
                    <Field component={Inputs.Switch} name="featured" label="Feature Dog on Home Page" />
                  </Grid>
                )}
                <Grid item>
                  <Field component={Inputs.Switch} name="medicalDog" label="Medical Dog?" />
                </Grid>
              </Grid>
              {/* Name, breeds */}
              <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <Field name="name" label="Dog Name" required component={Inputs.Text} />
                </Grid>
                <Grid item md={4} xs={12} sx={{ mb: { sm: "8px", md: 0 } }}>
                  <Field
                    name="primaryBreed"
                    label="Primary Breed"
                    required
                    component={Inputs.OptionDropdown}
                    searchFunction={getBreedOptions}
                    filterForm={{ filters: ["primary:1"], pageSize: 50 }}
                    hideLargeResults
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field
                    name="secondaryBreed"
                    label="Secondary Breed"
                    component={Inputs.OptionDropdown}
                    searchFunction={getBreedOptions}
                    filterForm={{ pageSize: 50 }}
                    hideLargeResults
                  />
                </Grid>
              </Grid>
              {/* Aka, gender, adoption fee */}
              <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <Field name="alsoKnownAs" label="Also Known As" component={Inputs.Text} />
                </Grid>
                <Grid item md={4} xs={12} sx={{ mb: { sm: "8px", md: 0 } }}>
                  <Field
                    name="gender"
                    label="Gender"
                    required
                    component={Inputs.OptionDropdown}
                    searchFunction={getGenderOptions}
                  />
                </Grid>
                <Grid item md={4} xs={12} sx={{ mb: { sm: "8px", md: 0 } }}>
                  <Field
                    name="adoptionFee"
                    label="Adoption Fee"
                    required
                    searchFunction={getFees}
                    component={Inputs.OptionDropdown}
                  />
                </Grid>
              </Grid>
              {/* dates, foster */}
              <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <Field
                    name="intakeDate"
                    label="Intake Date"
                    required
                    component={Inputs.Datepicker}
                    handleChange={() => {
                      if (!dirtyForm) {
                        setDirtyForm(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field
                    name="approximateBirthdate"
                    label="Approximate Birth Date"
                    required
                    component={Inputs.Datepicker}
                    handleChange={() => {
                      if (!dirtyForm) {
                        setDirtyForm(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field
                    name="foster"
                    label="Foster"
                    component={Inputs.OptionDropdown}
                    searchFunction={getUserSearch}
                    filterForm={{
                      pageNumber: 1,
                      pageSize: 10,
                      filters: ["role:3"],
                    }}
                  />
                </Grid>
              </Grid>
              {/* shelter, status and sub status */}
              <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <Field
                    name="status"
                    label="Status"
                    required
                    component={Inputs.OptionDropdown}
                    searchFunction={getStatusOptions}
                    onSelected={async (value: KeyValue) => {
                      if (initialValues.status.id !== 3 && value?.id! === 3) {
                        await clearAdoption();
                        await getPendingAdoption();
                      } else if (initialValues.status.id === 3) {
                        if (value?.id !== 3) {
                          setRemoveRecordModal(true);
                        }
                      }
                      setDogStatus(value);
                      setFieldValue("subStatus", null);
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field
                    disabled={!values || !values.status}
                    name="subStatus"
                    label="Sub Status"
                    required
                    component={Inputs.Dropdown}
                    options={subStatus?.results || []}
                  />
                </Grid>
                {values?.status?.id! === 7 && (
                  <Grid item md={4} xs={12}>
                    <Field
                      name="dispositionDate"
                      label="Disposition Date"
                      required
                      component={Inputs.Datepicker}
                      handleChange={() => {
                        if (!dirtyForm) {
                          setDirtyForm(true);
                        }
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={4} xs={12} sx={{ mb: { sm: "8px", md: 0 } }}>
                  <Field
                    name="dogOrigin"
                    label="Source"
                    required
                    component={Inputs.OptionDropdown}
                    searchFunction={getShelterSearch}
                  />
                </Grid>
              </Grid>
              {/* Headline */}
              <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
                <Grid item md={12} xs={12}>
                  <Field name="headline" label="Headline" component={Inputs.Text} required />
                </Grid>
              </Grid>
              {/* description */}
              <Grid container spacing={1} justifyContent="flex-start">
                <Grid item md={12} xs={12}>
                  <Field name="description" label="Description" component={Inputs.TextArea} required />
                </Grid>
              </Grid>
              <Grid item xs={12} marginBottom={1}>
                <Typography variant="h5" color="error" textAlign="center">
                  The following text will be automatically added to all Dog Descriptions: PA kennel license #5310 • 100
                  W Main St Lansdale, PA 19446
                </Typography>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 2,
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: theme.palette.primary.contrastText,
                  paddingY: 2,
                  zIndex: 1000,
                }}
              >
                <ActionButton type="button" text="Cancel" onClick={handleCancelBack} color="secondary" />
                <Inputs.Submit isSubmitting={status === "loading"} text="Save" color="primary" />
              </Box>
              <Dialog
                open={adoptionWarningModal}
                title={
                  initialValues.id === 0
                    ? "This dog can not be set as adopted until you are finished creating it."
                    : "There is no pending adoption record for this dog. Do you want to add an adoption record? Otherwise click cancel and choose a different status."
                }
              >
                <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
                  <ActionButton
                    type="button"
                    color="secondary"
                    text="Cancel"
                    onClick={handleAdoptionRecordModalClose}
                  />
                  {initialValues.id > 0 && (
                    <ActionButton
                      type="button"
                      text={"Add Record"}
                      disabled={status === "loading" ? true : false}
                      onClick={async () => {
                        await dispatch(clearAdoption());
                        await setAdoptionRecordModal(true);
                        await setAdoptionWarningModal(false);
                      }}
                    />
                  )}
                </DialogActions>
              </Dialog>
              <Modal
                open={adoptionRecordModal}
                onClose={() => {
                  setAdoptionRecordModal(false);
                  setFieldValue("status", initialStatus);
                }}
              >
                <AddAdoptionRecord
                  onCancelClose={handleAdoptionRecordModalClose}
                  onSuccessClose={() => {
                    setAdoptionRecordModal(false);
                    setSaveReminder(true);
                  }}
                />
              </Modal>
              <Modal
                open={removeRecordModal}
                onClose={() => {
                  setRemoveRecordModal(false);
                  setFieldValue("status", initialStatus);
                }}
              >
                <ReturnDogModal
                  onSubmit={() => {
                    dispatch(
                      editDog({
                        dog: {
                          ...values,
                          adoptionFee: values.adoptionFee.value
                            ? parseInt(values.adoptionFee.value)
                            : initialValues.adoptionFee,
                        },
                        archiveAdoption: true,
                      })
                    ).then(() => {
                      dispatch(getDogById(initialValues.id));
                      if (setActiveTab) {
                        setActiveTab(1);
                      }
                    });
                  }}
                  onClose={() => {
                    setFieldValue("status", initialStatus);
                    setRemoveRecordModal(false);
                  }}
                />
              </Modal>
              {/* <Modal
                open={disposition}
                onClose={() => {
                  setFieldValue("subStatus", undefined);
                  setDisposition(false);
                }}
              >
                <DispositionDateModal
                  onClose={() => {
                    setFieldValue("subStatus", undefined);
                    setDisposition(false);
                  }}
                  onSubmit={(value) => {
                    setFieldValue("dispositionDate", value.dispositionDate);
                    setDisposition(false);
                  }}
                />
              </Modal> */}
            </Form>
          );
        }}
      </Formik>
      <SnackBarToast
        open={saveReminder}
        message={"Adoption record added! Remember to click the save button!"}
        type="success"
        handleClose={() => setSaveReminder(false)}
        warningText
      />
    </Container>
  );
};

export default EditCreateDogTab;
