import { Instagram } from "@mui/icons-material";
import { Grid, Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import InstagramPostVM from "../../../types/instagramPost";
import theme from "../../../theme";

interface Props {
  post: InstagramPostVM;
}

const InstagramPost: React.FC<Props> = (props) => {
  const { post } = props;

  return (
    <Link to={post.permalink} target={"_blank"}>
      <Grid key={post.id} sx={{ cursor: "pointer" }}>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              height: { md: "15%", xs: "25%" },
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              background: "linear-gradient(0deg, #000000 10%, #ffffff00 100%)",
              paddingBottom: "5px",
            }}
          >
            <Instagram fontSize="large" sx={{ mr: 1, color: theme.palette.background.paper }} />
          </Box>
          <Box
            component={"img"}
            src={post.url}
            sx={{
              aspectRatio: "1 / 1",
              objectFit: "cover",
              width: "100%",
              marginBottom: "-6px",
            }}
          />
        </Box>
      </Grid>
    </Link>
  );
};

export default InstagramPost;
