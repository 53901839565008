import React from "react";
import { Box } from "@mui/material";
import SiteSearchView from "../views/SiteSearchView";

interface Props {}

const SiteSearchPage: React.FC<Props> = (props) => {
  return (
    <Box sx={{ marginBottom: "25px", height: "100%" }}>
      <SiteSearchView />
    </Box>
  );
};

export default SiteSearchPage;
