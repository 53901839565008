import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { useDispatch, useSelector } from "./store";
import SnackBarToast from "./components/base/SnackbarToast";
import { getLoggedInUser } from "./slices/auth";
import { handleErrorToastState, handleSuccessToastState } from "./slices/toast";

interface Props {}

const App: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { errorToastOpen, errorMessage, successMessage, successToastOpen } = useSelector((state) => state.toast);
  const { loggedInUser } = useSelector((state) => state.auth);
  const [loaded, setLoaded] = useState(false);

  const content = useRoutes(routes(!!loggedInUser, loggedInUser?.roles?.length! > 0, loggedInUser));

  const closeErrorToast = () => {
    dispatch(handleErrorToastState(false));
  };

  const closeSuccessToast = () => {
    dispatch(handleSuccessToastState(false));
  };

  useEffect(() => {
    const getUser = async () => {
      await dispatch(getLoggedInUser());
      setLoaded(true);
    };
    getUser();
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackBarToast open={successToastOpen} message={successMessage} type="success" handleClose={closeSuccessToast} />
      <SnackBarToast open={errorToastOpen} message={errorMessage} type="error" handleClose={closeErrorToast} />
      {loaded && content}
    </ThemeProvider>
  );
};

export default App;
