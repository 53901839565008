export enum DateFilter {
  YearToDate = 1,
  MonthToDate,
  LastYear,
  LastMonth,
  OneDay,
  OneWeek,
  OneMonth,
  ThreeMonths,
}
