import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

interface Props {}

const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  padding-top: 100px;
`;

const Background = styled.div`
  background-image: url(${"/static/images/metal.jpg"});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  position: absolute;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
`;
const AuthLayout: React.FC<Props> = (props) => {
  return (
    <Root>
      <Wrapper>
        <Background />
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Wrapper>
    </Root>
  );
};

export default AuthLayout;
