import { AttachMoney, Handshake, MedicalServices } from "@mui/icons-material";
import React from "react";
import Icon, { IconType } from "../components/base/Icon";
import theme from "../theme";
import { useMediaQuery } from "@mui/material";

interface Props {
  id: number;
  dropdown?: boolean;
}

const GetEventIcon: React.FC<Props> = (props) => {
  const { id, dropdown } = props;
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {id === 1 ? (
        <Handshake color={dropdown ? "primary" : "success"} fontSize={sm ? "small" : "medium"} />
      ) : id === 2 ? (
        <AttachMoney color={dropdown ? "primary" : "success"} fontSize={sm ? "small" : "medium"} />
      ) : id === 3 ? (
        <MedicalServices color={dropdown ? "primary" : "success"} fontSize={sm ? "small" : "medium"} />
      ) : id === 4 ? (
        <Icon
          type={IconType.DogsFilled}
          color={dropdown ? theme.palette.primary.main : theme.palette.success.main}
          size={sm ? "1.25rem" : "1.5rem"}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default GetEventIcon;
