import React, { createRef, useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import SearchParams from "../../types/searchParams";
import theme from "../../theme";
import { getEvent, getEvents, searchEvents } from "../../slices/events";
import CalendarView from "./HomeScreen/CalendarView";
import EventDisplay from "./HomeScreen/EventDisplay";
import { AttachMoney, Handshake, MedicalServices, Star } from "@mui/icons-material";
import Icon, { IconType } from "../base/Icon";

interface Props {}

const EventsView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { searchResults, status } = useSelector((store) => store.events);
  const [scheduledFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 3,
    filters: ["status:2", "status:3"],
    orderBy: "start",
    orderDirection: "Ascending",
  });

  useEffect(() => {
    dispatch(searchEvents({ search: { ...scheduledFilterForm } })).then((result: any) => {
      if (result && result.payload && result.payload.results && result.payload.results.length) {
        dispatch(getEvent(result.payload.results[0].id));
      }
    });
    dispatch(getEvents());
  }, [dispatch, scheduledFilterForm]);

  const pageRef = createRef<HTMLDivElement>();

  const handleScroll = () => {
    pageRef.current?.scrollIntoView();
  };

  return (
    <Box>
      {status === "loading" && !searchResults ? (
        <Typography textAlign={"center"} variant="h2">
          Loading events, please wait...
        </Typography>
      ) : (
        <Grid
          container
          sx={{
            paddingBottom: { lg: 5, md: 0 },
            flexDirection: { md: "row", xs: "column" },
            alignItems: { md: "center", xs: "stretch" },
            px: 2,
          }}
          spacing={2}
        >
          <Grid item lg={6} xs={12} ref={pageRef}>
            <EventDisplay />
          </Grid>
          <Grid item lg={6} xs={12}>
            <CalendarView handleScroll={handleScroll} />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                flexDirection: { md: "row", xs: "column" },
              }}
              justifyContent={"space-around"}
              pb={2}
            >
              <Grid item>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    typography: { xs: "caption", sm: "body2", lg: "body1" },
                    gap: 1,
                  }}
                >
                  <Star color="primary" />
                  Featured Event
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    typography: { xs: "caption", sm: "body2", lg: "body1" },
                    gap: 1,
                  }}
                >
                  <Handshake sx={{ color: "#0C7BDC" }} />
                  Meet and Greet
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    typography: { xs: "caption", sm: "body2", lg: "body1" },
                    gap: 1,
                  }}
                >
                  <AttachMoney sx={{ color: "#009E73" }} />
                  HAL Fundraiser
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    typography: { xs: "caption", sm: "body2", lg: "body1" },
                    gap: 1,
                  }}
                >
                  <MedicalServices sx={{ color: "#DC3220" }} />
                  Medical Dog Feature/Fundraiser
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    typography: { xs: "caption", sm: "body2", lg: "body1" },
                    gap: 1,
                  }}
                >
                  <Icon type={IconType.DogsFilled} color="#403f4a" />
                  Other
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default EventsView;
