import React from "react";
import VolunteerView from "../views/VolunteerView";
import { Box } from "@mui/material";

interface Props {}

const VolunteerPage: React.FC<Props> = (props) => {
  return (
    <Box sx={{ marginTop: "24px" }}>
      <VolunteerView />
    </Box>
  );
};

export default VolunteerPage;
