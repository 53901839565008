import React from "react";
import { Grid } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import { Inputs } from "../../forms";
import * as Yup from "yup";
import { getStatesSearch, getTimelines, getWorkSchedule } from "../../../slices/options";
import { useDispatch, useSelector } from "../../../store";
import ApplicantInfo from "../../../types/application/applicantInfo";
import { saveApplicantInfoStep } from "../../../slices/applications";
import getEmailRegex from "../../../helpers/emailRegex";

interface Props {
  formikRef?: React.RefObject<FormikProps<any>>;
}

const ApplicantInfoStep: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const initialState: ApplicantInfo = useSelector((store) => ({
    id: store.applications.myApplication?.applicant?.id || store.auth.loggedInUser?.id,
    firstName: store.applications.myApplication?.applicant?.firstName || store.auth.loggedInUser?.firstName,
    lastName: store.applications.myApplication?.applicant?.lastName || store.auth.loggedInUser?.lastName,
    age: store.applications.myApplication?.applicant?.age
      ? store.applications.myApplication.applicant.age
      : store.auth.loggedInUser?.age === 0
      ? undefined
      : store.auth.loggedInUser?.age,
    line1: store.applications.myApplication?.applicant?.address?.line1 || store.auth.loggedInUser?.address?.line1,
    line2: store.applications.myApplication?.applicant?.address?.line2 || store.auth.loggedInUser?.address?.line2,
    city: store.applications.myApplication?.applicant?.address?.city || store.auth.loggedInUser?.address?.city,
    state: store.applications.myApplication?.applicant?.address?.state || store.auth.loggedInUser?.address?.state,
    postalCode:
      store.applications.myApplication?.applicant?.address?.postalCode || store.auth.loggedInUser?.address?.postalCode,
    email: store.applications.myApplication?.applicant?.email || store.auth.loggedInUser?.email,
    phone: store.applications.myApplication?.applicant?.phone || store.auth.loggedInUser?.phone,
    phone2: store.applications.myApplication?.applicant?.secondaryPhone || store.auth.loggedInUser?.secondaryPhone,
    occupation: store.applications.myApplication?.occupation,
    workSchedule: store.applications.myApplication?.workSchedule,
    adoptionReason: store.applications.myApplication?.adoptionReason,
    adoptionTimeline: store.applications.myApplication?.adoptionTimeline,
  }));

  const handleSubmit = (values: ApplicantInfo) => {
    dispatch(saveApplicantInfoStep(values));
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    age: Yup.number()
      .min(1, "Required")
      .typeError("Required")
      .required("Required")
      .test("noDecimal", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
    line1: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.object().typeError("Delete typed input and select an option").required("Required"),
    postalCode: Yup.string().min(5, "Must be a valid zip code").max(5, "Must be a valid zip code").required("Required"),
    email: Yup.string()
      .email("Must be a valid email")
      .matches(getEmailRegex(), "Must be a valid email")
      .required("Required"),
    phone: Yup.string().min(14, "Phone number is not valid").required("Required"),
    occupation: Yup.string().required("Required"),
    workSchedule: Yup.object().typeError("Delete typed input and select an option").required("Required"),
    adoptionReason: Yup.string().required("Required"),
    adoptionTimeline: Yup.object().typeError("Delete typed input and select an option").required("Required"),
  });

  return (
    initialState && (
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={props.formikRef}
        validateOnMount
      >
        <Form noValidate>
          <Grid container sx={{ paddingTop: "5px" }}>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Text} name="firstName" label="First Name" required />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Text} name="lastName" label="Last Name" required />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Number} name="age" label="Age" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field component={Inputs.Text} name="line1" label="Address Line 1" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field component={Inputs.Text} name="line2" label="Address Line 2" />
            </Grid>
            <Grid item xs={12} md={5}>
              <Field component={Inputs.Text} name="city" label="City" required />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="state"
                label="State"
                component={Inputs.OptionDropdown}
                searchFunction={getStatesSearch}
                required
                filterForm={{ pageSize: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field component={Inputs.Text} name="postalCode" label="Zip Code" required />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Text} name="email" label="Email" required disabled />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Phone} name="phone" label="Primary Phone" required />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field component={Inputs.Phone} name="phone2" label="Secondary Phone" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field component={Inputs.Text} name="occupation" label="Occupation" required />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="workSchedule"
                label="Work Schedule"
                component={Inputs.OptionDropdown}
                searchFunction={getWorkSchedule}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name="adoptionReason" label="Why do you want a dog?" component={Inputs.Text} required />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="adoptionTimeline"
                label="When do you want to adopt?"
                component={Inputs.OptionDropdown}
                searchFunction={getTimelines}
                required
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    )
  );
};

export default ApplicantInfoStep;
