import { InternalAxiosRequestConfig } from "axios";
import Pool from "../UserPool";

const axiosJwtInterceptor = async (config: InternalAxiosRequestConfig) => {
  try {
    // save token
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err: Error, session: any) => {
        const token = session?.idToken?.jwtToken;
        if (err) {
          console.error(err);
        } else if (token) {
          config!.headers!.Authorization = `Bearer ${token}`;
        }
      });
    }
  } catch (error) {}

  return config;
};

export default axiosJwtInterceptor;
