import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import InstagramPost from "./InstagramPost";
import { useDispatch, useSelector } from "../../../store";
import { getInstagramPosts } from "../../../slices/social";

interface Props {}

const InstagramFeed: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { instagramPosts } = useSelector((store) => store.social);

  useEffect(() => {
    dispatch(getInstagramPosts());
  }, [dispatch]);

  return (
    <Grid container direction={"row"} justifyContent={"space-evenly"}>
      {instagramPosts.map((post) => {
        return (
          <Grid item key={post.id} md={3} xs={6}>
            <InstagramPost post={post} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default InstagramFeed;
