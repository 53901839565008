import { Typography, Box, DialogActions } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "../../../store";
import Dropzone from "../../base/Dropzone";
import StyledTable from "../../base/StyledTable";
import Modal from "../../base/Modal";
import UploadView from "../FileUploadView";
import Dialog from "../../base/Dialog";
import ActionButton from "../../base/ActionButton";
import FileVM from "../../../types/fileVM";
import Moment from "react-moment";
import TableActions from "../../base/TableActions";
import { deleteFile, getFilesById, getOneFileById } from "../../../slices/file";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import { handleSuccessToastState, setSuccessMessage } from "../../../slices/toast";
import EmailDocumentView from "../EmailDocumentView";

interface Props {
  applicantId: number;
}

const DocumentView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { fileSearch, newFile, status: fileStatus } = useSelector((store) => store.file);
  const { loggedInUser } = useSelector((store) => store.auth);
  const [uploadModal, setUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);

  const [fileUpload, setFileUpload] = useState<FileVM>({
    id: 0,
    fileType: { id: 6, value: "" },
    entityId: props.applicantId,
    url: "",
    fileName: "",
  });

  useEffect(() => {
    getFiles();
  }, [dispatch]);

  const getFiles = () => {
    dispatch(getFilesById({ entityId: props.applicantId, typeId: 6 }));
  };

  const handleDeleteModal = (id: number) => {
    setDeleteModal(true);
    dispatch(getOneFileById(id));
  };

  const handleSelectFile = (file: File[]) => {
    if (file && file[0] && file[0].name) {
      setFileUpload({ ...fileUpload, upload: file[0], fileName: file[0].name });
      setUploadModal(true);
    }
  };

  const handleEditFile = async (id: number) => {
    const result: any = await dispatch(getOneFileById(id));
    setFileUpload(result.payload);
    setUploadModal(true);
  };

  const handleDelete = (id: number) => {
    dispatch(deleteFile(id)).then(() => {
      getFiles();
      setDeleteModal(false);
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const close = () => {
    setUploadModal(false);
    setFileUpload({ id: 0, fileType: { id: 6, value: "" }, entityId: props.applicantId, url: "", fileName: "" });
  };

  const closeEmail = () => {
    setEmailModal(false);
  };

  const closeEmailSuccess = () => {
    dispatch(handleSuccessToastState(true));
    dispatch(setSuccessMessage("Document successfully sent!"));
    setEmailModal(false);
  };

  const handleEmail = async (id: number) => {
    await dispatch(getOneFileById(id));
    await setEmailModal(true);
  };

  const headers = ["Actions", "Date", "Name", "Type"];

  const rows =
    (!!props.applicantId &&
      fileSearch
        .slice()
        .reverse()
        .map((file: FileVM) => {
          return {
            actions: (
              <TableActions
                id={file.id!}
                handleView={() => {
                  window.open(file.url, "_blank");
                }}
                handleEmailDocument={handleEmail}
                handleEdit={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleEditFile : undefined}
                handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteModal : undefined}
              />
            ),
            uploaded: file.uploadedDate && <Moment format="MM/DD/YYYY">{file.uploadedDate!}</Moment>,
            name: file.fileName,
            type: file.documentType?.value,
          };
        })) ||
    [];

  return (
    <Box sx={{ borderRadius: "25px" }}>
      {checkUserRole(loggedInUser!, UserRoles.Admin) && (
        <Box>
          <Typography textAlign={"center"}>Upload Application Documents</Typography>
          <Dropzone
            onDrop={(file: File[]) => {
              handleSelectFile(file);
            }}
          />
        </Box>
      )}
      {fileSearch.length ? (
        <StyledTable
          headers={headers}
          rows={rows}
          defaultPaging
          paging={true}
          sizeVariant="small"
          title={<Typography variant="body2">Application Documents</Typography>}
          totalRows={fileSearch.length}
        />
      ) : null}
      <Modal open={uploadModal} onClose={close}>
        <UploadView
          onCancel={close}
          upload={fileUpload}
          afterSave={() => {
            getFiles();
            close();
          }}
          submittingStatus={fileStatus === "loading"}
          type="people"
        />
      </Modal>
      <Dialog
        open={deleteModal}
        title={`Are you sure you want to delete ${
          newFile.fileName ? newFile.fileName : `this ${newFile.documentType?.value!}`
        }? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" color="secondary" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={fileStatus === "loading"}
              color="error"
              onClick={() => {
                handleDelete(newFile.id!);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Modal open={emailModal} onClose={closeEmail}>
        <EmailDocumentView
          onCancel={closeEmail}
          onSuccessClose={closeEmailSuccess}
          submittingStatus={fileStatus === "loading"}
          fileIds={[newFile?.id!]}
        />
      </Modal>
    </Box>
  );
};

export default DocumentView;
