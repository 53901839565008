import { Box, Grid, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Inputs } from "../../forms";
import { getRoleOptions, getStatesSearch } from "../../../slices/options";
import { useSelector } from "../../../store";
import * as Yup from "yup";
import ActionButton from "../../base/ActionButton";
import theme from "../../../theme";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import User from "../../../types/user";
import getEmailRegex from "../../../helpers/emailRegex";

interface Props {
  handleSubmit: (values: any) => Promise<void>;
  handleCancelBack: () => void;
}

const EditCreateUserTab: React.FC<Props> = (props) => {
  const { handleSubmit, handleCancelBack } = props;
  const { user, status } = useSelector((store) => store.users);
  const { loggedInUser } = useSelector((store) => store.auth);
  const [roleChanged, setRoleChanged] = useState(false);

  const handleChangedRole = () => {
    setRoleChanged(true);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Must include first name").typeError("Required"),
    lastName: Yup.string().required("Must include last name").typeError("Required"),
    email: Yup.string()
      .email("Must be a valid email")
      .matches(getEmailRegex(), "Must be a valid email")
      .required("Must include email")
      .typeError("Required"),
    phone: Yup.string()
      .min(14, "Must be a valid phone number")
      .required("Must include phone number")
      .typeError("Required"),
    secondaryPhone: Yup.string().nullable(),
    address: Yup.object()
      .nullable()
      .shape({
        line1: Yup.string().nullable(),
        line2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.object().nullable(),
        postalCode: Yup.string().min(5, "Must be a valid zip code").max(5, "Must be a valid zip code").nullable(),
      }),
    roles: Yup.array().nullable(),
    fosterStart: Yup.date().nullable().typeError("Not a valid date"),
    fosterEnd: Yup.date().nullable().typeError("Not a valid date"),
    kennelLicense: Yup.string().nullable(),
    secondaryEmail: Yup.string().email("Must be a valid email").matches(getEmailRegex(), "Must be a valid email"),
    facebookName: Yup.string().nullable(),
  });

  const initialValues: User = {
    ...user,
    address: {
      line1: user.address?.line1 || undefined,
      line2: user.address?.line2 || undefined,
      city: user.address?.city || undefined,
      state: user.address?.state || undefined,
      postalCode: user.address?.postalCode || undefined,
    },
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form noValidate>
          <Grid container spacing={1} justifyContent="center">
            {roleChanged && user && user.id > 0 && (
              <Grid item xs={12} marginBottom={1}>
                <Typography variant="h5" color="error" textAlign="center">
                  Reminder: After changing a user's role, they must log out, clear their cache, and log back in to use
                  the new role.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={checkUserRole(values, UserRoles.Foster) ? 4 : 12}>
              <Field
                name="roles"
                label="Roles"
                searchFunction={getRoleOptions}
                component={Inputs.OptionDropdown}
                multiple
                onClick={handleChangedRole}
              />
            </Grid>
            {checkUserRole(values, UserRoles.Foster) && (
              <>
                <Grid item md={4} xs={12}>
                  <Field name="fosterStart" label="Foster Start" component={Inputs.Datepicker} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Field name="fosterEnd" label="Foster End" component={Inputs.Datepicker} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
            <Grid item md={6} xs={12}>
              <Field name="firstName" label="First Name" required component={Inputs.Text} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field name="lastName" label="Last Name" required component={Inputs.Text} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
            <Grid item md={4} xs={12}>
              <Field name="email" label="Email" disabled={user && user.id > 0} required component={Inputs.Text} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field name="secondaryEmail" label="Secondary Email" component={Inputs.Text} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field name="kennelLicense" label="Kennel License" component={Inputs.Text} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"}>
            <Grid item md={4} xs={12}>
              <Field name="phone" label="Primary Phone" required component={Inputs.Phone} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field name="secondaryPhone" label="Secondary Phone" component={Inputs.Phone} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field name="facebookName" label="Facebook Name" component={Inputs.Text} />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item md={8} xs={12}>
              <Field name="address.line1" label="Address Line 1" component={Inputs.Text} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field name="address.line2" label="Address Line 2" component={Inputs.Text} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-start" alignItems={"center"} mb={4}>
            <Grid item md={4} xs={12}>
              <Field name="address.city" label="City" component={Inputs.Text} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field
                name="address.state"
                label="State"
                component={Inputs.OptionDropdown}
                searchFunction={getStatesSearch}
                filterForm={{ pageSize: 50 }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field name="address.postalCode" label="Zip Code" component={Inputs.Text} />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 2,
              position: "sticky",
              bottom: 0,
              backgroundColor: theme.palette.primary.contrastText,
              paddingY: 2,
              zIndex: 1000,
            }}
          >
            <ActionButton type="button" text={"Cancel"} onClick={handleCancelBack} color="secondary" />
            {checkUserRole(loggedInUser!, UserRoles.Admin) ||
            (checkUserRole(loggedInUser!, UserRoles.Personnel) && loggedInUser?.id !== user.id) ? (
              <Inputs.Submit isSubmitting={status === "loading"} text="Save" color="primary" />
            ) : (
              <></>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditCreateUserTab;
