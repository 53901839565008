import React, { useEffect, useState } from "react";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import { CardActionArea, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../theme";
import FileVM from "../../types/fileVM";
import IconButton from "./IconButton";
import { Delete, Favorite } from "@mui/icons-material";
import VideoPlayer from "./VideoPlayer";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";

interface Props {
  files: FileVM[];
  onPictureClick?: (id: number, url: string) => void;
  onHeartClick?: (id: number, url: string) => void;
  onDeleteClick?: (id: number, url: string) => void;
  onVideoClick?: (video: FileVM) => void;
  mainPhotoId?: number;
  onUpdateFileOrder?: (files: FileVM[]) => void;
}

const DragDropList: React.FC<Props & CarouselProps> = (props) => {
  const { files, onPictureClick, onDeleteClick, onHeartClick, onVideoClick, mainPhotoId, onUpdateFileOrder } = props;

  const [localFiles, setLocalFiles] = useState(files);

  useEffect(() => {
    setLocalFiles(files);
  }, [files.length]);

  const grid = 8;

  const reorder = (list: FileVM[], startIndex: number, endIndex: number) => {
    let result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const updatedResult = result.map((item, index) => {
      return { ...item, order: index + 1 };
    });

    onUpdateFileOrder!(updatedResult);
    setLocalFiles(updatedResult);
  };

  const onDragEnd = (result: { source: any; destination: any }) => {
    if (!result.destination) {
      return;
    }

    reorder(localFiles, result.source.index, result.destination.index);
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const [width, setWidth] = useState(0);

  const handleResize = () => {
    setWidth(document.getElementById("dropzone")?.clientWidth!);
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (width === 0) {
      const interval = setInterval(() => {
        handleResize();
      }, 0);
      return () => clearInterval(interval);
    } else if (width !== document.getElementById("dropzone")?.clientWidth) {
      handleResize();
    }
  }, [width]);

  return (
    <Box sx={{ width: width, pb: 2 }}>
      <Typography variant="h3" textAlign="center">
        Click and drag to rearrange photos
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <Grid
              style={getListStyle(snapshot.isDraggingOver)}
              container
              alignItems={"center"}
              direction={"row"}
              wrap="nowrap"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {localFiles.map((file, index) => (
                <Grid item sx={{ display: "flex", alignItems: "center" }} key={index} padding={1}>
                  <CardActionArea
                    disableRipple
                    sx={{
                      width: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                      height: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                    }}
                  >
                    <Draggable key={file.id} draggableId={file.id?.toString()!} index={index}>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {file.fileType?.id === 1 ||
                          file.fileType?.id === 2 ||
                          file.fileType?.id === 7 ||
                          file.fileType?.id === 10 ? (
                            <Box
                              onClick={() => {
                                if (onPictureClick) {
                                  onPictureClick(file?.id!, file?.url!);
                                }
                              }}
                            >
                              <Box
                                component="img"
                                alt={file.url}
                                src={file.url}
                                sx={{
                                  objectFit: "cover",
                                  height: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                                  width: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                                  borderRadius: { xs: "15px" },
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                objectFit: "cover",
                                height: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                                width: { lg: "90px", md: "75px", sm: "75px", xs: "55px" },
                              }}
                            >
                              <VideoPlayer video={file} onVideoClick={onVideoClick} />
                            </Box>
                          )}
                          {onHeartClick && (file.fileType?.id === 1 || file.fileType?.id === 2) && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: { sm: 0, xs: -5 },
                                left: { sm: 0, xs: -8 },
                                color:
                                  mainPhotoId === file.id
                                    ? theme.palette.error.main
                                    : theme.palette.primary.contrastText,
                                borderRadius: 25,
                              }}
                            >
                              <IconButton
                                icon={<Favorite />}
                                label="Make Main Image"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (onHeartClick) {
                                    onHeartClick(file?.id!, file?.url!);
                                  }
                                }}
                                size={"10px"}
                              />
                            </Box>
                          )}
                          {onDeleteClick && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: { sm: 0, xs: -5 },
                                right: { sm: 0, xs: -10 },
                                color: theme.palette.primary.contrastText,
                                borderRadius: 25,
                              }}
                            >
                              <IconButton
                                icon={<Delete color={"error"} />}
                                label="Delete"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (onDeleteClick) {
                                    onDeleteClick(file?.id!, file.url);
                                  }
                                }}
                                size={"10px"}
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Draggable>
                  </CardActionArea>
                </Grid>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Box>
  );
};

export default DragDropList;
