import { Box, Modal as ModalContainer } from "@mui/material";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = (props) => {
  const { open, onClose, children } = props;
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>{children}</Box>
    </ModalContainer>
  );
};

export default Modal;
