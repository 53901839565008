import React from "react";
import { FieldProps, useFormikContext } from "formik";
import { Box, SwitchProps } from "@mui/material";
import Switch from "../base/Switch";

interface Props {
  label: string;
  onChange?: (checked: boolean) => void;
}

const FormikSwitch: React.FC<FieldProps & SwitchProps & Props> = (props) => {
  const formikProps = useFormikContext();

  const { field, label, disabled, ...rest } = props;

  const handleChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    formikProps.setFieldValue(props.field.name, checked);
    props.onChange?.(checked);
  };

  return (
    <Box sx={{ paddingBottom: "24px" }}>
      <Switch onChange={handleChange} checked={!!field.value} label={label} disabled={disabled} />
    </Box>
  );
};

export default FormikSwitch;
