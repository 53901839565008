import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import getSearchQueryString from "../helpers/getSearchQueryString";
import KeyValue from "../types/keyValue";
import SearchParams from "../types/searchParams";
import SearchResults from "../types/searchResults";
import User from "../types/user";
import ApplicationSimple from "../types/applicationSimple";

interface UsersState {
  status: "idle" | "loading" | "failed";
  users: User[];
  user: User;
  userSearch?: SearchResults;
}

const initialState: UsersState = {
  status: "idle",
  users: [],
  user: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    secondaryPhone: "",
    address: {},
    active: true,
    roles: [],
    favoriteDogs: [],
    password: "",
  },
};

export const getUsers = createAsyncThunk<User[]>("/users/get", async () => {
  const response = await http.get<User[]>(`/users`);
  return response.data;
});

export const getUserId = createAsyncThunk<User, number>("/users/id", async (id: number) => {
  const response = await http.get<User>(`/users/${id}`);
  return response.data;
});

export const getUserSearch = createAsyncThunk<SearchResults, SearchParams>(
  "users/search",
  async (search: SearchParams) => {
    let qs = getSearchQueryString(search);
    const response = await http.get<SearchResults>(`/users/search?${qs}`);
    return response.data;
  }
);

export const deleteUser = createAsyncThunk<User, number>("/user/delete", async (id: number) => {
  const response = await http.delete<User>(`/users/${id}`);
  return response.data;
});

export const updateUser = createAsyncThunk<User, User>("user/edit", async (model: User) => {
  const response = await http.put<User>(`/users/${model.id}`, model);
  return response.data;
});

export const updateUserRoles = createAsyncThunk<User, { id: number; roles: KeyValue[] }>(
  "user/roles",
  async ({ id, roles }) => {
    const response = await http.patch(`/users/${id}/roles`, roles);
    return response.data;
  }
);

export const toggleActive = createAsyncThunk<null, { id: number; active: boolean }>(
  "users/toggle-active",
  async ({ id, active }) => {
    const response = await http.patch(`/users/${id}`, active);
    return response.data;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUserValues(state) {
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = "idle";
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserId.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(getUserId.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.status = "idle";
        state.user = initialState.user;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(updateUser.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUserSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.userSearch = action.payload;
      })
      .addCase(getUserSearch.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { resetUserValues } = usersSlice.actions;

export default usersSlice.reducer;
