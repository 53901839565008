import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { Instagram, Facebook } from "@mui/icons-material";
import theme from "../../theme";
import DonateButton from "../base/DonateButton";
import Icon, { IconType } from "../base/Icon";

interface Props {
  includeDonate: boolean;
}

const Footer: React.FC<Props> = (props) => {
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.success.main,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: "40px",
          width: "100%",
          paddingY: "1px",
          zIndex: 5000,
        }}
      >
        <Typography variant="caption">PA kennel license #5310 • 100 W Main St Lansdale, PA 19446</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.info.main,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          position: "fixed",
          bottom: 0,
          width: "100%",
          paddingY: "2px",
          zIndex: 5000,
          height: "40px",
        }}
      >
        <Box sx={{ marginLeft: 2, display: "flex", flexDirection: "row" }}>
          <Typography variant="h5" color="primary">
            COPYRIGHT 2024
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2, marginRight: 1 }}>
          <Link
            to="https://www.tiktok.com/@homeatlastdogrescue?_t=8dbZZxiwFsE&_r=1"
            target={"_blank"}
            style={{ marginBottom: "5px" }}
          >
            <Icon type={IconType.TikTok} color={theme.palette.primary.main} size="15px" />
          </Link>
          <Link
            to="https://www.instagram.com/homeatlastdogrescue/?hl=en"
            target={"_blank"}
            style={{ marginBottom: "-5px" }}
          >
            <Instagram color="primary" />
          </Link>
          <Link to="https://www.facebook.com/HomeAtLastDogRescuePA" target={"_blank"} style={{ marginBottom: "-5px" }}>
            <Facebook color="primary" />
          </Link>
          {props.includeDonate && <DonateButton size="small" />}
        </Box>
      </Box>
    </>
  );
};

export default Footer;
