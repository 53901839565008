import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "../../theme";
import ActionButton from "./ActionButton";
import Icon, { IconType } from "./Icon";

interface Props {
  page?: number;
  rowsPerPage?: number;
  changePage?: (page: number, pageSize: number) => void;
  totalRows?: number;
  headers: string[];
  rows: any[];
  paging?: boolean;
  defaultPaging?: boolean;
  sortOptions?: (e: MouseEvent) => void;
  sizeVariant: "small" | "large";
  title?: React.ReactNode;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const tablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginLeft: "20px",
        gap: 1,
        width: "100%",
      }}
    >
      <Box>
        <ActionButton
          type="button"
          text="Previous"
          color="secondary"
          disabled={page === 0}
          onClick={handleBackButtonClick}
        />
      </Box>
      <Box>
        <ActionButton
          type="button"
          text="Next"
          color="primary"
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={handleNextButtonClick}
        />
      </Box>
    </Box>
  );
};

const StyledTable: React.FC<Props> = (props) => {
  const [page, setPage] = useState(props.page ? props.page - 1 : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.defaultPaging ? 5 : props.rowsPerPage ? props.rowsPerPage : 10
  );

  const small = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (props.page) {
      setPage(props.page! - 1);
    }
  }, [props.page]);

  useEffect(() => {
    if (props.rowsPerPage) {
      setRowsPerPage(props.rowsPerPage!);
    }
  }, [props.rowsPerPage]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.changePage) {
      props.changePage(newPage + 1, rowsPerPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.changePage) {
      props.changePage(1, parseInt(event.target.value, 10));
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <TableContainer sx={{ borderRadius: props.sizeVariant === "small" ? "8px" : 0 }}>
        <Table>
          <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
            {props.title && (
              <TableRow>
                <TableCell colSpan={12} sx={{ paddingY: "10px", color: "#fff" }}>
                  {props.title}
                </TableCell>
              </TableRow>
            )}
            <TableRow sx={{ backgroundColor: props.sizeVariant === "small" ? theme.palette.secondary.main : "" }}>
              {props.headers.map((header, index) => (
                <TableCell
                  key={header}
                  align="center"
                  sx={{
                    maxWidth: "100px",
                    paddingTop: "2px;",
                    paddingBottom: "2px",
                    borderRight:
                      props.sizeVariant === "small"
                        ? `1px solid ${theme.palette.primary.main}`
                        : `2px solid ${theme.palette.background.default}`,
                    borderBottom: props.sizeVariant === "small" ? `1px solid ${theme.palette.primary.main}` : `none`,
                    "&:last-child": { borderRight: 0, width: props.sizeVariant === "small" ? "100px" : "25px" },
                  }}
                >
                  <Typography
                    component={"span"}
                    variant={props.sizeVariant === "small" ? "h5" : "body2"}
                    id={header}
                    color={props.sizeVariant === "small" ? "primary" : "secondary"}
                    onClick={(e: any) => {
                      if ((props.sortOptions && header.includes("↑")) || header.includes("↓")) {
                        props.sortOptions!(e);
                      }
                    }}
                    sx={{
                      cursor: header.includes("↑") ? "pointer" : header.includes("↓") ? "pointer" : "default",
                    }}
                  >
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 && props.rows?.length > 0 && !props.changePage
              ? props.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : props.rows
            )?.map((row, key) => (
              <TableRow
                key={key}
                sx={{
                  backgroundColor:
                    props.sizeVariant === "small"
                      ? key % 2
                        ? theme.palette.secondary.main
                        : theme.palette.background.paper
                      : theme.palette.success.main,
                  "&:hover": {
                    "& .highlightIcon": {
                      display: props.sizeVariant === "large" ? "block" : "none",
                    },
                  },
                }}
              >
                {Object.values(row).map((value: any, key) => (
                  <TableCell
                    key={key}
                    align="center"
                    sx={{
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      whiteSpace: "nowrap",
                      borderRight:
                        props.sizeVariant === "small"
                          ? `1px solid ${theme.palette.primary.main}`
                          : `2px solid ${theme.palette.primary.main}`,
                      borderBottom:
                        props.sizeVariant === "small"
                          ? `1px solid ${theme.palette.primary.main}`
                          : `2px solid ${theme.palette.primary.main}`,
                      "&:last-child": { borderRight: 0 },
                    }}
                  >
                    {key === 0 && (
                      <Box className="highlightIcon" sx={{ display: "none", position: "absolute", marginLeft: -2 }}>
                        <Icon size="25px" type={IconType.DogsFilled}></Icon>
                      </Box>
                    )}
                    <Typography
                      component={"span"}
                      variant={props.sizeVariant === "large" ? "h5" : "caption"}
                      color={theme.palette.primary.main}
                    >
                      {value}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {props.paging ? (
          <Box>
            {props.defaultPaging || small ? (
              <TablePagination
                sx={{
                  backgroundColor: props.sizeVariant === "small" ? theme.palette.background.default : "",
                  height: "50px",
                  padding: 0,
                  margin: 0,
                }}
                component="div"
                count={props.totalRows || (props.rows && props.rows.length)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={
                  props.sizeVariant === "small" ? [5] : [10, 25, 50, { label: "All", value: props.totalRows! || -1 }]
                }
              />
            ) : (
              <TablePagination
                component="div"
                count={props.totalRows || (props.rows && props.rows.length)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, { label: "All", value: props.totalRows! || -1 }]}
                ActionsComponent={tablePaginationActions}
              />
            )}
          </Box>
        ) : (
          <></>
        )}
      </TableContainer>
    </Box>
  );
};

export default StyledTable;
