import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import getSearchQueryString from "../helpers/getSearchQueryString";
import SearchParams from "../types/searchParams";
import SearchResults from "../types/searchResults";
import EventVM from "../types/event";
import KeyValue from "../types/keyValue";
import FileVM from "../types/fileVM";

interface EventsState {
  status: "idle" | "loading" | "failed";
  searchResults?: SearchResults;
  event: EventVM;
  events: EventVM[];
  photo: FileVM;
  eventTypes: KeyValue[];
}

const initialState: EventsState = {
  status: "idle",
  event: {
    id: 0,
    name: "",
    address: {},
    photos: [],
    details: "",
    featured: false,
    status: { id: 3, value: "" },
  },
  photo: {
    fileType: {
      id: 2,
      value: "",
    },
    url: "",
  },
  events: [],
  eventTypes: [],
};

export const getEvents = createAsyncThunk<EventVM[]>("events-get", async () => {
  const response = await http.get<EventVM[]>(`/events`);
  return response.data;
});

export const postEvent = createAsyncThunk<EventVM, EventVM>("events-post", async (e: EventVM) => {
  const response = await http.post<EventVM>(`/events`, e);
  return response.data;
});

export const editEvent = createAsyncThunk<EventVM, EventVM>("events-edit", async (e: EventVM) => {
  const response = await http.put<EventVM>(`/events/${e.id!}`, e);
  return response.data;
});

export const getEvent = createAsyncThunk<EventVM, number>("events-get-id", async (id: number) => {
  const response = await http.get<EventVM>(`/events/${id}`);
  return response.data;
});

export const deleteEvent = createAsyncThunk<null, number>("events-delete", async (id: number) => {
  const response = await http.delete(`/events/${id}`);
  return response.data;
});

export const searchEvents = createAsyncThunk<
  SearchResults,
  {
    search: SearchParams;
    start?: Date;
    end?: Date;
  }
>("events-search", async ({ search, start, end }) => {
  let qs = getSearchQueryString(search);
  let date = "";

  function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  date += !!start && isValidDate(start) ? `&start=${start.toUTCString()}` : "";
  date += !!end && isValidDate(end) ? `&end=${end.toUTCString()}` : "";

  const response = await http.get<SearchResults>(`/events/search?${qs}${date}`);
  return response.data;
});

export const getEventTypes = createAsyncThunk<KeyValue[]>("events/types", async () => {
  const response = await http.get("/events/types");
  return response.data;
});

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    clearEvents: (state) => {
      state.events = [];
      state.event = { ...initialState.event };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.status = "idle";
        state.events = action.payload;
      })
      .addCase(getEvents.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(postEvent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postEvent.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(postEvent.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editEvent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editEvent.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(editEvent.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getEvent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.status = "idle";
        state.event = action.payload;
      })
      .addCase(getEvent.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteEvent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteEvent.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(searchEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchEvents.fulfilled, (state, action) => {
        state.status = "idle";
        state.searchResults = action.payload;
      })
      .addCase(searchEvents.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getEventTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getEventTypes.fulfilled, (state, action) => {
        state.status = "idle";
        state.eventTypes = action.payload;
      })
      .addCase(getEventTypes.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { clearEvents } = eventsSlice.actions;

export default eventsSlice.reducer;
