import { Button, Typography } from "@mui/material";
import React from "react";
import { debounce } from "lodash";

interface Props {
  color?: "primary" | "secondary" | "success" | "error" | "warning";
  disabled?: boolean;
  text: string;
  onClick?: (e?: any) => void;
  small?: boolean;
  type: "button" | "reset";
  fullWidth?: boolean;
}

const ActionButton: React.FC<Props> = (props) => {
  const { color, disabled, text, onClick, small, type, fullWidth } = props;

  const handleClick = debounce((e?: any) => {
    onClick!(e);
  }, 500);

  return (
    <Button
      sx={{
        height: small ? "25px" : "35px",
        width: fullWidth ? "100%" : "auto",
        paddingX: small ? "10px" : "20px",
        borderRadius: small ? "5px" : "10px",
      }}
      onClick={handleClick}
      color={color ? color : "primary"}
      size="small"
      type={type}
      variant="contained"
      disabled={disabled}
    >
      <Typography variant={small ? "body2" : "button"}>{text}</Typography>
    </Button>
  );
};

export default ActionButton;
