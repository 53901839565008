import React from "react";
import TitleBar from "../base/TitleBar";
import AdminUsersTable from "../views/AdminUsersTable";

interface Props {}

const UsersPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="People" />
      <AdminUsersTable />
    </>
  );
};

export default UsersPage;
