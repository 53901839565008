import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { editDog, getDogById, getDogSimpleById, postNewDog } from "../../slices/dogs";
import { getFilesById } from "../../slices/file";
import FileVM from "../../types/fileVM";
import { getUserSearch } from "../../slices/users";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  getBreedOptions,
  getGenderOptions,
  getFees,
  getStatusOptions,
  getShelterSearch,
  getMicrochipSearch,
} from "../../slices/options";
import ReadOnlyDog from "./EditCreateDog/ReadOnlyDog";
import KeyValue from "../../types/keyValue";
import Tabs from "../base/Tabs";
import Tab from "../base/Tab";
import EditCreateDogTab from "./EditCreateDog/EditCreateDogTab";
import DogPicturesTab from "./EditCreateDog/DogPicturesTab";
import DogNoteTab from "./EditCreateDog/DogNoteTab";
import DogAdoptionApplicationTab from "./EditCreateDog/DogAdoptionApplicationTab";
import DogDocumentTab from "./EditCreateDog/DogDocumentTab";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import {
  Box,
  CircularProgress,
  Container,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import theme from "../../theme";
import { editAdoption, getAdoption, getAdoptionsSearch } from "../../slices/adoptions";
import SearchParams from "../../types/searchParams";
import { FormikProps } from "formik";
import Dialog from "../base/Dialog";
import ActionButton from "../base/ActionButton";
import AdditionalDogInfoTab from "./EditCreateDog/AdditionalDogInfoTab";

interface Props {}

const EditCreateDogView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const tab = queryParams.get("tab");
  const { id } = useParams();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { dog, status } = useSelector((store) => store.dogs);
  const { fees } = useSelector((store) => store.options);
  const { adoption } = useSelector((store) => store.adoptions);
  const infoFormRef = useRef<FormikProps<any>>(null);
  const [loading, setLoading] = useState(true);
  const [viewImage, setViewImage] = useState("");
  const [dirtyForm, setDirtyForm] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [nextTab, setNextTab] = useState(0);
  const { state } = useLocation();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [activeTab, setActiveTab] = useState(
    dog.id > 0 &&
      state?.edit === true &&
      (checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory))
      ? 2
      : !!tab
      ? +tab
      : 1
  );
  const [filterForm] = useState<SearchParams>({
    query: "",
    filters: [`dog:${dog.id}`],
    pageNumber: 1,
    pageSize: 5,
    orderBy: "id",
    orderDirection: "Descending",
  });

  const getMainPhoto = (photos: FileVM[]) => {
    photos.map((photo) => {
      if (photo.id === dog.mainPhotoId) {
        setViewImage(photo.url);
      }
    });
  };

  const setViewPhoto = (id: number) => {
    dog.photos.map((photo) => {
      if (photo.id === id) {
        setViewImage(photo.url);
      }
    });
  };

  useEffect(() => {
    dispatch(getBreedOptions({ query: "" }));
    dispatch(getGenderOptions({ query: "" }));
    dispatch(getFees({ query: "" }));
    dispatch(getUserSearch({ query: "", filters: ["role:3"] }));
    dispatch(getShelterSearch({ query: "" }));
    dispatch(getMicrochipSearch({ query: "" }));
    dispatch(getStatusOptions({ query: "" }));
    dispatch(getFilesById({ entityId: +id!, typeId: 1 }));
    if (dog.id !== +id!) {
      dispatch(getDogById(+id!)).then(() => {
        dispatch(getFilesById({ entityId: +id!, typeId: 1 }));
      });
      dispatch(getDogSimpleById(+id!));
    }
  }, [dispatch]);

  const initialValues = {
    ...dog,
    adoptionFee: fees?.results.find((fee: KeyValue) => `${dog.adoptionFee}.00` === fee.value),
  };

  const updatePendingAdoption = () => {
    dispatch(
      getAdoptionsSearch({
        search: { ...filterForm },
      })
    ).then((result: any) => {
      if (result && result.payload && result.payload.results && result.payload.results.length) {
        if (result.payload.results[0].status.id === 2 || result.payload.results[0].status.id === 1) {
          dispatch(getAdoption(result.payload.results[0].id)).then((result: any) => {
            dispatch(
              editAdoption({
                ...result.payload,
                applicationId: adoption.applicationId,
                status: { id: 2, value: "Pending" },
              })
            );
          });
        }
      }
    });
  };

  const updateCompletedAdoption = () => {
    dispatch(
      getAdoptionsSearch({
        search: { ...filterForm },
      })
    ).then((result: any) => {
      if (result && result.payload && result.payload.results && result.payload.results.length) {
        if (result.payload.results[0].status.id === 2 || result.payload.results[0].status.id === 1) {
          dispatch(getAdoption(result.payload.results[0].id)).then((result: any) => {
            dispatch(
              editAdoption({
                ...result.payload,
                applicationId: adoption.applicationId,
                status: { id: 3, value: "Completed" },
              })
            );
          });
        }
      }
    });
  };

  const handleSubmit = async (values: any) => {
    if (dog.id === 0) {
      await dispatch(
        postNewDog({
          ...values,
          adoptionFee: parseInt(values.adoptionFee.value),
        })
      ).then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          dispatch(handleSuccessToastState(true));
          dispatch(setSuccessMessage("Dog successfully added!"));
          dispatch(getDogById(result.payload.id)).then(() => {
            navigate(`/admin/dog/${result.payload.id}`);
            dispatch(getDogSimpleById(result.payload.id));
            setActiveTab(3);
          });
        }
      });
    } else {
      await dispatch(
        editDog({
          dog: {
            ...values,
            adoptionFee: values.adoptionFee.value ? parseInt(values.adoptionFee.value) : dog.adoptionFee,
            intakeDate: values.intakeDate ? values.intakeDate : dog?.intakeDate,
            approximateBirthdate: values.approximateBirthdate ? values.approximateBirthdate : dog?.approximateBirthdate,
            ageGroup: {},
            notes: [],
          },
        })
      ).then((response: any) => {
        if (response.meta.requestStatus === "fulfilled") {
          if (values.status.id === 3 && values.subStatus.id === 3) {
            if (adoption.id! < 1) {
              updateCompletedAdoption();
            } else {
              dispatch(editAdoption({ ...adoption, applicationId: adoption.applicationId }));
            }
          } else if (values.status.id === 3 && values.subStatus.id !== 3) {
            updatePendingAdoption();
          }
          dispatch(handleSuccessToastState(true));
          dispatch(setSuccessMessage("Dog successfully updated!"));
          dispatch(getDogById(dog.id));
          setActiveTab(1);
          setDirtyForm(false);
        }
      });
    }
  };

  const handleCancelBack = async () => {
    setLoading(true);
    await dispatch(getDogById(dog.id));
    navigate("/admin/dogs");
    await setLoading(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (dirtyForm) {
      setWarningModal(true);
      setNextTab(parseInt(event.target.value));
    } else {
      setActiveTab(parseInt(event.target.value));
    }
  };

  const dogTabs = [
    {
      id: 1,
      value: "Summary",
      element: (
        <ReadOnlyDog
          viewImage={viewImage}
          getMainPhoto={getMainPhoto}
          setViewPhoto={setViewPhoto}
          handleCancelBack={handleCancelBack}
        />
      ),
    },
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
      ? [
          {
            id: 2,
            value: "Dog Info",
            element: (
              <EditCreateDogTab
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                loading={loading}
                handleCancelBack={handleCancelBack}
                setActiveTab={setActiveTab}
                formikRef={infoFormRef}
                setDirtyForm={setDirtyForm}
                dirtyForm={dirtyForm}
              />
            ),
          },
          {
            id: 3,
            value: "Additional Info",
            element: (
              <AdditionalDogInfoTab
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                loading={loading}
                handleCancelBack={handleCancelBack}
                setActiveTab={setActiveTab}
                formikRef={infoFormRef}
                setDirtyForm={setDirtyForm}
                dirtyForm={dirtyForm}
              />
            ),
          },
          {
            id: 4,
            value: "Pictures",
            element: <DogPicturesTab viewImage={viewImage} setViewImage={setViewImage} getMainPhoto={getMainPhoto} />,
          },
        ]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Foster) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer) ||
    checkUserRole(loggedInUser!, UserRoles.Inventory)
      ? [{ id: 5, value: "Notes", element: <DogNoteTab /> }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Foster) ||
    checkUserRole(loggedInUser!, UserRoles.Inventory) ||
    checkUserRole(loggedInUser!, UserRoles.Document) ||
    checkUserRole(loggedInUser!, UserRoles.Volunteer)
      ? [{ id: 6, value: "Documents", element: <DogDocumentTab dogId={+id!} /> }]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Foster)
      ? [{ id: 7, value: "Adoption Info", element: <DogAdoptionApplicationTab id={+id!} /> }]
      : []),
  ];

  const continueTabSwitch = () => {
    setActiveTab(nextTab);
    setWarningModal(false);
    setDirtyForm(false);
  };

  const saveAndContinue = async () => {
    await infoFormRef.current?.submitForm();
    continueTabSwitch();
  };

  return (
    <>
      {status === "loading" || !dog ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" size="60px" />
        </Box>
      ) : dog.id > 0 && md ? (
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          dirty={dirtyForm}
          setWarningModal={setWarningModal}
          setNextTab={setNextTab}
        >
          {dogTabs.map((tab) => (
            <Tab label={tab.value} key={tab.id}>
              {tab.element}
            </Tab>
          ))}
        </Tabs>
      ) : dog.id > 0 && !md ? (
        <>
          <Container>
            {dogTabs.length > 1 && (
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel sx={{ color: theme.palette.primary.main }}>Options</InputLabel>
                <Select
                  value={activeTab.toString()}
                  label="Options"
                  onChange={handleChange}
                  sx={{
                    color: theme.palette.primary.main,
                    background: theme.palette.background.paper,
                    borderColor: theme.palette.primary.main,
                    borderWidth: "2px",
                    borderRadius: "10px",
                  }}
                >
                  {dogTabs.map((tab) => (
                    <MenuItem value={tab.id} key={tab.id}>
                      {tab.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Container>
          {activeTab === 1 && (
            <ReadOnlyDog
              viewImage={viewImage}
              getMainPhoto={getMainPhoto}
              setViewPhoto={setViewPhoto}
              handleCancelBack={handleCancelBack}
            />
          )}
          {activeTab === 2 && (
            <EditCreateDogTab
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              loading={loading}
              handleCancelBack={handleCancelBack}
              setActiveTab={setActiveTab}
              formikRef={infoFormRef}
              setDirtyForm={setDirtyForm}
              dirtyForm={dirtyForm}
            />
          )}
          {activeTab === 3 && (
            <AdditionalDogInfoTab
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              loading={loading}
              handleCancelBack={handleCancelBack}
              setActiveTab={setActiveTab}
              formikRef={infoFormRef}
              setDirtyForm={setDirtyForm}
              dirtyForm={dirtyForm}
            />
          )}
          {activeTab === 4 && (
            <DogPicturesTab viewImage={viewImage} setViewImage={setViewImage} getMainPhoto={getMainPhoto} />
          )}
          {activeTab === 5 && <DogNoteTab />}
          {activeTab === 6 && <DogDocumentTab dogId={+id!} />}
          {activeTab === 7 && <DogAdoptionApplicationTab id={+id!} />}
        </>
      ) : (
        <>
          {checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory) ? (
            <EditCreateDogTab
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              loading={loading}
              handleCancelBack={handleCancelBack}
              formikRef={infoFormRef}
              setDirtyForm={setDirtyForm}
              dirtyForm={dirtyForm}
            />
          ) : null}
        </>
      )}
      <Dialog open={warningModal} title="You have unsaved changes. Do you want to save before continuing?" warning>
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" color="error" text="No" onClick={continueTabSwitch} />
            <ActionButton
              type="button"
              text={"Yes"}
              disabled={status === "loading" ? true : false}
              onClick={saveAndContinue}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditCreateDogView;
