import Address from "../types/address";
import { isIOS, isSafari } from "react-device-detect";

const getAddressLink = (address: Address) => {
  const destination = encodeURIComponent(`${address.line1} ${address.line2} ${address.postalCode}, ${address.city}`);
  if (isIOS || isSafari) {
    return `http://maps.apple.com/?address=${destination}`;
  } else {
    return `http://maps.google.com/?daddr=${destination}`;
  }
};

export default getAddressLink;
