import React, { createRef, useEffect, useState } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import InstagramFeed from "./InstagramFeed";
import VideoBanner from "./VideoBanner";
import TextHeader from "../../base/TextHeader";
import theme from "../../../theme";
import VideoBackground from "./VideoBackground";

interface Props {}

const HomeView: React.FC<Props> = (props) => {
  const lg = useMediaQuery(theme.breakpoints.up("md"));
  const eventRef = createRef<HTMLDivElement>();

  const useIsVisible = (ref: any) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      if (lg && ref.current) {
        const observer = new IntersectionObserver(([entry]) => {
          setShow(entry.isIntersecting);
        });

        observer.observe(ref.current);
      }
    }, [ref]);
    return show;
  };

  return (
    <>
      <VideoBackground useIsVisible={useIsVisible} eventRef={eventRef} />
      {/* <VideoBanner useIsVisible={useIsVisible} eventRef={eventRef} /> */}
      <Grid container>
        <Grid item xs={12}>
          <TextHeader text="Ready to adopt? Click here!" link="/dogs" />
        </Grid>
        <Grid item xs={12} sx={{}}>
          <InstagramFeed />
        </Grid>
        <Grid item xs={12} sx={{}} ref={eventRef}>
          <TextHeader text="Click to see upcoming events!" link="/events" />
        </Grid>
      </Grid>
    </>
  );
};

export default HomeView;
