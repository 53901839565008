import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../forms";
import { getStatesSearch } from "../../slices/options";
import * as Yup from "yup";
import User from "../../types/user";
import ActionButton from "../base/ActionButton";
import { updateProfile } from "../../slices/auth";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import { getUserId } from "../../slices/users";
import UserProfileTables from "./UserProfileTables";
import getEmailRegex from "../../helpers/emailRegex";

interface Props {}

const UserProfileView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { loggedInUser, status } = useSelector((store) => store.auth);
  const { user } = useSelector((store) => store.users);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserId(loggedInUser?.id!));
    dispatch(getStatesSearch({ query: "" }));
  }, [dispatch, loggedInUser]);

  const handleSubmit = (values: User) => {
    dispatch(
      updateProfile({
        ...values,
        favoriteDogs: [],
        roles: [],
        address:
          values.address?.line1 && values.address?.city && values.address?.state && values.address?.postalCode
            ? {
                line1: values.address.line1,
                line2: values.address.line2 ? values.address.line2 : "",
                city: values.address.city,
                state: values.address.state,
                postalCode: values.address.postalCode,
              }
            : undefined,
      })
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(handleSuccessToastState(true));
        dispatch(setSuccessMessage("Profile Updated Successfully"));
      }
    });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().nullable().required("Required").typeError("Required"),
    lastName: Yup.string().nullable().required("Required").typeError("Required"),
    email: Yup.string()
      .nullable()
      .email("Must be a valid email")
      .matches(getEmailRegex(), "Must be a valid email")
      .required("Required")
      .typeError("Required"),
    phone: Yup.string().min(14, "Must be a valid phone number").nullable().required("Required").typeError("Required"),
    age: Yup.number()
      .nullable()
      .min(1, "Required")
      .required("Required")
      .typeError("Required")
      .test("noDecimal", "Whole numbers only", (value) => !/[.]/.test(value.toString())),
    address: Yup.object()
      .shape({
        line1: Yup.string().nullable(),
        line2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.object().nullable(),
        postalCode: Yup.string().min(5, "Must be a valid zip code").max(5, "Must be a valid zip code"),
      })
      .nullable(),
  });

  return (
    <Box>
      <Box sx={{ mt: "10px", py: 1, marginBottom: 3 }}>
        <Typography variant="h1" color="primary" textAlign={"center"}>
          My Profile
        </Typography>
      </Box>
      <Container>
        {!!user && !!user.firstName ? (
          <Formik validationSchema={validationSchema} initialValues={user} onSubmit={handleSubmit} enableReinitialize>
            <Form noValidate>
              <Grid container alignItems="center" justifyContent={"center"} sx={{ paddingBottom: 1 }}>
                <Grid item md={4} sm={6} xs={12}>
                  <Field name="firstName" label="First Name" component={Inputs.Text} secondary />
                </Grid>
                <Grid item md={4} sm={6} xs={12} sx={{}}>
                  <Field name="lastName" label="Last Name" component={Inputs.Text} secondary />
                </Grid>
                <Grid item sm={2} md={4} xs={12} sx={{}}>
                  <Field name="age" label="Age" component={Inputs.Number} secondary />
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{}}>
                  <Field name="email" label="Email Address" disabled component={Inputs.Text} secondary />
                </Grid>
                <Grid item md={6} sm={4} xs={12} sx={{}}>
                  <Field name="phone" label="Phone Number" component={Inputs.Phone} secondary />
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{}}>
                  <Field name="address.line1" label="Address Line 1" component={Inputs.Text} secondary />
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{}}>
                  <Field name="address.line2" label="Address Line 2" component={Inputs.Text} secondary />
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{}}>
                  <Field name="address.city" label="City" component={Inputs.Text} secondary />
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ marginTop: 1 }}>
                  <Field
                    name="address.state"
                    label="State"
                    component={Inputs.OptionDropdown}
                    secondary
                    searchFunction={getStatesSearch}
                    filterForm={{ pageSize: 50 }}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{}}>
                  <Field name="address.postalCode" label="Zip Code" component={Inputs.Text} secondary />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 2,
                  position: "sticky",
                  top: 0,
                  paddingBottom: 2,
                }}
              >
                <ActionButton
                  text="Cancel"
                  onClick={() => {
                    navigate("/home");
                  }}
                  color="warning"
                  type="button"
                />
                <Inputs.Submit isSubmitting={status === "loading"} text="Save" color="primary" />
              </Box>
            </Form>
          </Formik>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="primary" size="60px" />
          </Box>
        )}
        <Box marginY={3}>
          <UserProfileTables userId={loggedInUser?.id!} />
        </Box>
      </Container>
    </Box>
  );
};

export default UserProfileView;
