import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { editEvent, getEvent, postEvent } from "../../../slices/events";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import EventVM from "../../../types/event";
import { Box, Container, Grid } from "@mui/material";
import theme from "../../../theme";
import ActionButton from "../../base/ActionButton";
import { Inputs } from "../../forms";
import { getStatesSearch } from "../../../slices/options";
import { createVet, editVet, getVet } from "../../../slices/vet";
import Vet from "../../../types/vet";
import getEmailRegex from "../../../helpers/emailRegex";

interface Props {}

const EditCreateVet: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vet, status } = useSelector((store) => store.vet);
  const [loading, setLoading] = useState(true);
  const editMode = window.location.href.includes("?edit=true");
  const { id } = useParams();

  useEffect(() => {
    if (vet?.id !== +id!) {
      dispatch(getVet(+id!));
    }
  }, [dispatch, id, vet?.id]);

  const validationSchema = Yup.object().shape(
    {
      clinicName: Yup.string()
        .nullable()
        .when("vetName", {
          is: undefined,
          then: (schema) => schema.required("Either Clinic or Vet Name is required"),
        }),
      vetName: Yup.string()
        .nullable()
        .when("clinicName", {
          is: undefined,
          then: (schema) => schema.required("Either Clinic or Vet Name is required"),
        }),
      mainPhone: Yup.string().min(14, "Must be a valid phone number").nullable().nullable(),
      email: Yup.string().email("Must be a valid email").matches(getEmailRegex(), "Must be a valid email").nullable(),
      contactName: Yup.string().nullable(),
      address: Yup.object()
        .shape({
          line1: Yup.string().nullable(),
          line2: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.object().nullable(),
          postalCode: Yup.string().min(5, "Must be a valid zip code").max(5, "Must be a valid zip code").nullable(),
        })
        .nullable(),
    },
    [["clinicName", "vetName"]]
  );

  const handleSubmit = async (values: Vet) => {
    if (vet?.id === 0) {
      await dispatch(
        createVet({
          ...values,
          address:
            values.address &&
            values.address.line1 &&
            values.address.city &&
            values.address.state &&
            values.address.postalCode
              ? {
                  line1: values.address.line1,
                  line2: values.address.line2 ? values.address.line2 : "",
                  city: values.address.city,
                  state: values.address.state,
                  postalCode: values.address.postalCode,
                }
              : undefined,
        })
      ).then((result: any) => {
        if (result && result.payload) {
          dispatch(getVet(result.payload.id));
          navigate(`/admin/vets`);
        }
      });
    } else if (vet?.id! > 0) {
      await dispatch(editVet(values));
      setLoading(true);
      navigate(`/admin/vets`);
      setLoading(false);
    }
  };

  const handleCancelBack = async () => {
    navigate("/admin/vets");
  };

  return (
    <>
      <Container>
        <Formik
          enableReinitialize={loading}
          initialValues={vet!}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate>
              {editMode ? (
                <>
                  <Grid container spacing={1} direction="row" justifyContent="center" alignItems={"center"}>
                    <Grid item xs={12}>
                      <Field
                        name="clinicName"
                        label="Clinic Name"
                        required={!values.contactName}
                        component={Inputs.Text}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="vetName"
                        label="Veterinarian Name"
                        required={!values.clinicName}
                        component={Inputs.Text}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field name="contactName" label="Main Contact" component={Inputs.Text} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field name="mainPhone" label="Main Phone Number" component={Inputs.Phone} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field name="email" label="Email" component={Inputs.Text} />
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Field name="address.line1" label="Address Line 1" component={Inputs.Text} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field name="address.line2" label="Address Line 2" component={Inputs.Text} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field name="address.city" label="City" component={Inputs.Text} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="address.state"
                        label="State"
                        component={Inputs.OptionDropdown}
                        searchFunction={getStatesSearch}
                        filterForm={{ pageSize: 50 }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field name="address.postalCode" label="Zip Code" component={Inputs.Text} />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 2,
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: theme.palette.primary.contrastText,
                  paddingY: 2,
                  zIndex: 1000,
                }}
              >
                <ActionButton
                  text={editMode ? "Cancel" : "Back"}
                  onClick={handleCancelBack}
                  color="secondary"
                  type="button"
                />
                {editMode ? (
                  <Inputs.Submit isSubmitting={status === "loading"} text="Save" color="primary" />
                ) : (
                  <ActionButton
                    text="Edit"
                    onClick={() => {
                      navigate(`/admin/vets/${vet?.id}?edit=true`);
                    }}
                    color="primary"
                    type="button"
                  />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default EditCreateVet;
