import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";

interface SearchState {
  searchString: string;
}

const initialState: SearchState = {
  searchString:"",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchString(state, action){
        state.searchString = action.payload
    }
  },
  extraReducers: (builder) => {},
});

export default searchSlice.reducer;
export const { setSearchString } = searchSlice.actions;

