import React from "react";
import TitleBar from "../base/TitleBar";
import ReportsView from "../views/ReportsView";

interface Props {}

const ReportsPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Reports" />
      <ReportsView />
    </>
  );
};

export default ReportsPage;
