import { Box, Container } from "@mui/material";
import React from "react";
import theme from "../../theme";
import IconButton from "./IconButton";
import Icon, { IconType } from "./Icon";

interface Props {
  children: Array<any>;
  activeImage: number;
  setActiveImage: React.Dispatch<React.SetStateAction<number>>;
}

const BannerControl: React.FC<Props> = (props) => {
  const { activeImage, setActiveImage } = props;

  const handleImageChange = (tabNumber: number) => {
    setActiveImage(tabNumber);
  };

  return (
    <Box>
      <Box>{props.children[activeImage - 1]}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {props.children.map((step, index) => {
          return (
            <React.Fragment key={index}>
              <IconButton
                icon={
                  <Icon
                    type={IconType.DogsFilled}
                    color={
                      activeImage === index + 1 ? theme.palette.background.paper : `${theme.palette.background.paper}50`
                    }
                    size="30px"
                  />
                }
                label=""
                onClick={() => {
                  handleImageChange(index + 1);
                }}
                hideTooltip
              />
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default BannerControl;
