import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Container, DialogActions, Grid, Typography } from "@mui/material";
import SearchParams from "../../types/searchParams";
import { deleteFile, getOneFileById, searchFiles } from "../../slices/file";
import FileVM from "../../types/fileVM";
import Moment from "react-moment";
import TableActions from "../base/TableActions";
import StyledTable from "../base/StyledTable";
import Search from "../base/Search";
import { debounce } from "lodash";
import Dialog from "../base/Dialog";
import ActionButton from "../base/ActionButton";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import Dropzone from "../base/Dropzone";
import Modal from "../base/Modal";
import UploadView from "./FileUploadView";
import EmailDocumentView from "./EmailDocumentView";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import { setDocumentFilter } from "../../slices/file";

interface Props {}

const DocumentsView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { fileSearchResults, newFile, status: fileStatus, documentFilter } = useSelector((store) => store.file);
  const [deleteModal, setDeleteModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [fileUpload, setFileUpload] = useState<FileVM>({
    id: 0,
    fileType: { id: 4, value: "" },
    entityId: 0,
    url: "",
    fileName: "",
  });

  const handleSelectFile = (file: File[]) => {
    if (file && file[0] && file[0].name) {
      setFileUpload({ ...fileUpload, upload: file[0], fileName: file[0].name });
      setUploadModal(true);
    }
  };

  useEffect(() => {
    dispatch(searchFiles({ ...documentFilter }));
  }, [dispatch, documentFilter]);

  const handleDeleteModal = (id: number) => {
    setDeleteModal(true);
    dispatch(getOneFileById(id));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteFile(id)).then(() => {
      dispatch(searchFiles({ ...documentFilter }));
      setDeleteModal(false);
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const updateSearch = debounce((query: string) => {
    dispatch(setDocumentFilter({ ...documentFilter, pageNumber: 1, pageSize: 10, query: query }));
  }, 250);

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setDocumentFilter({ ...documentFilter, pageNumber: pageNumber, pageSize: pageSize }));
  };

  const close = () => {
    setFileUpload({ id: 0, fileType: { id: 4, value: "" }, entityId: 0, url: "", fileName: "" });
    setUploadModal(false);
  };

  const closeEmail = () => {
    setEmailModal(false);
  };

  const closeEmailSuccess = () => {
    dispatch(handleSuccessToastState(true));
    dispatch(setSuccessMessage("Document successfully sent!"));
    setEmailModal(false);
  };

  const handleEmail = async (id: number) => {
    await dispatch(getOneFileById(id));
    await setEmailModal(true);
  };

  const handleDownload = (id: number) => {
    dispatch(getOneFileById(id)).then((result) => {
      if (result && result.payload) {
        let file = result.payload as FileVM;
        let a = document.createElement("a");
        a.href = file.url;
        a.download = file.fileName!;
        a.click();
        a.remove();
      }
    });
  };

  const headers = ["Actions", "Date", "Name", "Type"];

  const rows =
    (!!fileSearchResults?.results &&
      fileSearchResults?.results.map((file: FileVM) => {
        return {
          actions: (
            <TableActions
              id={file.id!}
              handleView={() => {
                window.open(file.url, "_blank");
              }}
              handleDownload={
                file.id! > 0 && file.fileName?.toLowerCase() !== "electronic adoption contract"
                  ? handleDownload
                  : undefined
              }
              handleDelete={
                checkUserRole(loggedInUser!, UserRoles.Admin) &&
                file.id! > 0 &&
                file.fileName?.toLowerCase() !== "electronic adoption contract"
                  ? handleDeleteModal
                  : undefined
              }
              handleEmailDocument={
                file.id! > 0 && file.fileName?.toLowerCase() !== "electronic adoption contract"
                  ? handleEmail
                  : undefined
              }
            />
          ),
          uploaded: file.uploadedDate && <Moment format="MM/DD/YYYY">{file.uploadedDate!}</Moment>,
          name: file.fileName,
          type: file.fileType?.value,
        };
      })) ||
    [];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: -1 }}>
      <Container>
        <Grid container spacing={1} alignItems="center" justifyContent={"center"} sx={{ paddingBottom: 1 }}>
          <Grid item xs={12}>
            <Search label="Search" handleChange={updateSearch} value={documentFilter.query} />
          </Grid>
        </Grid>
        {checkUserRole(loggedInUser!, UserRoles.Admin) ? (
          <Box>
            <Dropzone
              onDrop={(file: File[]) => {
                handleSelectFile(file);
              }}
            />
          </Box>
        ) : null}
      </Container>
      <Box margin={1}>
        {fileStatus === "loading" && !fileSearchResults ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            {fileStatus === "loading" && (
              <Typography variant="h2" sx={{ textAlign: "center", marginY: fileStatus === "loading" ? 0 : "29px" }}>
                Loading, please wait...
              </Typography>
            )}
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={documentFilter.pageNumber}
                rowsPerPage={documentFilter.pageSize}
                totalRows={fileSearchResults?.totalResults}
                changePage={changePage}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No documents to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you want to delete ${
          newFile.fileName ? newFile.fileName : `this ${newFile.documentType?.value!}`
        }? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" color="secondary" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={fileStatus === "loading" ? true : false}
              color="error"
              onClick={() => {
                handleDelete(newFile.id!);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Modal open={uploadModal} onClose={close}>
        <UploadView
          adminDocuments
          onCancel={close}
          upload={fileUpload}
          afterSave={() => {
            dispatch(searchFiles({ ...documentFilter }));
            close();
          }}
          submittingStatus={fileStatus === "loading"}
          type="none"
        />
      </Modal>
      <Modal open={emailModal} onClose={closeEmail}>
        <EmailDocumentView
          onCancel={closeEmail}
          onSuccessClose={closeEmailSuccess}
          submittingStatus={fileStatus === "loading"}
          fileIds={[newFile?.id!]}
        />
      </Modal>
    </Box>
  );
};

export default DocumentsView;
