import { createSlice } from "@reduxjs/toolkit";

interface ToastState {
  errorToastOpen: boolean;
  errorMessage: string;
  successToastOpen: boolean;
  successMessage: string;
}

const initialState: ToastState = {
  errorToastOpen: false,
  errorMessage: "",
  successToastOpen: false,
  successMessage: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    handleErrorToastState: (state, action) => {
      state.errorToastOpen = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    handleSuccessToastState: (state, action) => {
      state.successToastOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
  },
  extraReducers: () => {},
});

export default toastSlice.reducer;
export const { handleErrorToastState, setErrorMessage, handleSuccessToastState, setSuccessMessage } =
  toastSlice.actions;
