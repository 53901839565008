import React from "react";
import TitleBar from "../base/TitleBar";
import DocumentsView from "../views/DocumentsView";

interface Props {}

const DocumentsPage: React.FC<Props> = (props) => {
  return (
    <>
      <TitleBar title="Documents" />
      <DocumentsView />
    </>
  );
};

export default DocumentsPage;
