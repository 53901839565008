import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { deleteNote, getNotes, postNotes } from "../../../slices/notes";
import { useDispatch, useSelector } from "../../../store";
import Note from "../../../types/note";
import NoteUpload from "../../base/NoteUpload";
import NoteDisplay from "../NoteDisplay";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";

interface Props {}

const ApplicationNotes: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { adminApplication } = useSelector((store) => store.applications);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { notes } = useSelector((store) => store.notes);

  useEffect(() => {
    dispatch(getNotes({ entityId: adminApplication?.applicant?.id!, typeId: 3 }));
  }, [dispatch, adminApplication?.applicant?.id]);

  const addNote = (note: string) => {
    const newNote: Note = {
      id: 0,
      value: note,
      addedByUser: { ...loggedInUser!, address: undefined },
      addedDate: new Date(),
      noteType: { id: 3, value: "" },
      entityId: adminApplication?.applicant?.id,
    };
    dispatch(postNotes(newNote)).then(() => {
      dispatch(getNotes({ entityId: adminApplication?.applicant?.id!, typeId: 3 }));
    });
  };

  const removeNote = async (value: Note) => {
    await dispatch(deleteNote(value.id));
    await dispatch(getNotes({ entityId: adminApplication?.applicant?.id!, typeId: 3 }));
  };

  return (
    <Container sx={{ marginY: 3 }}>
      {(checkUserRole(loggedInUser!, UserRoles.Admin) ||
        checkUserRole(loggedInUser!, UserRoles.Foster) ||
        checkUserRole(loggedInUser!, UserRoles.Volunteer)) && (
        <NoteUpload onNoteAdd={(note: string) => addNote(note)} />
      )}
      <Grid container direction={"column"}>
        {notes &&
          notes.length > 0 &&
          notes.map((note, index) => {
            return (
              <React.Fragment key={index}>
                <NoteDisplay
                  note={note}
                  onDelete={() => removeNote(note)}
                  onNoteEdit={() => dispatch(getNotes({ entityId: adminApplication?.applicant?.id!, typeId: 3 }))}
                />
              </React.Fragment>
            );
          })}
      </Grid>
    </Container>
  );
};

export default ApplicationNotes;
