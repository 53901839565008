import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";

interface FormikSubmitButtonProps {
  isSubmitting: boolean;
  text?: string;
  disabled?: boolean;
  color?: "primary" | "secondary";
}

const FormikSubmitButton: React.FC<FormikSubmitButtonProps> = (props) => {
  const { isSubmitting, text, color } = props;

  return (
    <Button
      sx={{
        height: "35px",
        paddingX: "20px",
        borderRadius: "10px",
      }}
      color={color ? color : "primary"}
      size="small"
      type="submit"
      variant="contained"
      disabled={isSubmitting || props.disabled}
    >
      <Typography variant="button">{text ?? "Submit"}</Typography>
      {isSubmitting && <CircularProgress size={20} sx={{ ml: 5 }} />}
    </Button>
  );
};

export default FormikSubmitButton;
