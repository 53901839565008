import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import theme from "../../theme";
import { Link } from "react-router-dom";

interface Props {}

const AdoptionProcessView: React.FC<Props> = (props) => {
  const numberedLists = [
    {
      title: "Apply",
      items: [
        "To help us determine if a dog is a good fit for your family, you must submit an adoption application. If you are a new to our site, you will be asked to set up an account.",
        "Required info includes 2 personal (not family members) references. It's best to use people who have seen you interact with or care for dogs. We also require vet and/or landlord contact info if applicable.",
        "Please keep in mind that providing honest answers to all the questions will help us determine if a dog is a good fit for your family.",
        "You will receive email confirmation after you submit your application. If you do not get this email, your application did not go through so please apply again",
      ],
    },
    {
      title: "Review",
      items: [
        "We review each application to decide which home best suits our dog's needs. Applications are not given priority based on the order they are received. Completing an application is not a guarantee that you will be selected as the adopter of the dog you apply for.",
        "We call your personal references as well as veterinarian and/or landlord if applicable. All your current pets [dogs/cats] must be spayed / neutered and up to date on required vaccinations & preventatives. These checks are performed to confirm that you will be a responsible dog parent who will provide a safe home and appropriate care including routine vetting. During your home visit, in addition to assessing the suitability of your living environment, we observe the HAL dog's behavior, especially around any children and/or other pets you may have.",
        "Before an adoption can be finalized everyone living in your home must meet the dog you want to adopt.",
        "At any point in the approval process Home At Last reserves the right to reject an application. Even though your application may be completely processed it is not a guarantee that you will be selected as the adopter of the dog you've applied for, nor does it give you automatic approval to adopt a different HAL dog. Home At Last reserves the right to process your application again when you move your interest to another dog.",
      ],
    },
  ];

  return (
    <Grid container direction={"column"} alignItems={"center"}>
      <Grid item>
        <Grid container direction={"row"} pb={5}>
          <Grid item xs={12}>
            <Box sx={{ marginTop: 2, marginBottom: 5 }}>
              <Typography textAlign={"center"} variant="h1">
                So You Want To Adopt Your New Best Friend?
              </Typography>
            </Box>
          </Grid>

          {/* Left Side */}
          <Grid item md={7} sm={12} sx={{ paddingX: { lg: 10, xs: 3 } }}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item sx={{ mb: 3, display: { md: "none", xs: "flex" } }} alignSelf={"center"}>
                <Box
                  component="img"
                  alt={`dog sitting in a basket`}
                  src="/static/images/adoption-process.jpeg"
                  sx={{
                    objectFit: "cover",
                    height: { sm: "300px", xs: "200px" },
                    width: { sm: "400px", xs: "300px" },
                    borderRadius: "25px",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  At{" "}
                  <Box fontWeight={"bold"} display={"inline"} component={"span"}>
                    Home At Last Dog Rescue (HAL){" "}
                  </Box>
                  our goal is to make the best match taking into consideration the dog's background and your family's
                  needs. Every dog is different: personality, temperament, energy, comfort with people and other
                  animals, medical needs and more. We take all these factors into consideration when making a
                  furever-home match. We are very careful about the placements we make. We want the next stop on a dog's
                  journey to be its permanent home.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                  Unfortunately, we cannot hold a dog for a future adoption date. Holding a dog in a foster home means
                  we have less space available for other dogs in life-or-death situations that need our immediate help.
                  If you are interested in a specific dog that is ready for adoption, you must be prepared to adopt in
                  the next few days.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Right Side */}
          <Grid item md={5} sm={12} sx={{ paddingX: { lg: 10, md: 7, xs: 5 }, display: { md: "flex", xs: "none" } }}>
            <Grid container direction={"column"}>
              <Grid item sx={{ mb: 5 }}>
                <Box
                  component="img"
                  alt={`dog sitting in a basket`}
                  src="/static/images/adoption-process.jpeg"
                  sx={{
                    objectFit: "cover",
                    height: { lg: "400px", md: "425px" },
                    width: "100%",
                    borderRadius: "25px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          alignSelf: "stretch",
          mx: { md: 10, sm: 5, xs: 3 },
        }}
        mb={5}
        pb={2}
      >
        <Typography textAlign={"center"} variant="h1">
          Our Process
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction={"column"} alignItems={"center"}>
          {numberedLists.map((listItem, index) => {
            return (
              <Grid item sx={{ px: { md: 10, sm: 5, xs: 3 } }} mb={10} key={index}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      padding: 1,
                      borderRadius: 2,
                      marginBottom: "-25px",
                      zIndex: 100,
                      alignSelf: "center",
                    }}
                  >
                    <Typography color={theme.palette.primary.contrastText} variant="h2" textAlign={"center"}>
                      {listItem.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingX: { md: 5, xs: 3 },
                      borderRadius: 5,
                      paddingY: 3,
                    }}
                  >
                    <ol>
                      {listItem.items.map((item) => (
                        <li>
                          <Typography my={3}>{item}</Typography>
                        </li>
                      ))}
                    </ol>
                  </Box>
                </Box>
              </Grid>
            );
          })}
          <Grid item sx={{ px: { md: 10, sm: 5, xs: 3 }, width: "100%" }} mb={10}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: 1,
                  borderRadius: 2,
                  marginBottom: "-25px",
                  zIndex: 100,
                  alignSelf: "center",
                }}
              >
                <Typography color={theme.palette.primary.contrastText} variant="h2" textAlign={"center"}>
                  Finalization Of Adoption
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingX: 5,
                  borderRadius: 5,
                  paddingY: 3,
                }}
              >
                <Typography
                  textAlign={"center"}
                  sx={{
                    marginBlockStart: "1em",
                  }}
                >
                  To finalize an adoption, you will sign our adoption contract and medical addendum if there's an
                  existing medical condition. Additionally, you'll pay an adoption fee which is necessary to cover
                  expenses like spaying/neutering, routine vetting, vaccinating, and transporting our dogs.
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{
                    marginBlockStart: "1em",
                  }}
                >
                  The adoption fee for a dog between the ages of 8 weeks & 2 years is $450. For a dog more than 2 years
                  old the fee is $400. Some of our dogs are designated as benefactor dogs and have a $650 adoption fee.
                  A portion of this higher fee helps fund expensive surgeries for medical dogs that we rescue. Each
                  dog's fee is notated in its bio.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sx={{ px: { md: 10, sm: 5, xs: 3 } }} mb={5}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: 1,
                  borderRadius: 2,
                  marginBottom: "-25px",
                  zIndex: 100,
                  alignSelf: "center",
                }}
              >
                <Typography color={theme.palette.primary.contrastText} variant="h2" textAlign={"center"}>
                  Additional Information
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingX: { md: 5, xs: 3 },
                  borderRadius: 5,
                  paddingY: 3,
                }}
              >
                <ul>
                  <li style={{ marginBottom: 15 }}>
                    To help reduce the number of unwanted animals, our dogs are sterilized prior to adoption unless
                    there is a medical reason that this surgery can't be done at this time.
                  </li>
                  <li style={{ marginTop: 15, marginBottom: 15 }}>
                    At the time of adoption your HAL dog will be current on a well checkup and required vaccinations &
                    preventatives, microchipped, and heart worm tested.
                  </li>
                  <li style={{ marginTop: 15, marginBottom: 15 }}>
                    Ready to move forward with the adoption process? To apply, click{" "}
                    <Link to="/dogs">Adoptable Dogs</Link> above and select the dog you'd like to adopt.
                  </li>
                  <li style={{ marginTop: 15 }}>
                    Please note, if you are interested in multiple dogs, you can include their names at the end of the
                    application. There is no need to submit multiple applications.
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdoptionProcessView;
