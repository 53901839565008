import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import {
  Box,
  CircularProgress,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { register } from "../../slices/auth";
import ActionButton from "../base/ActionButton";
import { getUserId, updateUser, updateUserRoles } from "../../slices/users";
import { Container } from "@mui/system";
import TableActions from "../base/TableActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRoleOptions, getStatesSearch } from "../../slices/options";
import ReadOnlyUser from "./EditCreateUser/ReadOnlyUser";
import { deleteDog, getDogById, getDogsSearch } from "../../slices/dogs";
import StyledTable from "../base/StyledTable";
import SearchParams from "../../types/searchParams";
import Dialog from "../base/Dialog";
import DogAdoptionRecord from "./DogAdoptionRecord";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import UserDocumentTable from "./EditCreateUser/UserDocumentTable";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import EditCreateUserTab from "./EditCreateUser/EditCreateUserTab";
import Tabs from "../base/Tabs";
import Tab from "../base/Tab";
import UserNoteTab from "./EditCreateUser/UserNoteTab";
import theme from "../../theme";
import UserAdoptionApplicationTab from "./UserAdoptionTab";

interface Props {}

const EditCreateUser: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, status } = useSelector((store) => store.users);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { dogSearch, dog } = useSelector((store) => store.dogs);
  const { state } = useLocation();
  const { id } = useParams();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [activeTab, setActiveTab] = useState(
    user.id > 0 &&
      state?.edit === true &&
      (checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel))
      ? 2
      : 1
  );
  const [filterForm, setFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
    orderBy: "name",
    orderDirection: "Ascending",
    query: "",
    filters: [`foster:${user.id}`, "status:1", "status:2", "status:8", "status:9"],
  });

  useEffect(() => {
    if (user.id !== +id!) {
      dispatch(getUserId(+id!));
    }
  }, [dispatch, id, user.id]);

  useEffect(() => {
    dispatch(getDogsSearch({ search: filterForm }));
  }, [dispatch, filterForm]);

  useEffect(() => {
    dispatch(getStatesSearch({ query: "" }));
    dispatch(getRoleOptions({ query: "" }));
  }, [dispatch]);

  const handleSubmit = async (values: any) => {
    if (user.id === 0) {
      await dispatch(
        register({
          ...values,
          id: 0,
          active: true,
          roles: [],
          favoriteDogs: [],
          password: "",
          address: {
            line1: values.address.line1,
            line2: values.address.line2,
            city: values.address.city,
            state: values.address.state,
            postalCode: values.address.postalCode,
          },
        })
      ).then((result: any) => {
        if (values.roles.length > 0) {
          dispatch(updateUserRoles({ id: result.payload.id, roles: values.roles })).then(() => {
            dispatch(getUserId(result.payload.id));
          });
        }
        if (result.meta.requestStatus === "fulfilled") {
          dispatch(handleSuccessToastState(true));
          dispatch(setSuccessMessage("Person created successfully"));
        }
        navigate(`/admin/user/${result.payload.id}`);
        setActiveTab(1);
      });
    } else if (user.id > 0) {
      await dispatch(
        updateUser({
          ...values,
          address: {
            line1: values.address.line1,
            line2: values.address.line2,
            city: values.address.city,
            state: values.address.state,
            postalCode: values.address.postalCode,
          },
        })
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(handleSuccessToastState(true));
          dispatch(setSuccessMessage("Person updated successfully"));
        }
      });
      setActiveTab(1);
      await dispatch(updateUserRoles({ id: user.id, roles: values.roles }));
      await dispatch(getUserId(user.id));
    }
  };

  const handleDeleteOpen = (id: number) => {
    setDeleteModal(true);
    dispatch(getDogById(id));
    setDeleteId(id);
  };
  const handleDeleteDog = async (id: number) => {
    await dispatch(deleteDog(id));
  };

  const cancelDelete = () => {
    setDeleteModal(false);
    setDeleteId(0);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setActiveTab(parseInt(event.target.value));
  };

  const headers = ["Actions", "ID", "Dog Name", "Breed", "Status"];

  const rows =
    dogSearch?.results.map((dog) => {
      return {
        actions: (
          <TableActions
            handleEdit={
              checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                ? () => {
                    dispatch(getDogById(dog.id)).then(() => {
                      navigate(`/admin/dog/${dog.id}`, { state: { edit: true } });
                    });
                  }
                : undefined
            }
            handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
            handleView={() => {
              dispatch(getDogById(dog.id)).then(() => {
                navigate(`/admin/dog/${dog.id}`, { state: { edit: false } });
              });
            }}
            id={dog.id}
          />
        ),
        id: (
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {dog.id}
          </Typography>
        ),
        dogName: dog.name,
        breed: dog.primaryBreed.value,
        status: dog.status.value,
      };
    }) || [];

  const handleCancelBack = () => {
    navigate("/admin/users");
  };

  const changePage = (pageNumber: number, pageSize: number) => {
    setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
  };

  const userTabs = [
    {
      id: 1,
      value: "Summary",
      element: <ReadOnlyUser />,
    },
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel)
      ? [
          {
            id: 2,
            value: "Edit Info",
            element: <EditCreateUserTab handleSubmit={handleSubmit} handleCancelBack={handleCancelBack} />,
          },
        ]
      : []),
    ...(dogSearch?.results?.length! > 0
      ? [
          {
            id: 3,
            value: "Foster Dogs",
            element: (
              <Box sx={{ marginBottom: 5 }}>
                <StyledTable
                  headers={headers}
                  rows={rows}
                  defaultPaging
                  paging={true}
                  page={filterForm.pageNumber}
                  rowsPerPage={filterForm.pageSize}
                  totalRows={dogSearch?.totalResults}
                  changePage={changePage}
                  sizeVariant="small"
                  title={<Typography variant="body2">Foster Dogs</Typography>}
                />
              </Box>
            ),
          },
        ]
      : []),
    ...(checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel)
      ? [
          {
            id: 4,
            value: "Documents",
            element: (
              <Box sx={{ marginBottom: 5 }}>
                <UserDocumentTable />
              </Box>
            ),
          },
          {
            id: 5,
            value: "Applications / Adoptions",
            element: (
              <Box sx={{ marginBottom: 5 }}>
                <UserAdoptionApplicationTab id={user?.id} />
              </Box>
            ),
          },
        ]
      : []),
    ...(loggedInUser?.id !== user.id &&
    (checkUserRole(loggedInUser!, UserRoles.Admin) ||
      checkUserRole(loggedInUser!, UserRoles.Foster) ||
      checkUserRole(loggedInUser!, UserRoles.Inventory) ||
      checkUserRole(loggedInUser!, UserRoles.Personnel) ||
      checkUserRole(loggedInUser!, UserRoles.Volunteer))
      ? [
          {
            id: 6,
            value: "Notes",
            element: <UserNoteTab />,
          },
        ]
      : []),
  ];

  return (
    <>
      <Container>
        {user.id > 0 && md ? (
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
            {userTabs.map((tab) => (
              <Tab label={tab.value} key={tab.id}>
                {tab.element}
              </Tab>
            ))}
          </Tabs>
        ) : user.id > 0 && !md ? (
          <>
            {userTabs.length > 1 && (
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel sx={{ color: theme.palette.primary.main }}>Options</InputLabel>
                <Select
                  value={activeTab.toString()}
                  label="Options"
                  onChange={handleChange}
                  sx={{
                    color: theme.palette.primary.main,
                    background: theme.palette.background.paper,
                    borderColor: theme.palette.primary.main,
                    borderWidth: "2px",
                    borderRadius: "10px",
                  }}
                >
                  {userTabs.map((tab) => (
                    <MenuItem value={tab.id} key={tab.id}>
                      {tab.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {activeTab === 1 && <ReadOnlyUser />}
            {activeTab === 2 && <EditCreateUserTab handleSubmit={handleSubmit} handleCancelBack={handleCancelBack} />}
            {activeTab === 3 && (
              <Box sx={{ marginBottom: 5 }}>
                <StyledTable
                  headers={headers}
                  rows={rows}
                  defaultPaging
                  paging={true}
                  page={filterForm.pageNumber}
                  rowsPerPage={filterForm.pageSize}
                  totalRows={dogSearch?.totalResults}
                  changePage={changePage}
                  sizeVariant="small"
                  title={<Typography variant="body2">Foster Dogs</Typography>}
                />
              </Box>
            )}
            {activeTab === 4 && (
              <Box sx={{ marginBottom: 5 }}>
                <UserDocumentTable />
              </Box>
            )}
            {activeTab === 5 && (
              <Box sx={{ marginBottom: 5 }}>
                <DogAdoptionRecord id={user?.id!} type="user" />
              </Box>
            )}
            {activeTab === 6 && <UserNoteTab />}
          </>
        ) : (
          <>
            {checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Personnel) ? (
              <EditCreateUserTab handleSubmit={handleSubmit} handleCancelBack={handleCancelBack} />
            ) : null}
          </>
        )}
        <Dialog
          open={deleteModal}
          title={`Are you sure you would like to delete ${dog?.name}? This cannot be undone.`}
          warning
        >
          <Box>
            <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
              <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
              <ActionButton
                type="button"
                text={"Delete"}
                disabled={status === "loading" ? true : false}
                color="error"
                onClick={async () => {
                  setDeleteModal(false);
                  await handleDeleteDog(deleteId);
                  setDeleteId(0);
                  await dispatch(getDogsSearch({ search: filterForm }));
                }}
              />
            </DialogActions>
          </Box>
        </Dialog>
      </Container>
    </>
  );
};

export default EditCreateUser;
