import { Box, Container } from "@mui/material";
import React from "react";
import { useSelector } from "../../store";
import DogAdoptionRecord from "./DogAdoptionRecord";
import UserApplicationTable from "./UserApplicationTable";

interface Props {
  id: number;
}

const UserAdoptionApplicationTab: React.FC<Props> = (props) => {
  const { user } = useSelector((store) => store.users);

  return (
    <Container>
      <Box sx={{ marginY: 3 }}>
        <UserApplicationTable userId={user?.id!} />
      </Box>
      <Box sx={{ marginY: 3 }}>
        <DogAdoptionRecord id={user?.id!} type="user" />
      </Box>
    </Container>
  );
};

export default UserAdoptionApplicationTab;
