import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { Box, Container, DialogActions, Grid, Tooltip, Typography } from "@mui/material";
import { clearState, deleteVet, getVet, searchVet } from "../../../slices/vet";
import { debounce } from "lodash";
import Vet from "../../../types/vet";
import TableActions from "../../base/TableActions";
import ActionButton from "../../base/ActionButton";
import StyledTable from "../../base/StyledTable";
import Search from "../../base/Search";
import { useNavigate } from "react-router-dom";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import Dialog from "../../base/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../../helpers/getAddressLink";
import { setVetFilter } from "../../../slices/filters";

interface Props {}

const VetsView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchResults, vet, status } = useSelector((store) => store.vet);
  const { vetsFilter } = useSelector((store) => store.filters);
  const { loggedInUser } = useSelector((store) => store.auth);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(searchVet({ ...vetsFilter }));
  }, [dispatch, vetsFilter]);

  const handleDeleteModal = (id: number) => {
    setDeleteModal(true);
    dispatch(getVet(id));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteVet(id)).then(() => {
      dispatch(searchVet({ ...vetsFilter }));
      setDeleteModal(false);
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const updateSearch = debounce((query: string) => {
    dispatch(setVetFilter({ ...vetsFilter, pageNumber: 1, pageSize: 10, query: query }));
  }, 250);

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setVetFilter({ ...vetsFilter, pageNumber: pageNumber, pageSize: pageSize }));
  };

  const headers = ["Clinic", "Vet", "Contact", "Phone", "Email", "Location"];

  if (checkUserRole(loggedInUser!, UserRoles.Admin)) {
    headers.unshift("Actions");
  }

  const rows =
    (!!searchResults?.results &&
      searchResults?.results.map((vet: Vet) => {
        const hasActions = checkUserRole(loggedInUser!, UserRoles.Admin);

        let rowObject = {
          clinic: vet.clinicName,
          vet: vet.vetName,
          contact: vet.contactName,
          phone: vet.mainPhone,
          email: vet.email,
          location:
            vet.address?.line1 && vet.address.city && vet.address.state && vet.address.postalCode ? (
              <Box>
                {vet.address?.city}, {vet.address?.state?.value}{" "}
                <Tooltip
                  title={
                    <Box>
                      <Box display="flex" flexDirection="row">
                        {vet.address.line1}
                      </Box>
                      {vet.address.line2 && <Box>{vet.address.line2}</Box>}
                      <Box display="flex" flexDirection="row">
                        {vet.address?.city}, {vet.address?.state?.value} {vet.address.postalCode}
                      </Box>
                    </Box>
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faLocationDot}
                    size="sm"
                    onClick={() => window.open(getAddressLink(vet.address!), "_blank")}
                  />
                </Tooltip>
              </Box>
            ) : (
              ""
            ),
        };

        return !hasActions
          ? rowObject
          : {
              actions: (
                <TableActions
                  id={vet.id!}
                  handleDelete={handleDeleteModal}
                  handleEdit={() => {
                    dispatch(getVet(vet.id!)).then(() => {
                      navigate(`/admin/vets/${vet.id}?edit=true`);
                    });
                  }}
                />
              ),
              clinic: vet.clinicName,
              vet: vet.vetName,
              contact: vet.contactName,
              phone: vet.mainPhone,
              email: vet.email,
              location:
                vet.address?.line1 && vet.address.city && vet.address.state && vet.address.postalCode ? (
                  <Box>
                    {vet.address?.city}, {vet.address?.state?.value}{" "}
                    <Tooltip
                      title={
                        <Box>
                          <Box display="flex" flexDirection="row">
                            {vet.address.line1}
                          </Box>
                          {vet.address.line2 && <Box>{vet.address.line2}</Box>}
                          <Box display="flex" flexDirection="row">
                            {vet.address?.city}, {vet.address?.state?.value} {vet.address.postalCode}
                          </Box>
                        </Box>
                      }
                    >
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faLocationDot}
                        size="sm"
                        onClick={() => window.open(getAddressLink(vet.address!), "_blank")}
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  ""
                ),
            };
      })) ||
    [];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: -1 }}>
      <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
        <Container>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end" sx={{ paddingBottom: 1 }}>
            {checkUserRole(loggedInUser!, UserRoles.Admin) && (
              <Grid item display="flex">
                <ActionButton
                  text="Add New"
                  color="primary"
                  onClick={() => {
                    dispatch(clearState());
                    navigate(`/admin/vets/0?edit=true`);
                  }}
                  type="button"
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={1} alignItems="center" justifyContent={"center"} sx={{ paddingBottom: 1 }}>
          <Grid item xs={12}>
            <Search label="Search" handleChange={updateSearch} value={vetsFilter.query} />
          </Grid>
        </Grid>
      </Container>
      <Box margin={1}>
        {status === "loading" && !searchResults ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" sx={{ textAlign: "center", marginY: status === "loading" ? 0 : "29px" }}>
              {status === "loading" ? `Loading, please wait...` : <></>}
            </Typography>
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={vetsFilter.pageNumber}
                rowsPerPage={vetsFilter.pageSize}
                totalRows={searchResults?.totalResults}
                changePage={changePage}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No vets to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you want to delete ${
          vet?.clinicName ? vet.clinicName : `this vet`
        }? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton text="Cancel" color="secondary" type="button" onClick={cancelDelete} />
            <ActionButton
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={() => {
                handleDelete(vet?.id!);
              }}
              type="button"
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default VetsView;
