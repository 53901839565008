import { Box, Typography, Grid } from "@mui/material";
import { Formik, Field, Form } from "formik";
import theme from "../../../theme";
import ActionButton from "../../base/ActionButton";
import { Inputs } from "../../forms";
import * as Yup from "yup";
import KeyValue from "../../../types/keyValue";
import { useDispatch, useSelector } from "../../../store";
import Note from "../../../types/note";
import { postNotes } from "../../../slices/notes";

interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

interface Return {
  reason?: KeyValue;
  otherReason: string;
}

const ReturnDogModal: React.FC<Props> = (props) => {
  const { onClose, onSubmit } = props;
  const dispatch = useDispatch();
  const { dog } = useSelector((store) => store.dogs);
  const { loggedInUser } = useSelector((store) => store.auth);

  const validationSchema = Yup.object().shape({
    reason: Yup.object().required("Required").typeError("Required"),
    otherReason: Yup.string().when("reason", {
      is: (reason: KeyValue) => reason && reason.id === 8,
      then: (schema) => schema.typeError("Error").required("Required"),
    }),
  });

  const returnStatuses = [
    { id: 1, value: "Adopter changed mind/ doesn't want" },
    { id: 2, value: "Adopter allergies" },
    { id: 3, value: "Animals didn't get along" },
    { id: 4, value: "Owner can't care for" },
    { id: 5, value: "Dog/health behavior" },
    { id: 6, value: "Not good fit" },
    { id: 7, value: "Administrative error" },
    { id: 8, value: "Other" },
  ];

  const addDogNote = (values: any) => {
    const newNote: Note = {
      id: 0,
      value: `Dog returned because: ${values.reason.id === 8 ? values.otherReason : values.reason.value}`,
      addedByUser: { ...loggedInUser!, address: undefined },
      addedDate: new Date(),
      noteType: { id: 1, value: "" },
      entityId: dog.id,
    };
    dispatch(postNotes(newNote));
  };

  const initialValues: Return = {
    otherReason: "",
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: 400,
        p: 2,
        borderRadius: 10,
        textAlign: "center",
      }}
    >
      <Typography mb={2}>{"Dog Returning from Adopted"}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          addDogNote(values);
          onSubmit();
        }}
      >
        {({ values }) => (
          <Form noValidate>
            <Grid container direction={"row"}>
              <Grid item xs={12} my={2}>
                <Typography color={theme.palette.error.main}>
                  Changing this dog's status will immediately update all of the changes made to the dog. Complete any
                  other changes before finishing.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="reason"
                  label="Return Reason"
                  required
                  component={Inputs.Dropdown}
                  options={returnStatuses}
                />
              </Grid>
              {values?.reason?.id === 8 && (
                <Grid item xs={12}>
                  <Field name="otherReason" label="Other" required component={Inputs.Text} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container direction={"row"} justifyContent={"space-around"}>
                  <Grid item>
                    <ActionButton type="button" color="secondary" onClick={onClose} text="Cancel" />
                  </Grid>
                  <Grid item>
                    <Inputs.Submit isSubmitting={false} color="primary" text="Save" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ReturnDogModal;
