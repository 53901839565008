import { Box, Typography, Paper, Container } from "@mui/material";
import { StepProps } from "./Step";
import React, { ReactElement, createRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import theme from "../../theme";
import ActionButton from "./ActionButton";
import StepperRef from "../../types/stepperRef";

interface Props {
  disableStepClicks?: boolean;
  onNext?: (stepName: string, stepIndex: number) => void;
  onPrev?: (stepName: string, stepIndex: number) => void;
  onSubmit?: () => void;
  children: Array<ReactElement<StepProps>>;
  status: "idle" | "loading" | "failed";
  secondStatus: "idle" | "loading" | "failed";
}

const Stepper = forwardRef<StepperRef, Props>((props, ref) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const stepContainerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    stepContainerRef.current?.scrollTo({ behavior: "smooth", top: 0 });
  }, [props.onNext]);

  const next = () => {
    let newPage = currentPage + 1;

    if (newPage > props.children.length) {
      newPage = props.children.length;
    }
    setCurrentPage(newPage);
    stepContainerRef.current?.scrollTo({ behavior: "smooth", top: 0 });
  };

  const previous = () => {
    let newPage = currentPage - 1;

    if (newPage < 1) {
      newPage = 1;
    }

    setCurrentPage(newPage);
  };

  useImperativeHandle(
    ref,
    () => ({
      next,
      previous,
    }),
    [currentPage]
  );

  const handleStepClicked = (pageNumber: number) => {
    if (!props.disableStepClicks) {
      setCurrentPage(pageNumber);
    }
  };

  const handleNextClicked = (e?: any) => {
    //if on Next is provided then us that logic instead
    stepContainerRef.current?.scrollTo({ behavior: "smooth", top: 1024 });

    if (!!props.onNext) {
      props.onNext?.(props.children[currentPage - 1].props.label, currentPage - 1);
      return;
    }

    next();
  };

  const handlePreviousClicked = (e?: any) => {
    //if on Prev is provided then us that logic instead
    if (!!props.onPrev) {
      props.onPrev?.(props.children[currentPage - 1].props.label, currentPage - 1);
      return;
    }

    previous();
  };

  const handleSubmitClicked = (e?: any) => {
    props.onSubmit?.();
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Container sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            "& :first-child": { borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" },
            "& :last-child": { borderTopRightRadius: "20px", borderBottomRightRadius: "20px" },
            "& .active": {
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            zIndex: "200",
          }}
        >
          {props.children.map((step, index) => {
            return (
              <Box
                key={step.props.label}
                className={index + 1 === currentPage ? "active" : ""}
                onClick={() => {
                  handleStepClicked(index + 1);
                }}
                sx={{
                  background: theme.palette.info.main,
                  flex: 1,
                  textAlign: "center",
                  paddingBottom: "4px",
                  border: "1px solid #CCC",
                  boxShadow: "5px 5px 5px #CCC",
                  color: "theme.palette.primary.main",
                  cursor: !!props.disableStepClicks ? "default" : "pointer",
                }}
              >
                <Typography variant="h4"> {step.props.label}</Typography>
              </Box>
            );
          })}
        </Box>

        <Box sx={{ flex: "1 1 auto;", zIndex: "100", paddingBottom: "20px" }}>
          <Paper
            sx={{
              marginX: {
                xs: "-10px",
                sm: "40px",
              },

              paddingX: {
                xs: "5px",
                sm: "30px",
              },

              paddingTop: {
                xs: "0px",
                md: "30px",
              },

              paddingBottom: "10px",
              height: "100%",

              borderRadius: "25px",
            }}
          >
            <Box sx={{ height: "100%", position: "relative" }}>
              <Box
                sx={{
                  top: 0,
                  bottom: "50px",
                  width: "100%",
                  overflow: "auto",
                }}
                ref={stepContainerRef}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    display: {
                      xs: "block",
                      md: "none",
                    },
                  }}
                >
                  <Typography variant="h2"> {props.children[currentPage - 1].props.label}</Typography>
                </Box>
                {props.children[currentPage - 1].props.children}
              </Box>
              <Box
                sx={{
                  bottom: 0,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {currentPage === 1 ? (
                    <div></div>
                  ) : (
                    <ActionButton type="button" text={"Previous"} color="warning" onClick={handlePreviousClicked} />
                  )}
                  {currentPage === props.children.length ? (
                    <ActionButton
                      type="button"
                      text="Submit"
                      color="primary"
                      onClick={handleSubmitClicked}
                      disabled={props.status === "loading" || props.secondStatus === "loading"}
                    />
                  ) : (
                    <ActionButton type="button" text="Next" color="primary" onClick={handleNextClicked} />
                  )}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
});

export default Stepper;
