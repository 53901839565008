import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import NoteUpload from "../../base/NoteUpload";
import NoteDisplay from "../NoteDisplay";
import { useDispatch, useSelector } from "../../../store";
import Note from "../../../types/note";
import { deleteNote, getNotes, postNotes } from "../../../slices/notes";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";

interface Props {}

const UserNoteTab: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { notes } = useSelector((store) => store.notes);
  const { user } = useSelector((store) => store.users);
  const { loggedInUser } = useSelector((store) => store.auth);

  useEffect(() => {
    getNotesForRoles();
  }, [dispatch]);

  const getNotesForRoles = () => {
    if (
      checkUserRole(loggedInUser!, UserRoles.Admin) ||
      checkUserRole(loggedInUser!, UserRoles.Foster) ||
      checkUserRole(loggedInUser!, UserRoles.Inventory) ||
      checkUserRole(loggedInUser!, UserRoles.Personnel) ||
      checkUserRole(loggedInUser!, UserRoles.Volunteer)
    ) {
      dispatch(getNotes({ entityId: user.id, typeId: 3 }));
    }
  };

  const removeNote = async (value: Note) => {
    await dispatch(deleteNote(value.id));
    await dispatch(getNotes({ entityId: user.id, typeId: 3 }));
  };

  const addNote = (note: string) => {
    const newNote: Note = {
      id: 0,
      value: note,
      addedByUser: { ...loggedInUser!, address: undefined },
      addedDate: new Date(),
      noteType: { id: 3, value: "" },
      entityId: user.id,
    };
    dispatch(postNotes(newNote)).then(() => {
      dispatch(getNotes({ entityId: user.id!, typeId: 3 }));
    });
  };

  return (
    <Box sx={{ marginY: 3 }}>
      <NoteUpload onNoteAdd={(note: string) => addNote(note)} />
      <Grid container direction={"column"}>
        {notes &&
          notes.length > 0 &&
          notes.map((note, index) => {
            return (
              <NoteDisplay
                key={index}
                note={note}
                onDelete={() => removeNote(note)}
                onNoteEdit={() => dispatch(getNotes({ entityId: user.id!, typeId: 3 }))}
              />
            );
          })}
      </Grid>
    </Box>
  );
};

export default UserNoteTab;
