import React from "react";
import HelpUsView from "../views/HelpUsView";
import { Box } from "@mui/material";

interface Props {}

const HelpUsPage: React.FC<Props> = (props) => {
  return (
    <Box>
      <HelpUsView />
    </Box>
  );
};

export default HelpUsPage;
