import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import ApplicationHistory from "../../../types/applicationHistory";
import theme from "../../../theme";
import { Pets, AccessTime } from "@mui/icons-material";
import Moment from "react-moment";
import Modal from "../../base/Modal";
import HistoryVM from "../../../types/historyVM";
import { useDispatch } from "../../../store";
import { getApplication, setActiveApp } from "../../../slices/applications";

interface Props {
  adminApplication: ApplicationHistory;
  dog?: boolean;
  cat?: boolean;
  other?: boolean;
  prev?: boolean;
}

const HistoryPetsView: React.FC<Props> = (props) => {
  const { adminApplication, dog, cat, other, prev } = props;
  const dispatch = useDispatch();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const [hover, setHover] = useState({ visible: false, id: 0 });
  const [menu, setMenu] = useState(false);
  const [petArray, setPetArray] = useState<HistoryVM[]>([]);

  useEffect(() => {
    if (
      !!dog &&
      adminApplication?.history?.HouseholdDogs &&
      adminApplication?.history?.HouseholdDogs.length &&
      adminApplication?.history?.HouseholdDogs?.length > 1
    ) {
      setPetArray(adminApplication?.history?.HouseholdDogs);
    } else if (
      !!cat &&
      adminApplication?.history?.HouseholdCats &&
      adminApplication?.history?.HouseholdCats.length &&
      adminApplication?.history?.HouseholdCats?.length > 1
    ) {
      setPetArray(adminApplication.history.HouseholdCats);
    } else if (
      !!other &&
      adminApplication?.history?.HouseholdOtherPets &&
      adminApplication?.history?.HouseholdOtherPets.length &&
      adminApplication?.history?.HouseholdOtherPets?.length > 1
    ) {
      setPetArray(adminApplication?.history?.HouseholdOtherPets);
    } else if (
      !!prev &&
      adminApplication?.history?.HouseholdPreviousPets &&
      adminApplication?.history?.HouseholdPreviousPets.length &&
      adminApplication?.history?.HouseholdPreviousPets?.length > 1
    ) {
      setPetArray(adminApplication?.history?.HouseholdPreviousPets);
    }
  }, [cat, dog, other, prev]);

  const renderTooltip = (itemList: any[]) => {
    return (
      <Box
        ref={anchorRef}
        onClick={
          sm
            ? () => {
                setMenu(true);
              }
            : undefined
        }
      >
        <Tooltip
          title={
            <Box sx={{ width: "fit-content" }}>
              {itemList?.map((item) => {
                const value = JSON.parse(item.value);
                return (
                  <Box
                    onMouseEnter={() => setHover({ visible: true, id: item.id })}
                    onMouseLeave={() => setHover({ visible: false, id: 0 })}
                    color={
                      hover && item.id === hover.id ? theme.palette.secondary.main : theme.palette.primary.contrastText
                    }
                    sx={{ cursor: "pointer" }}
                    onClick={async () => {
                      dispatch(setActiveApp(item.id));
                    }}
                  >
                    <Typography>
                      <Moment format="MM/DD/YY">{item.date}</Moment>- {`${value.Name}`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          }
        >
          <AccessTime fontSize="small" />
        </Tooltip>
      </Box>
    );
  };

  return (
    <>
      {!!dog ? (
        <Grid
          item
          xs={12}
          marginTop={1}
          sx={{
            backgroundColor:
              adminApplication?.history?.HouseholdDogs &&
              adminApplication?.history?.HouseholdDogs.length &&
              adminApplication?.history?.HouseholdDogs?.length > 0
                ? theme.palette.secondary.main
                : "inherit",
          }}
        >
          {adminApplication?.householdDogs && adminApplication.householdDogs.length > 0
            ? adminApplication?.householdDogs?.map((dog, index) => {
                return (
                  <Grid container spacing={2} mb={3} key={index}>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "25px",
                        flexDirection: "column",
                      }}
                    >
                      <Pets />
                      {adminApplication?.history?.HouseholdDogs &&
                      adminApplication?.history?.HouseholdDogs.length &&
                      adminApplication?.history?.HouseholdDogs?.length > 0
                        ? renderTooltip(adminApplication?.history?.HouseholdDogs!)
                        : null}
                    </Grid>
                    <Grid item md={3} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Dog Number {index + 1}
                      </Typography>
                      <Typography variant="body1">{dog.name}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Year Joined Family
                      </Typography>
                      <Typography variant="body1">{dog.adoptionYear}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Age
                      </Typography>
                      <Typography variant="body1">{dog.age?.value}</Typography>
                    </Grid>
                    <Grid item xs={1} md={1} />
                    <Grid item md={3} xs={5}>
                      <Typography variant="body2" fontStyle="italic">
                        Gender
                      </Typography>
                      <Typography variant="body1">{dog.gender?.value}</Typography>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Typography variant="body2" fontStyle="italic">
                        Breed
                      </Typography>
                      <Typography variant="body1">{dog.breed?.value}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Temperament
                      </Typography>
                      <Typography variant="body1">{dog.temperament?.value}</Typography>
                    </Grid>
                    <Grid item xs={1} md={1} />
                    <Grid item md={3} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Sleep Location
                      </Typography>
                      <Typography variant="body1">{dog.sleepLocation?.value}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Dog Origin
                      </Typography>
                      <Typography variant="body1">{dog.dogOrigin?.value}</Typography>
                    </Grid>
                  </Grid>
                );
              })
            : adminApplication?.history?.HouseholdDogs?.length! > 0 &&
              renderTooltip(adminApplication?.history?.HouseholdDogs!)}
        </Grid>
      ) : !!cat ? (
        <Grid
          item
          xs={12}
          marginTop={1}
          sx={{
            backgroundColor:
              adminApplication?.history?.HouseholdCats &&
              adminApplication?.history?.HouseholdCats.length &&
              adminApplication?.history?.HouseholdCats?.length > 0
                ? theme.palette.secondary.main
                : "inherit",
          }}
        >
          {adminApplication?.householdCats && adminApplication.householdCats.length > 0
            ? adminApplication?.householdCats.map((cat, index) => {
                return (
                  <Grid container spacing={2} mb={3} key={index}>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "25px",
                        flexDirection: "column",
                      }}
                    >
                      <Pets />
                      {adminApplication?.history?.HouseholdCats &&
                      adminApplication?.history?.HouseholdCats.length &&
                      adminApplication?.history?.HouseholdCats?.length > 0
                        ? renderTooltip(adminApplication?.history?.HouseholdCats!)
                        : null}
                    </Grid>
                    <Grid item md={3} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Cat Number {index + 1}
                      </Typography>
                      <Typography variant="body1">{cat.name}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={5}>
                      <Typography variant="body2" fontStyle="italic">
                        Year Joined Family
                      </Typography>
                      <Typography variant="body1">{cat.yearAdopted}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6} sx={{ display: { xs: "initial", md: "none" } }}>
                      <Typography variant="body2" fontStyle="italic">
                        Gender
                      </Typography>
                      <Typography variant="body1">{cat.gender?.value}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Age
                      </Typography>
                      <Typography variant="body1">{cat.age?.value}</Typography>
                    </Grid>
                    <Grid item xs={1} md={1} />

                    <Grid item md={3} xs={11} sx={{ display: { xs: "none", md: "initial" } }}>
                      <Typography variant="body2" fontStyle="italic">
                        Gender
                      </Typography>
                      <Typography variant="body1">{cat.gender?.value}</Typography>
                    </Grid>
                  </Grid>
                );
              })
            : adminApplication?.history?.HouseholdCats?.length! > 0 &&
              renderTooltip(adminApplication?.history?.HouseholdCats!)}
        </Grid>
      ) : !!other ? (
        <Grid
          item
          xs={12}
          marginTop={1}
          sx={{
            backgroundColor:
              adminApplication?.history?.HouseholdOtherPets &&
              adminApplication?.history?.HouseholdOtherPets.length &&
              adminApplication?.history?.HouseholdOtherPets?.length > 0
                ? theme.palette.secondary.main
                : "inherit",
          }}
        >
          {adminApplication?.householdOtherPets && adminApplication.householdOtherPets.length > 0
            ? adminApplication?.householdOtherPets.map((pet, index) => {
                return (
                  <Grid container spacing={2} mb={3} key={index}>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "25px",
                        flexDirection: "column",
                      }}
                    >
                      <Pets />
                      {adminApplication?.history?.HouseholdOtherPets &&
                      adminApplication?.history?.HouseholdOtherPets.length &&
                      adminApplication?.history?.HouseholdOtherPets?.length > 0
                        ? renderTooltip(adminApplication?.history?.HouseholdOtherPets!)
                        : null}
                    </Grid>
                    <Grid item md={3} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Other Pet Number {index + 1}
                      </Typography>
                      <Typography variant="body1">{pet.name}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={5}>
                      <Typography variant="body2" fontStyle="italic">
                        Animal Type
                      </Typography>
                      <Typography variant="body1">{pet.animalType?.value}</Typography>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Typography variant="body2" fontStyle="italic">
                        Year Joined Family
                      </Typography>
                      <Typography variant="body1">{pet.yearAdopted}</Typography>
                    </Grid>
                  </Grid>
                );
              })
            : adminApplication?.history?.HouseholdOtherPets.length! > 0 &&
              renderTooltip(adminApplication?.history?.HouseholdOtherPets!)}
        </Grid>
      ) : !!prev ? (
        <Grid
          item
          xs={12}
          marginTop={1}
          sx={{
            backgroundColor:
              adminApplication?.history?.HouseholdPreviousPets &&
              adminApplication?.history?.HouseholdPreviousPets.length &&
              adminApplication?.history?.HouseholdPreviousPets?.length > 0
                ? theme.palette.secondary.main
                : "inherit",
          }}
        >
          {adminApplication?.householdPreviousPets && adminApplication.householdPreviousPets.length > 0
            ? adminApplication?.householdPreviousPets.map((pet, index) => {
                return (
                  <Grid container spacing={2} mb={3} key={index}>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "25px",
                        flexDirection: "column",
                      }}
                    >
                      <Pets />
                      {adminApplication?.history?.HouseholdPreviousPets &&
                      adminApplication?.history?.HouseholdPreviousPets.length &&
                      adminApplication?.history?.HouseholdPreviousPets?.length > 0
                        ? renderTooltip(adminApplication?.history?.HouseholdPreviousPets!)
                        : null}
                    </Grid>
                    <Grid item md={3} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Previous Pet Number {index + 1}
                      </Typography>
                      <Typography variant="body1">{pet.name}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: { md: "none", xs: "flex" } }} />
                    <Grid item md={4} xs={5}>
                      <Typography variant="body2" fontStyle="italic">
                        Years Owned
                      </Typography>
                      <Typography variant="body1">{pet.yearsOwned}</Typography>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Typography variant="body2" fontStyle="italic">
                        Animal Type
                      </Typography>
                      <Typography variant="body1">{pet.previousPetType?.value}</Typography>
                    </Grid>
                    <Grid item xs={1} md={1} />
                    <Grid item md={3} xs={11}>
                      <Typography variant="body2" fontStyle="italic">
                        Reason No Longer Owned
                      </Typography>
                      <Typography variant="body1">{pet.reasonNoLongerOwned}</Typography>
                    </Grid>
                  </Grid>
                );
              })
            : adminApplication?.history?.HouseholdPreviousPets?.length! > 0 &&
              renderTooltip(adminApplication?.history?.HouseholdPreviousPets!)}
        </Grid>
      ) : (
        <></>
      )}
      <Modal open={menu} onClose={() => setMenu(false)}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: "10px",
          }}
        >
          <Typography textAlign={"center"}>
            {!!dog
              ? "Current Dogs"
              : !!cat
              ? "Current Cats"
              : !!other
              ? "Current other Pets"
              : !!prev
              ? "Previous Pets"
              : ""}
          </Typography>
          <Box
            sx={{
              maxHeight: "250px",
              overflow: "auto",
              padding: "5px",
            }}
          >
            {petArray.map((item) => {
              const pet = JSON.parse(item.value);

              return (
                <Box color={theme.palette.primary.main} sx={{ textDecoration: "underline", padding: "5px" }}>
                  <Typography
                    onClick={async () => {
                      dispatch(setActiveApp(item.id));
                      setMenu(false);
                    }}
                  >
                    <Moment format="MM/DD/YY">{item.date}</Moment>- {`${pet.Name}`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default HistoryPetsView;
