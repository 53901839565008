import React, { useEffect } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../../store";
import { getSource } from "../../../slices/sources";
import theme from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../../helpers/getAddressLink";
import ActionButton from "../../base/ActionButton";
import { useNavigate } from "react-router-dom";

interface Props {
  id: number;
}

const SourceDetailView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { source } = useSelector((store) => store.sources);

  useEffect(() => {
    dispatch(getSource(props.id));
  }, [dispatch, props.id]);

  const handleBack = () => {};

  return (
    <>
      <Paper
        sx={{
          background: theme.palette.background.default,
          padding: {
            xs: "15px",
            sm: "30px",
          },
          height: "100%",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <Grid container spacing={3} display="flex" flexDirection="row" justifyContent="center" alignItems={"baseline"}>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Name
            </Typography>
            <Typography variant="body1">{source.name}</Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Kennel License
            </Typography>
            <Typography variant="body1">{source.license}</Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Type
            </Typography>
            <Typography variant="body1">{source.originType?.value}</Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Address
            </Typography>
            {!!source.address && !!source.address.line1 && (
              <>
                <Typography>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faLocationDot}
                    size="sm"
                    onClick={() => window.open(getAddressLink(source?.address!), "_blank")}
                  />{" "}
                  {source.address?.line1}
                </Typography>
                {source.address?.line2 && <Typography>{source.address?.line2}</Typography>}
                <Typography>
                  {source.address?.city}, {source.address?.state?.value} {source.address?.postalCode}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Website
            </Typography>
            <Typography variant="body1">{source.website}</Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Current Dogs from Source
            </Typography>
            <Typography variant="body1">{source.numberOfDogs ?? ""}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          background: theme.palette.background.default,
          padding: {
            xs: "15px",
            sm: "30px",
          },
          height: "100%",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <Grid container spacing={3} display="flex" flexDirection="row" justifyContent="center" alignItems={"baseline"}>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Primary Contact
            </Typography>
            <Typography variant="body1">{source.primaryContactName}</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Title
            </Typography>
            <Typography variant="body1">{source.primaryContactTitle}</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Phone Number
            </Typography>
            <Typography variant="body1">{source.primaryContactPhoneNumber}</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Email
            </Typography>
            <Typography variant="body1">{source.primaryContactEmail}</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        sx={{
          background: theme.palette.background.default,
          padding: {
            xs: "15px",
            sm: "30px",
          },
          height: "100%",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <Grid container spacing={3} display="flex" flexDirection="row" justifyContent="center" alignItems={"baseline"}>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Secondary Contact
            </Typography>
            <Typography variant="body1">{source.secondaryContactName}</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Title
            </Typography>
            <Typography variant="body1">{source.secondaryContactTitle}</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Phone Number
            </Typography>
            <Typography variant="body1">{source.secondaryContactPhoneNumber}</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" fontStyle="italic">
              Email
            </Typography>
            <Typography variant="body1">{source.secondaryContactEmail}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 2,
          position: "sticky",
          bottom: 0,
          backgroundColor: theme.palette.primary.contrastText,
          paddingY: 2,
          zIndex: 1000,
        }}
      >
        <ActionButton
          text={"Back"}
          onClick={() => {
            navigate(`/admin/sources`);
          }}
          color="secondary"
          type="button"
        />
        <ActionButton
          text="Edit"
          onClick={() => {
            navigate(`/admin/sources/${source?.id}?edit=true`);
          }}
          color="primary"
          type="button"
        />
      </Box>
    </>
  );
};

export default SourceDetailView;
