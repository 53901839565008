import React, { useState } from "react";
import { useDispatch } from "../../../store";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import theme from "../../../theme";
import { register, setAuthRoute, setAuthModal, getLoggedInUser, setAwsUser } from "../../../slices/auth";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../../../UserPool";
import { handleErrorToastState, setErrorMessage } from "../../../slices/toast";
import getEmailRegex from "../../../helpers/emailRegex";

interface Props {}

const RegisterView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("You must include your first name").typeError("Required"),
    lastName: Yup.string().required("You must include your last name").typeError("Required"),
    phone: Yup.string()
      .min(14, "Must be a valid phone number")
      .required("You must include your phone number")
      .typeError("Required"),
    email: Yup.string()
      .email("Must be a valid email")
      .matches(getEmailRegex(), "Must be a valid email")
      .required("You must include your email")
      .typeError("Required"),
    password: Yup.string()
      .required("You must enter a valid password")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, "Your password must have at least 1 numerical character")
      .matches(/[a-z]/, "Your password must have at least 1 lowercase character")
      .matches(/[A-Z]/, "Your password must have at least 1 uppercase character")
      .typeError("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords do not match")
      .typeError("Required"),
  });

  const handleSubmit = async (values: any) => {
    if (values.password === values.confirmPassword) {
      await dispatch(
        register({
          id: 0,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          secondaryPhone: "",
          active: true,
          roles: [],
          favoriteDogs: [],
          password: values.password,
        })
      ).then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") {
          const user = new CognitoUser({
            Username: values.email,
            Pool: UserPool,
          });

          const authDetails = new AuthenticationDetails({
            Username: values.email,
            Password: values.password,
          });

          user.authenticateUser(authDetails, {
            onSuccess: () => {
              dispatch(getLoggedInUser()).then(async (result) => {
                dispatch(setAuthModal(false));
              });
            },

            onFailure: (err) => {
              dispatch(setErrorMessage(err.message));
              dispatch(handleErrorToastState(true));
            },
          });

          dispatch(setAwsUser(user));
        }
      });
      await dispatch(setAuthModal(false));
    } else {
      return;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingY: { xs: 25, sm: 2, md: 0 },
        marginX: { lg: 35, md: 10, sm: 0 },
        height: "100%",
        overflow: "auto",
      }}
    >
      <Grid
        container
        direction={"row"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ paddingY: { xs: 25, sm: 0 } }}
      >
        <Grid item md={4} sm={12} alignSelf="center" display={{ sm: "inline", xs: "none" }}>
          <Box
            sx={{
              display: " flex",
              justifyContent: "center",
              paddingX: { md: "35px", sm: "0px" },
            }}
          >
            <Box
              component="img"
              alt="logo"
              src="/static/images/hallogo.png"
              sx={{
                height: 200,
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={8}
          sm={12}
          sx={{
            backgroundColor: { md: theme.palette.background.paper, sm: theme.palette.secondary.main },
            paddingY: { md: "50px", xs: "0px" },
            paddingX: { md: "35px", xs: "20px" },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{ color: { md: theme.palette.primary.main, xs: theme.palette.primary.main }, marginY: 2 }}
          >
            Create Account
          </Typography>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form noValidate>
              <Grid container>
                <Grid item xs={6}>
                  <Field
                    name="firstName"
                    label="First Name"
                    required
                    component={Inputs.Text}
                    secondary={md ? false : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="lastName"
                    label="Last Name"
                    required
                    component={Inputs.Text}
                    secondary={md ? false : true}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field name="email" label="Email" required component={Inputs.Text} secondary={md ? false : true} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    name="phone"
                    label="Phone Number"
                    required
                    component={Inputs.Phone}
                    secondary={md ? false : true}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    name="password"
                    label="Password"
                    required
                    component={Inputs.Password}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    secondary={md ? false : true}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    required
                    component={Inputs.Password}
                    showPassword={showPassword}
                    secondary={md ? false : true}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-around"} sx={{ my: 2 }}>
                <Grid item>
                  <Inputs.Submit text="Create" isSubmitting={false} color="primary" />
                </Grid>
              </Grid>
            </Form>
          </Formik>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, justifyContent: "center" }}>
            <Typography
              variant="h5"
              sx={{
                marginTop: 1,
                textDecoration: "underline",
                cursor: "pointer",
                color: theme.palette.primary.main,
              }}
              onClick={() => dispatch(setAuthRoute("login"))}
            >
              Back to Login
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterView;
