import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { Box, Container, DialogActions, Grid, Tooltip, Typography } from "@mui/material";
import { debounce } from "lodash";
import TableActions from "../../base/TableActions";
import ActionButton from "../../base/ActionButton";
import StyledTable from "../../base/StyledTable";
import Search from "../../base/Search";
import { useNavigate } from "react-router-dom";
import checkUserRole, { UserRoles } from "../../../helpers/checkUserRole";
import Dialog from "../../base/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import getAddressLink from "../../../helpers/getAddressLink";
import { resetSourceFilter, setResetSourceFilterBool, setSourceFilter } from "../../../slices/filters";
import { clearState, deleteSource, getSource, searchSources } from "../../../slices/sources";
import DogSource from "../../../types/dogSource";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../../forms";
import { getSourceTypes } from "../../../slices/options";
import KeyValue from "../../../types/keyValue";

interface Props {}

const SourcesView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { searchResults, source, status } = useSelector((store) => store.sources);
  const { sourcesFilter, resetSourceFilterBool } = useSelector((store) => store.filters);
  const { loggedInUser } = useSelector((store) => store.auth);

  const [deleteModal, setDeleteModal] = useState(false);

  const checkForReset = () => {
    if (!resetSourceFilterBool) {
      dispatch(setResetSourceFilterBool(true));
    } else {
      handleClear();
    }
  };

  useEffect(() => {
    dispatch(searchSources({ ...sourcesFilter }));
  }, [dispatch, sourcesFilter]);

  useEffect(() => {
    checkForReset();
  }, [dispatch]);

  const handleDeleteModal = (id: number) => {
    setDeleteModal(true);
    dispatch(getSource(id));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteSource(id)).then(() => {
      dispatch(searchSources({ ...sourcesFilter }));
      setDeleteModal(false);
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const updateSearch = debounce((query: string) => {
    dispatch(setSourceFilter({ ...sourcesFilter, pageNumber: 1, pageSize: 10, query: query }));
  }, 500);

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setSourceFilter({ ...sourcesFilter, pageNumber: pageNumber, pageSize: pageSize }));
  };

  const headers = ["Kennel License", "Name", "Type", "Location", "Primary Contact", "Primary Number"];

  if (checkUserRole(loggedInUser!, UserRoles.Admin)) {
    headers.unshift("Actions");
  }

  const handleClear = () => {
    dispatch(resetSourceFilter());
  };

  const rows =
    (!!searchResults?.results &&
      searchResults?.results.map((source: DogSource) => {
        const hasActions = checkUserRole(loggedInUser!, UserRoles.Admin);

        let rowObject = {
          license: source.license,
          name: source.name,
          type: source.originType?.value,
          address:
            source.address?.line1 && source.address.city && source.address.state && source.address.postalCode ? (
              <Box>
                {source.address?.city}, {source.address?.state?.value}{" "}
                <Tooltip
                  title={
                    <Box>
                      <Box display="flex" flexDirection="row">
                        {source.address.line1}
                      </Box>
                      {source.address.line2 && <Box>{source.address.line2}</Box>}
                      <Box display="flex" flexDirection="row">
                        {source.address?.city}, {source.address?.state?.value} {source.address.postalCode}
                      </Box>
                    </Box>
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faLocationDot}
                    size="sm"
                    onClick={() => window.open(getAddressLink(source.address!), "_blank")}
                  />
                </Tooltip>
              </Box>
            ) : (
              ""
            ),
          primaryContact: source.primaryContactName,
          primaryNumber: source.primaryContactPhoneNumber,
        };

        return !hasActions
          ? rowObject
          : {
              actions: (
                <TableActions
                  id={source.id!}
                  handleView={() => {
                    dispatch(setResetSourceFilterBool(false));
                    dispatch(getSource(source.id!)).then(() => {
                      navigate(`/admin/sources/${source.id}`);
                    });
                  }}
                  handleDelete={handleDeleteModal}
                  handleEdit={() => {
                    dispatch(setResetSourceFilterBool(false));
                    dispatch(getSource(source.id!)).then(() => {
                      navigate(`/admin/sources/${source.id}?edit=true`);
                    });
                  }}
                />
              ),
              license: source.license,
              name: source.name,
              type: source.originType?.value,
              address:
                source.address?.line1 && source.address.city && source.address.state && source.address.postalCode ? (
                  <Box>
                    {source.address?.city}, {source.address?.state?.value}{" "}
                    <Tooltip
                      title={
                        <Box>
                          <Box display="flex" flexDirection="row">
                            {source.address.line1}
                          </Box>
                          {source.address.line2 && <Box>{source.address.line2}</Box>}
                          <Box display="flex" flexDirection="row">
                            {source.address?.city}, {source.address?.state?.value} {source.address.postalCode}
                          </Box>
                        </Box>
                      }
                    >
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faLocationDot}
                        size="sm"
                        onClick={() => window.open(getAddressLink(source.address!), "_blank")}
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  ""
                ),
              primaryContact: source.primaryContactName,
              primaryNumber: source.primaryContactPhoneNumber,
            };
      })) ||
    [];

  const handleFiltering = (e: KeyValue[], filterName: string) => {
    let filters = sourcesFilter?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (e) {
      let newFilters: string[] = [];

      e.forEach((filter: KeyValue) => {
        let newFilterName = `${filterName}:${filter.id}`;
        newFilters.push(newFilterName);
      });

      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      dispatch(setSourceFilter({ ...sourcesFilter, pageNumber: 1, pageSize: 10, filters: newList }));
    } else {
      dispatch(setSourceFilter({ ...sourcesFilter, pageNumber: 1, pageSize: 10, filters: otherFilters }));
    }
  };

  const handleTypeFilter = (value: KeyValue[]) => {
    handleFiltering(value, "type");
  };

  const handleReset = () => {
    dispatch(resetSourceFilter());
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: -1 }}>
      <Formik initialValues={{ type: [] }} onSubmit={() => {}} enableReinitialize>
        {({ resetForm }) => (
          <Form noValidate>
            <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
              <Container>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {checkUserRole(loggedInUser!, UserRoles.Admin) && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        padding: 1,
                      }}
                    >
                      <Box>
                        <ActionButton
                          type="reset"
                          text="Reset"
                          color="success"
                          onClick={async () => {
                            await handleClear();
                            handleReset();
                            resetForm();
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "flex-end",
                      padding: 1,
                    }}
                  >
                    {(checkUserRole(loggedInUser!, UserRoles.Admin) ||
                      checkUserRole(loggedInUser!, UserRoles.Inventory)) && (
                      <Box>
                        <ActionButton
                          text="Add New"
                          color="primary"
                          onClick={() => {
                            dispatch(clearState());
                            navigate(`/admin/sources/0?edit=true`);
                          }}
                          type="button"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Container>
            </Box>
            <Container>
              <Grid container spacing={1} alignItems="center" justifyContent={"center"} sx={{ paddingBottom: 1 }}>
                <Grid item md={6} xs={12}>
                  <Search label="Search" handleChange={updateSearch} value={sourcesFilter.query} />
                </Grid>
                <Grid item md={6} xs={12} sx={{ mb: "-22px" }}>
                  <Field
                    name="type"
                    label="Type"
                    component={Inputs.OptionDropdown}
                    searchFunction={getSourceTypes}
                    onSelected={handleTypeFilter}
                    multiple
                  />
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
      <Box margin={1}>
        {status === "loading" && !searchResults ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" sx={{ textAlign: "center", marginY: status === "loading" ? 0 : "29px" }}>
              {status === "loading" ? `Loading, please wait...` : <></>}
            </Typography>
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={sourcesFilter.pageNumber}
                rowsPerPage={sourcesFilter.pageSize}
                totalRows={searchResults?.totalResults}
                changePage={changePage}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No sources to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you want to delete ${source.name}? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton text="Cancel" color="secondary" type="button" onClick={cancelDelete} />
            <ActionButton
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={() => {
                handleDelete(source?.id!);
              }}
              type="button"
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default SourcesView;
