import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { Box, Container, DialogActions, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { clearEvents, deleteEvent, getEvent, searchEvents } from "../../../slices/events";
import { debounce } from "lodash";
import EventVM from "../../../types/event";
import Moment from "react-moment";
import TableActions from "../../base/TableActions";
import Search from "../../base/Search";
import StyledTable from "../../base/StyledTable";
import Dialog from "../../base/Dialog";
import ActionButton from "../../base/ActionButton";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../../forms";
import { getEventStatus } from "../../../slices/options";
import Switch from "../../base/Switch";
import KeyValue from "../../../types/keyValue";
import {
  resetEventEnd,
  resetEventFilter,
  resetEventStart,
  setEventEnd,
  setEventFilter,
  setEventStart,
  setFeaturedEvents,
} from "../../../slices/filters";

interface Props {}

const AdminEventsTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchResults, event, status } = useSelector((store) => store.events);
  const { eventFilter, featuredEvents, eventStart, eventEnd } = useSelector((store) => store.filters);
  const { eventStatuses } = useSelector((store) => store.options);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  var date = new Date();

  //reset dates if it's cleared and set to december 31, 1969
  useEffect(() => {
    if (eventStart?.toDateString() === "Wed Dec 31 1969") {
      dispatch(resetEventStart());
    }
    if (eventEnd?.toDateString() === "Wed Dec 31 1969") {
      dispatch(resetEventEnd());
    }
  }, [eventStart, eventEnd]);

  //only run search with date if the start date is valid
  useEffect(() => {
    if ((date instanceof Date && !isNaN(eventStart?.valueOf()!)) || eventStart === null) {
      dispatch(
        searchEvents({
          search: { ...eventFilter },
          start: eventStart!,
          end: eventEnd!,
        })
      );
    }
  }, [dispatch, eventStart]);

  //only run search with date if the end date is valid
  useEffect(() => {
    if ((date instanceof Date && !isNaN(eventEnd?.valueOf()!)) || eventEnd === null) {
      dispatch(
        searchEvents({
          search: { ...eventFilter },
          start: eventStart!,
          end: eventEnd!,
        })
      );
    }
  }, [dispatch, eventEnd]);

  //run search when the filter form updates
  useEffect(() => {
    dispatch(
      searchEvents({
        search: { ...eventFilter },
        start: eventStart!,
        end: eventEnd!,
      })
    );
  }, [dispatch, eventFilter]);

  const handleDeleteModal = (id: number) => {
    setDeleteModal(true);
    dispatch(getEvent(id));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteEvent(id)).then(() => {
      dispatch(searchEvents({ search: { ...eventFilter } }));
      setDeleteModal(false);
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const updateSearch = debounce((query: string) => {
    dispatch(setEventFilter({ ...eventFilter, pageNumber: 1, pageSize: 10, query: query }));
  }, 250);

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setEventFilter({ ...eventFilter, pageNumber: pageNumber, pageSize: pageSize }));
  };

  const headers = ["Actions", "Event Duration", "Name", "Status", "Featured"];

  const rows =
    (!!searchResults?.results &&
      searchResults?.results.map((e: EventVM) => {
        return {
          actions: (
            <TableActions
              id={e.id!}
              handleEdit={() => {
                dispatch(getEvent(e.id!)).then(() => {
                  navigate(`/admin/events/${e.id!}`, { state: { edit: true } });
                });
              }}
              handleView={() => {
                dispatch(getEvent(e.id!)).then(() => {
                  navigate(`/admin/events/${e.id!}`);
                });
              }}
              handleDelete={handleDeleteModal}
            />
          ),
          duration:
            e.start?.toString().split("T")[0] === e.end?.toString().split("T")[0] ? (
              <>
                <Moment format="MM/DD/YYYY">{e.start}</Moment> <br />
                <Moment format="h:mmA">{e.start}</Moment> - <Moment format="h:mmA">{e.end}</Moment>
              </>
            ) : (
              <>
                Starts: <Moment format="MM/DD/YYYY">{e.start}</Moment> at <Moment format="h:mmA">{e.start}</Moment>{" "}
                <br />
                Ends: <Moment format="MM/DD/YYYY">{e.end}</Moment> at <Moment format="h:mmA">{e.end}</Moment>
              </>
            ),
          name: e.name,
          status: e.status?.value,
          featured: e.featured === true ? "Yes" : "",
        };
      })) ||
    [];

  const handleFiltering = (filterName: string, e?: KeyValue[]) => {
    let filters = eventFilter?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    console.log(e);

    if (e) {
      let newFilters: string[] = [];

      e.forEach((filter: KeyValue) => {
        let newFilterName = `${filterName}:${filter.id}`;
        newFilters.push(newFilterName);
      });

      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      console.log(newList);
      dispatch(setEventFilter({ ...eventFilter, pageNumber: 1, pageSize: 10, filters: newList }));
    } else {
      dispatch(setEventFilter({ ...eventFilter, pageNumber: 1, pageSize: 10, filters: otherFilters }));
    }
  };

  const handleSwitch = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (!!checked) {
      let filter: KeyValue = {
        id: 1,
        value: "",
      };
      handleFiltering("featured", [filter]);
    } else {
      handleFiltering("featured");
    }
    dispatch(setFeaturedEvents(checked));
  };

  const handleStatusFilter = (value: KeyValue[]) => {
    console.log(value);
    handleFiltering("status", value);
  };

  const handleClear = () => {
    dispatch(resetEventFilter());
    dispatch(resetEventStart());
    dispatch(resetEventEnd());
  };

  const handleReset = (resetForm: () => void) => {
    resetForm();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 1 }}>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            status: [],
            eventStart: eventStart,
            eventEnd: eventEnd,
          }}
          onSubmit={() => {}}
        >
          {(formProps: any) => {
            return (
              <Form noValidate>
                <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
                  <Container>
                    <Grid container spacing={1} alignItems={"center"} sx={{ paddingBottom: 1 }}>
                      <Grid item sm={9} xs={12} justifyContent="flex-start" display="flex">
                        <Switch label="Show Featured Only" checked={featuredEvents} onChange={handleSwitch} />
                      </Grid>
                      <Grid item sm={3} display="flex" justifyContent="flex-end" gap={2}>
                        <ActionButton
                          type="button"
                          text="Add New"
                          color="primary"
                          onClick={() => {
                            dispatch(clearEvents());
                            navigate(`/admin/events/0`);
                          }}
                        />
                      </Grid>
                      <Grid item md={5} justifyContent="flex-end" sx={{ display: { sm: "none", xs: "flex" } }}>
                        <ActionButton
                          text={showFilters ? "Hide Filters" : "Show Filters"}
                          onClick={() => setShowFilters(!showFilters)}
                          type="button"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        justifyContent="flex-end"
                        display="flex"
                        gap={2}
                        sx={{ display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}
                      >
                        <ActionButton
                          type="reset"
                          text="Reset Filters"
                          color="success"
                          onClick={async (event) => {
                            await handleClear();
                            await handleReset.bind(formProps.resetForm);
                            await handleSwitch(event, false);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ paddingBottom: 1, display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}
                >
                  <Grid item md={6} xs={12} sx={{}}>
                    <Field
                      name="eventStart"
                      label="Start Date"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setEventStart(new Date(value)));
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} sx={{}}>
                    <Field
                      name="eventEnd"
                      label="End Date"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setEventEnd(new Date(value)));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="status"
                      label="Status"
                      component={Inputs.OptionDropdown}
                      searchFunction={getEventStatus}
                      onSelected={handleStatusFilter}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Search label="Search" handleChange={updateSearch} value={eventFilter.query} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
      <Box margin={1}>
        {status === "loading" && !searchResults ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" sx={{ textAlign: "center", marginY: status === "loading" ? 0 : "29px" }}>
              {status === "loading" ? `Loading, please wait...` : <></>}
            </Typography>
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={eventFilter.pageNumber}
                rowsPerPage={eventFilter.pageSize}
                totalRows={searchResults?.totalResults}
                changePage={changePage}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No events to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you want to delete ${event?.name}? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" color="secondary" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={() => {
                handleDelete(event?.id!);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AdminEventsTable;
